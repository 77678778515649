import React, {useEffect, useRef, useState} from 'react';


import './style.less';

const IconContainerWithDescription = ({icon, label, text, disabled, smallDesc, className = " ", capitalizeFirstLetter = true, hideColon = true, pullDownText = false}) => {

    const ref = useRef();

    const [isOverflowed, setIsOverflowed] = useState(false);

    useEffect(() => {
        setIsOverflowed(ref.current?.offsetWidth < ref.current?.scrollWidth)
    }, [ref]);

    return (
        <div className={'IconContainerWithDescription ' + className + (disabled ? ' disabled-icon-container' : '')}>
            <div className={'iconContainer flexInlineCenter'}>
                <img src={icon} alt={"icon"}/>
            </div>
            <div className={'description-wrapper' + (smallDesc ? ' overflowable' : '')} ref={ref}>
                <label>{label + (text && !hideColon ? ':' : '')}</label>
                <p  className={(capitalizeFirstLetter ? 'capitalize' : '')+(pullDownText ? ' pull-down ' : '')+((smallDesc && isOverflowed) ? ' small-desc' : '')}>{text}</p>
            </div>
        </div>
    )
}

export default IconContainerWithDescription;
