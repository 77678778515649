import React, {useRef, useState} from 'react';

import './style.less';

const PriceComponent = ({
                            disabled = false,
                            forceShowTag = false,
                            name,
                            value,
                            error,
                            pageLabel,
                            watchValue,
                            register,
                            rules,
                            placeholder = '20€',
                            className,
                            onChange,
                            integrationKey,
                            ...input
                        }) => {
    const [inputFocused, setInputFocused] = useState(false);

    watchValue = watchValue === null || watchValue === undefined ? value : watchValue;

    const onBlur = () => {
        setInputFocused(false);
        if (input.onBlur) input.onBlur();
    }

    const onFocus = () => {
        setInputFocused(true);
    }

    function resizeInput() {
        return (watchValue||forceShowTag) ? (Math.ceil(Math.log10((watchValue||forceShowTag) + 1)) + 0.5) + 'ch' : '';
    }

    const componentRef = useRef();
    const inputRef = useRef(null);

    const maxWidth = componentRef.current ? (componentRef.current.offsetWidth - 30) + 'px' : undefined;

    document.getElementById('roomPrice')?.addEventListener('keypress', preventDotsAndCommas);

    function preventDotsAndCommas(e){
        if (e.charCode == 46) e.preventDefault();
        if (e.charCode == 44) e.preventDefault();
    }

    return (
        <div
            className={`PriceComponent basic_container hover_focus_border input_field ${error ? 'input_error' : null} ${disabled ? 'disabled' : null} ${inputFocused ? 'focus' : ''}`}
            ref={componentRef} onClick={() => {
            if (!integrationKey) inputRef.current.focus()
        }}>
            <div>
                <input type="number" onWheel={(e) => e.target.blur()} style={{maxWidth: maxWidth, width: resizeInput()}}
                       name={name}
                       id={"roomPrice"}
                       defaultValue={value ? value : ''}
                       disabled={disabled || integrationKey} placeholder={placeholder}
                       ref={(e) => {
                           if (register) register(e, {valueAsNumber: true, ...rules})
                           inputRef.current = e;
                       }} {...input} onFocus={onFocus} onBlur={onBlur}
                       onChange={(value) => {
                           if (onChange) onChange(value.target.valueAsNumber)
                       }
                       }/>
                {(watchValue > 0 || (forceShowTag && watchValue !== undefined && watchValue !== null)) && <p className={"euro-tag"}> €</p>}
            </div>
            {error && <p className={'error_message'}>{error.message}</p>}
        </div>
    )
}

export default PriceComponent;
