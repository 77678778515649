import {useTranslation} from 'react-i18next';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {connect} from 'react-redux';

import './style.less'
import {propertyAdHistoryActions} from '../../../../../../../../redux/actions';

const Buttons = ({
                   clickBack,
                   clickAdvance,
                   updateProperty,
                   disableUpdate,
                   executeOnAdvance,
                   currentPageLevel,
                   pageLabel,
                   propertyAdHistory,
                   addHistoryPop,
                   changePage,
                   saveAndExit
                 }) => {
  const {t, i18n} = useTranslation();

  return (
    <div className={'Buttons'}>
      <button type={'button'} disabled={propertyAdHistory.history.length < 2} className={'btn btn_white'}
              onClick={() => {
                clickBack ? clickBack() : null
                if (changePage) {
                  const previousPage = propertyAdHistory.history[propertyAdHistory.history.length - 2]
                  addHistoryPop(2)
                  changePage(previousPage.level, previousPage.room);
                }
              }}>
        <p>{t('buttons.back')}</p>
      </button>

      {saveAndExit && <button type={'button'} className={'btn btn_white'} onClick={saveAndExit} disabled={disableUpdate}>
        <p>{t('buttons.save_exit')}</p>
      </button>}

      {currentPageLevel !== '3.1.2' && <button className={'btn btn_blue submit'} onClick={() => {
        updateProperty && updateProperty({...clickAdvance});
        if (executeOnAdvance) executeOnAdvance()
      }} disabled={disableUpdate}>
        <p>{t('buttons.next_step')}</p>
      </button>}

      {currentPageLevel === '3.1.2' &&
       <button id={'publish_button'} className={'btn btn_blue'} disabled={disableUpdate}
               onClick={() => executeOnAdvance(true)}>
         <p>{t(pageLabel + '.publish_button')}</p>
       </button>}

    </div>
  )
}

function mapStateToProps(state) {
  return {propertyAdHistory: state.propertyAdHistory};
}

const actionCreator = {
  addHistoryPop: propertyAdHistoryActions.pop
};

export default connect(mapStateToProps, actionCreator)(Buttons);
