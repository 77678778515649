import { userConstants } from "../constants";

const stateInital = { loggedIn: false, loginChecked: false };

export function login(state = stateInital, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        loggedIn: false,
        user: action.payload.email,
      };
    case userConstants.LOGIN_SUCESS:
    case userConstants.GET_USER:
      return {
        ...state,
        loginChecked: true,
        loggedIn: true,
        user: action.payload.user,
      };
    case userConstants.UPDATE_VALUES:
      return {
        ...state,
        user: {...state.user,...action.payload}
      }
    case userConstants.LOGIN_FAIL:
    case userConstants.LOGOUT:
      return {
        ...state,
        loginChecked: true,
        loggedIn: false,
        user: null
      };
    default:
      return state;
  }
}
