import React from "react";
import { useTranslation } from "react-i18next";
import CityBuildingIcon from "../../../../components/svg/CityBuilding";
import TimelineIcon from "../../../../components/svg/Timeline";
import PlaylistAddCheckIcon from "../../../../components/svg/PlaylistAddCheck";

import "./style.less";

const IntroductionContainer = (props) => {
  const { t } = useTranslation();

  const translationContent = ["advertiseFree", "maximizeGains", "efficientManagement"];
  const iconComponents = [CityBuildingIcon, TimelineIcon, PlaylistAddCheckIcon];

  return (
    <div className="IntroductionContainer">
      <div className="IntroductionContainer__title">
        <h1>Inlife,</h1>
        <h2>{t("start.introduction.rentalPlatform")}</h2>
      </div>

      <div className="IntroductionContainer__wrapper">
        {translationContent.map((option, index) => {
          const IconComponent = iconComponents[index % iconComponents.length];

          return (
            <div className="IntroductionContainer__container" key={index}>
              <div className="icon-container">
                <div className="icon">
                  <IconComponent />
                </div>
              </div>

              <div className="content">
                <h3>{t(`start.introduction.${option}.label`)}</h3>
                <p>{t(`start.introduction.${option}.description`)}</p>
              </div>
            </div>
          )
        })
        }
      </div>

    </div>
  )
}

export default IntroductionContainer;
