import React from "react";
import './style.less';

import { useTranslation } from "react-i18next";
import { isArray, isInteger } from 'lodash';
import CloseIcon from "../../svg/Close";

const AppliedFilters = ({ appliedFilters, removeFilter, clearAll }) => {
    const { t, i18n } = useTranslation();
    const filtersPath = "main_page.radar.offers_filters";

    const isDate = (filter) => {
        const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
        return dateRegex.test(filter);
    }

    if (!Object.keys(appliedFilters).filter(f => !["page", "pageSize", "searchKey"].includes(f)).length) return null;

    return (
        <div className="applied-filters">
            {Object.keys(appliedFilters).filter(f => !["page", "pageSize"].includes(f)).map((filter, index) => {
                let transDir = "main_page" + (filter === "occupation" ? '.my_visits.visit_card.occupation.' : '.radar.offers_filters.')
                let filterValue = filter === "sort" || filter === "occupation" ? t(transDir + appliedFilters[filter]) : appliedFilters[filter];
                /* Handle budgetRange filter */
                if (isArray(filterValue)) return (
                    <div key={index} className="selected-filter" onClick={() => removeFilter(filter)}>
                        {`€${filterValue[0]} - €${filterValue[1]}`}
                        <CloseIcon />
                    </div>
                )

                /* Handle numRooms filter */
                if (isInteger(filterValue)) return (
                    <div key={index} className="selected-filter" onClick={() => removeFilter(filter)}>
                        {`${filterValue} ${t(filtersPath + ".rooms")}`}
                        <CloseIcon />
                    </div>
                )

                /* Handle dates filter */
                if (isDate(filterValue)) return (
                    <div key={index} className="selected-filter" onClick={() => removeFilter(filter)}>
                        {new Date(filterValue).toLocaleDateString(i18n)}
                        <CloseIcon />
                    </div>
                )

                return (
                    <div key={index} className="selected-filter" onClick={() => removeFilter(filter)}>
                        {filterValue}
                        <CloseIcon />
                    </div>
                )
            })}

            {Object.values(appliedFilters).length > 1 ? <button className="btn btn-ghost" onClick={clearAll}>
                {t("main_page.radar.offers_filters.clearAll")}
            </button> : null}
        </div>
    )
}

export default AppliedFilters;
