export const visitServices = {
    getVisits,
    getVisit,
    acceptVisit,
    rejectVisit,
    suggestNewProperty
};

import {get, post} from "./index.js";

async function getVisits(page, filters) {
    return await get(`/visit/getLandlordVisits`, {page, filters});
}
async function getVisit(visit_id) {
    return await get(`/visit/get/`+visit_id);
}

async function acceptVisit(visit_id, selectedDate) {
    return await post('/visit/acceptVisit', {visit_id, selectedDate});
}

async function rejectVisit(form) {
    return await post('/visit/rejectVisit', form)
}

async function suggestNewProperty(fields) {
    try {
        return await post('/visit/suggestNewProperty', fields)
    } catch (e) {
        throw e;
    }
}
