import React from 'react';
import PropTypes from 'prop-types';
import "./style.less";

import { usePagination } from '../../hooks/usePagination';
import ChevronRightIcon from '../svg/Navigation/ChevronRight';
import ChevronLeftIcon from '../svg/Navigation/ChevronLeft';

const Pagination = ({ onPageChange, totalCount, siblingCount = 1, currentPage, pageSize = 8 }) => {
    const DOTS = "...";

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    /* if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    } */

    const lastPage = paginationRange[paginationRange.length - 1];

    const handleNextPage = () => {
        onPageChange(currentPage + 1);
    };

    const handlePreviousPage = () => {
        onPageChange(currentPage - 1);
    };

    return (
        <div className="pagination">
            <button className="btn btn-tertiary"
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
            >
                <ChevronLeftIcon />
                Previous
            </button>

            <div className="pagination__range">
                {paginationRange.map(pageNumber => {
                    if (pageNumber === DOTS) {
                        return <div className="btn dots">&#8230;</div>;
                    }

                    return (
                        <button
                            className={`btn ${pageNumber === currentPage && "selected"}`}
                            onClick={() => onPageChange(pageNumber)}
                            key={pageNumber}
                        >
                            {pageNumber}
                        </button>
                    ); 
            })}
            </div>

            <button
                className="btn btn-tertiary"
                onClick={handleNextPage}
                disabled={currentPage === lastPage}
            >
                Next
                <ChevronRightIcon />
            </button>
        </div>
    );
};

Pagination.propTypes = {
    totalCount: PropTypes.number.isRequired,
    siblingCount: PropTypes.number,
    currentPage: PropTypes.number.isRequired,
    pageSize: PropTypes.number,
    onPageChange: PropTypes.func.isRequired
};

export default Pagination;
