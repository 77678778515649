const toggleObjects = (value,oldValues,setValues) => {
  let index =  oldValues.indexOf(value);
  let copyValues=[...oldValues]
  if(index === -1) {
    copyValues.push(value)
    setValues(copyValues);
  }
  else {
    copyValues.splice(index,1)
    setValues(copyValues);
  }
}

export  {toggleObjects};