import React from "react";

const Assignment = (props) => (
    <svg
        width={8}
        height={8}
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle
            id="Ellipse 71"
            cx={4}
            cy={4}
            r={3}
            stroke="#1D9AFA"
            strokeWidth={2}
        />
    </svg>
);

export default Assignment;
