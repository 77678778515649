import React, {useEffect, useMemo, useRef, useState} from 'react';

import './style.less'
import { useTranslation } from 'react-i18next';
import moment from "moment";
import { clampText } from "../../../../../../../../../utils/stringFunctions";
import verifyAttributeExistenceToString
    from "../../../../../../../../../components/ResumeItem/functions/verifyAttributeExistenceToString";
import MUIMenu, { StyledMenuItem } from "../../../../../../../../../components/MUIMenu";
import calendarGrey from "./images/calendarGrey.svg";
import { propertyServices } from "../../../../../../../../../services";
import DatePicker from "react-datepicker";
import { usePopupDialog } from "../../../../../../../../../components/PopupDialog";
import RadioButtons from "../../../../../../MyAds/containers/Ad/components/RadioButtons";
import PriceComponent from "../../../../../../MyAds/containers/Ad/components/PriceComponent";
import AmountButtonsInput from "../../../../../../MyAds/containers/Ad/components/AmountButtonsInput";
import RentByMonth
    from "../../../../../../MyAds/containers/Ad/containers/room_details/RoomsMain2.2_2.4.js/components/RoomPrices/components/RentByMonth";
import {useForm} from "react-hook-form";
import IconContainerWithDescription from "../../../../../../../../../components/IconContainerWithDescription";
import Bathrooms from "../../../../../../../../../images/Bathrooms.svg";
import Bed from "../../../../../../../../../images/Bed.svg";
import Bills from "../../../../../../../../../images/Bills.svg";
import cleaningService from "../../../../../../../../../images/CleaningServices.svg";
import WalkingMetro from "../../../../../../../../../images/WalkingMetro.svg";
import WalkingSupermarket from "../../../../../../../../../images/WalkingSupermarket.svg";
import Wifi from "../../../../../../../../../images/Wifi.svg";
import Loading from "../../../../../../../../../components/Loading";
import Tooltip from '../../../../../../../../../components/Tooltip';
import InfoCircleIcon from '../../../../../../../../../components/svg/InfoCircle';
import CheckIcon from '../../../../../../../../../components/svg/Check';

const componentLabel = 'dashboard_list_item'

const pageLabel = 'main_page.my_ads.create_ad.room_details.room_prices';

const RoomResumePopOver = (props) => {

    const { t, i18n } = useTranslation();
    const label = "main_page.radar.offers_details";
    const locale = localStorage.getItem('i18nextLng').split('-')[0];
    const [roomNumber, setRoomNumber] = useState();
    const [room, setRoom] = useState();
    const [datePickerOpened, setDatePickerOpened] = useState(false);
    const [editingMode, setEditingMode] = useState(true);
    const [menuOpen, setMenuOpen] = useState(false);
    const [reason, setReason] = useState();
    const [showReasons, setShowReasons] = useState(false);
    const [calendarLoading, setCalendarLoading] = useState(false);
    const [canSelect, setCanSelect] = useState(true);
    const [roomRent, setRoomRent] = useState();

    const toggleSelectedRoom = () => {
        if (isSelected) {
            props.handleDeselectRoom(room);
        } else {
            props.handleSelectRoom(room);
        }
    }

    useEffect(() => {
        let roomValue;
        if (props.property.rooms && !roomNumber) {
            Object.entries(props.property?.rooms).forEach(([key, value]) => {
                if (props.property.rooms[key]?.id === props.room?.id || props.property.rooms[key]?.id === props.room) {
                    setRoomNumber(key.split("_")?.[1]);
                    roomValue = value;
                }
            })
        }
        setRoom(roomValue ? roomValue : props.room);
    }, [props.property, props.room]);

    useEffect(() => {
        setRoomRent(room?.rent)
    }, [room?.rent]);

    const isSelected = useMemo(() => {
        let flag = false;
        if (props.offers) {
            props.offers.forEach((o) => {
                if (flag) return;
                if (o.rooms.find((r) => {
                    return r === room?.id
                })) flag = true;
            })
        }
        return flag;
    }, [props.offers, room])


    const stay = useMemo(() => {
        if (props.tenant?.preferences?.moveOut && props.tenant?.preferences?.moveIn)
            return moment(props.tenant?.preferences?.moveOut).diff(moment(props.tenant?.preferences?.moveIn), 'months');
    }, [props.tenant?.preferences?.moveIn, props.tenant?.preferences?.moveOut]);

    const stayInDays = () => {
        if (props.tenant?.preferences?.moveOut && props.tenant?.preferences?.moveIn)
            return moment(props.tenant?.preferences?.moveOut).diff(moment(props.tenant?.preferences?.moveIn), 'days');
    }

    const handleClick = () => {
        if (!datePickerOpened && !editingMode) {
            setMenuOpen(!menuOpen);
        }
    };

    const handleClose = (e) => {
        if (anchorRef.current && anchorRef.current?.contains(e.target)) {
            return;
        }
        setMenuOpen(false);
    }

    const anchorRef = useRef();
    const menuRef = useRef(null);

    let expirationDate = useMemo(() => {
        if (props.date) {
            let date = moment(props.date).add("7", "days");
            let difDays = date.diff(Date.now(), "days");
            let difHours = date.diff(Date.now(), "hours");
            return (difDays > 0 ? (difDays + " " + (difDays === 1 ? t(label + ".day") : t(label + ".days"))) : (difHours > 0 ? (difHours + +" " + (difHours === 1 ? t(label + ".hour") : t(label + ".hours"))) : t(label + ".expired")));
        }
    }, [props.date]);

    const getSortedUnavailability = (unavailability, isByStart) => {
        return unavailability.sort((a, b) => {
            if (moment(isByStart ? b.start : b.end).startOf("day").isBefore(moment(isByStart ? a.start : a.end).startOf("day"))) return 1;
            if (moment(isByStart ? b.start : b.end).startOf("day").isAfter(moment(isByStart ? a.start : a.end).startOf("day"))) return -1;
            return 0;
        });
    }

    const getAllFilteredUnavailability = useMemo(() => {
        let all = [];
        if (room?.unavailability)
            all.push(...room?.unavailability.filter((un) => moment(un.start).startOf("day").isAfter(moment().startOf("day")) || moment(un.end).startOf("day").isAfter(moment().startOf("day"))));
        if (room?.importedUnavailability)
            all.push(...room?.importedUnavailability.filter((un) => (moment(un.start).startOf("day").isAfter(moment().startOf("day")) || moment(un.end).startOf("day").isAfter(moment().startOf("day"))) && !un.removed));
        return all;
    }, [room?.unavailability, room?.importedUnavailability]);

    const minDate = useMemo(() => {
        const all = getAllFilteredUnavailability;
        if (all && all.length > 0) {
            let minDate = new Date();
            let sortByStart = getSortedUnavailability(all, true);
            if (moment(moment()).startOf("day").diff(sortByStart[0].start, "months") >= room.periodStayMin) return new Date();
            let sortByEnd = getSortedUnavailability(all, false);
            let counter = 1;
            sortByEnd.forEach((un) => {
                if (moment(minDate).format("DD MM YYYY") !== moment().format("DD MM YYYY")) return;
                if (counter === sortByEnd.length) {
                    minDate = new Date(moment(un.end).add(1, "day").toISOString());
                    return;
                }
                if (moment(moment(un.end).add(1, "day")).startOf("day").diff(moment(sortByEnd[counter].start).startOf("day"), "months") >= room.periodStayMin) minDate = new Date(moment(un.end).add(1, "day").toISOString());
                counter++;
            });
            return minDate;
        } else {
            return new Date();
        }
    }, [room?.unavailability, room?.importedUnavailability]);

    const excludedDates = useMemo(() => {
        return getAllFilteredUnavailability.reduce((acc, unavailability) => {
            let dateArray = [];
            let currentDate = moment(unavailability.start);
            let stopDate = moment(unavailability.end);
            while (currentDate <= stopDate) {
                dateArray.push(currentDate.toDate())
                currentDate.add(1, 'days');
            }
            return [...acc, ...dateArray];
        }, []);
    }, [room?.unavailability, room?.importedUnavailability]);


    const isAvailable = useMemo(() => {
        let moveIn = moment(props.tenant.preferences.moveIn).startOf("day");
        let moveOut = moment(props.tenant.preferences.moveOut).endOf("day");
        if (room?.periodStayMin && props.tenant.preferences.stayLength < room?.periodStayMin){
            setCanSelect(false);
            setReason({ reason: t(label + ".period_min_unavailability", { count: room?.periodStayMin }), type: "period_min" });
            return false;
        }
        if (moment(room?.firstAvailability).isAfter(moment(props.tenant.preferences.moveIn))) {
            if (moment(room?.firstAvailability).add(-15, "days").isAfter(moveIn)) setCanSelect(false);
            return false;
        }
        let available = true;
        getAllFilteredUnavailability?.forEach((un) => {
            if(!canSelect) return;
            let startMoment = moment(un.start).startOf("day");
            let endMoment = moment(un.end).startOf("day");
            if (moveIn.isBetween(startMoment, endMoment)) {
                if ((moveIn.diff(endMoment, "days") <= -15 && moveIn.diff(endMoment, "days") >= 0)) setCanSelect(false);
                available = false;
                setReason(un);
            }
            if (moveOut.isBetween(startMoment, endMoment)) {
                if (moveOut.diff(startMoment, "days") >= 15 && moveOut.diff(startMoment, "days") <= 0) setCanSelect(false);
                available = false;
                setReason(un);
            }
            if (moveIn.isBefore(startMoment) && startMoment.diff(moveIn, "month") < room?.periodStayMin) {
                available = false;
                un["reason"] = t(label + ".period_min_unavailability", { count: room?.periodStayMin });
                setReason(un);
            }
        });
        return available;
    }, [props.tenant, props.room, room?.unavailability, room?.importedUnavailability, room, room?.firstAvailability]);

    const EditRoom = () => {

        const popupDialog = usePopupDialog();

        const prices = useMemo(() => {
            if (room) {
                return propertyServices.getPricesInterval(room, props.property);
            }
        }, [room]);

        const { register, errors, watch, control, setValue, getValues } = useForm({
            shouldFocusError: true,
            mode: 'onChanges',
            reValidateMode: 'onChange'
        });

        const SimpleDatePicker = () => {

            const handleChange = async (date) => {
                setCalendarLoading(true);
                setDatePickerOpened(false);
                let tempRoom = room;
                tempRoom["firstAvailability"] = date.toISOString();
                setRoom(tempRoom);
                let response = await propertyServices.updateAvailableFrom(props.property.id, roomNumber, date.toISOString());
                if (response.status === "success") {
                    tempRoom["firstAvailability"] = response.firstAvailability;
                    setRoom(tempRoom);
                }
                setCalendarLoading(false);
                setDatePickerOpened(false);
            }

            return (<div className="simple-datepicker-revamped">
                <DatePicker
                    excludeDates={excludedDates}
                    onChange={async (date) => {
                        await handleChange(date);
                    }}
                    selected={new Date(room.firstAvailability)}
                    showPreviousMonths={false}
                    locale={localStorage.getItem('i18nextLng').split('-')[0]}
                    dateFormat={'dd MMM yyyy'}
                    formatWeekDay={nameOfDay => nameOfDay.substr(0, 1).toUpperCase()}
                    popperPlacement='bottom-start'
                    dayClassName={date =>
                        moment(date).isBefore(moment(minDate), 'day') ? 'passed_day' : undefined
                    }
                    minDate={minDate}
                    onClickOutside={() => setDatePickerOpened(false)}
                />
            </div>)
        }

        const EditModal = () => {

            const [fixedRent, setFixedRent] = useState(room?.fixedRent);
            const [rent, setRent] = useState(room?.rent);
            const [rentMonths, setRentMonths] = useState(room?.rentMonths);
            const [discount, setDiscount] = useState(room?.discount);

            useEffect(() => {
                setValue("rentMonths", rentMonths)
            }, [rentMonths])

            return (
                <div className={"editModal"}>
                    <div className={'input_container'}>
                        <label htmlFor={'fixedRent'}>{`${t(pageLabel + '.fixedRent.label')}`}</label>
                        <RadioButtons radioButtons={[{id: 'fixedRent', buttonValue: 'true', label: 'yes'},
                            {id: 'no_fixedRent', buttonValue: 'false', label: 'no'}]} name={'fixedRent'}
                                      value={fixedRent}
                                      className={'double'} control={control} rules={{required: true}}
                                      setFixedRent={((e) => {
                                          setFixedRent(e.target.value)
                                      })}
                                      onChange={((e) => {
                                          setFixedRent(e.target.value)
                                      })}/>
                    </div>

                    <div className={'input_container_double input_info'}>
                        <div className={'input_container'}>
                            <label htmlFor={'rent'}>{t(pageLabel + '.rent.label')}</label>
                            <PriceComponent name="rent" type="number"
                                            value={rent ? rent : ''}
                                            onChange={(value) => {
                                                setRent(value);
                                            }}
                                            onWheel={(e) => e.target.blur()}
                                            placeholder={`€400`}
                                            register={register}
                                            rules={{
                                                required: `${t(pageLabel + '.rent.error')}`,
                                                valueAsNumber: true
                                            }}
                                            className={`${errors.rent ? 'input_error' : null} input_field  basic_container`}/>
                            {errors.rent && <p className={'error_message'}>{errors.rent.message}</p>}
                        </div>


                        <div className={'input_container'}>
                            <label htmlFor={'discount'}>{`${t(pageLabel + '.discount.label')}`}</label>
                            <AmountButtonsInput name={'discount'} value={discount ? discount : 0} placeholder={'0%'}
                                                min={0}
                                                id={'discount'}
                                                watchValue={watch('discount')} setValue={setValue}
                                                amount={1}
                                                className={`${errors.discount ? 'input_error' : null} input_field`}
                                                register={register({valueAsNumber: true})}
                                                onChange={(e) => {
                                                    setDiscount(e.target.value);
                                                }}/>
                            {errors.discount && <p className={'error_message'}>{errors.discount.message}</p>}
                        </div>
                        {<p className={'info'}>{t(pageLabel + '.rent.info2')}</p>}
                    </div>

                    {fixedRent === "false" &&
                    <RentByMonth fixedPrice={rent} rent={props.room.rent} t={t} register={register}
                                 name={"rentMonths"}
                                 errors={errors}
                                 rentMonths={rentMonths}
                                 setRentMonths={(value) => {
                                     setRentMonths(value)
                                 }}
                                 pageLabel={pageLabel}
                                 onChange={((e) => {
                                     setRentMonths(e.target.value)
                                 })}/>}

                    <div className={'input_container'}>
                        <label htmlFor={'fixedRent'}>{`${t(pageLabel + '.availableFrom.required')}`}</label>
                        <SimpleDatePicker />
                    </div>
                </div>
            )
        }

        const changePrices = async () => {
            let property = props.property;
            popupDialog.openPopupDialog({
                title: t('main_page.my_ads.create_ad.room_details.room_prices.rent.title'),
                message: <EditModal />,
                request: async () => {
                    let values = getValues();
                    let fields = {};
                    Object.entries(values).forEach(([key, value]) => {
                        if (room[key] !== value && key !== "fixedRent") fields[key] = value;
                        if(key === "fixedRent" && room[key] !== !!value) fields[key] = !!value;
                    });
                    if (Object.values(fields).length > 0) {
                        property = (await propertyServices.updateRoomDetails(fields, props.property.id, ("room_"+roomNumber))).property;
                    }
                    setRoom(property.rooms[("room_"+roomNumber)]);
                }
            });
        }

        return (<button className="btn btn-secondary" onClick={() => changePrices()}>
            {t(label + ".edit")}
        </button>)
    }

    const prices = useMemo(() => {
        if (room) {
            return propertyServices.getPricesInterval(room, props.property);
        }
    }, [room]);

    const sectionLabel = "property_details";

    let numBathrooms = props.property?.numBathroom;
    Object.values(props.property.rooms).forEach(room => {
        if (room.privateBathroom) numBathrooms++;
    });

    let containerWithDescriptionsList = [
        {
            icon: cleaningService,
            label: 'cleaningServices',
            hidden: (!props.property?.cleaningLady || props.property?.cleaningLady === "no")
        },
        {icon: Bills, label: 'billsIncluded', hidden: !props.property?.billsIncluded},
        { icon: Wifi, label: 'wifi', hidden: (!props.property?.wifi || props.property?.wifi === "no") },
        {
            icon: Bed,
            label: 'bedrooms',
            text: props.property?.bedrooms,
            hidden: props.property?.accommodation === "residence"
        },
        {
            icon: Bathrooms,
            label: 'numBathroom',
            text: numBathrooms,
            hidden: props.property?.accommodation === "residence"
        },
        { icon: WalkingMetro, label: 'distMetro', text: props.property?.distMetro + ' min' },
        { icon: WalkingSupermarket, label: 'distSupermarket', text: props.property?.distSupermarket + ' min' },
    ].filter(c => !c.hidden);

    const ref = useRef();

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current?.contains(event.target)) {
                setShowReasons(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    return (
        <div className={"roomResume " + (props.type === "offer" ? ("offer" + (props.offerOpen === props.offerID ? "" : " rooms")) : ((isSelected ? "selected" : "") + (!canSelect ? " roomResumeUnavailable" : "")))}>
            <div className="room-resume__photo"
                style={{ "backgroundImage": `url(${props.room.photos?.featured?.md})` }}
            >
                {!isAvailable && props.type !== "offer" ? <div className="room__blocked">
                    <div className="status">
                        {t(label + ".unavailable")}
                        {reason ? <Tooltip position='right'>
                            <Tooltip.Icon>
                                <InfoCircleIcon width={16} height={16} />
                            </Tooltip.Icon>
                            <Tooltip.Content>
                                <p>{reason.reason}</p>
                                {reason.type !== "period_min" &&
                                    <p>{t(label + ".unavailability", {
                                        start: moment(reason.start).format("DD/MM/yyyy"),
                                        end: moment(reason.end).format("DD/MM/yyyy")
                                    })}</p>}
                            </Tooltip.Content>
                        </Tooltip> : null}
                    </div>
                </div> : null}
            </div>
            <div className="room-resume__content">
                <div className={"roomResume--title"}>
                    {(props.type === "offer" && props.room.title) ? <>
                        <h5>{clampText(props.room.title[locale], 60)}</h5>
                        <h5>{props.address}</h5>
                    </> : <>
                        <h5>{clampText(verifyAttributeExistenceToString(props.room, 'internalName'), 60)}</h5>
                        <h5>{props.address}</h5>
                        </>}

                    {props.type !== "offer" && <>
                        <p>ID {props.room.id?.split("-")[0].toUpperCase()} | {t(label + (props.room.numPeople > 1 ? ".max_people_plural" : ".max_people"), { "num": props.room.numPeople })}</p>
                    </>}
                    {props.type === "offer" && <div className={"info"}>
                            <div className={'detailsSection'}>
                                {containerWithDescriptionsList.map(container => {
                                    return (<IconContainerWithDescription key={container.label} icon={container.icon}
                                                                          label={t(`${sectionLabel}.${container.label}`)}
                                                                          text={container.text}/>)
                                })}
                            </div>
                    </div>}
                    {props.type === "offer" && <div className="roomResume--rent_container">
                            <div className={"rent"}>
                                <p>{t('main_page.my_visits.visit_card.monthly_rent')}</p>
                                <div>
                                    {prices &&
                                    <h4> {prices} </h4>}
                                    {props.property.billsIncluded &&
                                    <p>{t(label + ".bills_included")}</p>}
                                </div>
                            </div>
                        <div>
                            {room?.periodStayMin &&
                                <p><img src={calendarGrey} alt={"calendar-icon"}/>
                                    <strong>{t(label + ".min_stay")}:</strong>{props.room?.periodStayMin} {t(label + (props.room?.periodStayMin === 1 ? ".month" : ".months"))}
                                </p>}
                            {room?.firstAvailability &&
                                <p className={"available"}>{t(label + ".available_from")}: {moment(room?.firstAvailability).format("DD/MM/yyyy")}</p>}
                        </div>
                    </div>}
                </div>

                {props.type !== "offer" && <div className="room-resume__price">
                    <div>
                        <p>{t(label + ".price")}:</p>
                        <h5>{`€${roomRent}`}</h5>
                    </div>
                    {!isAvailable && <div>
                        <p>{t(label + ".availableFrom")}:</p>
                        <h5 className='availability'>{new Date(props.room.firstAvailability).toLocaleDateString(i18n)}</h5>
                    </div>}
                </div>}


                {props.type !== "offer" && <div className={"roomResume--actions"}>
                    <EditRoom editingMode={editingMode} setEditingMode={setEditingMode} />
                    {/* <button className="btn btn-secondary" onClick={() => setEditingMode(!editingMode)}>
                        {t(label + ".edit")}
                    </button> */}
                    {!isSelected ? <button className="btn btn-primary"
                        disabled={!isAvailable}
                        onClick={() => { props.handleClickRoom(props.room, isSelected); toggleSelectedRoom() }}
                    >
                        {t(label + ".select")}
                    </button> : <button className="btn btn-ghost"
                        disabled={!isAvailable}
                        onClick={() => { props.handleClickRoom(props.room, isSelected); toggleSelectedRoom() }}
                    >
                        <CheckIcon />
                        {t(label + ".selected")}
                    </button>}

            </div>}

            </div>

        </div>
    )
}

export default RoomResumePopOver;
