import React from "react";

const Condominium = (props) => (
    <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g fillRule="evenodd" clipRule="evenodd" fill="#F0F3F6">
            <path d="M3.253 3.741c0-.133.108-.241.241-.241h9.012c.133 0 .241.108.241.241v9.012a.241.241 0 0 1-.241.241H3.494a.241.241 0 0 1-.241-.241V3.74Zm.483.242v8.528h8.528V3.983H3.736Z" />
            <path d="M4.54 7.603a.24.24 0 0 1 .242-.24h1.287a.24.24 0 0 1 .241.24v1.288a.241.241 0 0 1-.241.241H4.782a.241.241 0 0 1-.242-.241V7.603Zm.483.242v.804h.805v-.804h-.805Zm2.092-.242a.24.24 0 0 1 .241-.24h1.288a.24.24 0 0 1 .241.24v1.288a.241.241 0 0 1-.241.241H7.356a.241.241 0 0 1-.241-.241V7.603Zm.483.242v.804h.804v-.804h-.804Zm2.092-.242a.24.24 0 0 1 .241-.24h1.287a.24.24 0 0 1 .242.24v1.288a.241.241 0 0 1-.242.241H9.931a.241.241 0 0 1-.241-.241V7.603Zm.482.242v.804h.805v-.804h-.805Zm2.092-.242a.24.24 0 0 1 .242-.24h1.287c.133 0 .242.107.242.24v1.288a.241.241 0 0 1-.242.241h-1.287a.241.241 0 0 1-.242-.241V7.603Zm.483.242v.804h.805v-.804h-.805Zm-.483 2.333c0-.133.108-.241.242-.241h1.287c.133 0 .242.108.242.241v1.287a.241.241 0 0 1-.242.242h-1.287a.241.241 0 0 1-.242-.242v-1.287Zm.483.241v.805h.805v-.805h-.805ZM4.54 5.029c0-.134.108-.242.242-.242h1.287c.133 0 .241.108.241.242v1.287a.241.241 0 0 1-.241.241H4.782a.241.241 0 0 1-.242-.24V5.028Zm.483.241v.805h.805V5.27h-.805Zm2.092-.241c0-.134.108-.242.241-.242h1.288c.133 0 .241.108.241.242v1.287a.241.241 0 0 1-.241.241H7.356a.241.241 0 0 1-.241-.24V5.028Zm.483.241v.805h.804V5.27h-.804Zm2.092-.241c0-.134.108-.242.241-.242h1.287c.134 0 .242.108.242.242v1.287a.241.241 0 0 1-.242.241H9.931a.241.241 0 0 1-.241-.24V5.028Zm.482.241v.805h.805V5.27h-.805Zm-3.701 4.908c0-.133.108-.241.242-.241h2.574c.134 0 .242.108.242.241v2.575a.241.241 0 0 1-.242.241H6.713a.241.241 0 0 1-.242-.241v-2.575Zm.483.241v2.092h2.092V10.42H6.954Z" />
            <path d="M8 9.937c.133 0 .241.108.241.241v2.575a.241.241 0 0 1-.482 0v-2.575c0-.133.108-.241.241-.241Zm4.264-3.621c0-.133.108-.241.242-.241h2.253c.133 0 .241.108.241.241v6.437a.241.241 0 0 1-.241.241h-2.253a.241.241 0 0 1-.242-.241V6.316Zm.483.241v5.954h1.77V6.558h-1.77ZM1.966 7.603a.24.24 0 0 1 .24-.24h1.288a.24.24 0 0 1 .242.24v1.288a.241.241 0 0 1-.242.241H2.207a.241.241 0 0 1-.241-.241V7.603Zm.482.242v.804h.805v-.804h-.805Zm-.482 2.333a.24.24 0 0 1 .24-.241h1.288c.134 0 .242.108.242.241v1.287a.241.241 0 0 1-.242.242H2.207a.241.241 0 0 1-.241-.242v-1.287Zm.482.241v.805h.805v-.805h-.805Z" />
            <path d="M1 6.316c0-.133.108-.241.241-.241h2.253c.134 0 .242.108.242.241v6.437a.241.241 0 0 1-.242.241H1.241A.241.241 0 0 1 1 12.753V6.316Zm.483.241v5.954h1.77V6.558h-1.77Z" />
        </g>
    </svg>
);

export default Condominium;
