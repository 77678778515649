import React from 'react';
import {useTranslation} from 'react-i18next';

import './style.less'

import FacebookIcon from "../../../../components/svg/SocialMedia/Facebook";
import AppleIcon from "../../../../components/svg/SocialMedia/Apple";
import GoogleIcon from "../../../../components/svg/SocialMedia/Google";

const SocialAccountButtons = (props) => {
  const {t} = useTranslation();

  return (
    <div className="SocialAccountButtons">
      <div className='button-container'>
        <form action="/api/auth/landlord/facebook" method="GET">
          <button type="submit" id={'btn_facebook'} className="btn btn-secondary">
            <FacebookIcon />
            Facebook
          </button>
        </form>
      </div>

      {/* <div className='button-container'>
        <form>
          <button type="submit" id={'btn_apple'} disabled={true} className="btn btn-secondary">
            <AppleIcon />
            Apple
          </button>
        </form>
      </div> */}

      <div className='button-container'>
        <form action="/api/auth/landlord/google" method="GET">
          <button type="submit" id={'btn_google'} className="btn btn-secondary">
            <GoogleIcon />
            Google
          </button>
        </form>
      </div>
    </div>
  )
}

export default SocialAccountButtons;
