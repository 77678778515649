import React, {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {NavLink, useHistory} from 'react-router-dom';
import 'react-phone-number-input/style.css'
import './style.less';
import SocialAccountButtons from '../../components/SocialAccountButtons';
import {useAuth} from "../../../../services/authServices";
import CountryAndPhoneSelect from '../../../../components/CountryAndPhoneSelect';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: 'none',
    color: state.isSelected ? 'blue' : 'black',
    textAlign: 'left',
    padding: '0px',
    border: 'none',
    width: '95%',
    paddingLeft: '5px',
    margin: '0px'
  }),
  control: (base) => ({
    ...base,
    border: 0,
    // This line disable the blue border
    boxShadow: 'none',
    height: '20',
    maxHeight: '30px',
    width: '100%',
    margin: '0px'
  }),
  singleValue: (provided, state) => ({
    opacity: state.isDisabled ? 0.5 : 1,
    transition: 'opacity 300ms',
    color: 'black',
    width: '100%',
    height: '20',
    paddingLeft: '5px',
    margin: '0px'
  }),
  valueContainer: (provided, state) => ({
    padding: '0px',
    margin: '0px'
  })
};

const SignUpContainer = () => {
  const auth = useAuth();
  const {t} = useTranslation();
  let history = useHistory();

  const [phoneNumber, setPhoneNumber] = useState()
  const [country, setCountry] = useState('PT');
  const [dialCode, setDialCode] = useState('+351');
  let [errosReq, setErrosReq] = useState([]);

  const loading = useRef(false);

  const onSubmit = async (values) => {
    if(loading.current) return;
    loading.current = true;
    setErrosReq([]);
    const countryAbbreviation = country.toLowerCase();
    values.phone = values.phone.replace(dialCode, "");
    values.dialCode = dialCode;
    try {
      await auth.signup({
            ...values,
            firstName: values.first_name,
            lastName: values.last_name,
            country: countryAbbreviation,
            language: localStorage.getItem('i18nextLng')?.split('-')[0] || "en"
          }
      );
      history.push("/start/verification");
    } catch (errs) {
      if(errs[0]?.errors) errs = errs[0]?.errors.map(e => e.msg);
      console.log(errs)
      setErrosReq(errs);
    }
    loading.current = false;
  }

  const {handleSubmit, control, register, errors, watch, clearErrors, setValue} = useForm({shouldFocusError: true});

  return (
    <div className="SignUpContainer">

      <SocialAccountButtons/>
      <div className="with-email-separator-container">
        <hr className="separator-line" />
        <p>{t("start.signup.orEmail")}</p>
        <hr className="separator-line" />
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={'base-info row'}>
          <div className="input-container">
            <label htmlFor={'first_name'}>{`${t('start.signup.first_name')}`}</label>
            <input
              name="first_name"
              placeholder={`${t('start.signup.first_name')}`}
              className={`${errors.first_name ? 'input_error' : null} input_field`}
              ref={register({
                required: `${t('start.signup.required.first_name')}`,
                minLength: {
                  value: 2,
                  message: `${t('start.signup.min_length.first_name')}`
                },
                pattern: {
                  value: /^([a-zA-Z]|[à-ú]|[ ]|[À-Ú])+$/,
                  message: `${t('start.signup.pattern.first_name')}`
                }
              })}
            />
            {errors.first_name && <p className={'error_message'}>{errors.first_name.message}</p>}
          </div>
          <div className="input-container">
            <label htmlFor={'last_name'}>{`${t('start.signup.last_name')}`}</label>
            <input
              name="last_name"
              placeholder={`${t('start.signup.last_name')}`}
              className={`${errors.last_name ? 'input_error' : null} input_field`}
              ref={register({
                required: `${t('start.signup.required.last_name')}`,
                minLength: {
                  value: 2,
                  message: `${t('start.signup.min_length.last_name')}`
                },
                pattern: {
                  value: /^([a-zA-Z]|[à-ú]|[ ]|[À-Ú])+$/,
                  message: `${t('start.signup.pattern.last_name')}`
                }
              })}
            />
            {errors.last_name && <p className={'error_message'}>{errors.last_name.message}</p>}
          </div>
        </div>
          <CountryAndPhoneSelect country={country} setCountry={setCountry} setDialCode={setDialCode} control={control}
                                 phoneNumber={phoneNumber} setValue={setValue} setPhoneNumber={setPhoneNumber} errors={errors}
                                 errorsReq={errosReq}
          clearCountryError={() => clearErrors('country')} />
        <div className={'signup__email'}>
          <div className="input-container">
            <label htmlFor={'email'}>{`${t('start.signup.email')}`}</label>
            <input
              name="email"
              placeholder={`${t('start.signup.email_placeholder')}`}
              className={`${(errors.email || errosReq.includes('err-user_already_exists')) ? 'input_error' : null} input_field`}
              ref={register({
                required: `${t('start.signup.required.email')}`,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: `${t('start.signup.pattern.email')}`
                }
              })}
            />
            {errors.email && <p className={'error_message'}>{errors.email.message}</p>}
            {errosReq.includes('err-user_already_exists') &&
             <p className={'error_message'}>{t('error.user_already_exists')}</p>}
          </div>
        </div>
        <div className={'base-info row'}>
          <div className="input-container">
            <label htmlFor={'password'}>{`${t('start.signup.password')}`}</label>
            <input
              name="password"
              type="password"
              placeholder={`${t('start.signup.password_placeholder')}`}
              className={`${errors.password ? 'input_error' : null} input_field`}
              ref={register({
                required: `${t('start.signup.required.password')}`,
                minLength: {
                  value: 6,
                  message: `${t('start.signup.min_length.password')}`
                }
              })}
            />
            {errors.password && <p className={'error_message'}>{errors.password.message}</p>}
          </div>
          <div className="input-container">
            <label htmlFor={'confirm_password'}>{`${t('start.signup.confirm_password')}`}</label>
            <input
              name="confirm_password"
              type="password"
              placeholder={`${t('start.signup.confirm_password')}`}
              className={`${errors.confirm_password ? 'input_error' : null} input_field`}
              ref={register({
                required: `${t('start.signup.required.confirm_password')}`,
                validate: (value) =>
                  value === watch('password') || `${t('start.signup.required.password_dont_match')}`
              })}
            />
            {errors.confirm_password && <p className={'error_message'}>{errors.confirm_password.message}</p>}
          </div>
        </div>

        <div id="terms_conditions">
          <p>{t("terms.by_signing_up")} <a href="https://inlifehousing.com/terms-conditions/" className={"link link_blue"}>{t("terms.terms_and_conditions")}</a> {t("and")} <a href="https://inlifehousing.com/privacy-policy/" className={"link link_blue"}>{t("terms.privacy_policy")}</a>.</p>
        </div>
        <button type="submit" disabled={loading.current} className={'btn btn_blue'}>{t('start.signup.button')}</button>

        <div id="actions">
          <p>{t('start.signup.have_account')}</p>
          <NavLink to="login" className={'blue-link-label'}>{t('start.signup.login')}</NavLink>
        </div>

      </form>
    </div>
  )
}


export default SignUpContainer;
