import React, { useMemo, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {FlagIcon} from 'react-flag-kit';

import avatarFemale from "../../../VisitCard/images/avatar_female.png";
import avatarMale from "../../../VisitCard/images/avatar_male.png";

import './style.less'
import moment from "moment";

import NotesIcon from '../../../svg/Notes';
import VideocamIcon from '../../../svg/Videocam';
import InfoCircleIcon from '../../../svg/InfoCircle';
import Tooltip from "../../../Tooltip"
import { useHistory } from 'react-router-dom';
import { isMobile } from '../../../../utils/responsive';
import Drawer from '../../../Drawer';

const componentLabel = 'dashboard_list_item'

const RoundedCircleFlag = ({code}) => {

  return (
    <div className={'RoundedCircleFlag'}>
      <div className={'CountryFlagCircle'}
           style={{backgroundImage: `url(https://github.com/madebybowtie/FlagKit/raw/master/Assets/PNG/${code}@2x.png?raw=true)`}}/>
    </div>
  )
}

function ageLabel(age) {
  const {t} = useTranslation();
  let ageGroup = {'kid': '<18', 'young': '19-25', 'youngadult': '26-35', 'adult': '36-49', 'old': '50+'}[age];
  return `${ageGroup} ${t('years')}`
}

function getAge(birthday) {
  if (birthday) {
    let split = birthday.split("-");
    const { t } = useTranslation();
    return (split && split.length > 0) ? `${(moment().year() - split[split.length - 1])} ${t('years')}` : "";
  }
}

const TenantInfo = ({ tenant, move_details, type, nameStyle, listType }) => {

  const { t } = useTranslation();
  const history = useHistory();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const stay = useMemo(() => {
    if (!move_details) return "";
    if (move_details?.stay) return move_details.stay;
    return moment(move_details?.move_out).diff(moment(move_details?.move_in), 'months');
  }, [move_details]);

  const stayInDays = () => {
    if (move_details?.move_out && move_details?.move_in) {
      let splitMoveIn = move_details?.move_in.split("/");
      let moveIn = splitMoveIn?.[2] + "-" + splitMoveIn?.[1] + "-" + splitMoveIn?.[0];
      let splitMoveOut = move_details?.move_out.split("/");
      let moveOut = splitMoveOut?.[2] + "-" + splitMoveOut?.[1] + "-" + splitMoveOut?.[0];
      return moment(moveOut, "YYYY/MM/DD").diff(moment(moveIn, "YYYY/MM/DD"), 'days');
    }
  }

  if(!tenant) return null;

  const openTenantDetails = (e) => {
    e.preventDefault();
    history.push(`/radar/${tenant.id}/details/`);
  }

  return (
    <div className={(`TenantInfo ${type}` + (listType === "offer" ? " noMaxWidth" : ""))}>

      <div className="tenant__photo avatar-container"
        style={{ backgroundImage: `url(${tenant.photo || (tenant.personalDetails?.gender === "female" ? avatarFemale : avatarMale)})` }}
        onClick={openTenantDetails}
      >
        {tenant.personalDetails?.nationality && <RoundedCircleFlag code={tenant.personalDetails?.nationality.toUpperCase()} />}
      </div>

      <div className="tenant__info">
        <div className="tenant__info__name">
          <h4 onClick={openTenantDetails}>{tenant.name.split(" ")[0]}</h4>
          <div className="tenant__info__icons" onClick={openTenantDetails}>
            {tenant?.personalDetails?.extraInfo && <div className="icon">
              <NotesIcon />
            </div>}
            {tenant?.tenantVideo && <div className="icon" onClick={openTenantDetails}>
              <VideocamIcon />
            </div>}
          </div>
        </div>

        <div className="tenant__personal-details">
          {`${t('gender.' + tenant.personalDetails?.gender)} · 
            ${(tenant.personalDetails?.age ? ageLabel(tenant.personalDetails?.age) : (tenant.personalDetails?.birthdate ? getAge(tenant.personalDetails?.birthdate) : ""))} · 
            ${tenant.personalDetails?.occupation && t(componentLabel + "." + tenant.personalDetails?.occupation)}`}
        </div>
        {tenant?.offers !== undefined && <div className={`tenant__offers-sent ${tenant?.offers.length > 0 ? "sent" : ""}`}>
          {isMobile ? <>
            <InfoCircleIcon onClick={() => setIsDrawerOpen(!isDrawerOpen)} />
            <Drawer isOpen={isDrawerOpen}
              onClose={() => setIsDrawerOpen(!isDrawerOpen)}>
              {tenant?.offers?.length === 0 ?
                t(`${componentLabel}.noOffersSent`) :
                <Trans i18nKey={`${componentLabel}.offersSentContent`} values={{ offersSent: tenant.offers.length, offersLeft: 3 - tenant.offers.length }} />
              }
            </Drawer>
          </> : <Tooltip>
            <Tooltip.Icon>
              <InfoCircleIcon />
            </Tooltip.Icon>
            <Tooltip.Content>
              {tenant?.offers === 0 ?
                t(`${componentLabel}.noOffersSent`) :
                <Trans i18nKey={`${componentLabel}.offersSentContent`} values={{ offersSent: tenant.offers.length, offersLeft: 3 - tenant.offers.length }} />
              }
              {/* <p>{t(componentLabel + '.offersSent')}</p> */}
            </Tooltip.Content>
          </Tooltip>}

          {`${tenant.offers?.length}/${t(`${componentLabel}.numOffersSent`)}`}
        </div>}

        {move_details && <div className={'RequestDates'}>
            {listType !== "PotentialTenants" &&
                <>
                    <p className={'small'}>
              <span>{t(componentLabel + '.entry_date')}:</span> {move_details.move_in?.replace(/ /g, "/")}</p>
                    <p className={'small'}>
              <span>{t(componentLabel + '.exit_date')}:</span> {move_details.move_out?.replace(/ /g, "/")}</p>
                </>}
          {/* {(move_details && listType !== "offer") &&
                <p className={'small'}>
                    <span>{t(componentLabel + '.totalStay') + ': '}</span>
                  {tenant.preferences.stayLength ? (tenant.preferences.stayLength + " " + (stay > 1 ? t('months') : t('month')))
                      : `${stay > 0 ? stay : stayInDays()}
                  ${stay > 0 ? (stay > 1 ? t('months') : t('month')) : (stayInDays() > 1 ? t('days') : t('day'))}`}
                </p>} */}
            {(listType !== "PotentialTenants" && listType !== "offer") &&
                <p className={'small link link_blue'}>{t(componentLabel + '.future_payments_details')}</p>}
          </div>}
        {/* <p className={'small mobile'}>
          <span>{t(componentLabel + '.registration_date')}</span>&nbsp;{moment(tenant.registrationDate).format("DD/MM/YYYY")}
        </p> */}
        </div>

    </div>
  )

}

export default TenantInfo;
