import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import getAreasNamesFromNums from '../../../functions/getAreasNamesFromNums';
import CommonAreaComponent from './components/CommonAreaComponent';
import {propertyAdHistoryActions} from '../../../../../../../../../redux/actions';

import './style.less';

const CommonAreasDetails = ({
                              property,
                              updateProperty,
                              currentPageLevel,
                              currentRoomNumber,
                              addHistoryPush,
                              propertyAdHistory,
                              changePage,
                              addHistoryPop,
                              goToNextAfterUpdate
                            }) => {

  const propertyCommonAreas = getAreasNamesFromNums(property, true);
  const [commonAreaIndex, setCommonAreasIndex] = useState(currentRoomNumber ? currentRoomNumber : 1);

  useEffect(() => {
    setCommonAreasIndex(currentRoomNumber || 1);
  }, [currentRoomNumber]);

  let amenetiesOptionsNames = {
    kitchen: ['fridge', 'freezer', 'oven', 'stove', 'potsPans', 'kitchenware', 'coffeeMachine', 'toaster', 'microwave', 'kettle', 'tables', 'chairs', 'juicer', 'blender', 'kitchenHood', 'window'],
    livingRoom: ['sofa', 'sofaBed', 'table', 'chairs', 'desk', 'tv', 'ac', 'heater', 'window', 'balcony'],
    bathroom: ['toilet', 'sink', 'shower', 'bathtub', 'mirror', 'hairdryer', 'window', 'jacuzzi']
  };

  let areaToFill = propertyCommonAreas[commonAreaIndex - 1] || propertyCommonAreas[0];

  const goToNextArea = () => {
    if (commonAreaIndex < propertyCommonAreas.length && goToNextAfterUpdate) {
      setCommonAreasIndex(prevState => prevState + 1);

      //add to the propertyAdHistory
      addHistoryPush({level: currentPageLevel, room: commonAreaIndex + 1}, propertyAdHistory)
    }
  };

  const goToPreviousArea = () => {
    if (commonAreaIndex > 1) {
      setCommonAreasIndex(prevState => prevState - 1);
      addHistoryPop(1)
    } else {
      const previousPage = propertyAdHistory.history[propertyAdHistory.history.length - 2];
      addHistoryPop(2);
      if (previousPage) {
        changePage(previousPage.level, previousPage.room);
      }
    }
  };

  if (commonAreaIndex > propertyCommonAreas.length) {
    changePage('2.1');
    return <div></div>;
  }

  return (
    <div className={'CommonAreasDetails section_content'}>
      <CommonAreaComponent key={commonAreaIndex} areaName={areaToFill}
                           goToNextArea={goToNextArea}
                           goToPreviousArea={goToPreviousArea}
                           finishedSectionDetails={commonAreaIndex === propertyCommonAreas.length}
                           amenetiesAreaOptionsNames={amenetiesOptionsNames[areaToFill.split('_')[0]]}
                           property={property} updateProperty={updateProperty}
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {propertyAdHistory: state.propertyAdHistory};
}

const actionCreator = {
  addHistoryPush: propertyAdHistoryActions.push,
  addHistoryPop: propertyAdHistoryActions.pop
};

export default connect(mapStateToProps, actionCreator)(CommonAreasDetails);
