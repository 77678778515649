import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";

import './style.less';
import Ad from '../Ad';
import NavBarGoBack from "../../../../../../components/NavBarGoBack";

const CreateNewAd = () => {
    const path = "/my_ads/ad"
    return (
        <div className={"CreateNewAdContainer"}>
            <NavBarGoBack/>
            <div className={"CreateNewAd container"}>
                <Switch>
                    <Route key={"create"} path={`${path}/create`} render={() => <Ad/>}/>
                    <Route key={"edit"} path={`${path}/edit/:property_id/:room_id?/:property_level?`}
                           render={() => (<Ad/>)}/>
                    <Redirect to={`${path}/create`}/>
                </Switch>
            </div>
        </div>)
}

export default CreateNewAd;
