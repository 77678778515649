import React, {useEffect, useMemo, useState} from 'react';

import './style.less'
import { Trans, useTranslation } from "react-i18next";
import {reservationServices} from "../../../../../../services/reservationServices";
import Loading from "../../../../../../components/Loading";
import {useHistory, useParams} from "react-router-dom";
import moment from "moment";
import {clampText, redactPhoneAndEmail} from "../../../../../../utils/stringFunctions";
import avatarFemale from "../../../../../../components/VisitCard/images/avatar_female.png";
import avatarMale from "../../../../../../components/VisitCard/images/avatar_male.png";
import confirmationNumber from "../../images/confirmation_number_24dp.svg";
import Select from "react-select";
import DatePicker from 'react-datepicker';
import CancellationTimeline from "../../components/CancellationTimeline";
import {useAuth} from "../../../../../../services/authServices";


const CancelReservation = ({updateReservation}) => {

    const {setUser} = useAuth();
    const {t} = useTranslation();
    const history = useHistory();
    const {reservation_id} = useParams();
    const [reservation, setReservation] = useState(null);
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [motive, setMotive] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [availableFrom, setAvailableFrom] = useState(new Date());
    const [error, setError] = useState(false);


    const componentLabel = "main_page.reservations.cancel_reservation."
    const translationDirc = "main_page.reservations.reservation_details."


    const checkIcon = (visibility) => ({
        alignItems: 'center',
        display: "flex",
        ":before": {
            content: '" "',
            display: "block",
            visibility: visibility,
            marginRight: 8,
            transform: "rotate(45deg)",
            height: "13px",
            width: "6px",
            borderBottom: "2px solid #1674F0",
            borderRight: "2px solid #1674F0",
        }
    })

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: "0.75rem",
            backgroundColor: state.isSelected ? 'rgba(87, 180, 242, 0.1)' : undefined,
            color: state.isSelected ? "#57B4F2" : "#8C9BA5",
            ...checkIcon(state.isSelected ? "visible" : "hidden"),
            ":hover": {
                backgroundColor: "#F5F7FA"
            }
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: "0.75rem",
            color: "#434A5F"
        }),
        placeholder: (provided) => ({
            ...provided,
            fontSize: "0.75rem",
            color: "#8C9BA5"
        }),
        indicatorSeparator: () => ({
            display: "none"
        }),
        dropdownIndicator: () => ({
            display: "flex",
            marginRight: "6px",
            color: "#5A6872"
        })
    };

    useEffect(() => {
        (async () => {
            let _reservation = await reservationServices.getReservationById(reservation_id);
            if(_reservation.currentStatus !== "paid" || _reservation.cancellation || moment(_reservation.moveIn).add(1, 'day').isBefore(moment())) {
                history.replace("/reservations/"+reservation_id+"/details");
                return null;
            }
            setReservation(_reservation);
            setAvailableFrom(_reservation.property.room.availableFrom)
        })();
    }, [])

    let reasonTranslationDirect = "main_page.reservations.reject_reservation.reason."
    const reasons = ['extended_stay', 'overlapped_reservations', 'property_unavailable'].map((reason) => ({id: "reason", value: reason, label:  t(reasonTranslationDirect + reason)}));

    let preWarningDays = useMemo(()=> {
        let days;
        if(reservation) {
            let requestDate = reservation.cancellation?.requestDate || new Date()
            let startDate = moment(requestDate).isBefore(moment(reservation.moveIn)) ? reservation.contractStartDate : reservation.moveIn;
            days = moment(startDate).startOf("day").diff(moment(requestDate).startOf("day"), "days");
            return days < 0 ? 0 : days;
        }
    },[reservation])

    /*let cancellationFee = useMemo(()=>{
        if(!reservation) return;
        let fee = 0;
        //60-30 dias do momento do check in - Só taxa de serviço
        // 30 - 0 dias do momento do check in - Só taxa de serviço + tx de cancelamento 100€
        // 0 dias - +1 dia do momento do check in - Só taxa de serviço + tx de cancelamento 250€
        if(preWarningDays <= 30) fee = 100 * 1.23;
        if(preWarningDays === 0) fee = 250 * 1.23; //no dia do check-in
        return fee.toFixed(2);
    }, [reservation, preWarningDays]) ;*/

    let monthlyRent = useMemo(() => {
        if (!reservation) return;
        return reservationServices.getMonthlyRent(reservation);
    }, [reservation]);



    const getRoom = () => {
        let property = reservation.property;
        if(!property) return null;
        if(property.room) return property.room;
        return property.rooms ? (reservation.room_id ? Object.values(property.rooms).find(r => r.id === reservation.room_id) : property.rooms["room_1"]) : null;
    }

    const isButtonDisabled = useMemo(() => {
        return !motive || !acceptedTerms || (['extended_stay', 'overlapped_reservations'].includes(motive?.value) && !availableFrom)
    }, [motive, acceptedTerms, availableFrom])


    const submitHandler = async () => {
        if(isButtonDisabled) {
            setError(true);
            return;
        }
        setSubmitting(true)
        let fields = {
            reservation_id: reservation_id,
            motive: motive.value
        }
        if(['extended_stay', 'overlapped_reservations'].includes(motive.value)) fields["availableFrom"] = availableFrom;
        await reservationServices.cancelReservation(fields);
        setSubmitting(false);
        history.replace("/reservations/"+reservation_id+"/details");
        updateReservation({...reservation, currentStatus: "cancelled", cancellation: {
            motive: motive.value
        }});
        setUser(user => ({...user, acceptedBookings: (user.acceptedBookings || 0) - 1, cancelledReservations: (user.cancelledReservations || 0) + 1}));
    }

    const hasPenalty = useMemo(() => {
        if (reservation && reservation?.property)
            return ((reservation.property.refundPolicy === 0 || preWarningDays < reservation.property.refundPolicy) && reservation.landlord.commission > 0);
    }, [reservation])

    const getPolicy = useMemo(() => {
        switch (reservation?.property?.refundPolicy) {
            case 0:
                return "non_refundable";
            case 15:
                return "flexible";
            case 30:
                return "moderate";
            case 60:
                return "strict";
        }
    }, [reservation])

    return (
        <div className={"CancelReservation"}>
            {submitting && <Loading cover={0.3}/>}
            {!reservation ? <Loading/> :
                <div className={"CancelReservation__content"}>
                    <div className={"CancelReservation__content-header"}>
                        <div className="CancelReservation__timeline">
                            <div className={"title"}><img src={confirmationNumber} alt={"confirmationNumber"} />{t(componentLabel + "cancellationPolicy_title")}</div>
                            <CancellationTimeline reservation={reservation} className={"cancellation-time-line"} />
                        </div>
                        {/*<div>
                            <div className={"title"}>{t(componentLabel+"cancellationPolicy_title")}</div>
                            <Trans i18nKey={componentLabel+"check_terms"} components={{
                                a: <a className="link link_blue" target="_blank"
                                      href={"https://inlifehousing.com/pt/termos-condicoes/"}/>
                            }}/>
                        </div>*/}
                        <div className={"cancellation_policy_box"}>
                            <span className={"title"}>{t(componentLabel + "cancellationPolicy_title")}</span>
                            <p className={"body"}>
                                {t(componentLabel + "policy." + getPolicy)}
                                {t(componentLabel + "policy." + (getPolicy === "non_refundable" ? "non_refundable_penalty" : "penalty"))}
                            </p>
                            <p className={"body"}>
                                {t(componentLabel + "policy.penalty_explanaition")}
                            </p>
                            <a className={"link_blue"}
                               href={"https://inlifehousing.com/pt/termos-condicoes/"}>{t(componentLabel + "terms_link")}</a>
                        </div>
                    </div>
                    <div className="horizontal_dashed_line w-100"/>
                    <div className={"CancelReservation__content-body"}>
                    <div className={"CancelReservation__content-body-left"}>
                        <div className={"CancelReservation-reservation-info"}>
                            <span className={"title"}>{t(componentLabel+"reservation_info.reservation")}</span>
                            <span className={"text"}>{t(componentLabel+"reservation_info.reservation_n")} <strong>{reservation_id?.split("-")?.[0]}</strong></span>
                            <span className={"text"}>{t(componentLabel+"reservation_info.inlife_return")} <strong>€{(reservation.payment.firstRent + reservation.payment.extraTenantsValue).toFixed(2)}</strong></span>
                            {hasPenalty ?
                                <>
                                        <span className={"text"}>{t(componentLabel + "penalty_no_percentage")}
                                        <strong>2%</strong></span>
                                    <span className={"text"}>{t(componentLabel + "penalty_explanation")}</span>
                                </>
                                : <span className={"text"}>{t(componentLabel + "reservation_info.service_fee")}
                                    <strong>€{reservation.inlifeCommissionVat}</strong></span>}
                        </div>
                        <div className={"CancelReservation__user-information"}>
                            <div className={"user-photo"} style={{backgroundImage: `url(${reservation.user.photo || (reservation.user.personalDetails.gender === "female" ? avatarFemale : avatarMale)})`}} />
                            <div className={"user-details"}>
                                <div className={"name"}>
                                    <span>{reservation.user.name}</span>
                                    <div className={"country-flag"} style={{backgroundImage: `url(https://github.com/madebybowtie/FlagKit/raw/master/Assets/PNG/${reservation.user.personalDetails.nationality.toUpperCase()}@2x.png?raw=true)`}} />
                                </div>
                                <span>{t("country." + reservation.user.personalDetails.nationality) + " - "+t(reservation.user.personalDetails.gender) + ", "}{reservation.user.personalDetails.age + " " + t('years')}</span>
                                <span>{t('main_page.my_visits.visit_card.occupation.'+reservation.user.personalDetails.occupation)} {t('at')} {reservation.user.personalDetails.occupation === "student" ? reservation.user.personalDetails.university?.split(" - ")[0] : reservation.user.personalDetails.occupation_detail}</span>
                                <div className={"numTenants-info"}><strong>{t(componentLabel+"reservation_info.reservation_for")} </strong>{reservation.numTenants + " " + t(`person${reservation.numTenants > 1 ? "_plural" : ""}`)}</div>
                            </div>
                        </div>
                        <div className="horizontal_dashed_line" style={{width: "95%"}} />
                        <div className={"ReservationDetails__bottom-about"}>
                           {reservation.user.personalDetails?.extraInfo && /*reservation.tenantInfoVerified &&*/
                            <div className={"ReservationDetails__section"}>
                                <span
                                    className={"title"}><strong>{t('main_page.reservations.reservation_details.about')}</strong></span>
                                <span className={"text"}>{redactPhoneAndEmail(reservation.user.personalDetails?.extraInfo)}</span>
                            </div>}
                            <div className={"ReservationDetails__section"}>
                                <span
                                    className={"title"}><strong>{t('main_page.reservations.reservation_details.details')}</strong></span>
                                <span
                                    className={"text"}>{clampText(reservation.property.title?.[localStorage.getItem('i18nextLng').split('-')[0]], 200)}</span>
                                <span className={"text"}>{reservation.property.address}</span>
                            </div>
                        </div>
                        <div className="horizontal_dashed_line" style={{width: "90%"}}/>
                        <div className={"ReservationDetails__bottom-reservation"}>
                            <div>
                                <div className={"ReservationDetails__section"}>
                                    <span
                                        className={"title"}><strong>{t('main_page.reservations.reservation_details.stay_beginning')}</strong></span>
                                    <span
                                        className={"text"}>{moment(reservation.moveIn).locale('pt').format("DD MMMM YYYY")}</span>
                                </div>
                                <div className={"ReservationDetails__section"}>
                                    <span
                                        className={"title"}><strong>{t('main_page.reservations.reservation_details.contract_start')}</strong></span>
                                    <span
                                        className={"text"}>{moment(reservation.contractStartDate).locale('pt').format("DD MMMM YYYY")}</span>
                                </div>
                                <div className={"ReservationDetails__section"}>
                                    <span
                                        className={"title"}><strong>{t('main_page.reservations.reservation_details.rent')}</strong></span>
                                    <span className={"text"}>{monthlyRent}</span>
                                </div>
                                <div className={"ReservationDetails__section"}>
                                    <span
                                        className={"title"}><strong>{t('main_page.reservations.reservation_details.securityDepositTitle')}</strong></span>
                                    <span
                                        className={"text"}>{"€" + (parseInt(reservation.securityDeposit.slice(1)) > 0 ? parseInt(reservation.securityDeposit.slice(1)) : 0)}</span>
                                </div>
                            </div>
                            <div>
                                <div className={"ReservationDetails__section"}>
                                    <span
                                        className={"title"}><strong>{t('main_page.reservations.reservation_details.stay_end')}</strong></span>
                                    <span
                                        className={"text"}>{moment(reservation.moveOut).locale('pt').format("DD MMMM YYYY")}</span>
                                </div>
                                <div className={"ReservationDetails__section"}>
                                    <span
                                        className={"title"}><strong>{t('main_page.reservations.reservation_details.contract_end')}</strong></span>
                                    <span
                                        className={"text"}>{moment(reservation.contractEndDate).locale('pt').format("DD MMMM YYYY")}</span>
                                </div>
                                <div className={"ReservationDetails__section"}>
                                    <span
                                        className={"title"}><strong>{t('main_page.reservations.reservation_details.extra_tenant')}</strong></span>
                                    <span
                                        className={"text"}>€{getRoom().extraTenantsPrice} {t(translationDirc + 'perMonth')}</span>
                                </div>
                                <div className={"ReservationDetails__section"}>
                                    <span
                                        className={"title"}><strong>{t('main_page.reservations.reservation_details.entry_pay')}</strong></span>
                                    <span
                                        className={"text"}>{reservationServices.getPaidAtMoveIn(reservation)} {t(translationDirc + "securityDeposit" + (reservation.property.paidAtMovinDate?.includes("Last Rent") ? '+lastRent' : ''))}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"CancelReservation__content-body-right"}>
                        {/*<div className={"title"}>{t(componentLabel+"cancellationPolicy_summary")}</div>
                            <div className={"policy-summary"}>
                            <div className={"labels"}>
                                <div>{t(componentLabel+"labels.accepted")}</div>
                                <div>{t(componentLabel+"labels.30days_before")}</div>
                                <div>{t(componentLabel+"labels.check-in")}</div>
                                <div>{t(componentLabel+"labels.24h_after")}</div>
                            </div>
                            <div className={"boxes-info"}>
                                <div className={"box-purple"}>{t(componentLabel+"fees.accepted")}</div>
                                <div className={"box-yellow"}>{t(componentLabel+"fees.30days_before")}</div>
                                <div className={"box-green"}>{t(componentLabel+"fees.check-in")}</div>
                                <div className={"box-blue"}>{t(componentLabel+"fees.24h_after")}</div>
                            </div>
                        </div>*/}
                        {preWarningDays >= 0 &&
                        <div className={"reason-select"}>
                            <label className={"title"}>
                                {t(componentLabel+"reason_question")}
                                <span className={"required_field"}>*</span>
                            </label>
                            <Select
                                styles={customStyles}
                                options={reasons}
                                value={motive}
                                placeholder={t("choose_from_the_list")}
                                onChange={(value) =>{
                                    setMotive(value);
                                    setAvailableFrom(null);
                                }}
                            />
                        </div>}

                        {['extended_stay', 'overlapped_reservations'].includes(motive?.value) &&
                            <div className={"availableFrom-picker"}>
                                <div className={"text"}>{t(componentLabel + "availableFrom_label")}: <span className={"required_field"}>*</span></div>
                                <div className={"CustomDatePicker"} style={{width: "fit-content"}}>
                                        <DatePicker className={"input_field basic_container"}
                                            showPreviousMonths={false}
                                            locale={localStorage.getItem('i18nextLng').split('-')[0]}
                                            dateFormat={'dd MMM yyyy'}
                                            onChange={(date) => setAvailableFrom(date)}
                                            selected={availableFrom}
                                            minDate={new Date().setDate(new Date().getDate() + 1)}
                                            placeholderText={"Escolha uma data"}
                                            disabledKeyboardNavigation
                                            formatWeekDay={nameOfDay => nameOfDay.substr(0, 1).toUpperCase()}
                                            popperPlacement='top-start'
                                            popperModifiers={{
                                                flip: {
                                                    behavior: ['bottom'] // don't allow it to flip to be above
                                                },
                                                preventOverflow: {
                                                    enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                                },
                                                hide: {
                                                    enabled: false // turn off since needs preventOverflow to be enabled
                                                },
                                                computeStyle: { gpuAcceleration: false }
                                            }} />
                                </div>
                            </div>}
                        {preWarningDays >= 0 && (
                        <div className={"terms"}>
                            <div className={"input-checkbox"}>
                                <input type="checkbox" name="terms" id="terms" checked={acceptedTerms} onChange={()=> setAcceptedTerms((t)=> !t)} />
                                <label htmlFor="terms">{t(componentLabel+"terms.check_label")} <span className={"required_field"}>*</span>
                                </label>
                            </div>
                            { preWarningDays <= 30 && (
                                <div>
                                    <div className={"dot"} />
                                    <span>{t(componentLabel+"terms.term_3")}</span>
                                </div>
                            )}
                            <div>
                                <div className={"dot"} />
                                        <span>
                                            <Trans i18nKey={componentLabel + "terms.term_2"} values={{ name: reservation?.user?.name }} />
                                        </span>
                            </div>
                        </div>)}
                        {error && <div className={"error_message"} style={{marginBottom: "15px"}}>{t(componentLabel + "error_message")} <span className={"required_field"}>*</span></div>}
                        {preWarningDays >= 0 &&
                        <div className={"buttons"}>
                            <div className={"btn"} onClick={()=> history.replace("/reservations/"+reservation_id+"/details")} >{t(componentLabel+"go_back")}</div>
                            <div className={"btn btn-special"+(isButtonDisabled?" disabled":"")} onClick={submitHandler}>{t(componentLabel+"cancel")}</div>
                        </div>}
                    </div>
                    </div>
                </div>}
        </div>
    )
}

export default CancelReservation;
