import React, {useEffect, useMemo, useState} from 'react';

import './style.less'
import Loading from "../../../../../../components/Loading";
import ReservationCard from "../../../../../../components/ReservationCard";
import moment from 'moment';
import {useTranslation} from "react-i18next";
import EmptyState from "../../../../../../components/EmptyState";
import FiltersContainer from "../../../../../../components/FiltersContainer";
import PaginationLegacy from "../../../../../../components/PaginationLegacy";
import Filters from "../../../../../../components/Filters";
import {grind} from "../../../../../../utils/stringFunctions";

const ReservationList = ({
                             reservations,
                             filter,
                             updateReservationList,
                             totalResults,
                             filters,
                             setFilters,
                             ...props
                         }) => {

    const {t} = useTranslation();

    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [searchFilter, setSearchFilter] = useState(filters.searchKey ? filters.searchKey : "");
    const [isByAsc, setIsByAsc] = useState(true);
    const [lastSort, setLastSort] = useState("");
    const [currentReservations, setCurrentReservations] = useState();

    let pages = props.pages;

    let sortedReservations = reservations?.sort((a, b) => {
        return moment(b.requestDate) - moment(a.requestDate)
    })

    useEffect(() => {
        (async () => {
            await handleFilter(typeof filter === "string" ? "currentStatus" : "searchKey", filter);
            setLoading(false)
        })();
    }, [])


    //TODO penso que isto não é necessário, desde que já filtramos no elastic/backend
    const getFiltered = (filter) => {
        return sortedReservations?.filter(reservation => {
            //para retirar as reservas que não têm os dados corretos no payment (casos antigos)
            if(!reservation.payment?.fixedRent && !reservation.payment?.rentValue && !reservation.payment?.rentMonths || (reservation.type === "instant booking" && reservation.payment?.status === "unpaid")) return false;
            if (filter === 'requests') {
                return !reservation.acceptedDate && reservation.currentStatus !== "expired" && !reservation.rejectedDate;
            } else if (filter === "pending") {
                return !["expired", "rejected", "cancelled"].includes(reservation.currentStatus) && (reservation.currentStatus === "payment" || (reservation.needsStudentAcceptance  && moment(reservation.acceptedDate).add(1, "day").isAfter(moment())));
            } else if (filter === "confirmed") {
                return (reservation.acceptedDate && (reservation.payment.status === "paid" || reservation.type !== "booking")) && !reservation.rejectedDate && !reservation.needsStudentAcceptance;
            } else if (filter === "rejected"){
                return (reservation.currentStatus === "expired" || reservation.currentStatus === "rejected") && !reservation.cancellation;
            } else if (filter === "cancelled") {
                return  reservation.cancellation;
            } else {
                return reservation;
            }
        });
    }

    let filteredReservations = useMemo(() => {
        return getFiltered(filter);
    }, [reservations, sortedReservations, filter]);

    const applyFilterProperty = () => {
        if(filteredReservations && filteredReservations.length)
        return filteredReservations
            .filter(reservation => {
                if (searchFilter) {
                    let sanitizedSearch = grind(searchFilter).trim();
                    let propertyFieldsSearch = [grind(reservation.property.internal_name),
                        grind(reservation.property.address),
                        grind(reservation.property.floor),
                        grind(reservation.property.region),
                        ...Object.values(reservation.property?.rooms || {}).map(room => grind(room.internalName))
                    ].join(' ');
                    return sanitizedSearch.split(' ').every(searchWord => propertyFieldsSearch.includes(searchWord))
                } else return true;
            });
    }

    useEffect(() => {
        setCurrentReservations(applyFilterProperty());
    }, [searchFilter, filteredReservations]);

    const handleFilter = async (filter2, value) => {
        setLoading(true);
        if (filter2 === "currentStatus" && value === "rejected") {
            value = "rejected_or_expired";
        }
        setFilters(prevState => ({...prevState, [filter2]: value}));
        await updateReservationList(page, filter, filter2, value);
        setLoading(false);
    }

    const sort = (filterValue, isByAsc) => {
        setLoading(true);
        let reservationsFiltered = [...(currentReservations ?  currentReservations : filteredReservations)];
        let filter = filterValue || lastSort;
        if (filter === "stayDates") {
            reservationsFiltered.sort((a, b) => isByAsc ?
                (moment(a.moveOut).diff(moment(a.moveIn))) - (moment(b.moveOut).diff(moment(b.moveIn)))
                : (moment(b.moveOut).diff(moment(b.moveIn))) - (moment(a.moveOut).diff(moment(a.moveIn))));
        } else if (["acceptedDate", "requestDate", "moveIn", "moveOut", "paymentDate"].includes(filter)) {
            reservationsFiltered.sort((a, b) => isByAsc ? moment(a[filter]) - moment(b[filter]) : moment(b[filter]) - moment(a[filter]));
        } else if (filter === "firstRent") {
            reservationsFiltered.sort((a, b) => isByAsc ? moment(a.payment?.[filter]) - moment(b.payment?.[filter]) : moment(b.payment?.[filter]) - moment(a.payment?.[filter]));
        } else {
            reservationsFiltered.sort((a, b) => isByAsc ? a[filter] - b[filter] : b[filter] - a[filter]);
        }
        setCurrentReservations(reservationsFiltered);
        setFilters(prevState => ({...prevState, sort: filter}));
        setLoading(false);
    }

    return (
        <div className={"ReservationList"}>
            <div className="page__header">
                <div className="page__header__title">
                    <h3>{t(props.header)}</h3>
                {props.pages && <FiltersContainer pages={props.pages} type={"reservations"}/>}
            </div>
            <Filters filters={filters} setFilters={setFilters} setSearchFilter={setSearchFilter}
                     isByAsc={isByAsc} setIsByAsc={setIsByAsc} lastSort={lastSort} type={"reservation"}
                     handleFilter={handleFilter} sort={sort}
                     resetFilters={async () => {
                         setLoading(true);
                         await updateReservationList(0, filter, null, null, true);
                         setLoading(false)
                     }}/>
            </div>
            {loading && <Loading/>}
            <PaginationLegacy setPage={setPage} page={page} total={totalResults} updateList={updateReservationList}
                        setLoading={setLoading} filter={filter} filters={filters} searchKey={searchFilter}/>
            <div className="reservations">
            {reservations === null || loading ?
                <Loading/> : (filteredReservations && filteredReservations.length > 0 ? (currentReservations ? currentReservations : filteredReservations).map(reservation => (
                    <ReservationCard key={reservation.id + "card"} reservation={reservation} filter={filter}
                                     updateReservations={updateReservationList}/>
                )) : <EmptyState type={"reservations"} />)
                }
            </div>
        </div>
    )
}

export default ReservationList
