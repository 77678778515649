import React, {useState} from 'react'
import Select, {components} from 'react-select'
import {IoMdCheckmark} from 'react-icons/io';
import {Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import './style.less'

const ReactSelect = ({className, ...props}) => {
  const {t} = useTranslation();

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'rgba(29, 154, 250, 0.1)' : undefined
    }),
    menu: provided => ({...provided, minWidth: 'max-content'}),
    menuList: provided => ({...provided, paddingRight: '10px'}),
  };

  const Option = props => {
    if (!props.isMulti)
      return (<div
        className={`SelectOptionContainer ${props.isDisabled ? 'disabled' : null} ${props.isSelected ? 'selected' : null}`}>
        <components.Option {...props} > {props.isSelected ? <IoMdCheckmark/> : null} <label
          className={`${!props.isSelected ? 'not-selected' : null} `}>{props.label}</label></components.Option>
      </div>);
    else return (<div className={`${props.isSelected ? 'checked' : null}`}>
      <components.Option className="Select__option__multi" {...props}>
        <div className={`${props.isSelected ? 'checked' : null} check_mark`}>{props.isSelected ?
          <IoMdCheckmark/> : null}</div>
        <label className={`"not-selected"`}>{props.label}</label></components.Option>
    </div>);
  };

  const [options, setOptions] = useState(props.value);

  const params = {
    styles: customStyles,
    hideSelectedOptions: false,
    className: `${!props.isMulti ? 'Select' : 'ReactSelectMulti'} ${options?.length >= props.maxLength?'disabled':''} basic_container ${className}`,
    classNamePrefix: 'Select',
    components: {
      NoOptionsMessage: () => <p style={{padding: '0 5px'}}>{t('no_options')}</p>,
      IndicatorSeparator: () => null,
      ClearIndicator: () => null,
      Option
    }
  }

  if (props.control)
    return (<Controller
      as={<Select/>}
      {...params}
      isDisabled={props.disabled}
      {...props}
    />)

  if (props.isMulti) {
    if (props.options?.length === 0) return null;

    return (
      <Select
        {...params}
        placeholder={`${t('choose_from_the_list')}`}
        {...props}
        multi={true}
        blurInputOnSelect={false}
        closeMenuOnSelect={false}
        value={options}
        onChange={(value) => {
          if(props.maxLength && (value.length > props.maxLength)) {
            return;
          }
          setOptions(value);
          props.onChange(value);
        }}
      />
    );
  }

  return (<Select {...params} {...props}/>)
};

const findReactSelectInitialOptions = (defaultOptions, options) => {
  return defaultOptions.map(defaultOption => {
    return options.find(option => option.value === defaultOption) || {label: defaultOption, value: defaultOption};
  });
};

module.exports = {ReactSelect, findReactSelectInitialOptions}

export default ReactSelect;

