import React, {useMemo, useRef} from 'react';
import "./style.less"
import place from "../../../../../../../../components/VisitCard/images/place.svg";
import meetingRoom from "../../../../../../../../components/VisitCard/images/meeting_room.svg";
import money from "../../../../../../../../components/VisitCard/images/money.svg";
import {useTranslation} from "react-i18next";
import compareArrowsIconWhite from "../../../../../../../../components/ListItem/images/compareArrowsIconWhite.svg";
import TenantInfo from "../../../../../../../../components/ListItem/components/TenantInfo";
import {clampText} from "../../../../../../../../utils/stringFunctions";
import expandMore from "../OfferPopOver/images/expandMore.svg";
import verifyAttributeExistenceToString
    from "../../../../../../../../components/ResumeItem/functions/verifyAttributeExistenceToString";
import {propertyServices} from "../../../../../../../../services";
import moment from "moment";
import calendarIconGray from "../../../../../../../../components/ListItem/images/calendarIconGray.svg";
import CompareArrowsIcon from "../../../../../../../../components/svg/CompareArrows";
import PlaceIcon from "../../../../../../../../components/svg/Place";
import MeetingRoomIcon from "../../../../../../../../components/svg/MeetingRoom";
import EuroIcon from "../../../../../../../../components/svg/Euro";
import CalendarIcon from "../../../../../../../../components/svg/Calendar";
import ExpandMoreIcon from "../../../../../../../../components/svg/ExpandMore";

const OfferCard = ({tenant, property, rooms, handleOpenOffer, offerID}) => {

    const {t} = useTranslation();
    const locale = localStorage.getItem('i18nextLng').split('-')[0];

    const propertyRef = useRef();

    const prices = useMemo(() => {
        if (property) {
            return propertyServices.getRentsInterval(property);
        }
    }, [property]);

    return (<div className={"OfferCard"} ref={propertyRef} onClick={() => {handleOpenOffer(offerID);}}>
            <div className={"Card__image"} style={{backgroundImage: `url(${property?.photos?.["featured_0"]?.[0]?.md ? property?.photos?.["featured_0"]?.[0]?.md : property?.photos?.[0]?.md})`}}/>
            <div className={"Card__body"}>
                <div className={"Card__property-info"}>
                    {property?.title ?
                        <h5>{clampText( property?.title[locale], 60) }</h5> :
                        <h5>{clampText(verifyAttributeExistenceToString(property, 'internal_name'), 60)}</h5>}
                    <div className={"property-details"}>
                    <span>
                        <PlaceIcon width={16} />
                        {property?.address}
                    </span>
                    <span>
                        <MeetingRoomIcon width={16} />
                        ID {property?.id?.split("-")[0]?.toUpperCase()}
                    </span>
                    <span>
                        <EuroIcon width={16} />
                            {t('main_page.my_visits.visit_card.monthly_rent')}
                        <strong>{prices}</strong>
                        </span>
                    </div>
                </div>
            <div className={"compareArrowsContainer"}>
                <CompareArrowsIcon />
            </div>
                {tenant &&
                <TenantInfo listType={"offer"} tenant={tenant} type={'fullIcon'} move_details={{
                    "move_in": moment(tenant.preferences?.moveIn).format("DD MM YYYY"),
                    "move_out": moment(tenant.preferences?.moveOut).format("DD MM YYYY")
                }}/>}
                {tenant &&
                    <div className={"tenant_dates_info"}>
                        <div>
                        <CalendarIcon />
                            <p className={'small'}>
                                <span>{t('dashboard_list_item.entry_date') + ':'}</span> <strong>{moment(tenant.preferences?.move_in).format("DD/MM/yyyy")}</strong></p>
                        </div>
                        <div>
                        <CalendarIcon />
                            <p className={'small'}>
                                <span>{t('dashboard_list_item.exit_date') + ':'}</span> <strong>{moment(tenant.preferences?.move_out).format("DD/MM/yyyy")}</strong></p>
                        </div>
                    </div>}
            {rooms && <ExpandMoreIcon onClick={() => handleOpenOffer(offerID)} />}
            </div>
        </div>
    )
}

export default OfferCard;