import React from "react";
import {useTranslation} from 'react-i18next';
import {userServices} from '../../../../../../services'

import "./style.less";
import FiltersContainer from "../../../../../../components/FiltersContainer";
import LogoutIcon from "../../../../../../components/svg/Account/Logout";

const MyAccountNav = (props) => {

    const {t} = useTranslation();

    return (
        <div className={"AccountDetails__container noWidth"}>
            {props.pages &&
            <FiltersContainer pages={props.pages} type={"my_account"} horizontal={true}/>}
            <div className={"endSession"}>
                <LogoutIcon />
                <p onClick={async () => {
                    await userServices.logout();
                    window.location.href = "/start/login";
                }}>{t("my_account.logout")}</p>
            </div>
        </div>
    );
};


export default MyAccountNav;
