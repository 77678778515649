const getLevelsMaxRoom = (rooms,currentLevel) => {
  let level  = 0;
  const roomsNames = Object.keys(rooms)
  roomsNames.map(room => {
    if(parseFloat(rooms[room].roomLevel) >= parseFloat(currentLevel))
      level++;
  })
  if(level===0) return 1
  return level
}

export default getLevelsMaxRoom