import React, {useState} from 'react';

import CloneInformation from '../../../../components/CloneInformation';
import StepperBar from '../StepperBar';

import './style.less';
import {useTranslation} from 'react-i18next';

const getRoomsTitles = (property, numberOfRooms, t) => {

    let propertyRoomsTitles = []
    for (let roomIndx = 1; roomIndx <= numberOfRooms; roomIndx++) {
        if (property.accommodation === "residence") {
            let bedrooms = property.bedroom_types;
            propertyRoomsTitles.push({title: roomIndx <= bedrooms ? `${t('room')} ${roomIndx}` : `${t('studio')} ${roomIndx - bedrooms}`})
        } else {
            propertyRoomsTitles.push({title: `${t('room')} ${roomIndx}`})
        }
    }
    return propertyRoomsTitles;
}

const RoomDetailsHeader = ({
                               currentRoom,
                               setCurrentRoomIndx,
                               changeInfo,
                               cloneValue,
                               maxRoom,
                               setMaxRoom,
                               rooms,
                               title,
                               currentPageLevel,
                               property
                           }) => {
    let roomPhoto = rooms['room_' + currentRoom.idx]?.photos?.featured?.md;

    const {t} = useTranslation();

    //filter the rooms that are not at the currentPageLevel level and the actual room
    const roomOptions = Object.keys(rooms).map(option => ({label: rooms[option].internalName, value: option}))
        .filter(option => option.value !== 'room_' + currentRoom.idx && option.label && parseFloat(rooms[option.value].roomLevel) >= parseFloat(currentPageLevel));

    return (<div className={'RoomDetailsHeader'} key={currentRoom.name}>
        <h4 className={'title'} style={{width: '100%'}}>{title}</h4>

        <div className={'RoomDetailsHeader__center'}>
            <div>
                <label className={'RoomDetailsHeader__center-room'}>{(property.accommodation === 'residence' && currentRoom.idx > property.bedroom_types)? `${t('studio')} ${currentRoom.idx - property.bedroom_types}` : `${t('room')} ${currentRoom.idx}`}</label>
                <span style={{backgroundImage: 'url(' + roomPhoto + ')'}}/>
            </div>
            {Object.keys(rooms).length > 1 &&
            <CloneInformation key={currentRoom.idx} cloneValue={cloneValue} changeInfo={changeInfo}
                              options={roomOptions}
                              title={t('main_page.my_ads.create_ad.room_details.clone_room')}
                              placeholder={t('room') + ' 1'}/>}
        </div>

        {Object.keys(rooms).length > 1 &&
        <StepperBar steps={getRoomsTitles(property, Object.keys(rooms).length, t)} activeStep={currentRoom.idx}
                    setActiveStep={setCurrentRoomIndx}
                    maxStep={maxRoom} setMaxStep={setMaxRoom}/>}

    </div>)
}

export default RoomDetailsHeader;
