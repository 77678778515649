import React, {useMemo, useState, useEffect} from "react";
import { Trans, useTranslation } from "react-i18next";
import "./style.less";

import { useForm } from "react-hook-form";
import { userServices } from "../../../../services";

import CountryAndPhoneSelect from "../../../../components/CountryAndPhoneSelect";
import useQuery from "../../../../utils/query";
import {useAuth} from "../../../../services/authServices";

import EmailIcon from "../../../../components/svg/Email";
import PhoneIcon from "../../../../components/svg/Phone";
import DoneAllIcon from "../../../../components/svg/DoneAll";
import VerifyEmail from "../../../../components/svg/Actions/VerifyEmail";
import EmailValidated from "../../../../components/svg/Actions/EmailValidated";
import VerifyPhone from "../../../../components/svg/Actions/VerifyPhone";
import PhoneValidated from "../../../../components/svg/Actions/PhoneValidated";
import PasswordChanged from "../../../../components/svg/Actions/PasswordChanged";
import AccountValidated from "../../../../components/svg/Actions/AccountValidated";
import Separator from "../../../../components/svg/Separator";

const VerificationContainer = () => {

    const {t} = useTranslation();
    const query = useQuery();
    const {user, setUser} = useAuth();

    const [isLoading, setIsLoading] = useState(null);
    const [step, setStep] = useState(1);

    const [phoneNumber, setPhoneNumber] = useState()
    const [country, setCountry] = useState('PT');
    const [dialCode, setDialCode] = useState('+351');

    const {register, control, formState, getValues, errors, setError, setValue, clearErrors} = useForm({mode: "onChange", shouldFocusError: true});

    if(query.token) setValue("token", query.token, {shouldValidate: true, shouldDirty: true})

    //step 1 validate email
    //step 2 email succesfully verified
    //step 3 (optional) change/insert phone number
    //step 4 validate phone number
    //step 5 phone number succesfully verified
    //step 6 (optional) change password
    //step 7 account verified

    useEffect(()=> {
        if(!user) return;
        if(!user.email_verified) {
            setStep(1);
        } else if(!user.phone_verified || !user.phone) {
            if(!user.phone) {
                setStep(3)
            } else {
                setStep(4)
            }
        } /* else if(!user.googleId || (!user.has_password && !user.impersonateOriginal && !user.googleId) || (user.hasOwnProperty('changedTemporaryPassword') && !user.changedTemporaryPassword)) {
            setStep(6)
        } */
    }, [user])


    const onSubmit = async () => {
        if (step === 1 || step === 4) {
            setIsLoading("verifyingData");
            let token = getValues().token;
            try {
                if (user) {
                    if (step === 1) {
                        await userServices.verifyEmail(user.email, token);
                    } else {
                        await userServices.verifyPhone(token);
                    }
                }
                //location.reload();
            } catch (e) {
                console.log(e)
                if(e.error === "invalidToken") {
                    setError("token", {type: "string", message: "invalidToken"})
                }
                if (e.validationErrors) {
                    e.validationErrors.forEach(err => {
                        setError(err.param, {type: "string", message: t("error." + err.msg)});
                    })
                }
                return;
            }
        } else if (step === 2) {
            setStep(user?.phone ? 4 : 3);
            if(user?.phone) await userServices.sendPhoneVerification();
            //setShowSuccessPage(false);
            setIsLoading(null);
            return;
        } else if (step === 3) {
            let newPhoneNumber = getValues().phone;
            if(newPhoneNumber) {
                newPhoneNumber = newPhoneNumber.replace(dialCode, "").trim();
                setIsLoading("sendingEmails")
                try {
                    setUser(await userServices.saveAccountDetails({
                        phone: newPhoneNumber,
                        dialCode,
                        language: localStorage.getItem('i18nextLng')?.split('-')[0]||"en"
                    }));
                    setStep(4)
                    await userServices.sendPhoneVerification();
                }catch(e){
                    if (e.validationErrors) {
                        e.validationErrors.forEach(err => {
                            setError(err.param, {type: "string", message: t("error." + err.msg)});
                        })
                    }
                }
                setIsLoading(null);
                return;
            }
        } /* else if (step === 6) {
            //change password
            setIsLoading("verifyingData");
            let password = getValues().password;
            try {
                await userServices.changePassword({password});
            } catch (e) {
                if (e.validationErrors) {
                    e.validationErrors.forEach(err => {
                        setError(err.param, {type: "string", message: t("verification." + err.param + ".err-message")});
                    })
                }
            }
        } */ //commented because it's not being used, current application for this will be the multiple users feature

        if(!Object.values(errors).length) {
            if ((step === 4 && (user.has_password || user.googleId || user.facebookId) && (user.changedTemporaryPassword || user.changedTemporaryPassword === undefined)) || step === 6) {
                setStep(7);
                setTimeout(() => {
                    location.href = "/dashboard";
                }, 10000);
            } else {
                setStep(prevStep => prevStep + 1)
            }
        }
        setIsLoading(null);
    }

    const resend = async () => {
        setIsLoading("sendingEmails");
        try {
            if (user) {
                if (step === 1) {
                    await userServices.resendVerifyEmail(user.email);
                } else {
                    await userServices.sendPhoneVerification();
                }
            }
        } catch (e) {
            if (e.validationErrors) {
                e.validationErrors.forEach(err => {
                    setError(err.param, {type: "string", message: t("error." + err.msg)});
                })
            }
            console.log(e);
        }
        setIsLoading(null);
    }

    const illustrationStepContent = [null, VerifyEmail, EmailValidated, VerifyPhone, VerifyPhone, PhoneValidated, PasswordChanged, AccountValidated];
    const IllustrationComponent = useMemo(() => {
        return illustrationStepContent[step] || null;
    }, [step]);

    const possibleLabels = [null, "email", "emailValidated", "updatePhone", "phone", "phoneValidated", "changePassword", "accountValidated"];
    const stepLabel = useMemo(() => {
        return possibleLabels[step] || null;
    }, [step]);

    const continueDisabled = useMemo(() => {
        if (step === 4) return false;
        if (step === 3) {
            return !formState.isDirty || !getValues().phone || errors.phone || isLoading;
        }
        return (!formState.isDirty || formState.isSubmitting || !formState.isValid || isLoading) && step !== 2
    }, [step, formState.isDirty, formState.isValid, formState.isSubmitting, getValues().phone, errors.phone, isLoading]);

    return (
        <div className="verification-container">
            <div className="verification-container__steps">
                <div className="verification-container__steps__icons">
                    <div className={`icon ${step === 1 ? "active" : "validated"}`}><EmailIcon /></div>
                    <Separator />
                    <div className={`icon ${(step === 3 || step === 4) ? "active" : (step === 5 || step === 6 || step === 7) && "validated"}`}><PhoneIcon /></div>
                    <Separator />
                    <div className={`icon ${step === 7 && "validated"}`}><DoneAllIcon /></div>
                </div>
                <div className="verification-container__steps__labels">
                    <p className={(step === 1 || step === 2) ? "active" : ""}>{t("verification.steps.email")}</p>
                    <p className={(step === 3 || step === 4) ? "active" : ""}>{t("verification.steps.phone")}</p>
                    <p className={step === 7 ? "active" : ""}>{t("verification.steps.account")}</p>
                </div>
            </div>

            {IllustrationComponent ? <IllustrationComponent /> : null}

            <h3 className="verification-container__title">{stepLabel ? t("verification." + stepLabel + ".title") : null}</h3>
            <p className="verification-container__description">
                {step === 1 && <p className="verification-container__description">
                    <Trans i18nKey={"verification.email.description"} values={{ mail: user?.email }} components={{ b: <strong /> }} />
                </p>}
                {step === 2 && <p className="verification-container__description">
                    {t(`verification.emailValidated.description`)}
                </p>}
                {step === 3 && <p className="verification-container__description">
                    {t(`verification.updatePhone.description`)}
                </p>}
                {step === 4 && <p className="verification-container__description">
                    <Trans i18nKey={"verification.phone.description"} values={{ dial: user?.dialCode, number: user?.phone }} components={{ b: <strong /> }} />
                </p>}
                {step === 5 && <p className="verification-container__description">
                    {t(`verification.phoneValidated.description`)}
                </p>}
                {step === 7 && <p className="verification-container__description">
                    {t(`verification.accountValidated.description`)}
                </p>}
            </p>

            <form>
                <div className={`base-info ${step === 2 && 'none'}`}>
                    <div className="input-container">
                        {(step === 1 || step === 4) && <>
                            <label htmlFor={step === 3 ? "phoneInput" : "tokenInput"}>{t("verification." + (step === 3 ? "phoneInput" : "tokenInput"))}</label>
                            <input className={"form-input"} type={"number"} placeholder={t("verification.code")} name={"token"} ref={register({ required: true, minLength: 6, maxLength: 6 })} />
                        </>}
                        {step === 3 && <CountryAndPhoneSelect country={country}
                            setCountry={setCountry}
                            setDialCode={setDialCode} setValue={setValue}
                            control={control}
                            defaultPhone={(user?.dialCode && user?.phone) ? (user?.dialCode + " " + user?.phone) : undefined}
                            phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber}
                            errors={errors} clearCountryError={() => clearErrors('country')}
                        />}
                        {errors.token?.message && <p className={"error-message"}>{t("verification.invalidCode")}</p>}
                        {errors.password?.message && <p className={"error-message"}>{errors.password?.message}</p>}
                    </div>
                </div>

                <div className="btn-container">
                    {step === 7 ? <button className="btn btn-primary" onClick={() => { location.href = "/dashboard"; }}>
                        {t("verification.exit")}
                    </button> : <button type={'button'} className={'btn btn-primary'}
                        disabled={continueDisabled}
                        onClick={() => onSubmit()}>
                        {t((step === 1 || step === 4) ? "verification.verify" : step === 7 ? "verification.exit" : "verification.continue")}
                    </button>}
                </div>

            </form>
            {step === 3 && <div className="verification-container__actions">
                <p>{t("verification.updatePhone.helper")}</p>
            </div>}
            {step === 4 && <div className="verification-container__actions">
                <p><Trans i18nKey="verification.phone.notCorrectPhone" components={{ a: <a className="blue-link-label" onClick={() => setStep(3)} /> }} /></p>
            </div>}
            <div className="verification-container__actions">
                {step !== 2 && step !== 3 && step !== 5 && step !== 7 && (isLoading === "sendingEmails" ? <p>{t("verification.sendingCode")}</p> : <p>{t("verification.didntReceiveCode")}</p>)}
                <div className={"VerificationContainer-action__btn"}>
                    {(step === 1 || step === 4) && <a type={'button'} className={'blue-link-label'} onClick={resend}>
                        {t('buttons.resend')}
                    </a>}
                </div>
            </div>
        </div>
    )
}


export default VerificationContainer;
