import React, {useEffect, useState} from "react";
import {BiInfoCircle} from "react-icons/bi";

import './style.less'
import {useTranslation} from 'react-i18next';

const months = ["january","february","march","april","may","june","july","august","september","october","november","december"];

const RentByMonth = ({fixedPrice,rent,register,errors,rentMonths,setRentMonths,pageLabel}) => {

    const {t} = useTranslation();
    let [rmChange, setRmChange] = useState(0);

    useEffect(() => {
        let monthsPricesFinal = {};

        months.map(month => {
            monthsPricesFinal[month] = rentMonths ? rentMonths[month] : rent;
        })

        setRentMonths(monthsPricesFinal);
        setRmChange(rmChange+1);
    },[rent])

    const changeMonthValue = (month,value) => {
        if(value > 9999 || value < 0) return;
        setRentMonths({...rentMonths, [month]: value})
    }

    //It can only be defined 3 diferent values for the rents
    const isValidFieldset = () => {
        let monthsPricesValuesArray = Object.values(rentMonths)
        let differentValues=monthsPricesValuesArray.filter((x, i, a) => a.indexOf(x) == i).length;

        return !(differentValues > 3 || differentValues === 0);
    }

    months.forEach(month => (document.getElementById('roomPrice_' + month)?.addEventListener('keypress', preventDotsAndCommas)))

    function preventDotsAndCommas(e){
        if (e.charCode == 46) e.preventDefault();
        if (e.charCode == 44) e.preventDefault();
    }

    return (
        <div className={"RentByMonth"}>
            <fieldset
                className={"RentByMonth__months"} id={'rentMonths'} name={'rentMonths'} tabIndex={-1}
                ref={register({ validate:(value) => isValidFieldset() || t(pageLabel+'.rentMonths.info')})}>
                {months.map(month => (
                    <div key={month + rmChange} className={"RentByMonth__months-month"}>
                        <label>{t(pageLabel+'.rentMonths.'+month)}</label>
                        <input name={"rentMonths." + month} type="number" value={rentMonths ? rentMonths[month] : rent} id={"roomPrice_"+month}
                               ref={register("rentMonths."+month)} onClick={(e) => e.target.select()}
                               onChange={(value) => changeMonthValue(month, value.nativeEvent.target.valueAsNumber)}
                               placeholder={`€400`} className={`basic_container input_field`} onWheel={(e) => e.target.blur()}/>
                    </div>
                ))}
            </fieldset>
            <div className={"RentByMonth__info" + (errors.rentMonths ? " error_message" : "")}  style={{flex:'1'}}><BiInfoCircle/><p>{t(pageLabel+'.rentMonths.info')}</p></div>
        </div>
    )
}

export default RentByMonth;

export {months};