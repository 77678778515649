import React, {useState} from "react";
import "./style.less";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

const FiltersContainer = ({pages, type, horizontal}) => {


    const {t} = useTranslation();
    const history = useHistory();

    const changeFilterHandler = (e) => {
        e.preventDefault();
        let next = (e.target.href || e.target.parentElement.href).replace(location.origin, '');
        history.push(next);
    }

    const filterActive = (nav) => {
        if(nav.toLowerCase() === "/dashboard" && window.location.pathname.toLowerCase() === nav.toLowerCase()) return "active";
        if(nav.toLowerCase() !== "/dashboard" && window.location.pathname.toLowerCase().includes(nav.toLowerCase())) return "active"
    };


    return (
        <div className={"FiltersContainer__Container"}>
            <div className={"FiltersContainer" + (horizontal ? " horizontal" : "")}>
                {pages.map((page) => {
                    const { icon } = page;
                    const PageIcon = icon;
                    const [isIconActive, setIsIconActive] = useState((!!filterActive(`/${type}/${page.path}`)));

                    return (
                        <a key={page.label} href={`/${type}/${page.path}`}
                            className={filterActive(`/${type}/${page.path}`) ? `active ${type === "my_account" ? "no-border" : ""}` : ""}
                            onClick={changeFilterHandler} onMouseEnter={() => { setIsIconActive(true) }} onMouseLeave={() => { setIsIconActive((!!filterActive(`/${type}/${page.path}`))) }}>
                            {icon && <PageIcon className={isIconActive ? "active" : ""} />}
                            <span>{`${t(page.label)} ${page.num ? page.num : ""}`}</span>
                        </a>
                    )})}
            </div>
        </div>
    )
}

export default FiltersContainer;