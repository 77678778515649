import React, {useEffect, useState} from 'react';

import './style.less';
import PropertyAreas1 from './levels/PropertyAreas1';
import PropertyAreas2 from './levels/PropertyAreas2';
import {connect} from 'react-redux';

const pageLabel = 'main_page.my_ads.create_ad.house_details.property_areas'

const pages = [
  {
    title: pageLabel + '.level1.title',
    level: '1.3.1',
    component: PropertyAreas1
  },
  {
    title: pageLabel + '.level2.title',
    level: '1.3.2',
    component: PropertyAreas2
  }
];

const PropertyAreas = ({updateProperty, currentPageLevel, t, property, changePage}) => {

  const [currentPage, setCurrentPage] = useState(pages.find((page) => (currentPageLevel === page.level)));

  useEffect(() => {
    setCurrentPage(pages.find((page) => (currentPageLevel === page.level)))
  }, [currentPageLevel]);

  return (
    <div className={'PropertyAreas'}>
      <div>
        <h4 className={'title'}>{t(currentPage.title)}</h4>
        <div  className={"section_content"}>
          <currentPage.component currentLevel={currentPage.level} updateProperty={updateProperty} t={t}
                                 property={property} changePage={changePage}/>
        </div>
      </div>
    </div>
  );
};

export default PropertyAreas;
