import React, { useState, useRef } from "react";
import "./style.less";

import useOutsideClick from "../../../hooks/useOutsideClick";
import ExpandMoreIcon from "../../svg/ExpandMore";
import ExpandLessIcon from "../../svg/ExpandLess";

const DropdownSelect = ({ options, title, handleChange, selectedValue, placeholder }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => setIsOpen(!isOpen);
    const dropdownRef = useRef(null);

    useOutsideClick(dropdownRef, () => {
        setIsOpen(false);
    });

    const handleOptionClick = (option) => {
        handleChange(option);
        setIsOpen(false);
    };

    return (
        <div className="dropdown-select" ref={dropdownRef}>
            {title && <label>{title}</label>}

            <div className="dropdown__container">
                <div className={`dropdown__header ${isOpen && 'active'}`} onClick={toggleDropdown}>
                    {placeholder}
                    {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </div>

                {isOpen && (
                    <div className="dropdown-list__container">
                        <ul className="dropdown-list">
                            {options.map((option) => (
                                <li
                                    className="dropdown-list__item"
                                    onClick={() => handleOptionClick(option)}
                                    key={option.label}
                                >
                                    {option.label}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DropdownSelect;
