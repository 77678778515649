import {useTranslation} from 'react-i18next';

const verifyAttributeExistenceToString = (object, attribute, addToEndLabel) => {
    const {t, i18n} = useTranslation();

    let endResult = ''

    if (Array.isArray(attribute)) {
        for (let i = 0; i < attribute.length; i++) {
            if (object?.[attribute[i]]) {
                endResult += `${object[attribute[i]]}${i === attribute.length - 1 ? '' : ', '}`;
            }
        }
        if(endResult === '') return t('tbd');
        return endResult;
    } else {
        if (object?.[attribute]) {
            endResult = object[attribute];

            if (addToEndLabel) endResult = endResult + ' ' + addToEndLabel
        }
    }
    if(endResult === '') return t('tbd');
    return endResult;
}

export default verifyAttributeExistenceToString;
