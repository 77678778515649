import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';

import './style.less';
import Buttons from '../../../../../components/Buttons';
import {useForm} from 'react-hook-form';
import AmountButtonsInputContainer from '../../../../../components/AmountButtonsInputContainer';
import {updatePropertyActions} from "../../../../../../../../../../../redux/actions/updatePropertyActions";

const pageLabel = 'main_page.my_ads.create_ad.house_details.property_areas.level1';

const PropertyAreas1 = ({updateProperty, t, currentLevel, property, changePage, defineUpdateFunc}) => {

  const [minAreasError, setMinAreasError] = useState(false);
  const minAreasErrorRef = useRef(null);

  const onSubmit = async (values) => {
    setMinAreasError(false);
    if(property.accommodation === "apartment" && (values.numKitchen + values.numLivingRoom + values.numBathroom) === 0) {
      setMinAreasError(true)
      return true;
    }
    await updateProperty(values, currentLevel);
  };

  useEffect(() => {
    if(minAreasError)
      minAreasErrorRef.current.scrollIntoView({behavior: 'smooth', block: 'center'})
  }, [minAreasError])

  const {handleSubmit, register, errors, watch, setValue, formState} = useForm({shouldFocusError: true});

  useEffect(() => {
    defineUpdateFunc(async () => new Promise(async (resolve) => {
      if(!formState.isDirty) return resolve();
      await handleSubmit(async (values) => {
        resolve(await onSubmit(values))
      }, resolve)()
    }))
  }, [currentLevel, formState.isDirty]);

  let amountContainers = [{info: t(pageLabel + '.numKitchen.info'), name: 'numKitchen', value: property.numKitchen},
    {name: 'numLivingRoom', value: property.numLivingRoom},
    {info: t(pageLabel + '.numBathroom.info'), name: 'numBathroom', value: property.numBathroom},
    {name: 'numYard', value: property.numYard},
    {name: 'numTerrace', value: property.numTerrace},
    {name: 'numBalcony', value: property.numBalcony},
    {name: 'numRooftop', value: property.numRooftop},
    {name: 'numPool', value: property.numPool},
    {name: 'numGym', value: property.numGym},
    {name: 'numGarage', value: property.numGarage}];

  return (
    <div className={'PropertyAreas1'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={'scrollable'}>
          <h2 id="title">{t(pageLabel + '.label')}</h2>
          <div id="sub_title">
            <p>{t(pageLabel + '.subtitle2')}</p>
          </div>
          <div className="AmountButtonsInputContainers">
            {amountContainers.map((container, index) =>
              <AmountButtonsInputContainer t={t} labelPreFix={pageLabel} key={index} watch={watch} setValue={setValue}
                                           {...container} errors={errors} minAreasError={minAreasError}
                                           register={register({valueAsNumber: true})}/>)}
          </div>
          {minAreasError && <p ref={minAreasErrorRef} className={"error_message"}>{t(pageLabel + '.minAreasError')}</p>}
        </div>
        <Buttons saveAndExit={handleSubmit(v=>onSubmit({...v, saveAndExit: true}))} changePage={changePage}/>
      </form>
    </div>
  );
};

const actionCreator = {
  defineUpdateFunc: updatePropertyActions.defineUpdateFunc
};

export default connect(null, actionCreator)(PropertyAreas1);
