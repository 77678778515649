import React, {useEffect, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import {connect} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import {useTranslation} from 'react-i18next';

import Buttons from '../../../../../components/Buttons';
import {FieldSetCheckBoxes} from '../../../../../components/FieldSetCheckBoxes';
import RadioButtons from '../../../../../components/RadioButtons';
import livecallIcon from '../../images/livecallIcon.svg';
import onsiteIcon from '../../images/onsiteIcon.svg';
import VisitWeekDays from '../../components/VisitWeekDays';
import RadioButtonsWithIcons from '../../../../../components/RadioButtonsWithIcons';
import {updatePropertyActions} from '../../../../../../../../../../../redux/actions/updatePropertyActions';
import {DialogTitle} from '../../../../../../../../../../../components/MUIDialogComponents';

import './style.less';

import conversionIcon from './images/conversionIcon.svg';
import durationIcon from './images/durationIcon.svg';
import topLandlordIcon from './images/topLandlordIcon.svg';
import checkCircleIcon from './images/checkCircleIcon.svg';
import Select from "react-select";
import {dataServices} from "../../../../../../../../../../../services/dataServices";

const pageLabel = 'main_page.my_ads.create_ad.house_details.landlord_rules.level1';

const VisitTypesModal = ({open, setOpen}) => {
  const {t} = useTranslation();

  const componentLabel = pageLabel + '.visit_types_modal'

  const informationContainers =
    [
      {
        icon: durationIcon,
        label: t(componentLabel + '.duration.label'),
        description: t(componentLabel + '.duration.description')
      },
      {icon: conversionIcon, label: '>70%', description: t(componentLabel + '.conversion.description')},
      {
        icon: topLandlordIcon,
        label: t(componentLabel + '.top_landlord.label'),
        description: t(componentLabel + '.top_landlord.description')
      }
    ];

  const informationParagraphs = ['p1', 'p2', 'p3', 'p4']

  const handleClose = () => setOpen(false);

  return (
    <Dialog open={open} onClose={handleClose} className={`VisitTypesModal`}>
      <DialogTitle className="DialogHeader" onClose={handleClose}/>
      <div className={'DialogContent'}>
        <h4>{t(componentLabel + '.label')}</h4>
        <div className={'scrollable'}>
          <div className={'InformationContainers'}>
            {informationContainers.map(container => (
              <div className={'InformationContainer'}>
                <img src={container.icon}/>
                <h5>{container.label}</h5>
                <p>{container.description}</p>
              </div>
            ))}
          </div>
          <div className={'InformationParagraphs'}>
            {informationParagraphs.map(p => (
              <div>
                <img src={checkCircleIcon}/>
                <p>{t(`${componentLabel}.${p}`)}</p>
              </div>
            ))}
          </div>
        </div>
        <button className={'btn btn_blue'} onClick={handleClose}>{t('buttons.continue')}</button>
      </div>
    </Dialog>
  )
}

const LandlordRules1 = ({updateProperty, currentLevel, property, changePage, defineUpdateFunc}) => {
  const {t} = useTranslation();

  const [timezones, setTimezones] = useState([]);
  const dirtyTimezone = useRef(false);

  useEffect(() => {
    (async () => {
      let _timezones = await dataServices.getTimezones();

      let timezoneLabels = Object.keys(_timezones).map(t => {
        return {value: t, label: t + " (" + _timezones[t].desc + ")"}
      });
      setTimezones(timezoneLabels);
    })();
  }, []);

  const onSubmit = async (values) => {
    //the visit_weekdays can't be empty
    values.visit_weekdays = {};

    currentProperty.visit_weekdays.map(weekday => {
      const arrayLength = values[weekday] ? values[weekday].start.length : 0;

      if (arrayLength === 0) return;

      values.visit_weekdays[weekday] = {start: [], end: []};
      for (let i = 0; i < arrayLength; i++) {
        values.visit_weekdays[weekday].start[i] = values[weekday].start[i].label;
        values.visit_weekdays[weekday].end[i] = values[weekday].end[i].label;
      }
    });

    values.accept_visitors = values.accept_visitors === 'true';
    values.disallow_visit_guests = values.disallow_visit_guests === 'true';
    values.languages = currentProperty.languages;
    values.timezone = currentProperty.timezone;

    await updateProperty(values, currentLevel);

    dirtyTimezone.current = false;
  };

  const getLandlordTimezone = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    //TODO: use landlord timezone when auth is implemented on this page
    return timezone;
  }

  const [currentProperty, setCurrentProperty] = useState(() => {
    return {
      ...property,
      accept_visitors: property.hasOwnProperty('accept_visitors') ? property.accept_visitors.toString() : 'true',
      disallow_visit_guests: property.hasOwnProperty('disallow_visit_guests') ? property.disallow_visit_guests.toString() : 'false',
      accept_visit_type: property.accept_visit_type ? property.accept_visit_type : 'livecall',
      visit_weekdays: property.visit_weekdays ? Object.keys(property.visit_weekdays) : [],
      visit_weekdays_times: property.visit_weekdays ? property.visit_weekdays : {},
      languages: property.languages ? property.languages : [],
      timezone: property.timezone ? property.timezone : getLandlordTimezone(),
    }
  });

  let languagesNames = ['portuguese', 'english', 'spanish', 'italian', 'german', 'french'];

  const radioButtons = {
    accept_visitors: [
        {id: 'accept_visitors', buttonValue: 'true', label: 'yes'},
        {id: 'non_accept_visitors', buttonValue: 'false', label: 'no'}
    ],
    disallow_visit_guests: [
        {id: 'disallow_visit_guests', buttonValue: 'false', label: 'yes'},
        {id: 'allow_visit_guests', buttonValue: 'true', label: 'no'}
    ],
    acceptVisitorTypes: [
        {icon: livecallIcon, id: 'livecall', buttonValue: 'livecall', label: pageLabel + '.accept_visit_type.livecall'},
        {icon: onsiteIcon, id: 'onsite', buttonValue: 'onsite', label: pageLabel + '.accept_visit_type.onsite'}
    ]
  };

  const {handleSubmit, control, register, errors, setValue, watch, formState} = useForm({shouldFocusError: true});

  const handlePropertyFieldChange = (field, value) => {
    setCurrentProperty(prevState => ({...prevState, [field]: value}))
    if(field === "timezone") {
      dirtyTimezone.current = true;
    }
  };

  const watchFields = watch(['accept_visitors', 'accept_visit_type']);

  useEffect(() => defineUpdateFunc(async () => new Promise(async (resolve) => {
    if (!dirtyTimezone.current && (!formState.isDirty || Object.keys(formState.dirtyFields).length === 0)) return resolve();
    await handleSubmit(async (values) => {
      await onSubmit(values);
      resolve()
    }, resolve)()
  })), [currentProperty, formState.isDirty, formState.dirtyFields, dirtyTimezone.current]);

  const clearVisitWeekdays = () => {
    if (watchFields.accept_visitors === 'true') {
      handlePropertyFieldChange('visit_weekdays', []);
      handlePropertyFieldChange('visit_weekdays_times', {});
    }
  };

  const showSection = () => watchFields.accept_visitors === 'true' || (!watchFields.accept_visitors && currentProperty.accept_visitors === 'true');

  const [visitTypesModalOpen, setVisitTypesModalOpen] = useState(false);

  return (
    <div className={'LandlordRules1'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={'scrollable'}>
          <div className="LandlordRules_container">
            <div className={'input_container'}>
              <label htmlFor={'accept_visitors'}>{`${t(pageLabel + '.accept_visitors.label')}`}</label>
              <RadioButtons
                radioButtons={radioButtons.accept_visitors} name={'accept_visitors'}
                value={currentProperty.accept_visitors}
                className={'double'}
                onChange={clearVisitWeekdays}
                control={control}
                rules={{required: true}}/>
              <p className={'info'}>{t(pageLabel + '.accept_visitors.info')}</p>
            </div>

            {showSection() &&
             <div className={'input_container'}>
               <label htmlFor={'accept_visit_type'}>{`${t(pageLabel + '.accept_visit_type.label')}`}</label>
               <RadioButtonsWithIcons
                 radioButtons={radioButtons.acceptVisitorTypes}
                 valueName={'accept_visit_type'}
                 name={'accept_visit_type'}
                 value={currentProperty.accept_visit_type}
                 setValue={(value) => {
                   setValue('accept_visit_type', value, {shouldValidate: true})
                 }}
                 className={'double boxes basic_container'}
                 control={control}
                 rules={{required: true}}
                 watchValue={watchFields.accept_visit_type}
               />

               <div className="input_container_link">
                 <p className={'link link_blue'}
                    onClick={() => setVisitTypesModalOpen(true)}>{t(pageLabel + '.accept_visit_type.link')}</p>
                 <VisitTypesModal open={visitTypesModalOpen} setOpen={setVisitTypesModalOpen}/>
               </div>
             </div>}
          </div>

          {showSection() &&
           <div className="LandlordRules_container">
             <div className={'input_container'} id={'visit_week_days'} tabIndex={-1}>
               <label htmlFor={'visit_week_days'}>{t(pageLabel + '.visit_week_days.label')}</label>
               <div id={'input_times'}>
                 <VisitWeekDays weekdays={currentProperty.visit_weekdays}
                                weekdaysWithTimes={currentProperty.visit_weekdays_times}
                                setWeekDays={(value) => handlePropertyFieldChange('visit_weekdays', value)}
                                currentProperty={currentProperty}
                                pageLabel={pageLabel}
                                control={control}
                                errors={errors}
                                setValue={setValue}
                 />
               </div>
               {(formState.isSubmitted && currentProperty.visit_weekdays?.length === 0) &&
                <p className={'error_message'}>{t(pageLabel + '.visit_week_days.error')}</p>}
             </div>
           </div>}

          {showSection() &&
           <div className="LandlordRules_container LandlordRules_container_last">
             <div className={'input_checkboxes input_container'}>
               <label htmlFor={'languages'}>{`${t(pageLabel + '.languages.label')}`}</label>
               <FieldSetCheckBoxes t={t} checkBoxesNames={languagesNames} name={'languages'}
                                   values={currentProperty.languages}
                                   setValues={(value) => handlePropertyFieldChange('languages', value)}
                                   register={register}
                                   defaultObjectLabel={(value) => pageLabel + '.languages.' + value}/>

             </div>
           </div>}

          {showSection() && <div className={'input_container'}>
            <label htmlFor={'disallow_visit_guests'}>{`${t(pageLabel + '.disallow_visit_guests.label')}`}</label>
            <RadioButtons
                radioButtons={radioButtons.disallow_visit_guests} name={'disallow_visit_guests'}
                value={currentProperty.disallow_visit_guests}
                className={'double'}
                control={control}
                rules={{required: true}}/>
          </div>}

          {showSection() && <div className={'input_container'}>
            <label htmlFor={"timezone"}>{t("timezone")}</label>
            <Select
                name={"timezone"}
                options={timezones}
                defaultValue={timezones.find(tz => tz.value === property.timezone)}
                value={timezones.find(tz => tz.value === currentProperty.timezone)}
                onChange={(value) => handlePropertyFieldChange('timezone', value.value)}
                register={register}
                control={control}
                />
          </div>}
        </div>

        <Buttons saveAndExit={handleSubmit(v => onSubmit({...v, saveAndExit: true}))} changePage={changePage}/>
      </form>
    </div>
  );
};

const actionCreator = {
  defineUpdateFunc: updatePropertyActions.defineUpdateFunc
};

export default connect(null, actionCreator)(LandlordRules1);
