import Loading from "../../Loading";
import moment from "moment/moment";
import worldIcon from "../../../images/world_black_18dp.svg";
import {Trans, useTranslation} from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import React, {useMemo, useState} from "react";
import {ReactSelect} from "../../ReactSelect";
import {visitServices} from "../../../services";
import momenttz from "moment-timezone";
import {useHistory} from "react-router-dom";


const ScheduleDialog = ({openDialog, closeDialog, visit, setVisitMoment, setActionDialog}) => {

    const {t} = useTranslation();
    const history = useHistory();



    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [loading, setLoading] = useState(false);


    const hourOptions = useMemo(() => {
        let hourOptions = [];
        if (selectedDate) {
            let sdStart = selectedDate.start, sdEnd = selectedDate.end;
            if (sdStart.length < 5) sdStart = "0" + sdStart;
            if (sdEnd.length < 5) sdEnd = "0" + sdEnd;
            let chStart = moment(selectedDate.date + " " + sdStart);
            let chEnd = moment(selectedDate.date + " " + sdEnd);
            while (chStart.isSameOrBefore(chEnd)) {
                if (chStart.diff(moment(), "minutes") >= 25) hourOptions.push(chStart.format("HH:mm"));
                chStart.add(15, "minute");
            }
        }
        return hourOptions.map(hour => ({value: hour, label: hour}));
    }, [selectedDate])

    const handleSelectDate = async () => {
        setLoading(true);
        try {
            let selectedMoment = momenttz.tz(selectedDate.date + " " + selectedTime, visit.timezone || "Europe/Lisbon")
            await visitServices.acceptVisit(visit.id, selectedMoment);
            setVisitMoment(selectedMoment);
            closeDialog();
            setActionDialog("visit_accepted");
            let listing_id = visit.property?.id;
            if(visit.property?.accommodation !== "apartment") {
                listing_id += "_" + (visit.property?.room?.id || visit.room_id);
            }
            setTimeout(() => {
                history.push("/my_visits/scheduled");
            } , 2000)
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };

    return(
        <Dialog
            onClose={closeDialog}
            className="VisitCard__schedule-dialog"
            open={!!openDialog}>
            {openDialog && <div className={"schedule-dialog-container"}>
                {loading && <Loading cover={0.3}/>}
                <p className={"title"}>{t('main_page.my_visits.visit_card.schedule.title', {name: visit.user.name.split(" ")[0]})}</p>
                <div className={"schedule-dialog-body"}>
                    <div className={"left"}>
                        <p>{t('main_page.my_visits.visit_card.schedule.pick_day')}</p>
                        <div className={'day-picker-container'}>
                            {visit.chosenDates?.map((chosenDate, cdIndex) => (<div key={"cdd" + cdIndex}
                                                                                           onClick={() => {
                                                                                               setSelectedTime(null);
                                                                                               setSelectedDate(chosenDate)
                                                                                           }}
                                                                                           className={"date-box" + (selectedDate === chosenDate ? ' active' : '')}>
                                <span>{moment(chosenDate.date).utcOffset("+10:00").locale(localStorage.getItem('i18nextLng').split('-')[0]).format('DD MMM YYYY')}</span>
                                <strong>{chosenDate.start}-{chosenDate.end}</strong>
                            </div>))}
                        </div>
                    </div>
                    <div className={"div"}/>
                    <div className={"right"}>
                        {selectedDate &&
                            <div className={"select-date-time-container"}>
                                <div
                                    className={"date-box-title"}>{moment(selectedDate.date).utcOffset("+10:00").locale(localStorage.getItem('i18nextLng').split('-')[0]).format('DD MMM YYYY')}</div>
                                <p>{t('main_page.my_visits.visit_card.schedule.visit_req')}</p>
                                <div className={"date-box-title"}>
                                    {moment(selectedDate.date).utcOffset("+10:00").locale(localStorage.getItem('i18nextLng').split('-')[0]).format('DD MMM YYYY')}
                                    {" " + t('main_page.my_visits.visit_card.schedule.period') + " "}
                                    {selectedDate.start}-{selectedDate.end}h
                                </div>
                                <div className={"div-horiz"}/>
                                <p>{t('main_page.my_visits.visit_card.schedule.define_hour')}</p>
                                <ReactSelect
                                    placeholder={hourOptions[0]?.value}
                                    options={hourOptions}
                                    value={selectedTime ? selectedTime.value : null}
                                    onChange={(value) => {
                                        setSelectedTime(value.value);
                                    }}
                                />
                                <div className={"timezone"}><img src={worldIcon}
                                                                 alt={"worldIcon"}/>{t('main_page.my_visits.visit_card.schedule.timezone', {timezone: visit.timezone || "Europe/Lisbon"})}
                                </div>
                            </div>}
                    </div>
                </div>
                <span>
                    <Trans i18nKey={'main_page.my_visits.visit_card.schedule.terms'} components={{
                        name: visit.user.name.split(" ")[0],
                        date: selectedDate ? moment(selectedDate.date).utcOffset("+10:00").locale(localStorage.getItem('i18nextLng').split('-')[0]).format('DD MMM YYYY') : 'N/A',
                        time: selectedTime || 'N/A',
                        timezone: visit.timezone || "Europe/Lisbon",
                        l1: <a className="link link_blue" target="_blank"
                               href={"https://inlifehousing.com/terms-conditions/"}/>,
                        l2: <a className="link link_blue" target="_blank"
                               href={"https://inlifehousing.com/privacy-policy/"}/>
                    }}/>
                </span>
                <div className="schedule-dialog-buttons">
                    <button className="btn btn_white shadow" onClick={closeDialog}>
                        {t('buttons.cancel')}
                    </button>
                    <button className="btn btn_blue shadow"
                            onClick={handleSelectDate}
                            disabled={!selectedDate || !selectedTime || loading}>
                        {t('buttons.confirm')}
                    </button>
                </div>
            </div>}
        </Dialog>
    )
}


export default ScheduleDialog;
