import moment from "moment";

const formatDayMonthToMoment = (day, month, year) => {
    if(year) return formatDayMonthYearToMoment(day, month, year);
    return moment(new Date().getFullYear() + "-" + month + "-" + day, "YYYY-MM-DD");
}

const formatDayMonthYearToMoment = (day, month, year) => {
    return moment(year + "-" + month + "-" + day, "YYYY-MM-DD");
}


const calcFirstAvailabilityBasedOnUnavailabilities = (room) => {
    if(!room) return null;
    let firstAvailability = moment(room.availableFrom);
    if(firstAvailability.isBefore(moment()) || !firstAvailability.isValid()) firstAvailability = moment();
    [...(room.unavailability||[]), ...(room.importedUnavailability||[])]
        .filter(unavailability => {
            if(!unavailability || unavailability.removed) return false;
            let momentEnd = moment(unavailability.end);
            return momentEnd.isAfter(firstAvailability) || momentEnd.isSame(firstAvailability);
        }).sort((u1,u2) => {
        let d1 = new Date(u1.end), d2 = new Date(u2.end);
        return d1 > d2 ? 1 : d1 < d2 ? -1 : 0;
    }).forEach(unavailability => {
        let startMoment = moment(unavailability.start);
        let endMoment = moment(unavailability.end);
        if((firstAvailability.isAfter(startMoment) || firstAvailability.isSame(startMoment)) &&
            (firstAvailability.isBefore(endMoment) || firstAvailability.isSame(endMoment))) {
            firstAvailability = endMoment.clone().add(1, "day");
        }
    });
    return firstAvailability
}

const calcFirstAvailability = (room) => {
    const now = moment();
    if(!room) return now;
    let firstAvailabilityBasedOnUnavailabilities = calcFirstAvailabilityBasedOnUnavailabilities(room);
    if(!room.hasAvailability) return firstAvailabilityBasedOnUnavailabilities;
    const roomAvailableFrom = moment(room.availableFrom);
    let firstAvailability = moment.max([roomAvailableFrom, now, firstAvailabilityBasedOnUnavailabilities]);

    let availability_periods = room.availability_periods || [];

    availability_periods = availability_periods.sort((slot1, slot2) => {
        const currentYear = firstAvailability.year();
        const slot1moveInStart = new Date(slot1.temporary_year ? (slot1.temporary_year - 1) : currentYear, slot1.move_in.start.month - 1, slot1.move_in.start.day);
        const slot2moveInStart = new Date(slot2.temporary_year ? (slot2.temporary_year - 1) : currentYear, slot2.move_in.start.month - 1, slot2.move_in.start.day);
        let slot1moveInEnd = new Date(slot1moveInStart.getFullYear(), slot1.move_in.end.month - 1, slot1.move_in.end.day);
        let slot2moveInEnd = new Date(slot2moveInStart.getFullYear(), slot2.move_in.end.month - 1, slot2.move_in.end.day);
        while(slot1moveInEnd < slot1moveInStart) slot1moveInEnd.setFullYear(slot1moveInEnd.getFullYear() + 1)
        while(slot2moveInEnd < slot2moveInStart) slot2moveInEnd.setFullYear(slot2moveInEnd.getFullYear() + 1)
        let betweenSlot1 = firstAvailability.isBetween(slot1moveInStart, slot1moveInEnd, null, "[]");
        let betweenSlot2 = firstAvailability.isBetween(slot2moveInStart, slot2moveInEnd, null, "[]");
        if (betweenSlot1 && !betweenSlot2) {
            return -1;
        } else if (!betweenSlot1 && betweenSlot2) {
            return 1;
        } else if (betweenSlot1 && betweenSlot2) {
            return slot1moveInEnd - slot2moveInEnd;
        }
        while (moment(slot1moveInStart).isBefore(firstAvailability)) slot1moveInStart.setFullYear(slot1moveInStart.getFullYear() + 1);
        while (moment(slot2moveInStart).isBefore(firstAvailability)) slot2moveInStart.setFullYear(slot2moveInStart.getFullYear() + 1);
        return slot1moveInStart - slot2moveInStart;
    });

    // If there are no availability periods, return the first availability based on unavailabilities
    if (availability_periods.length < 1 || room.hasAvailability === false) {
        return firstAvailabilityBasedOnUnavailabilities;
    }

    const unavailabilities = [...(room.unavailability || []), ...(room.importedUnavailability || [])]
        .filter(unavailability => !unavailability.removed && moment(unavailability.start).isAfter(now))
        .sort((u1, u2) => moment(u1.end) - moment(u2.end));

    // Find the earliest valid availability slot
    let earliestValidAvailability = null;
    let curYear = firstAvailability.year();
    if(availability_periods[0].temporary_year) {
        curYear = availability_periods[0].temporary_year;
    }
    //prevent infinite loop, max 10 years
    while(!earliestValidAvailability && curYear < firstAvailability.year() + 10) {
        for (const availability of availability_periods) {
            let moveInStart = formatDayMonthToMoment(availability.move_in.start.day, availability.move_in.start.month, curYear);
            let moveInEnd = formatDayMonthToMoment(availability.move_in.end.day, availability.move_in.end.month, curYear);

            if (moveInEnd.isBefore(moveInStart)) {
                moveInEnd.add(1, "year");
            }

            if (moveInEnd.isBefore(firstAvailability, "day")) {
                continue; // is before first availability
            }
            let moveOutStart = formatDayMonthToMoment(availability.move_out.start.day, availability.move_out.start.month, curYear);
            let moveOutEnd = formatDayMonthToMoment(availability.move_out.end.day, availability.move_out.end.month, curYear);

            if (moveOutStart.isBefore(moveInEnd, "day")) {
                moveOutStart.add(1, "year");
                moveOutEnd.add(1, "year");
            }

            if (moveInStart.isBefore(firstAvailability, "day")) {
                moveInStart = firstAvailability.clone();
            }

            if (unavailabilities.some(u => {
                const uStart = moment(u.start).startOf("day");
                const uEnd = moment(u.end).startOf("day");
                return (uStart.isSameOrAfter(moveInStart) && uEnd.isSameOrBefore(moveOutEnd)) ||
                    (uStart.isBefore(moveInStart) && uEnd.isAfter(moveInEnd)) ||
                    (uStart.isBefore(moveOutStart) && uEnd.isAfter(moveOutEnd));
            })) {
                continue; // overlaps with an unavailability
            }

            let earliestSemiOverlap = null;
            for(const unavailability of unavailabilities) {
                const uStart = moment(unavailability.start).startOf("day");
                const uEnd = moment(unavailability.end).startOf("day");
                if(uStart.isBefore(moveInStart) && uEnd.isBefore(moveInEnd) && uEnd.isAfter(moveInStart)) { //unavailability ends before the last move in day on this availability
                    earliestSemiOverlap = uEnd.clone().add(1, "day");
                }
            }

            earliestValidAvailability = earliestSemiOverlap || moveInStart;
            break;
        }
        curYear++;
    }

    if (earliestValidAvailability === null) {
        firstAvailability = calcFirstAvailabilityBasedOnUnavailabilities(room);
        for (const availability of availability_periods) {
            let start = formatDayMonthYearToMoment(availability.move_in.start.day, availability.move_in.start.month, firstAvailability.year());
            if (start.isSameOrAfter(earliestValidAvailability, "day")) {
                earliestValidAvailability = start;
                break;
            }
        }
        if (earliestValidAvailability === null) earliestValidAvailability = formatDayMonthYearToMoment(availability_periods[0].move_in.start.day, availability_periods[0].move_in.start.month, firstAvailability.year() + 1);
    }

    return earliestValidAvailability;
}

export {calcFirstAvailability};
