import React, { useEffect, useState } from "react";

import "./style.less";
import PropertyDetails1 from "./levels/PropertyDetails1";
import PropertyDetails0 from "./levels/PropertyDetails0";
import PropertyDetails2 from "./levels/PropertyDetails2";
import PropertyDetails3 from "./levels/PropertyDetails3";
import PropertyDetails5 from "./levels/PropertyDetails5";


const pages = [
      {
        label: "main_page.my_ads.create_ad.house_details.property_details.level0.title",
        level:'1.1.0',
        component:PropertyDetails0,
      },
      {
        label: "main_page.my_ads.create_ad.house_details.property_details.level1.title",
        level:'1.1.1',
        component:PropertyDetails1,
      },
      {
        label: "main_page.my_ads.create_ad.house_details.property_details.level2.title",
        level:'1.1.2',
        component:PropertyDetails2,
      },
      {
        label: "main_page.my_ads.create_ad.house_details.property_details.level3.title",
        level:'1.1.3',
        component:PropertyDetails3,
      },
      {
        label: "main_page.my_ads.create_ad.house_details.more_info.tittle",
        level: '1.1.5',
        component:PropertyDetails5,
      }
];


const PropertyDetails = ({ updateProperty, currentPageLevel, t, property, changePage, ...props}) => {

  const [currentPage,setCurrentPage]=useState(pages.find((page) => (currentPageLevel === page.level)));

  useEffect(() => {
    setCurrentPage(pages.find((page) => (currentPageLevel=== page.level)))
  },[currentPageLevel]);


  return (
    <div className={"PropertyDetails"}>
      <div>
        <h4 className={"title"}>{t(currentPage.label)}</h4>
        <div className={"section_content"}>
          <currentPage.component currentLevel={currentPage.level} updateProperty={updateProperty} changePage={changePage} property={property} currentRoomNumber={props.currentRoomNumber}/>
        </div>
      </div>
    </div>
  );
};



export default PropertyDetails;
