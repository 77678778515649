import { userConstants } from "../constants";

const stateInital = {};

export function signup(state = stateInital, action) {
  switch (action.type) {
    case userConstants.SIGNUP_REQUEST:
      return {
        signupPending: true,
        user: action.payload.email,
      };
    case userConstants.SIGNUP_SUCESS:
      return {
        signupDone: true,
        user: action.payload.user,
      };
    case userConstants.SIGNUP_FAIL:
    case userConstants.LOGOUT:
      return {};
    default:
      return state;
  }
}
