import React, {useCallback, useMemo, useState, useRef, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {propertyAdHistoryActions} from '../../../../../../redux/actions';
import {connect} from 'react-redux';
import {usePopupDialog} from '../../../../../../components/PopupDialog';
import {propertyServices} from '../../../../../../services';
import PropertyResume from '../../../../../../components/ResumeItem/PropertyResume';
import {useTranslation} from 'react-i18next';

import './style.less'
import SearchFilter from '../../../../../../components/SearchFilter';
import EditAdDialog from '../../../../../../components/EditAdDialog';
import Loading from '../../../../../../components/Loading';
import {BsPlusCircle} from 'react-icons/bs';
import FiltersContainer from "../../../../../../components/FiltersContainer";
import useQuery from "../../../../../../utils/query";
import PaginationLegacy from "../../../../../../components/PaginationLegacy";
import {useAuth} from "../../../../../../services/authServices";


const IntegrationList = ({userProperties, totalResults, filter, addHistoryReset, updateUserPropertiesList, totalListings, ...props}) => {

    const {user, setUser} = useAuth();

    const history = useHistory();

    const {t} = useTranslation();
    const query = useQuery();
    const [pages, setPages] = useState();
    const [page, setPage] = useState(parseInt(query.prevPage) || 0);
    const [loading, setLoading] = useState(true);
    const [searchFilter, setSearchFilter] = useState('');

    useEffect(() => {
        (async () => {
            await updateUserPropertiesList(page, {"searchKey": searchFilter});
            setLoading(false);
        })();
    }, [searchFilter])

    const userPropertiesFilter = useMemo(() => {
        if (!userProperties) return [];
        return userProperties;
    }, [userProperties]);

    const searchTimeout = useRef(null);

    const handleSearchKeyChange = (searchedKey) => {
        //use deboucer to set searchFilter
        window.clearTimeout(searchTimeout.current);
        if (searchedKey == null || (searchedKey.length > 0 && searchedKey.length < 2)) return;
        setLoading(true)
        searchTimeout.current = window.setTimeout(() => {
            setSearchFilter(searchedKey);
            setLoading(false)
        }, 250);
    }

    return (<div className={"container"} id={"horizontalbar_content"}>
        <div className={'IntegrationList'}>
            <div className="page__header">
                <div className="page__header__title">
                    <h3 className={"AccountDetails__title"}>{t(`main_page.integrations.page_header`)}</h3>
                </div>

                <div className={"page__header__integrations"}>
                    <SearchFilter placeholder={'integrations_placeholder'} onChange={handleSearchKeyChange} />

                    <PaginationLegacy total={totalListings} setPage={setPage} page={page}
                                          updateList={updateUserPropertiesList} filter={filter || "published"}
                                          setLoading={setLoading} searchKey={searchFilter} isListings={false}/>
                </div>

            </div>


            {userProperties == null || loading ? <Loading/> : (userPropertiesFilter.length > 0
                ? userPropertiesFilter.map(property =>
                    (<PropertyResume key={property.id}
                                     tabIndex={'-1'}
                                     property={property}
                                     type={'Integrations'}
                                     history={history}
                                     currentPage={page}
                                     filter={filter}
                    />))
                :
                <div className={'AdsList_noAds'}>
                    <p>{t(`main_page.integrations.no_ads_to_show`)}</p>
                </div>)
            }
        </div>
    </div>)

};

export default IntegrationList;

