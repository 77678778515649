import React, {useEffect, useState, useCallback} from 'react';
import {connect} from 'react-redux';

import './style.less';
import Buttons from '../../../../../components/Buttons';
import {useForm} from 'react-hook-form';
import RadioButtons from '../../../../../components/RadioButtons';
import {FieldSetCheckBoxes} from '../../../../../components/FieldSetCheckBoxes';
import PriceComponent from '../../../../../components/PriceComponent';
import {updatePropertyActions} from '../../../../../../../../../../../redux/actions/updatePropertyActions';

const pageLabel = 'main_page.my_ads.create_ad.house_details.landlord_rules.level3'

const getBillsIncludedInitialValues = (property) => {
  if (property?.billsIncluded === true && property.billsMax) return 'yes_some'
  else if (property?.billsIncluded && property.billsSpecific && property.billsSpecific.length > 0) return 'yes_specific'
  else if (property?.billsIncluded === false) return 'no'
  else return 'yes'
}

const LandlordRules3 = ({updateProperty, t, currentLevel, property, changePage, defineUpdateFunc}) => {

  const onSubmit = async (values) => {
    values.billsMax = values.billsIncluded !== "yes_some" ? 0 : (values.billsMax ? values.billsMax : property?.billsMax);

    if(values.billsIncluded !== "yes_specific") {
      values.billsSpecific = [];
    }
    values.billsIncluded = values?.billsIncluded?.startsWith('yes');

    if (currentProperty.billsSpecific.length > 0 && currentProperty.billsIncluded === 'specific') values.billsSpecific = currentProperty.billsSpecific
    if (values.cleaningLadyFreq) values.cleaningLadyFreq = parseInt(values.cleaningLadyFreq);

    await updateProperty(values, currentLevel);
  }

  const [currentProperty, setCurrentProperty] = useState(() => {
    return {
      ...property,
      billsSpecific: property?.billsSpecific ? property.billsSpecific : [],
      billsMax: property?.billsMax ? property.billsMax : null,
      billsIncluded: getBillsIncludedInitialValues(property),
      cleaningLady: property?.cleaningLady ? property.cleaningLady : 'yes',
      cleaningLadyFreq: property?.cleaningLadyFreq ? property.cleaningLadyFreq : '1'
    }
  })

  const handlePropertyFieldChange = (field, value) => {
    setCurrentProperty(prevState => ({...prevState, [field]: value}))
  }

  let billsSpecificOptions = ['internet', 'tv', 'water', 'electricity', 'gas', 'condominium']

  const {handleSubmit, register, errors, control, watch, formState, setValue} = useForm({shouldFocusError: true});

  const watchFields = watch(['billsIncluded', 'billsMax', 'billsSpecific', 'cleaningLady', 'cleaningLadyPrice', 'billsMax']);

  useEffect(() => defineUpdateFunc(async () => new Promise(async (resolve) => {
    if (!formState.isDirty || Object.keys(formState.dirtyFields).length === 0) return resolve();
    await handleSubmit(async (values) => {
      await onSubmit(values);
      resolve()
    }, resolve)()
  })), [currentProperty, formState.isDirty, formState.dirtyFields, watchFields.billsSpecific]);

  const someBillsIncludedPriceComponent = useCallback(() => {
    return (<PriceComponent
      name="billsMax"
      disabled={watchFields.billsIncluded !== 'yes_some'}
      value={currentProperty.billsMax}
      error={errors.billsMax}
      watchValue={watchFields.billsMax}
      register={register}
      onChange={(value) => handlePropertyFieldChange('billsMax', value)}
      rules={{validate: value => validateBillsMax(value)}}
      />)
  }, [watchFields.billsIncluded, watchFields.billsMax,errors]);

  const validateBillsMax=(value)=> {
    if(watchFields.billsIncluded === 'yes_some'){
      if (!value || value<=0){
        return t(pageLabel + '.billsMax.validation')
      }
    }
    return true;
  };

  const radioButtons = {
    billsIncluded: [{id: 'yes', buttonValue: 'yes', label: pageLabel + '.billsIncluded.yes'},
      {
        id: 'some',
        buttonValue: 'yes_some',
        label: pageLabel + '.billsIncluded.some',
        component: someBillsIncludedPriceComponent
      },
      {id: 'specific', buttonValue: 'yes_specific', label: pageLabel + '.billsIncluded.specific'},
      {id: 'no', buttonValue: 'no', label: pageLabel + '.billsIncluded.not'}],
    cleaningLady: [
      {id: 'bills_included_yes', buttonValue: 'yes', label: 'yes'},
      {id: 'bills_included_no', buttonValue: 'no', label: 'no'},
      {id: 'bills_non_available', buttonValue: 'non_available', label: pageLabel + '.cleaningLady.non_available'}],
    cleaningLadyFreq: [
      {id: 'ladyfreq_once', buttonValue: '1', label: pageLabel + '.cleaningLadyFreq.1'},
      {id: 'ladyfreq_twice', buttonValue: '2', label: pageLabel + '.cleaningLadyFreq.2'},
      {id: 'ladyfreq_three', buttonValue: '3', label: pageLabel + '.cleaningLadyFreq.3'},
      {id: 'ladyfreq_all', buttonValue: '7', label: pageLabel + '.cleaningLadyFreq.7'}],
    cleaningLadyAreas: [
      {
        id: 'cleaningAreas_commonAreas',
        buttonValue: 'commonAreas',
        label: pageLabel + '.cleaningLadyAreas.commonAreas'
      },
      {
        id: 'cleaningAreas_AreasAndRooms',
        buttonValue: 'commonAreasAndRooms',
        label: pageLabel + '.cleaningLadyAreas.commonAreasAndRooms'
      },
      {id: 'cleaningAreas__rooms', buttonValue: 'rooms', label: pageLabel + '.cleaningLadyAreas.rooms'}]
  }

  return (
    <div className={'LandlordRules3'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={'scrollable'}>
          <div className={'LandlordRules_container'}>
            <div className={'input_container'}>
              <label htmlFor={'billsIncluded'}>{t(pageLabel + '.billsIncluded.label')}</label>
              <RadioButtons radioButtons={radioButtons.billsIncluded} name={'billsIncluded'}
                            value={currentProperty.billsIncluded}
                            watchValue={watchFields.billsIncluded}
                            className={'double'} setValue={handlePropertyFieldChange}
                            control={control} rules={{required: true}}/>
              {watchFields.billsIncluded === 'yes_specific' &&
               <div className={'input_checkboxes input_container'}>
                 <FieldSetCheckBoxes t={t} checkBoxesNames={billsSpecificOptions} name={'billsSpecific'}
                                     values={currentProperty.billsSpecific}
                                     setValues={value => {
                                       setValue('billsSpecific', value, { shouldDirty: true });
                                       handlePropertyFieldChange('billsSpecific', value)
                                     }}
                                     register={register({validate: value => currentProperty.billsSpecific.length > 0 || t(pageLabel + '.billsSpecific.error')})}
                                     defaultObjectLabel={(value) => `${pageLabel}.billsSpecific.${value}`}/>
                 {errors.billsSpecific && <p className={'error_message'}>{errors.billsSpecific.message}</p>}
               </div>}
            </div>
          </div>

          <div className={'LandlordRules_container'}>
            <div className={'input_container'}>
              <label htmlFor={'cleaningLady'}>{`${t(pageLabel + '.cleaningLady.label')}`}</label>
              <RadioButtons radioButtons={radioButtons.cleaningLady} name={'cleaningLady'}
                            value={currentProperty.cleaningLady}
                            watchValue={watchFields.cleaningLady}
                            setValue={handlePropertyFieldChange}
                            type={'triple'}
                            control={control} rules={{required: true}}/>
              {currentProperty.cleaningLady === 'no' &&
               <PriceComponent
                 id={'price_input'}
                 name="cleaningLadyPrice"
                 value={currentProperty.cleaningLadyPrice}
                 error={errors.cleaningLadyPrice}
                 placeholder={t(pageLabel + '.price_placeholder')}
                 watchValue={watchFields.cleaningLadyPrice}
                 register={register}
                 rules={{
                   required: {value: true, message: t(pageLabel + '.cleaningLadyPrice.error')},
                   valueAsNumber: true
                 }}/>}

            </div>

            {watchFields.cleaningLady !== 'non_available' &&
             <div className={'input_container'}>
               <label htmlFor={'cleaningLadyFreq'}>{`${t(pageLabel + '.cleaningLadyFreq.label')}`}</label>
               <RadioButtons
                 radioButtons={radioButtons.cleaningLadyFreq} name={'cleaningLadyFreq'}
                 value={currentProperty.cleaningLadyFreq}
                 t={t} type={'double'}
                 control={control} rules={{
                 required: {
                   value: currentProperty.cleaningLady !== 'non_available',
                   message: `${t(pageLabel + '.cleaningLadyFreq.error')}`
                 },
                 valueAsNumber: true
               }}/>
               {errors.cleaningLadyFreq && <p className={'error_message'}>{errors.cleaningLadyFreq.message}</p>}
             </div>}

            {watchFields.cleaningLady !== 'non_available' &&
             <div className={'input_container'}>
               <label htmlFor={'cleaningLadyAreas'}>{`${t(pageLabel + '.cleaningLadyAreas.label')}`}</label>
               <RadioButtons
                 radioButtons={radioButtons.cleaningLadyAreas}
                 name={'cleaningLadyAreas'}
                 value={currentProperty.cleaningLadyAreas ? currentProperty.cleaningLadyAreas : 'commonAreas'}
                 control={control} rules={{required: true}}
                 type={'triple'}/>
             </div>}
          </div>
        </div>

        <Buttons saveAndExit={handleSubmit(v => onSubmit({...v, saveAndExit: true}))} changePage={changePage}/>
      </form>
    </div>
  );
};

const actionCreator = {
  defineUpdateFunc: updatePropertyActions.defineUpdateFunc
};

export default connect(null, actionCreator)(LandlordRules3);
