import React from "react";

const InvoicingHistory = (props) => (
    <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 12a9 9 0 1 1 9 9c-2.49 0-4.73-1.01-6.36-2.64l1.42-1.42A6.944 6.944 0 0 0 13.5 19c3.87 0 7-3.13 7-7s-3.13-7-7-7-7 3.13-7 7h3l-4.04 4.03-.07-.14L1.5 12h3Zm8 1V8H14v4.15l3.52 2.09-.77 1.28L12.5 13Z"
    />
  </svg>
);

export default InvoicingHistory;
