import React from "react";

const InputAlert = (props) => (
    <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0ZM9 15v-2h2v2H9ZM9 5v6h2V5H9Z"
            fill="#F2B457"
        />
    </svg>
);

export default InputAlert;