import React, {useState} from 'react';
import {propertyServices} from '../../../../../../../../../../../services';
import './style.less'
import globeIcon from './images/globeIcon.svg';
import CheckBox from '../../../../../../../../../../../components/CheckBox';
import Buttons from '../../../../../components/Buttons';
import {useHistory} from 'react-router-dom';
import {useTranslation, Trans} from 'react-i18next';
import {useAuth} from "../../../../../../../../../../../services/authServices";
const pageLabel = 'main_page.my_ads.create_ad.publish.resume2';


const Resume2 = ({currentLevel, property, changePage}) => {

  const history = useHistory();
  const {t} = useTranslation();
  const {user, setUser} = useAuth();

  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const [verificationAsked,setVerificationAsked] = useState(false)

  const nextLevelButtonHandler = async () => {

    try {
      await propertyServices.propertyFinished({verificationAsked}, property.id);
      let amount = property.accommodation !== 'apartment' ? property.renting : 1;
      setUser(prev => ({...prev, inProgressListings: (prev.inProgressListings - amount > 0 ? prev.inProgressListings - amount : 0), activeListings: prev.activeListings + amount}));
      history.replace('/my_ads/published?finished_property');
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div className={'Resume2'}>
      <div className={'scrollable'}>
        <div className={'Resume__header'}>
          <img src={globeIcon}/>
          <h4>{`${t(pageLabel + '.label')} ${user ? user?.name : ""}!`}</h4>
        </div>

        <div className={'Resume2__textContainer'}>
          <div>
            <p><Trans
              i18nKey={pageLabel + '.text1'} // optional -> fallbacks to defaults if not provided
              values={{what: 'world'}}
              components={{strong: <b/>}}
            />
            </p>
            <p>{t(pageLabel + '.text2')}</p>
          </div>
          <p><Trans
            i18nKey={pageLabel + '.text3'} // optional -> fallbacks to defaults if not provided
            components={{strong: <b/>}}
          />
          </p>
          <p>{t(pageLabel + '.text4')}</p>
          <p>{t(pageLabel + '.text5')}</p>
          <p>{t(pageLabel + '.text6')}</p>
        </div>
      </div>

      <CheckBox value={verificationAsked}
                onChange={() => setVerificationAsked(prevState => !prevState)}
                generalClassName={'acceptedTerms transparent'}
                label={<Trans
                    i18nKey={pageLabel + '.schedule_verification'} // optional -> fallbacks to defaults if not provided
                    components={{strong: <b/>}}/>}
      />

      <CheckBox value={acceptedTerms}
                onChange={() => setAcceptedTerms(prevState => !prevState)}
                generalClassName={'acceptedTerms transparent'}
                label={<Trans
                    i18nKey={pageLabel + '.accepted_terms'} // optional -> fallbacks to defaults if not provided
                    values={{what: 'world'}}
                    components={{a: <a className={'link link_blue'} target={"_blank"} href={'https://inlifehousing.com/terms-conditions/'}/>}}
                />}/>

      <Buttons executeOnAdvance={async (value) => nextLevelButtonHandler(value)} pageLabel={pageLabel}
               currentPageLevel={currentLevel}
               disableUpdate={!acceptedTerms} changePage={changePage}/>
    </div>

  )
}



export default Resume2;
