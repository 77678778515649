import {propertyConstants} from '../constants';

const stateInital = {history: []};

export function propertyAdHistory(state = stateInital, action) {
  switch (action.type) {
    case propertyConstants.HISTORY_PUSH:
      return {
        ...state,
        history:[...state.history,action.payload.action]
      }
    case propertyConstants.HISTORY_POP:
      return {
        ...state,
        history:[...state.history.slice(0,-action.payload.number)]
      }
    case propertyConstants.HISTORY_RESET:
      return {
        ...state,
        history: []
      };
    default:
      return state;
  }
}
