const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const movePhoto = (photos,setPhotos, source, destination) => {
  let dInd = destination.droppableId;
  let sInd = source.droppableId;
  const result = move(photos[sInd], photos[dInd], source, destination);
  const newState = {...photos};
  newState[sInd] = result[sInd];
  newState[dInd] = result[dInd];

  if (newState[dInd].length < 11) {
    setPhotos(newState);
    return true;
  } else {
    return false;
  }
}

export {reorder,move,movePhoto};