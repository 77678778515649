import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';

import './style.less';
import Buttons from '../../../../../components/Buttons';
import {useForm} from 'react-hook-form';
import {FieldSetCheckBoxes} from '../../../../../components/FieldSetCheckBoxes';
import RadioButtons from '../../../../../components/RadioButtons';
import CheckBox from '../../../../../../../../../../../components/CheckBox';
import {updatePropertyActions} from "../../../../../../../../../../../redux/actions/updatePropertyActions";

const pageLabel = 'main_page.my_ads.create_ad.house_details.property_areas.level2';

const laundryServiceInitialValue = (property) => {
  if (property.laundryServiceNotIncluded === 'true') return 'laundryServiceNotIncluded';
  else if (property.laundryServiceNotAvailable === 'true') return 'laundryServiceNotAvailable';
  else return 'laundryServiceIncluded'
};

const commoditiesInitialValues = (property, options) => {
  let comoditiesFinal = [];
  if (property) {
    options.map(value => {
      if (property[value] === true) comoditiesFinal.push(value)
    })
  }
  return comoditiesFinal;
};

const PropertyAreas2 = ({updateProperty, t, currentLevel, property, changePage, defineUpdateFunc}) => {

  let commoditiesOptionsNames = ['wifi', 'dishWashingMachine', 'laundryMachine', 'dryingMachine', 'dryingRack', 'ironingBoard', 'tv', 'cableTv', 'towels', 'soap', 'elevator', 'fireExtinguisher',
    'privateParking', 'freeStreetParking', 'paidStreetParking', 'firstAidKit', 'handSanitizer', 'cctv', 'reception', 'coworkSpace',
    'library', 'photocopier', 'barLounge', 'wellbeingActivities', 'multimediaRoom', 'cinemaRoom'];

  //Initial values
  const [currentProperty, setCurrentProperty] = useState(() => {
    return {
      ...property,
      laundryServiceOption: laundryServiceInitialValue(property),
      laundryServiceSwitch: property.laundryService ? true : false,
      commodities: commoditiesInitialValues(property, commoditiesOptionsNames)
    }
  });

  const [changedSelectFields, setChangedSelectFields] = useState(false);

  const handlePropertyFieldChange = (field, value) => {
    setCurrentProperty(prevState => ({...prevState, [field]: value}));
    setChangedSelectFields(true);
  };

  const radioButtonsLaundryServices = [
    {
      id: 'laundryServiceIncluded',
      buttonValue: 'laundryServiceIncluded',
      label: pageLabel + '.laundryService.laundryServiceIncluded'
    },
    {
      id: 'laundryServiceNotIncluded',
      buttonValue: 'laundryServiceNotIncluded',
      label: pageLabel + '.laundryService.laundryServiceNotIncluded'
    },
    {
      id: 'laundryServiceNotAvailable',
      buttonValue: 'laundryServiceNotAvailable',
      label: pageLabel + '.laundryService.laundryServiceNotAvailable'
    }];

  const onSubmit = async (values) => {

    radioButtonsLaundryServices.map(button => {
      if (values.laundryService === button.buttonValue) {
        return values[button.buttonValue] = true
      } else return values[button.buttonValue] = false
    });

    values.laundryService = values.laundryService ? true : false;

    commoditiesOptionsNames.map(value => {
      if (currentProperty.commodities.includes(value)) values[value] = true;
      else values[value] = false;
    });

    await updateProperty(values, currentLevel);
    setChangedSelectFields(false);
  };

  const {handleSubmit, register, control, formState} = useForm({shouldFocusError: true});

  useEffect(() => defineUpdateFunc(async () => new Promise(async (resolve) => {
    if(!formState.isDirty && !changedSelectFields) return resolve();
    await handleSubmit(async (values) => {
      await onSubmit(values);
      resolve()
    }, resolve)()
  })), [currentProperty, formState.isDirty, changedSelectFields]);

  return (
    <div className={'PropertyAreas2'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={'scrollable'}>

          <div className={'input_container '}>
            <label htmlFor={'servicesOptions'}>{`${t(pageLabel + '.label')}`}</label>
            <CheckBox value={currentProperty.laundryServiceSwitch}
                      generalClassName={'right transparent'}
                      onChange={(value) => handlePropertyFieldChange('laundryServiceSwitch', !currentProperty.laundryServiceSwitch)}
                      label={t(pageLabel + '.laundryService.label')}/>
            <div id="PropertyAreas__services">
              <div id="laundryServiceSwitch">
                {currentProperty.laundryServiceSwitch &&
                 <RadioButtons radioButtons={radioButtonsLaundryServices} name={'laundryService'}
                               value={currentProperty.laundryServiceOption}
                               t={t} className={'single'} type={'single right'} control={control}
                   /*register={register({required:{ value:cleaningLady!=='non_available', message:`${t(pageLabel+".cleaningLadyFreq.error")}` }, })}*/ />}
              </div>
            </div>
          </div>


          <div className={'input_container commodities_checkboxes'}>
            <label htmlFor={'commodities'}>{`${t(pageLabel + '.comodities.label')}`}</label>
            <FieldSetCheckBoxes t={t} checkBoxesNames={commoditiesOptionsNames} name={'commodities'}
                                values={currentProperty.commodities} register={register} type={'right transparent'}
                                setValues={(value) => handlePropertyFieldChange('commodities', value)}
                                defaultObjectLabel={(value) => pageLabel + '.comodities.' + value}
                                amountInLine={'two-in-line'}/>
          </div>
        </div>

        <Buttons saveAndExit={handleSubmit(v=>onSubmit({...v, saveAndExit: true}))} changePage={changePage}/>
      </form>
    </div>
  );
};

const actionCreator = {
  defineUpdateFunc: updatePropertyActions.defineUpdateFunc
};

export default connect(null, actionCreator)(PropertyAreas2);
