import React, { useEffect, useState } from 'react';

import './style.less';
import { Route } from 'react-router-dom';
import {dashboardServices} from '../../../../services';
import PotentialTenantsList from './components/PotentialTenantsList';
import PagesSwitch from "../../../../components/PagesSwitch";
import Loading from "../../../../components/Loading";
import TenantDetails from "./containers/TenantDetails";

const MakeOffers = ({ path }) => {

  const [potentialTenants, setPotentialTenants] = useState([]);
  const [totalPotentialTenants, setTotalPotentialTenants] = useState(0);
  const [archivedTenants, setArchivedTenants] = useState([]);
  const [offers, setOffers] = useState();
  //const [filters, setFilters] = useState(localStorage.getItem("filters_tenants") ? JSON.parse(localStorage.getItem("filters_tenants")) : {});
  const [filters, setFilters] = useState({page: 0, pageSize: 25});
  const [loadingPotential, setLoadingPotential] = useState(true);

  useEffect(async () => {
    let tenants = await dashboardServices.getPotentialTenants(filters);
    (async () => {
      const archivedTenants = await dashboardServices.getArchivedTenants();
      setArchivedTenants(archivedTenants.potentialTenants);
    })();
    setPotentialTenants(tenants?.potentialTenants);
    setTotalPotentialTenants(tenants?.total)
    setLoadingPotential(false)
  }, []);

  useEffect(async () => {
    setOffers(Object.values(await dashboardServices.getOffers()));
  }, [])


  const getDeclined = (offers) => {
    return offers?.filter((o) => {
      return o.rooms?.filter((room) => {
        return !!room.rejectedDate
      })?.length > 0
    })
  };

  const getAccepted = (offers) => {
    return offers?.filter((o) => {
      return o.rooms?.filter((room) => {
        return !!room.acceptedDate
      })?.length > 0
    })
  };

  const getOffers = async (type) => {
    let tempOffers = offers;
    if (!offers) tempOffers = Object.values(await dashboardServices.getOffers());
    setOffers(tempOffers);
    if (type === "accepted") return getAccepted(tempOffers);
    if (type === "declined") return getDeclined(tempOffers);
    return tempOffers;
  }


  const pages = [
    {
      label: 'main_page.radar.potential.label',
      header: 'main_page.radar.potential.page_header',
      path: 'potential',
      component: PotentialTenantsList,
      filter: 'potential',
      filters: filters,
      setFilters: setFilters,
      num: potentialTenants?.length
    },
    {
      label: 'main_page.radar.sent.label',
      header: 'main_page.radar.sent.page_header',
      path: 'sent',
      component: PotentialTenantsList,
      filter: 'sent',
      filters: filters,
      setFilters: setFilters,
      num: offers?.length
    },
    {
      label: 'main_page.radar.accepted.label',
      header: 'main_page.radar.accepted.page_header',
      path: 'accepted',
      component: PotentialTenantsList,
      filter: 'accepted',
      filters: filters,
      setFilters: setFilters,
      num: getAccepted(offers)?.length
    },
    {
      label: 'main_page.radar.declined.label',
      header: 'main_page.radar.declined.page_header',
      path: 'declined',
      component: PotentialTenantsList,
      filter: 'declined',
      filters: filters,
      setFilters: setFilters,
      num:  getDeclined(offers)?.length
    },
    {
      label: 'main_page.radar.archived.label',
      header: 'main_page.radar.archived.page_header',
      path: 'archived',
      component: PotentialTenantsList,
      filter: 'archived',
      filters: filters,
      setFilters: setFilters,
      num: archivedTenants?.length
    }
  ];

    const updatePotentialTenantsList = async (page, filter, filter2, value, clearFilters = false) => {
        let filtersNew = {page};
        Object.entries(filters).forEach(([key, value]) => {
            if (value) filtersNew[key] = value;
        });
        if (typeof filter === "string") {
            filtersNew["currentStatus"] = filter;
        } else {
            filtersNew["searchKey"] = filter.searchKey;
        }
        if (filter2) filtersNew[filter2] = value;
      if (clearFilters) filtersNew = {currentStatus: typeof filter === "string" && filter, page: 0, pageSize: 25}
      localStorage.removeItem("filters_tenants")
        let tenants = await dashboardServices.getPotentialTenants(filtersNew);
        localStorage.setItem("filters_tenants", JSON.stringify(filtersNew));
        setPotentialTenants(tenants?.potentialTenants);
        setLoadingPotential(false)
    }

    return (
      <div className="MakeOffers">
        {loadingPotential ? <Loading /> : <PagesSwitch path={path}
                     pages={pages}
                     containerClass={true}
                     boxShadow={true}
                     type={"secondary"}
                     potentialTenants={potentialTenants}
                     offers={offers}
                     archivedTenants={archivedTenants}
                     setArchivedTenants={setArchivedTenants}
                     setPotentialTenants={setPotentialTenants}
                     loadingPotential={loadingPotential} totalPotentialTenants={totalPotentialTenants}
          getOffers={(type) => getOffers(type)} updatePotentialTenantsList={updatePotentialTenantsList}
          extraRoutes={[
            <Route
              key={path + "details"}
              path={`${path}/:user_id/details`}
              render={() => <TenantDetails />}
            />
          ]}
        />
        }
      </div>
    )
};

export default MakeOffers;
