import { displayFooterConstants } from "../constants";

const stateInital = { display: true};

export function footerDisplay(state = stateInital, action) {
  switch (action.type) {
    case displayFooterConstants.DISPLAY:
      return {
        ...state,
        display: action.payload,
      };
    default:
      return state;
  }
}