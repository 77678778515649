import React from 'react';
import "./style.less";

const VerticalTimeline = ({ items }) => {
    return (
        <div className="vertical-timeline">
            {items?.map((item, index) => {
                const { icon } = item;
                const IconComponent = icon;

                return (
                    <div key={index} className="timeline-item">
                        <div className="dot">
                            {item.icon ? <IconComponent /> : index + 1}
                            {index !== items.length - 1 && <div className="line"></div>}
                        </div>
                        <div className="content">{item.content}</div>
                    </div>
                )
            })}
        </div>
    )
}

export default VerticalTimeline;
