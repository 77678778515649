import React, {useContext, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import ReactCSSTransitionReplace from 'react-css-transition-replace';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSyncAlt} from '@fortawesome/free-solid-svg-icons';

import './styles.less';
import {faCheckCircle, faTimesCircle} from '@fortawesome/free-regular-svg-icons';

const PopupContext = React.createContext({});

const PopupDialog = ({data, open, requestGoing, requestResult, onConfirm, onCancel}) => {
  if (!data) return null;

  const {t} = useTranslation();

  let content = (
      <div key={'content'} className={'dialog-content'}>
        <h3>{data.title}</h3>
        {typeof data.message === "string" ?
            <p>{data.message}</p>
            : <div>{data.message}</div>}
      <div className="actions">
        <button className="btn btn_grey_soft" onClick={onCancel}>
          {t('buttons.cancel')}
        </button>
        <button className="btn btn_blue" onClick={onConfirm}>
          {t('buttons.confirm')}
        </button>
      </div>
    </div>
  );

  if (requestGoing) {
    content = (
      <div key={'progress'} className={'dialog-content progress'}>
        <div>
          <FontAwesomeIcon icon={faSyncAlt} spin={true}/>
        </div>
        <p>{t('popup-dialog.request_going')}</p>
      </div>
    );
  }

  if (requestResult) {
    if (requestResult.error)
      content = (
        <div key={'error'} className={'dialog-content error'}>
          <div>
            <FontAwesomeIcon icon={faTimesCircle}/>
            <h3>
              {t('popup-dialog.request_error')}
            </h3>
          </div>
          <p>{requestResult.message}</p>
          <div className="actions">
            <button className="btn btn_blue" onClick={onCancel}>
              {t('buttons.close')}
            </button>
          </div>
        </div>
      );
    else if (!data.start_request || !(data.title || data.message) || data.customSuccessDialog)
      content = (
          <div key={'success'} className={'dialog-content success'}>
            {data.customSuccessDialog?.message ?
                <div className={"action-dialog"}>
                  {data.customSuccessDialog.image && <img src={data.customSuccessDialog.image} />}
                  <div>{data.customSuccessDialog.message}</div>
                </div> :
                <div>
                  <FontAwesomeIcon icon={faCheckCircle}/>
                  <h3>
                    {t('popup-dialog.request_success')}
                  </h3>
                </div>}
            {requestResult.message && (
                <>
                  <p>{requestResult.message}</p>
                  <div className="actions">
                    <button
                        className="btn btn_blue"
                        onClick={() => {
                          onCancel();
                          data.final_callback && data.final_callback();
                        }}
                >
                  {t('buttons.close')}
                </button>
              </div>
            </>
          )}
        </div>
      );
  }

  return (
    <div className={'PopupDialog animate__animated ' + (open ? 'open animate__fadeIn' : 'animate__fadeOut')}>
      <div>
        <div>
          <div
            className={'popup_dialog_modal animate__animated ' + (open ? 'animate__fadeInUp' : 'animate__fadeOutDown')}
          >
            <ReactCSSTransitionReplace
              transitionName="cross-fade"
              transitionEnterTimeout={100}
              transitionLeaveTimeout={50}
            >
              {content}
            </ReactCSSTransitionReplace>
          </div>
        </div>
      </div>
    </div>
  );
};

const PopupDialogProvider = (props) => {
  const [popup, setPopup] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [requestGoing, setRequestGoing] = useState(false);
  const [requestResult, setRequestResult] = useState(false);
  const timeouts = useRef([]);
  const time = timeouts.current;

  const openPopupDialog = ({
                             title,
                             message,
                             request,
                             request_done,
                             start_request,
                             success_message,
                             final_callback,
                             customSuccessDialog
                           }) => {
    setPopup({
      title,
      message,
      request,
      request_done,
      start_request,
      final_callback,
      success_message,
      customSuccessDialog
    });
    setRequestResult(false);
    setPopupOpen(true);
  };

  const onConfirm = async () => {
    if (popup.request && !requestResult) {
      setRequestGoing(true);
      try {
        let result = await popup.request();
        popup.request_done && popup.request_done(result);

        let message = result?.message || popup.success_message;

        setRequestResult({
          message: message
        });

        if (message || ((popup.title || popup.message) && popup.start_request)) {
          // Manter popup aberta
        } else {
          // Fechar popup automaticamente
          time[time.length] = setTimeout(() => {
            popup.final_callback && popup.final_callback();
            setPopupOpen(false);
            time[time.length] = setTimeout(() => setPopup(false), 1000);
          }, 1000);
        }
      } catch (e) {
        setRequestResult({
          error: true,
          message: e?.error?.message
        });
      } finally {
        setRequestGoing(false);
      }
    } else {
      setPopupOpen(false);
      popup.final_callback && popup.final_callback();
      time[time.length] = setTimeout(() => setPopup(false), 1000);
    }
  };

  useEffect(() => {
    if (!popup) {
      clearTimeouts();
      setRequestResult(false);
    }
    else if (!popup.request && !popup.success_message) {
      time[time.length] = setTimeout(() => {
        setPopupOpen(false);
        time[time.length] = setTimeout(() => setPopup(false), 1000);
      }, 4000);
    } else if (popup.start_request && popup.request) onConfirm();
  }, [popup]);

  const clearTimeouts = () => {
    time.forEach((t) => clearTimeout(t));
    time.length = 0;
  };

  return (
    <PopupContext.Provider {...props} value={{openPopupDialog}}>
      {props.children}
      <PopupDialog
        data={popup}
        open={popupOpen}
        requestGoing={requestGoing}
        requestResult={requestResult}
        onConfirm={onConfirm}
        onCancel={() => {
          setPopupOpen(false);
          time[time.length] = setTimeout(() => setPopup(false), 1);
        }}
      />
    </PopupContext.Provider>
  );
};

const usePopupDialog = () => useContext(PopupContext);

export {PopupDialogProvider, usePopupDialog};

export default PopupDialog;
