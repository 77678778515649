
import React, { useEffect, useState } from "react";


import { MdContentCopy } from 'react-icons/md';

import './style.less'
import {ReactSelect} from '../../../../../../../../components/ReactSelect';
import {useTranslation} from 'react-i18next';

const CloneInformation = ({options,cloneValue,changeInfo,title,placeholder}) => {


  return (
    <div className="CloneInformation">
      <label htmlFor={"address"}>{title}</label>
      <ReactSelect
        placeholder={<p><MdContentCopy/> {placeholder}</p>}
        value={cloneValue}
        options={options}
        onChange={(value) => changeInfo(value)}/>
    </div>
  )
}

export default CloneInformation;