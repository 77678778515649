import React, {useEffect, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import {connect} from 'react-redux';

import Buttons from '../../../../../components/Buttons';
import {get} from '../../../../../../../../../../../services';
import './style.less';
import {ReactSelect} from '../../../../../../../../../../../components/ReactSelect';
import {useTranslation} from 'react-i18next';
import {updatePropertyActions} from '../../../../../../../../../../../redux/actions/updatePropertyActions';
import AddressAutocomplete from './components/AddressAutocomplete';
import GoogleMapReact from 'google-map-react';
import mapStyle from "./mapStyle.json";

import {propertyServices} from "../../../../../../../../../../../services";

const pageLabel = 'main_page.my_ads.create_ad.house_details.property_details.level2';

const PropertyDetails2 = ({updateProperty, currentLevel, property, changePage, defineUpdateFunc}) => {

  let [neighborhoodsOptions, setNeighborhoodsOptions] = useState([]);
  let [regionCoords, setRegionCoords] = useState({});

  const {t} = useTranslation();

  const filterOption = (option, inputValue) => {
    const {label, value} = option;
    // looking if other options with same label are matching inputValue
    if (value === 'Other') return true;
    else return value.toLowerCase().includes(inputValue.toLowerCase())
  };

  const {handleSubmit, control, register, errors, watch, setValue, clearErrors, formState, reset} = useForm({shouldFocusError: true});

  const onSubmit = async (values, SyntheticBaseEvent) => {
    if (!SyntheticBaseEvent || SyntheticBaseEvent.nativeEvent.submitter.className.includes('submit')) {
      if(values.neighborhood) values["neighborhood"] = values.neighborhood.value;
      if(!values.address && property.address) values.address = property.address;
      values.coordinates = center ? (center.lat + "," + center.lng) : property.coordinates;
      await updateProperty(values, currentLevel);
      let address = values.address;
      reset();
      setTimeout(()=>{
        property.address = address;
        property.coordinates = center;
        setValue('address', address);
      }, 50);
    } else {
      fetchCoords();
    }
  }

  useEffect(() => {
    // get the country options to pass to the ReactSelectMulti
    (async () => {
      const getNeighborhoodsOptions = (await get('/data/neighborhoods'))[property.region];
      let _regionCoords = (await get('/data/region_coords'))[property.region];
      setRegionCoords(_regionCoords);
      if(!property.coordinates && _regionCoords) {
        let centerCoords = {
          lat: parseFloat(_regionCoords.coords?.split(',')?.[0]),
          lng: parseFloat(_regionCoords.coords?.split(',')?.[1])
        };
        setDefaultCenter(centerCoords);
        setCenter(centerCoords);
      }
      if (!getNeighborhoodsOptions || getNeighborhoodsOptions.length === 0) setNeighborhoodsOptions([]);
      else
        setNeighborhoodsOptions((getNeighborhoodsOptions.map(neighborhood => {
          if (neighborhood === 'Other') return {label: t('other'), value: neighborhood}
          return {label: neighborhood, value: neighborhood}
        })));
    })();
  }, [property.region]);

  const watchAddress = watch('address');

  const neighborhoodDefaultValue = () => {
    if(property.neighborhood){
      if(property.neighborhood==='Other'){
        return {label: t('other'), value: 'Other'}
      }
        else
          return {label: property.neighborhood, value: property.neighborhood};
    }
    return false;
  }

  useEffect(() => {
    register('address', { required: t(pageLabel + '.address.required')});
    setValue('address',property.address?property.address : null);
  },[]);

  let _defaultCenter = property.coordinates && (typeof property.coordinates === "string") ?
      {
        lat: parseFloat(property.coordinates?.split(',')?.[0]),
        lng: parseFloat(property.coordinates?.split(',')?.[1])
      } : {lat: 0, lng: 0}; //just in case..
  const [defaultCenter, setDefaultCenter] = useState(_defaultCenter);
  const [center, setCenter] = useState(_defaultCenter);
  const [zoom, setZoom] = useState(property.coordinates ? 16 : 11);

  useEffect(() => defineUpdateFunc(async () => new Promise(async (resolve) => {
    let sameCenter = parseFloat(property.coordinates?.split(',')?.[0]) === center?.lat &&
        parseFloat(property.coordinates?.split(',')?.[1]) === center?.lng;
    if (!formState.isDirty && property.address === watchAddress && sameCenter) return resolve();
    await handleSubmit(async (values) => {
      await onSubmit(values);
      resolve();
    }, resolve)()
  })), [property, formState.isDirty, watchAddress, center]);

  const fetchCoords = async (address) => {
    if(!address) address = watchAddress || property.address;
    if(!address) return;
    let coords = await propertyServices.addressCoords(address, property.region);
    if (coords?.lat && coords?.lng){
      setCenter(coords);
      setZoom(16);
    }
    let _neighborhood = address?.split(",")?.slice(-1)?.[0];
    if(_neighborhood) {
      _neighborhood = _neighborhood.trim().toLowerCase();
      let foundNeighborhood = neighborhoodsOptions.find(n => n.value.toLowerCase() === _neighborhood);
      setValue("neighborhood", foundNeighborhood);
    }
  };

  const zoomTimeoutRef = useRef(Date.now());
  const mapContainerRef = useRef(null);

  const handleMapZoom = (e) => {
    e.preventDefault();
    if(zoomTimeoutRef.current + 50 > Date.now()) return;
    let _scrollDelta = e.deltaY;
    setZoom(z => {
      let nextZoom = _scrollDelta > 0 ? z-1 : z+1;
      if(nextZoom < 10) nextZoom = 10;
      if(nextZoom > 19) nextZoom = 19;
      return nextZoom;
    });
    zoomTimeoutRef.current = Date.now();
  };

  useEffect(() => {
    if(mapContainerRef.current) {
      mapContainerRef.current.addEventListener('wheel', handleMapZoom);
      return () => {
        if(mapContainerRef.current) {
          mapContainerRef.current.removeEventListener('wheel', handleMapZoom);
        }
      }
    }
  }, [mapContainerRef.current]);

  const handleMapChange = ({center}) => {
    setCenter(center);
  };

  return (
    <div className={'PropertyDetails2'}>
      <form onSubmit={handleSubmit(onSubmit)} >
        <div className={'scrollable'}>
          <div className={'input_container'}>
            <label htmlFor={'address'}>{`${t(pageLabel + '.address.label')}`}</label>
            {/*<input*/}
            {/*  name="address"*/}
            {/*  // defaultValue={property?.address ? property.address : null}*/}
            {/*  placeholder={`Rua Rodrigo da Fonseca, 58`}*/}
            {/*  onBlur={loadUrl}*/}
            {/*  className={`basic_container ${errors.address ? 'input_error' : ''} input_field`}*/}
            {/*  ref={register({*/}
            {/*    required: t(pageLabel + '.address.required'),*/}
            {/*    minLength: {*/}
            {/*      value: 10,*/}
            {/*      message: t(pageLabel + '.address.minLength')*/}
            {/*    }*/}
            {/*  })}*/}
            {/*/>*/}
            <AddressAutocomplete
              value={property.address ? property.address : null}
              setValue={(address) => {
                  setValue('address', address);
                  fetchCoords(address)
              }}
              region={property.region}
              onSelect={() => clearErrors('address')}
              className={`basic_container ${errors.address ? 'input_error' : ''}`}
              placeholder={["Barcelona", "Madrid", "Sevilla", "Valencia"].includes(property.region) ? "Barrio Ciudad Universitaria" : (["Milan", "Roma"].includes(property.region) ? "Via degli Ontani, 39" : "Rua Rodrigo da Fonseca, 58")}
              noOptionMessage={t(pageLabel + '.address.noOptionMessage')}
              regionCoords={regionCoords}
              getCoords={fetchCoords}
            />
            {errors.address && <p className={'error_message'}>{errors.address.message}</p>}
          </div>
          <div className={'input_container_double'}>
            <div className={'input_container'}>
              <label htmlFor={'floor'}>{`${t(pageLabel + '.floor.label')}`}</label>
              <input
                name="floor"
                defaultValue={property?.floor ? property.floor : null}
                placeholder={`1º`}
                ref={register()}
                className={`basic_container input_field`}
              />
            </div>
            <div className={'input_container'}>
              <label htmlFor={'postal'}>{`${t(pageLabel + '.postal.label')}`}</label>
              <input
                name="postal"
                placeholder={`1100-234`}
                defaultValue={property.postal}
                className={`basic_container ${errors.postal ? 'input_error' : null} input_field`}
                ref={register({
                  pattern: {
                    value: /^$|\d{4}(-\d{3})?$/,
                    message: `${t(pageLabel + '.postal.pattern')}`
                  }
                })}
              />
              {errors.postal && <p className={'error_message'}>{errors.postal.message}</p>}
            </div>
          </div>
          {["Lisboa", "Porto", "Barcelona", "Madrid", "Milan", "Roma", "Sevilla", "Valencia"].includes(property.region) && <div className={'input_container'}>
            <label htmlFor={'neighborhood'}>{t(pageLabel + '.neighborhoods.label')}</label>
            <ReactSelect
              name="neighborhood"
              placeholder={`${t(pageLabel + '.neighborhoods.placeholder')}`}
              defaultValue={neighborhoodDefaultValue()}
              options={neighborhoodsOptions}
              className={`${errors.neighborhood ? 'input_error' : null} Select`}
              control={control}
              filterOption={filterOption}
              removeSelected={false}
              rules={{required: {value: true, message: t(pageLabel + '.neighborhoods.required')}}}
              />
            {errors.neighborhood && <p className={'error_message'}>{errors.neighborhood.message}</p>}
          </div>}

          <div className={'input_container'}>
            <div className={'map_header'}>
              <label htmlFor={'map_preview'}><strong>{`${t(pageLabel + '.map.label')}`}</strong></label>
              {(watchAddress||property.address) && center && <div>
                <label><strong>N</strong> {(center.lat).toFixed(6)}</label>
                <label><strong>W</strong> {(center.lng).toFixed(6)}</label>
              </div>}
            </div>
            <div className={'map_preview'}
                 style={{width: '100%', height: '300px'}}>
              <div className={"Map"} ref={mapContainerRef}>
                <GoogleMapReact
                  bootstrapURLKeys={{key: process.env.GOOGLE_API_KEY}}
                  zoom={zoom}
                  onChange={handleMapChange}
                  options={{
                    styles: mapStyle,
                    fullscreenControl: false,
                    zoomControl: false,
                    scrollwheel: false,
                    disableDoubleClickZoom: true,
                    clickableIcons: false
                  }}
                  defaultCenter={defaultCenter}
                  center={center}/>
                {(watchAddress||property.address) && <div className={"marker"}/>}
              </div>
            </div>
          </div>
        </div>

        <Buttons changePage={changePage} saveAndExit={handleSubmit(v => onSubmit({...v, saveAndExit: true}))}/>
      </form>
    </div>
  );
};


const actionCreator = {
  defineUpdateFunc: updatePropertyActions.defineUpdateFunc,
};

export default connect(null, actionCreator)(PropertyDetails2);

