import React, {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';

import './style.less';
import {useTranslation} from 'react-i18next';

import linkArrowIcon from './images/linkArrowIcon.svg'
import bookMarkIcon from './images/bookMarkIcon.svg'
import assignmentIconBlue from './images/assignmentIconBlue.svg'
import personLocationIconPurple from './images/personLocationIconPurple.svg'
import radarIconGreen from './images/radarIconGreen.svg'
import assignmentIconGrey from './images/assignmentIconGrey.svg'
import personLocationIconGrey from './images/personLocationIconGrey.svg'
import radarIconGrey from './images/radarIconGrey.svg'
import accountManagerIcon from './images/accountManagerIcon.svg'
import visibilityIcon from './images/visibilityIcon.svg'
import lowerComission from './images/lowerComission.svg'
import pencilIconWhite from './images/pencilIconWhite.svg'
import noReservationRequestIcon from './images/noReservationRequestIcon.svg'
import noVisitRequestIcon from './images/noVisitRequestIcon.svg'
import smileIconGreen from './images/smileIconGreen.svg'

import TopLandlordSection from './components/TopLandlordSection';
import {dashboardServices, propertyServices} from "../../../../services";
import {isSm} from "../../../../utils/responsive";
import PotentialTenantsList from "../MakeOffers/components/PotentialTenantsList";
import Loading from "../../../../components/Loading";
import ReservationCard from "../../../../components/ReservationCard";
import VisitCard from "../../../../components/VisitCard";
import {useAuth} from "../../../../services/authServices";

const pageLabel = 'main_page.dashboard'

const DashboardNavContainers = ({dashboardInfo, handleOpenNav, type}) => {

    const {t} = useTranslation();

    const isSmallScreen = useMemo(() => {
        return isSm();
    }, []);

    const navContainers = useMemo(() =>
            [
                {
                    icon: assignmentIconBlue,
                    iconUnActive: assignmentIconGrey,
                    label: 'reservations',
                    total: 'total_reservations',
                    name: 'reservationRequest',
                    color: 'blue',
                    link: isSmallScreen ? '/show_reservations' : '/reservations',
                },
                {
                    icon: personLocationIconPurple,
                    iconUnActive: personLocationIconGrey,
                    label: 'visits',
                    total: 'total_visits',
                    name: 'visitRequest',
                    color: 'purple',
                    link: isSmallScreen ? '/show_visits' : '/my_visits',
                },
                {
                    icon: radarIconGreen,
                    iconUnActive: radarIconGrey,
                    label: 'potential_tenants',
                    total: 'total_potential',
                    name: 'potentialTenants',
                    color: 'green',
                    link: isSmallScreen ? '/show_offers' : '/radar'
                }
            ]
        , [dashboardInfo])

    const Counter = ({total, link}) => {
        return (
            <div className={'DashboardNavContainer__count' + (!(type && type === link) ? " unActive" : "")}>
                {dashboardInfo[total] !== undefined ?
                    <p>{dashboardInfo[total]}</p>
                    : <Loading relative={true}/>}
            </div>
        )
    }

    return (
        <div className={'DashboardNavContainers'}>
            {navContainers.map((nav) =>
                (<a key={nav.name}
                    className={`DashboardNavContainer ${nav.color} basic_container ` + ((type && type === nav.link) ? " active" : "")}
                    href={nav.link} onClick={(e) => {
                        e.preventDefault();
                        handleOpenNav(nav.link, isSmallScreen)
                    }}>
                        <div className={'DashboardNavContainer__mobile'}>
                            <div className={'DashboardNavContainer__mobile-title'}>
                                <div className={'DashboardIcon'}>
                                    <img src={(type && type === nav.link) ? nav.icon : nav.iconUnActive}
                                         alt={"nav-icon"}/>
                                </div>
                                <Counter total={nav.total} link={nav.link}/>
                            </div>
                            <div className={'DashboardNavContainer__mobile-title'}>
                                <h5>{t(pageLabel + '.' + nav.label + '.label')}</h5>
                                <img src={linkArrowIcon} alt={"arrow"}/>
                            </div>
                        </div>
                        <div className={'DashboardNavContainer__desktop'}>
                            <div className={'DashboardIcon'}>
                                <img src={nav.icon} alt={"nav-icon"}/>
                            </div>
                            <h5>{t(pageLabel + '.' + nav.label + '.label')}</h5>
                            <Counter total={nav.total}/>
                            <img src={linkArrowIcon} alt={"arrow"}/>
                        </div>
                    </a>
                ))}
        </div>
    )
}

const SectionHeader = ({title, showingCount, totalCount, onClick}) => {
    const {t} = useTranslation();

    return (
        <div className={'SectionHeader'}>
            <div>
                <h4>{title}</h4>
                <p>{`${t('showing')} ${showingCount} ${t('of')} ${totalCount} ${t('results')}`}</p>
            </div>
            <p className={'link link_blue'} onClick={onClick}>{t('see_all')}</p>
        </div>
    )
}

const WelcomeSection = ({user, showButtons}) => {
    const {t} = useTranslation();
    let history = useHistory();

    const componentLabel = pageLabel + '.welcome_section'

    return (
        <section className={'WelcomeSection container'}>
            <h2>{user}{t(componentLabel + '.title')}</h2>
            <p>{t(componentLabel + '.description')}</p>

            {showButtons &&
                < div className={'WelcomeSectionButtons'}>
                    <button className={'btn btn_blue'} onClick={() => history.push('/my_ads/ad/create')}>
                        <img src={pencilIconWhite} alt={"pencil"}/>
                        <p>{t(componentLabel + '.create_ad_button')}</p>
                    </button>
                    <button className={'btn btn_grey'} onClick={() => history.push('/my_account/account_details')}>
                        <img src={pencilIconWhite} alt={"pencil"}/>
                        <p>{t(componentLabel + '.fill_account_button')}</p>
                    </button>
                </div>}
        </section>)
}

const EmptySectionContainer = ({type, numProperties}) => {
    const {t} = useTranslation();

    const history = useHistory();

    const emptyContainersLabel = 'main_page.dashboard.welcome_section.containers'

    const emptyContainers = {
        "reservations": {
            name: 'reservations',
            color: 'blue',
            icon: noReservationRequestIcon,
            description: () => (<p className={'small'}>
                <b>{t('main_page.dashboard.welcome_section.how_it_works')}</b> {t(emptyContainersLabel + '.reservations.info')}
            </p>)
        },
        "visits": {
            name: 'visits',
            color: 'purple',
            icon: noVisitRequestIcon,
            description: () => (<p className={'small'}>
                <b>{t('main_page.dashboard.welcome_section.how_it_works')}</b> {t(emptyContainersLabel + '.visits.info')}
            </p>)
        },
        "potential_tenants": {
            name: 'potential_tenants',
            color: 'green',
            icon: smileIconGreen,
            description: () => numProperties > 0 ?
                (<p className={'small'}>
                    <b>{t('main_page.dashboard.welcome_section.how_it_works')} </b>
                    {t(emptyContainersLabel + '.potential_tenants.showing_soon')}
                </p>)
                : (<p className={'small'}>
                    <b>{t('main_page.dashboard.welcome_section.how_it_works')} </b>
                    {t(emptyContainersLabel + '.potential_tenants.info1')}
                    <span className={'link link_blue'}
                          onClick={() => history.push('/my_ads')}>{t(emptyContainersLabel + '.potential_tenants.link')}</span>
                    {t(emptyContainersLabel + '.potential_tenants.info2')}
                </p>)
        }
    };

    const container = emptyContainers[type];

    return <div className={"EmptySectionContainer container"}>
        <div className={`EmptySection basic_container ${container.color}`}>
            <div className={'DashboardIcon'}>
                <img src={container.icon} alt={"container"}/>
            </div>
            <div className={'WelcomeSectionContainerInfo'}>
                <h4>{t(emptyContainersLabel + '.' + container.name + '.label')}</h4>
                <container.description/>
            </div>
        </div>
    </div>
}

const DashboardSections = ({dashboardInfo, updateDashboardInfo, type}) => {

    const {t} = useTranslation();
    const history = useHistory();

    const [userProperties, setUserProperties] = useState([]);

    useEffect(() => {
        (async () => {
            const userProperties = await propertyServices.getCachedPropertiesList();
            setUserProperties(userProperties);
        })();
    }, []);

    const Reservations = () => {
        return (
            <>
                {(dashboardInfo.reservations && dashboardInfo.reservations.length === 0) ?
                    <EmptySectionContainer type={"reservations"}/> :
                    <section id={'reservationRequest'}>
                        <div className={'container'}>
                            <SectionHeader
                                title={t(`${pageLabel}.reservations.title`)}
                                showingCount={dashboardInfo.reservations.length}
                                totalCount={dashboardInfo.total_reservations}
                                onClick={() => history.push('/reservations')}/>
                            <div className={'DashboardList'}>
                                {dashboardInfo.reservations.map(reservation =>
                                    <ReservationCard key={reservation.id} reservation={reservation}/>
                                )}
                            </div>
                        </div>
                    </section>}
            </>
        )
    }

    const Visits = () => {
        return (
            <>
                {(dashboardInfo.visits && dashboardInfo.visits.length === 0) ?
                    <EmptySectionContainer type={"visits"}/> :
                    <section id={'visitRequest'}
                             style={{marginTop: dashboardInfo.reservations.length === 0 ? "60px" : ""}}>
                        <div className={'container'}>
                            <SectionHeader
                                title={t(`${pageLabel}.visits.title`)}
                                showingCount={dashboardInfo.visits.length}
                                totalCount={dashboardInfo.total_visits}
                                onClick={() => history.push('/my_visits')}/>
                            <div className={'DashboardList'}>
                                {dashboardInfo.visits.map(visit =>
                                    <VisitCard key={visit.id} filter={"pending"} visit={visit}
                                               updateVisits={updateDashboardInfo}/>)}
                            </div>
                        </div>
                    </section>}
            </>
        )
    }

    //TODO nao ir buscar sempre as casas do landlord pq pode n ser necessario
    const PotentialTenants = () => {
        return (
            <>
                {(dashboardInfo.potential_tenants && dashboardInfo.potential_tenants.length === 0) ?
                    <EmptySectionContainer type={"potential_tenants"} numProperties={userProperties.length}/> :
                    <section id={'potentialTenants'} className="potentialTenants-container">
                        {dashboardInfo.total_potential !== undefined ?
                            (dashboardInfo.total_potential > 0 ?
                                <div className={'container'}>
                                    <SectionHeader
                                        title={t(`${pageLabel}.potential_tenants.title`)}
                                        showingCount={dashboardInfo.potential_tenants?.length}
                                        totalCount={dashboardInfo.total_potential}
                                        onClick={() => history.push('/radar')} />
                                    <div className={'DashboardList'}>
                                        <PotentialTenantsList updateDashboardInfo={updateDashboardInfo}
                                                              userProperties={userProperties}
                                                              potentialTenants={dashboardInfo.potential_tenants}
                                            label={'main_page.radar.potential.label'}
                                                              filter={'potential'}
                                                              history={history} dashboard={true}/>
                                    </div>
                                </div>
                                : <div className={"WelcomeSectionContainers"}>
                                    <div className={`basic_container green`} key={"potential_tenants"}
                                         style={{width: "90%", marginLeft: "5%"}}>
                                        <div className={'DashboardIcon'}>
                                            <img src={radarIconGreen} alt={"radar icon"}/>
                                        </div>
                                        <div className={'WelcomeSectionContainerInfo'}>
                                            <h4>{t("main_page.dashboard.welcome_section.containers.potential_tenants.label")}</h4>
                                            <p className={'small'}>
                                                <b>{t('main_page.dashboard.welcome_section.how_it_works')}</b> {t('main_page.dashboard.welcome_section.containers.potential_tenants.info1')}
                                                <span
                                                    className={'link link_blue'}
                                                    onClick={() => history.push('/my_ads')}>{t('main_page.dashboard.welcome_section.containers.potential_tenants.link')}</span> {t('main_page.dashboard.welcome_section.containers.potential_tenants.info2')}
                                            </p>
                                        </div>
                                    </div>
                                </div>)
                            :
                            <div className={'container'}>
                                <Loading relative={true}/>
                            </div>}
                    </section>}
            </>
        )
    }

    return (
        <div className={'DashboardSections'}>
            <div className={'DashboardSections__sections'}>
                <Reservations/>
                <Visits/>
                <PotentialTenants/>
            </div>
            <div className={'DashboardSections__sections-mobile'}>
                {type && type === "/show_reservations" && <Reservations/>}
                {type && type === "/show_visits" && <Visits/>}
                {type && type === "/show_offers" && <PotentialTenants/>}
            </div>
        </div>
    )
}

const TopLandlordAdvantages = () => {
    const {t} = useTranslation();
    const componentLabel = pageLabel + '.top_landlord_advantages'

    const topLandlordContainers = useMemo(() => {
        return [
            {name: 'account_manager', icon: accountManagerIcon},
            { name: 'radar', icon: bookMarkIcon },
            {name: 'increased_visibility', icon: visibilityIcon},
            {name: 'lower_comission', icon: lowerComission}]
    }, [])

    return (
        <div className={'container TopLanlordAdvantages'}>
            <h4>{t(componentLabel + '.title')}</h4>
            <div>
                {topLandlordContainers.map(container => (
                    <div key={container.name}>
                        <div className={'DashboardIcon'}>
                            <img src={container.icon} alt={"container"}/>
                        </div>
                        <h6>{t(`${componentLabel}.${container.name}.title`)}</h6>
                        <p>{t(`${componentLabel}.${container.name}.description`)}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

const Dashboard = (props) => {

    const {t} = useTranslation();
    const {user, onLoaded} = useAuth();

    const history = useHistory();

    const [dashboardInfo, setDashboardInfo] = useState({loading: true});
    const [type, setType] = useState();

    const handleOpenNav = (link, isType) => {
        if (isType) {
            setType(link);
        } else {
            history.push(link);
        }
    }

    useEffect(() => {
        if (dashboardInfo.total_reservations > 0) {
            setType('/show_reservations');
        } else if (dashboardInfo.total_visits > 0) {
            setType('/show_visits');
        } else if (dashboardInfo.total_potential) {
            setType('/show_offers');
        }
    }, [dashboardInfo]);

    useEffect(() => {
        (async () => {
            let loadedUser = await onLoaded();
            if (!loadedUser) {
                history.push("/start/login");
            }
            if (loadedUser && ((!loadedUser.email_verified || !loadedUser.phone_verified || (user.hasOwnProperty('changedTemporaryPassword') && !user.changedTemporaryPassword) || (!loadedUser.has_password && !loadedUser.googleId && !loadedUser.facebookId)) && !loadedUser.impersonateOriginal)) {
                history.push('/start/verification');
            }
        })()
    }, [])

    useEffect(() => {
        (async () => {
            if (dashboardInfo.loading)
                await dashboardServices.getDashboardInfo().then((data) => setDashboardInfo(data));
        })();
    }, []);


    return (
        <div className={'Dashboard'}>
            <div className={'Dashboard__header container'}>
                <h3 className={'title'}>{t(pageLabel + '.title')}</h3>
                <DashboardNavContainers dashboardInfo={dashboardInfo}
                                        handleOpenNav={(val, isType) => handleOpenNav(val, isType)} type={type}/>
            </div>
            {!dashboardInfo.loading &&
                <>
                    {user?.houses?.length === 0
                        ? <WelcomeSection
                            showButtons={true}
                            user={user?.name?.split(' ')?.[0]} type={type}/>
                        : <DashboardSections dashboardInfo={dashboardInfo} type={type}/>}
                </>}
            {dashboardInfo.indicators && <>
                <section id={'topLandlord'}>
                    <TopLandlordSection status={dashboardInfo.indicator_global} indicators={dashboardInfo.indicators} user={user}/>
                </section>
                <section id={'potentialTenants'}>
                    <TopLandlordAdvantages/>
                </section>
            </>}
        </div>
    );
};


export default Dashboard;
