import React, {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import Buttons from '../../../../../components/Buttons';
import {get} from '../../../../../../../../../../../services';
import RadioButtons from '../../../../../components/RadioButtons';
import AmountButtonsInput from '../../../../../components/AmountButtonsInput';
import {FieldSetCheckBoxes} from '../../../../../components/FieldSetCheckBoxes';
import {
  ReactSelect,
  findReactSelectInitialOptions
} from '../../../../../../../../../../../components/ReactSelect';

import './style.less';
import {updatePropertyActions} from '../../../../../../../../../../../redux/actions/updatePropertyActions';
import {useAuth} from "../../../../../../../../../../../services/authServices";

const pageLabel = 'main_page.my_ads.create_ad.house_details.property_details.level3';

const ApartmentRoomsInfo = ({
                              register,
                              property,
                              errors,
                              watch,
                              setValue,
                              control,
                              hide,
                              setHasRoomsValue
                            }) => {
  const {t} = useTranslation();

  const radioButtonsHasRooms = [{buttonValue: 'true', label: pageLabel + '.has_rooms.true'},
    {buttonValue: 'false', label: pageLabel + '.has_rooms.false'}];

  const watchHasRooms = watch('has_rooms');


  return (
    <div className={'ApartmentRoomsInfo'}>
      {property.accommodation === 'apartment' && <div className={'input_container input_info' + (hide ? ' hidden' : '')}>
        <label htmlFor={'has_rooms'}>{t(pageLabel + '.has_rooms.label')}</label>
        <RadioButtons radioButtons={radioButtonsHasRooms} name={'has_rooms'}
                      watchValue={watchHasRooms}
                      setValue={setHasRoomsValue}
                      value={property.hasOwnProperty('has_rooms') ? (property.has_rooms + "") : 'true'}
                      className={'double'} control={control}/>
      </div>}


      {(property.accommodation === 'rooms' || (property.accommodation === 'apartment' && watchHasRooms === 'true')) &&
       <div
         className={`${property.accommodation === 'apartment' ? 'input_container_double input_info' : ''}` + (hide ? ' hidden' : '')}>
         <div className={'input_container'}>
           <label htmlFor={'bedrooms'}>{`${t(pageLabel + '.bedrooms.label')}`}</label>
           <AmountButtonsInput
             name={'bedrooms'} value={property.bedrooms ? property.bedrooms : null}
             placeholder={'Ex 4'} min={1} amount={1}
             watchValue={watch('bedrooms')} setValue={setValue}
             className={`${errors.bedrooms ? 'input_error' : null} input_field`}
             register={register({
               validate: value => value > 0 || t(pageLabel + '.bedrooms.required'),
               valueAsNumber: true
             })}
             id={'bedrooms'}
           />
           {errors.bedrooms && <p className={'error_message'}>{errors.bedrooms?.message}</p>}
           {property.accommodation === 'rooms' && <p className={'info'}>{t('visible_on_your_adds')}</p>}
         </div>


         <div className={'input_container'}>
           <label htmlFor={'renting'}>{t(pageLabel + `.renting_${property.accommodation}.label`)}</label>
           <AmountButtonsInput
             name={'renting'}
             value={property.renting ? property.renting : null}
             placeholder={'Ex 4'}
             min={1}
             amount={1}
             className={`${errors.renting ? 'input_error' : null} input_field`}
             watchValue={watch('renting')} setValue={setValue}
             register={register({
               validate: value => value > 0 || t(pageLabel + `.renting_${property.accommodation}.required`),
               valueAsNumber: true
             })}
             id={'renting'}
           />
           {errors.renting && <p className={'error_message'}>{errors.renting?.message}</p>}
           {<p
             className={'info'}>{property.accommodation === 'apartment' ? t(pageLabel + '.renting_rooms.info') : t('visible_on_your_adds')}</p>}
         </div>
       </div>}

      <div className={'input_container input_info' + (hide ? ' hidden' : '')}>
        <label htmlFor={'people'}>{`${t(pageLabel + '.people.label')}`}</label>
        <AmountButtonsInput
          name={'people'} value={property.people ? property.people : null} placeholder={'Ex 4'}
          min={1} amount={1}
          className={`${errors.people ? 'input_error' : null} input_field`}
          register={register({
            validate: value => value > 0 || `${t(pageLabel + '.people.required')}`,
            valueAsNumber: true
          })}
          watchValue={watch('people')} setValue={setValue}
          id={'people'}
        />
        {errors.people && <p className={'error_message'}>{errors.people?.message}</p>}
        {<p className={'info'}>{t('visible_on_your_adds')}</p>}
      </div>
    </div>
  )
};

const ResidenceInfo = ({register, errors, property, watch, setValue, hide}) => {
  const {t} = useTranslation();

  const watchStudioRooms = watch('studio_rooms');
  const watchBedrooms = watch('bedrooms');
  const watchStudioTypes = watch('studio_types');
  const watchBedRoomTypes = watch('bedroom_types');

  const validateBedroomsValues = value => (value + watchStudioRooms + watchBedrooms) > 0 || t(pageLabel + `.residence_studio_rooms.required`);
  const validateBedroomsTypesValues = value => (value + watchStudioTypes + watchBedRoomTypes) > 0 || t(pageLabel + `.residence_studio_types.required`);

  return (
    <div className={'ResidenceInfo' + (hide ? ' hidden' : '')}>
      <div className={'input_container_double input_info'}>
        <div className={'input_container'}>
          <label htmlFor={'studio_rooms'}>{`${t(pageLabel + '.residence_studio_rooms.label')}`}</label>
          <AmountButtonsInput
            name={'studio_rooms'} value={property.studio_rooms ? property.studio_rooms : null}
            placeholder={'Ex 4'} min={0}
            amount={1} className={`${errors.studio_rooms && errors.bedrooms ? 'input_error' : null} input_field`}
            register={register({validate: validateBedroomsValues, valueAsNumber: true})}
            id={'studio_rooms'}
            watchValue={watchStudioRooms} setValue={setValue}
          />
        </div>


        <div className={'input_container'}>
          <label htmlFor={'bedrooms'}>{`${t(pageLabel + '.residence_studio_bedrooms.label')}`}</label>
          <AmountButtonsInput
            name={'bedrooms'}
            value={property.bedrooms ? property.bedrooms : null}
            placeholder={'Ex 4'} min={0} id={'bedrooms'}
            amount={1}
            className={`${errors.bedrooms && errors.studio_rooms ? 'input_error' : null} input_field`}
            watchValue={watchBedrooms} setValue={setValue}
            register={register({validate: validateBedroomsValues, valueAsNumber: true})}/>
        </div>
        {(errors.studio_rooms && errors.bedrooms) && <p
          className={'error_message'}>{errors.studio_rooms ? errors.studio_rooms.message : errors.bedrooms?.message}</p>}
        {<p className={'info'}>{t(pageLabel + '.residence_studio_rooms.info1')}</p>}
        {<p className={'info'}>{t(pageLabel + '.residence_studio_rooms.info2')}</p>}
      </div>

      <div className={'input_container_double input_info'}>
        <div className={'input_container'}>
          <label htmlFor={'studio_types'}>{`${t(pageLabel + '.residence_studio_types.label')}`}</label>
          <AmountButtonsInput
            name={'studio_types'} value={property.studio_types ? property.studio_types : null}
            placeholder={'Ex 4'} min={0} amount={1}
            className={`${errors.studio_types && errors.bedroom_types ? 'input_error' : null} input_field`}
            register={register({validate: validateBedroomsTypesValues, valueAsNumber: true})}
            id={'studio_types'}
            watchValue={watchStudioTypes} setValue={setValue}/>
          {errors.studio_types && <p className={'error_message'}>{errors.bedroom_types?.message}</p>}
          {<p className={'info'}>{t(pageLabel + '.residence_studio_types.info')}</p>}
        </div>


        <div className={'input_container input_info'}>
          <label htmlFor={'bedroom_types'}>{`${t(pageLabel + '.residence_studio_bedroom_types.label')}`}</label>
          <AmountButtonsInput
            name={'bedroom_types'} value={property.bedroom_types ? property.bedroom_types : null}
            placeholder={'Ex 4'} min={0} amount={1}
            className={`${errors.studio_types && errors.bedroom_types ? 'input_error' : null} input_field`}
            register={register({validate: validateBedroomsTypesValues, valueAsNumber: true})}
            id={'bedroom_types'}
            watchValue={watchBedRoomTypes} setValue={setValue}/>
          {(errors.studio_types && errors.bedroom_types) && <p
            className={'error_message'}>{errors.studio_types ? errors.studio_types.message : errors.bedroom_types?.message}</p>}
          {<p className={'info'}>{t(pageLabel + '.residence_studio_bedroom_types.info')}</p>}
        </div>
      </div>
    </div>
  )
};

const PropertyDetails3 = ({updateProperty, currentLevel, property, changePage, defineUpdateFunc}) => {

  const {t} = useTranslation();
  const {user, setUser} = useAuth();

  const handlePropertyFieldChange = (field, value) => {
    if(field === "features" || field === "closeUniversities" || field === "closeMetroLines") {
      setChangedSelects(true);
    }
    setCurrentProperty(prevState => ({...prevState, [field]: value}))
  };

  let adjectivesNames = ['luminous', 'spacious', 'modern', 'new', 'comfortable', 'bright', 'cozy', 'renovated'];

  const radioButtonsMobilated = [{id: 'mobilated', buttonValue: 'true', label: pageLabel + '.mobilated.true'},
    {id: 'non_mobilated', buttonValue: 'false', label: pageLabel + '.mobilated.false'}];

  const radioButtonsColiving = [{id: 'coliving', buttonValue: 'true', label: pageLabel + '.coliving.true'},
    {id: 'non_coliving', buttonValue: 'false', label: pageLabel + '.coliving.false'}];

  let featuresOptions = useMemo(() => ['downtown', 'metroBus', 'foreigners', 'young', 'nightlife', 'restaurants', 'greenAreas', 'quiet', 'beach', 'bikeLanes', 'coworking', 'river', 'gyms', 'sights', 'venues'].map(option => ({
    value: option,
    label: t(pageLabel + '.features.' + option)
  })), []);
  let [universitiesOptions, setUniversitiesOptions] = useState([]);
  let metroLinesOptions = useMemo(() => {
    const metro_regions = {
      "Lisboa": ['blue', 'yellow', 'red', 'green'],
      "Porto": ['blue', 'yellow', 'red', 'green', 'orange', 'violet', 'pink'],
      "Barcelona": ['1', '2', '3', '4', '5', '9n', '9s', '10n', '10s', '11', 'fm'],
      "Madrid": ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', 'r', 'r1', 'r2', 'r3'],
      "Milan": ['1', '2', '3', '9', '10', '12', '14', '15', '16', '13', '24', '27', 'M1', 'M2', 'M3', 'M4', 'M5'],
      "Roma": ['A', 'B', 'C', '2', '3', '5', '8', '14', '19'],
      "Sevilla": ['1'],
      "Valencia": ['1', '2', '3', '4', '5', '6', '7', '8', '9']
    };
    return (metro_regions[property.region]||[]).map(option => ({
      value: option,
      label: ["Barcelona", "Madrid", "Milan", "Roma", "Sevilla", "Valencia"].includes(property.region) ? option.toUpperCase() : t(pageLabel + '.metro_lines.' + option) //TODO translate barcelona and madrid lines
    }));
  }, [property.region]);

  const [changedSelects, setChangedSelects] = useState(false);

  const [currentProperty, setCurrentProperty] = useState(() => {
    return {
      ...property,
      internal_name: property.internal_name ? property.internal_name : null,
      adjectives: property.adjectives ? property.adjectives : [],
      features: findReactSelectInitialOptions(property.features ? property.features : [], featuresOptions),
      closeUniversities: findReactSelectInitialOptions(property.universities ? (property.universities.length > 3 ? property.universities.slice(0, 3) : property.universities) : [], universitiesOptions),
      closeMetroLines: findReactSelectInitialOptions(property.metro_lines ? (property.metro_lines.length > 3 ? property.metro_lines.slice(0, 3) : property.metro_lines) : [], metroLinesOptions)
    }
  });

  const onSubmit = async (values) => {
    values.universities = currentProperty.closeUniversities ? currentProperty.closeUniversities.map(value => value.label) : [];
    values.metro_lines = currentProperty.closeMetroLines ? currentProperty.closeMetroLines.map(value => value.value) : [];
    values.features = currentProperty.features ? currentProperty.features.map(value => value.value) : [];
    values.adjectives = currentProperty.adjectives;

    values.mobilated = values.mobilated === 'true';
    values.coliving = values.coliving === 'true';

    if(!values.bedrooms) values.bedrooms = 1;

    if (property.accommodation === 'apartment') {
      values.has_rooms = values.has_rooms === 'true';
      if(!values.renting) values.renting = 1;
    } else if(property.accommodation === "rooms") {
      values.has_rooms = true;
    }
    await updateProperty(values, currentLevel);
    if (property.accommodation === 'rooms' && values.renting) {
      setUser(prev => ({...prev, inProgressListings: prev.inProgressListings + (values.renting -1)}));
    }else if(values.bedroom_types || values.studio_types){
      setUser(prev => ({...prev, inProgressListings: prev.inProgressListings + (values.bedroom_types ? values.bedroom_types : 0) + (values.studio_types ? values.studio_types : 0)}));
    }
    setChangedSelects(false);
  };

  useEffect(() => {
    // get the university options to pass to the ReactSelectMulti
    if (!property.region) return;
    (async () => {
      let nextUniversitiesOptions = (await get('/data/universities'))[property.region]?.map(university => ({
        label: university,
        value: university
      }));
      setUniversitiesOptions(nextUniversitiesOptions);
      setCurrentProperty(prevState => {
        return {
          ...prevState,
          closeUniversities: findReactSelectInitialOptions(property.universities ? property.universities : [], nextUniversitiesOptions),
        }
      })
    })();
  }, [property.region]);

  const {handleSubmit, register, errors, watch, setValue, control, formState} = useForm({shouldFocusError: true});

  useEffect(() => defineUpdateFunc(async () => new Promise(async (resolve) => {
    if (!changedSelects && !formState.isDirty) return resolve();
    await handleSubmit(async (values) => {
      await onSubmit(values);
      resolve();
    }, resolve)()
  })), [currentProperty, formState.isDirty, changedSelects]);

  useEffect(() => {
    if (errors) {
      const anchor = document.querySelector(`#${Object.keys(errors)[0]}`);
      if (anchor) anchor.scrollIntoView({behavior: 'smooth', block: 'center'})
    }
  }, [errors]);

  return (
    <div className={'PropertyDetails3'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={'scrollable'}>
          {/*internal_name*/}
          <div className={'input_container input_info'}>
            <label htmlFor={'internal_name'}>{`${t(pageLabel + '.internal_name.label')}`}</label>
            <input name="internal_name" placeholder={`Ex: Casa Alcântara`}
                   className={`basic_container ${errors.internal_name ? 'input_error' : null} input_field`}
                   defaultValue={currentProperty.internal_name}
                   ref={register({
                     required: `${t(pageLabel + '.internal_name.required')}`,
                     minLength: {value: 1, message: `${t(pageLabel + '.internal_name.minLength')}`}
                   })}/>
            {errors.internal_name && <p className={'error_message'}>{errors.internal_name?.message}</p>}
            {<p className={'info'}>{t('visible_for_you')}</p>}
          </div>

          <div className={'input_container'}>
            <label htmlFor={'area'}>{`${t(pageLabel + '.size.label')}`}</label>
            <input name="area" type="number" defaultValue={currentProperty.area}
                   placeholder={`Ex: 70 m2`} className={`basic_container input_field`}
                   ref={register({valueAsNumber: true})} onWheel={(e) => e.target.blur()}
            />
          </div>

          {/*hasRooms bedrooms renting people*/}
          {currentProperty.accommodation !== 'residence' &&
           <ApartmentRoomsInfo register={register} property={currentProperty} errors={errors} watch={watch}
                               setValue={setValue} control={control}
                               setHasRoomsValue={(value) => handlePropertyFieldChange('has_rooms', value)}
                               handlePropertyFieldChange={handlePropertyFieldChange}
           />}

          {/*studio_rooms bedrooms studio_types bedroom_types*/}
          {currentProperty.accommodation === 'residence' &&
           <ResidenceInfo register={register} errors={errors} property={currentProperty} watch={watch}
                          setValue={setValue}/>}


          {/*mobilated*/}
          <div className={'input_container'}>
            <label htmlFor={'mobilated'}>{`${t(pageLabel + '.mobilated.label')}`}</label>
            <RadioButtons radioButtons={radioButtonsMobilated} name={'mobilated'} value={'true'}
                          error={errors.mobilated}
                          className={'double'}
                          control={control}
                          errorMessage={t(pageLabel + '.mobilated.info')}
                          rules={{
                            validate: value => {
                              return value === 'true' || t(pageLabel + '.mobilated.info')
                            }
                          }}/>
            <p className={'info'}>{t(pageLabel + '.mobilated.info')}</p>
          </div>

          {/*coliving*/}
          {(currentProperty.accommodation === 'rooms' || currentProperty.accommodation === 'residence' )&&
          <div className={'input_container'}>
            <label htmlFor={'coliving'}>{`${t(pageLabel + '.coliving.label')}`}</label>
            <RadioButtons radioButtons={radioButtonsColiving} name={'coliving'} value={currentProperty.coliving || 'false'}
                          register={register}
                          error={errors.coliving}
                          className={'double'}
                          control={control}/>
          </div>}


          {/*adjectives*/}
          <div className={'input_checkboxes input_container'}>
            <label htmlFor={'adjectives'}>{`${t(pageLabel + '.adjectives.label')}`}</label>
            <FieldSetCheckBoxes t={t} checkBoxesNames={adjectivesNames} name={'adjectives'}
                                register={register} type={'basic_container'}
                                amountInLine={'four-in-line'} values={currentProperty.adjectives}
                                setValues={(value) => handlePropertyFieldChange('adjectives', value)}
                                defaultObjectLabel={(value) => 'main_page.my_ads.create_ad.adjectives.' + value}/>
          </div>


          <div className={'input_container_double'} id={'distances'}>
            {/*distMetro*/}
            <div className={'input_container'}>
              <label htmlFor={'distMetro'}>{`${t(pageLabel + '.distMetro.label')}`}</label>
              <AmountButtonsInput
                name={'distMetro'} value={currentProperty.distMetro} placeholder={'Ex 5min'}
                min={1}
                amount={1}
                units={'min'}
                id={'distMetro'}
                className={`${errors.distMetro ? 'input_error' : null} input_field`} watchValue={watch('distMetro')}
                setValue={setValue}
                register={register({
                  validate: value => value > 0 || t(pageLabel + '.distMetro.required'),
                  valueAsNumber: true
                })}/>
              {errors.distMetro && <p className={'error_message'}>{errors.distMetro?.message}</p>}
            </div>

            {/*distSupermarket*/}
            <div className={'input_container'}>
              <label htmlFor={'distSupermarket'}>{`${t(pageLabel + '.distSupermarket.label')}`}</label>
              <AmountButtonsInput
                name={'distSupermarket'} value={currentProperty.distSupermarket} placeholder={'Ex 5min'} min={0}
                amount={1}
                units={'min'}
                className={`${errors.distSupermarket ? 'input_error' : null} input_field`}
                watchValue={watch('distSupermarket')} setValue={setValue}
                register={register({
                  validate: value => value > 0 || t(pageLabel + '.distSupermarket.required'),
                  valueAsNumber: true
                })}
              />
              {errors.distSupermarket && <p className={'error_message'}>{errors.distSupermarket?.message}</p>}
            </div>
          </div>

          {/*universities*/}
          <div className={'Select input_container'}>
            <label htmlFor={'universities'} className={'no_margin'}>{t(pageLabel + '.universities.label')}</label>
            <p className={'info'}>{t(pageLabel + '.three_options')}</p>
            <ReactSelect key={'universities'}
                         isMulti={true}
                         maxLength={3}
                         value={currentProperty.closeUniversities} options={universitiesOptions}
                         onChange={(value) => handlePropertyFieldChange('closeUniversities', value)}/>
          </div>

          {/*/!*metro_lines*!/*/
            ["Lisboa", "Porto", "Barcelona", "Madrid", "Milan", "Roma", "Sevilla", "Valencia"].includes(property.region) &&
          <div className={'input_container'}>
            <label htmlFor={'metro_lines'}>{`${t(pageLabel + '.metro_lines.label')}`}</label>
            <ReactSelect key={'metro_lines'} isMulti={true}
                         maxLength={3}
                         value={currentProperty.closeMetroLines} className={'Select'} options={metroLinesOptions}
                         onChange={(value) => handlePropertyFieldChange('closeMetroLines', value)}/>
          </div>
          }

          {/*/!*features*!/*/}
          <div className={'input_container'}>
            <label htmlFor={'features'} className={'no_margin'}>{t(pageLabel + '.features.label')}</label>
            <p className={'info'}>{t(pageLabel + '.three_options')}</p>
            <ReactSelect key={'features'} isMulti={true}
                         maxLength={3}
                         value={currentProperty.features} className={'Select'}
                         options={featuresOptions}
                         onChange={(value) => handlePropertyFieldChange('features', value)}/>
          </div>

        </div>
        <Buttons changePage={changePage} saveAndExit={handleSubmit(v => onSubmit({...v, saveAndExit: true}))}/>
      </form>
    </div>
  );

};

const actionCreator = {
  defineUpdateFunc: updatePropertyActions.defineUpdateFunc
};

export default connect(null, actionCreator)(PropertyDetails3);

