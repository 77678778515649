const checkRoomRent = (max, min, rentMonths, rent) => {
    if (rentMonths) {
        Object.values(rentMonths).forEach((m) => {
            if (max && max < m) return false;
            if (min && min > m) return false;
        })
    } else {
        if (max && max < rent) return false;
        if (min && min > rent) return false;
    }
    return true;
}

const getPropertyUnavailabilityReason = (property, user) => {
    let reason = "";
    const userInfo = {...(user.preferences || {}), gender: user.personalDetails?.gender, occupation: user.personalDetails?.occupation};
    //filter properties with user preferences
    if (userInfo.city && property.region !== userInfo.city) {
        reason = "city";
    }

    if (userInfo.accommodation) {
        if ((userInfo.accommodation === "apartment" && property.accommodation !== "apartment") ||
            (["rooms", "residence"].includes(userInfo.accommodation) && property.accommodation === "apartment")) {
            reason = "accommodation";
        }
    }

    if (userInfo.numTenants && property.people < userInfo.numTenants) {
        reason = "numTenants";
    }

    if (userInfo.numRooms && property.rooms && (Object.values(property.rooms).length < userInfo.numRooms)) {
        reason = "numRooms";
    }

    if (userInfo.gender) {
        if (["ratherNotStay", "other"].includes(userInfo.gender) && (property.onlyMales || property.onlyFemales)) {
            reason = "onlyMales"
        } else if ((userInfo.gender === "male" && property.onlyFemales) || (userInfo.gender === "female" && property.onlyMales)) {
            reason = userInfo.gender === "male" ? "onlyFemales" : "onlyMales";
        }
    }

    if (userInfo.occupation && (userInfo.occupation === "student" && property.onlyWorkers) || (userInfo.occupation === "workers" && property.onlyStudents)) {
        reason = userInfo.occupation === "student" ? "onlyWorkers" : "onlyStudents";
    }

    // if (userInfo.location && userInfo.location !== "none") {
    //     const userLocation = userInfo.location;
    //     if (userLocation === "metro" && userInfo.metro) {
    //         if (property.metro_lines && property.metro_lines.filter((line) => line === userInfo.metro).length < 1) {
    //             reason = "metro"
    //         }
    //     }
    //
    //     if (userLocation === "university" && userInfo.university) {
    //         if (property.universities && property.universities.filter((university) =>  university === userInfo.university).length < 1) {
    //             reason = "university"
    //         }
    //     }
    //
    //     if (userLocation === "neighborhood" && userInfo.neighborhood) {
    //         if (property.neighborhood !== userInfo.neighborhood &&
    //             (!property.close_neighborhoods || property.close_neighborhoods.filter((neighborhood) => neighborhood === userInfo.neighborhood).length < 1)) {
    //             reason = "neighborhood"
    //         }
    //     }
    // }

    if ((userInfo.budgetMin || (userInfo.budgetMax && userInfo.budgetMax < 1600))) {
        if (property.rooms) {
            let availableRooms = Object.values(property.rooms).filter((room) => {
                //Chack here availability
                return checkRoomRent(userInfo.budgetMax + 100, userInfo.budgetMin - 100, room.rentMonths, room.rent)
            });
            if (availableRooms.length < (userInfo.numRooms || 1)) {
                reason = "budget"
            }
        }
    }


    if (!reason) {
        reason = "rooms"
        //TODO ideal era mostrar o "Disponível a partir de" na card da casa
    }

    return reason;
}




export { getPropertyUnavailabilityReason };