import React from "react";

const CreditCard = (props) => (
    <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="credit_card_24px">
            <path
                id="icon/action/credit_card_24px"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.333 2.66699H2.66634C1.92634 2.66699 1.33967 3.26033 1.33967 4.00033L1.33301 12.0003C1.33301 12.7403 1.92634 13.3337 2.66634 13.3337H13.333C14.073 13.3337 14.6663 12.7403 14.6663 12.0003V4.00033C14.6663 3.26033 14.073 2.66699 13.333 2.66699ZM13.333 12.0003H2.66634V8.00033H13.333V12.0003ZM2.66634 5.33366H13.333V4.00033H2.66634V5.33366Z"
                fill="#434A5F"
            />
        </g>
    </svg>
);

export default CreditCard;
