import React, {useEffect, useState} from 'react';
import {Route} from 'react-router-dom';
import './style.less';
import ReservationList from './components/ReservationsList';
import CancelReservation from './containers/CancelReservation';
import ReservationDetails from './containers/ReservationDetails';
import {reservationServices} from "../../../../services/reservationServices";
import PagesSwitch from "../../../../components/PagesSwitch";
import Loading from "../../../../components/Loading";
import {useAuth} from "../../../../services/authServices";

const pages = [
    {
        label: 'main_page.reservations.requests.label',
        header: 'main_page.reservations.requests.page_header',
        path: 'requests',
        component: ReservationList,
        filter: 'requests'
    },
    {
        label: 'main_page.reservations.pending.label',
        header: 'main_page.reservations.pending.page_header',
        path: 'pending',
        component: ReservationList,
        filter: 'pending'
    },
    {
        label: 'main_page.reservations.confirmed.label',
        header: 'main_page.reservations.confirmed.page_header',
        path: 'confirmed',
        component: ReservationList,
        filter: 'confirmed'
    },
    {
        label: 'main_page.reservations.rejected.label',
        header: 'main_page.reservations.rejected.page_header',
        path: 'rejected',
        component: ReservationList,
        filter: 'rejected'
    },
    {
        label: 'main_page.reservations.cancelled.label',
        header: 'main_page.reservations.cancelled.page_header',
        path: 'cancelled',
        component: ReservationList,
        filter: 'cancelled'
    }
]

const Reservations = ({path}) => {

    const {user} = useAuth();
    const [reservations, setReservations] = useState(null)
    const [totalResults, setTotalResults] = useState(0)
    //const [filters, setFilters] = useState(localStorage.getItem("filters_reservations") ? JSON.parse(localStorage.getItem("filters_reservations")) : {});
    const [filters, setFilters] = useState("");
    const [loading, setLoading] = useState(true)

    const updateReservationList = async (page, filter, filter2, value, clearFilters = false) => {
        if(filter === "rejected" || filter.currentStatus === "rejected") {
            filter = "rejected_or_expired";
        }

        let filtersNew = {};
        Object.entries(filters).forEach(([key, value]) => {
            if (value) filtersNew[key] = value;
        });

        if(typeof filter !== "string") {
            Object.entries(filter).forEach(([key, value]) => {
                if (value) filtersNew[key] = value;
            });
        }

        if (typeof filter === "string"){
            filtersNew["currentStatus"] = filter;
        } else{
           filtersNew["searchKey"] = filter?.searchKey;
        }
        if (filter2) filtersNew[filter2] = value;
        if (clearFilters) filtersNew = {currentStatus: typeof filter === "string" && filter}
        localStorage.removeItem("filters_reservations")
        let response = await reservationServices.getReservations(page, filtersNew);
        localStorage.setItem("filters_reservations", JSON.stringify(filtersNew));
        setTotalResults(response.total);
        setReservations(response.reservations);
    }

    const updateReservation = async (reservation) => {
        setReservations(_reservations => {
            if(!_reservations) return [reservation];
            const index = _reservations.findIndex(r => r.id === reservation.id);
            if (index !== -1) _reservations[index] = reservation;
            return _reservations;
        });
    }

    useEffect(() => {
        if (!user) return;
        pages[0].num = user.pendingReservationRequests;
        pages[1].num = user.pendingReservations;
        pages[2].num = user.acceptedBookings;
        pages[3].num = user.rejectedReservations;
        pages[4].num = user.cancelledReservations;
        setLoading(false);
    }, [user?.pendingReservationRequests, user?.pendingReservations, user?.acceptedBookings, user?.rejectedReservations, user?.cancelledReservations])

    return (
        <div className={'Reservations'}>
            {loading ? <Loading /> : <PagesSwitch path={path}
                         pages={pages}
                         containerClass={true}
                         boxShadow={true}
                         type={"secondary"}
                         reservations={reservations}
                         updateReservationList={updateReservationList}
                         totalResults={totalResults}
                         filters={filters}
                         setFilters={setFilters}
                         extraRoutes={[
                             <Route key={path + "cancel"} path={`${path}/:reservation_id/cancel`}
                                    render={()=><CancelReservation updateReservation={updateReservation}/>}/>,
                             <Route key={path + "details"} path={`${path}/:reservation_id/details`}
                                    render={()=><ReservationDetails updateReservation={updateReservation}/>}/>
                         ]}/>}
        </div>
    );
};

export default Reservations;
