import React from "react";

import {displayFooterConstants} from '../constants';

export const displayFooterActions = {
  display
}

function display(action) {
  return (dispatch) => {
    dispatch({type: displayFooterConstants.DISPLAY, payload: action});
  };
}

