import isAreaNotUnique from './isAreaNotUnique';
import getAreasNamesFromNums from './getAreasNamesFromNums';

const getPropertyAreasToSend = (propertyAreasCharacteristics,property,fieldName) => {
  let areasFinal = getAreasNamesFromNums(property).map(area =>  (area?.split('_')?.length === 1 && area !== 'toBeDefined' ? `${area}_0` : area));
  areasFinal=areasFinal.filter(area => (area!=='toBeDefined' && area !== fieldName))

  areasFinal = areasFinal.map(area => {
    let title = propertyAreasCharacteristics[area?.split('_')?.[0]]?.title;
    if (isAreaNotUnique(area, property)) title = title + ` ${+area?.split('_')?.[1] + 1}`
    return ({title, area: area})
  })
  return areasFinal;
}

export default getPropertyAreasToSend;
