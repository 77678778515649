import React from "react";

const NotInterested = (props) => (
    <svg
        width={26}
        height={26}
        viewBox="0 0 26 26"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M13 .5C6.1.5.5 6.1.5 13S6.1 25.5 13 25.5 25.5 19.9 25.5 13 19.9.5 13 .5ZM13 23C7.475 23 3 18.525 3 13c0-2.313.788-4.438 2.112-6.125l14.013 14.012A9.878 9.878 0 0 1 13 23Zm7.887-3.875L6.875 5.113A9.878 9.878 0 0 1 13 3c5.525 0 10 4.475 10 10a9.878 9.878 0 0 1-2.113 6.125Z"
        />
    </svg>
);

export default NotInterested;
