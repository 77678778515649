import React, {useEffect, useState} from "react";

import "./style.less";
import {useTranslation} from "react-i18next";
import MyAccountNav from "../MyAccountNav";
import saveIconWhite from "../../../../../../components/ResumeItem/images/saveIconWhite.svg";
import {useForm} from "react-hook-form";
import {propertyServices, userServices} from "../../../../../../services";
import pencilIconGrey from "../images/penciIconGrey.svg";
import verifiedIcon from "../images/verified.svg";
import warning from "../images/warning.svg";
import Loading from "../../../../../../components/Loading";
import attachment from "../../../../../../images/attachment.svg";

import {useAuth} from "../../../../../../services/authServices";

const Verifications = (props) => {
    const {t} = useTranslation();

    const {user} = useAuth();

    const [loading, setLoading] = useState(false);
    const [docName, setDocName] = useState(user?.IDDocumentName);
    const [properties, setProperties] = useState([]);

    const {handleSubmit, register} = useForm({shouldFocusError: true});

    const submit = async (values) => {
        setLoading(true);
        let formData = new FormData();
        let files = values.verificationDocument;
        let file = files[0];
        if (file) {
            formData.append('document', file, file.name);
            // types "IDDocument" or "bankProofDocument"
            await userServices.validateLandlord("IDDocument", formData);
            setDocName(file.name);
        }
        setLoading(false);
    };

    const isPropertyAvailable = (property) => {
        if (property.accommodation === 'apartment' && property.disabled) return !property.disabled
        if (property.accommodation === 'apartment') return !property.rooms?.['room_1']?.disabled
        return Object.values(property.rooms || {}).some(room => !room.disabled);
    }

    useEffect(() => {
        (async () => {
            setProperties((await propertyServices.getCachedPropertiesList()).filter((property) => property.finished && isPropertyAvailable(property)));
        })();
    }, []);

    const VerificationTags = ({verified}) => {
        return (
            <div className={"VerificationTag " + (verified ? "verified" : "unverified")}>
                <img src={verified ? verifiedIcon : warning}/>
                {verified ? "Verificado" : "Não Verificado"}
            </div>
        )
    }

    const handleVerification = async (e, property) => {
        setLoading(true);
        e.stopPropagation();
        propertyServices.askVerification(property.id);
        let propertiesTemp = properties.filter((p) => p.id !== property.id);
        setProperties([...propertiesTemp, {...property, verificationAsked: true}].sort((a, b) => {
            if (a.address > b.address) return 1;
            if (a.address < b.address) return -1;
            return 0
        }));
        setLoading(false);
    };

    const handleRemoveDocument = async () => {
        setLoading(true);
        await userServices.removeVerificationDocument("IDDocument");
        setLoading(false);
    }

    return (
        <div className={"Verifications"}>
            <MyAccountNav pages={props.pages}/>
            <div className="Verifications__container">
            <div className={"landlord-verification"}>
                {loading && <Loading/>}
                <div className={'input_container'}>
                    <div className={"input-header"}>
                        <label htmlFor={'phone'}>{t("verifications.phone_verification")}</label>
                        <VerificationTags verified={user?.phone_verified}/>
                    </div>
                    <input name="phone" type="text"
                           className={`basic_container input_field`}
                           readOnly={true}
                           placeholder={user?.phone}/>
                    <img className={"pencilIconGrey"} src={pencilIconGrey} alt={"Pencil Icon"}/>
                </div>
                <div className={'input_container'}>
                    <div className={"input-header"}>
                        <label htmlFor={'email'}>{"E-mail"}</label>
                        <VerificationTags verified={user?.email_verified}/>
                    </div>
                    <input name="email" type="email"
                           className={`basic_container input_field`}
                           placeholder={user?.email}
                           readOnly={true}/>
                    <img className={"pencilIconGrey"} src={pencilIconGrey} alt={"Pencil Icon"}/>
                </div>
                <form onSubmit={handleSubmit(submit)}>
                    <div className={'input_container verificationDocument'}>
                        <div className={"verificationDocument__header"}>
                            <label>{t("verifications.id")}</label>
                            <p>{t("verifications.id_desc")}</p>
                        </div>
                        <label htmlFor={'verificationDocument'}>
                            <div className={"verificationDocument__input"}>
                                <img src={attachment} alt={"icon"}/>
                                {docName ? docName : t("verifications.id_placeholder")}
                            </div>
                        </label>
                        <input name="verificationDocument" type="file" id={"verificationDocument"}
                               className={`basic_container input_field`}
                               ref={register({required: true})}/>
                    </div>
                    <p>{t("verifications.id_format")}</p>
                    <div className={'buttons_container'}>
                        <button type={'submit'} className={'btn btn_blue'}>{t('buttons.save')}<img
                            alt={"save icon"} src={saveIconWhite}/></button>
                        {/*<button type={'button'} className={'btn btn_grey'} onClick={handleRemoveDocument}>
                            {t('buttons.remove')} <img alt={"trash icon"} src={binIcon}/>
                        </button>*/}
                    </div>
                </form>
            </div>
            <div className={"property-verification"}>
                <h5>{t("verifications.properties")}</h5>
                <div className={"list"}>
                    {properties ? Object.keys(properties).map((key) => {
                        let property = properties[key];
                        let propertyImage = property.photos?.["featured_0"]?.[0]?.md || "";
                        return (
                            <div className={`property-card`} key={"property-card-" + key}>
                                <div className={"property-section"}>
                                    <div className={"property-image"}
                                         style={{backgroundImage: `url(${propertyImage})`}}/>
                                    <div className={"property-info"}>
                                        <span><strong>{property.internal_name}</strong></span>
                                        <span>{property.accommodation} em {property.region}</span>
                                        <span>T{property.bedrooms || (property.rooms && Object.values(property.rooms).length)}</span>
                                    </div>
                                    <div className={"VerificationTag__container"}>
                                        <VerificationTags verified={property.verified}/>
                                        {!property.verified &&
                                            <p onClick={(e) => {if(!property.verificationAsked) handleVerification(e, property)}} className={property.verificationAsked ? "asked" : ""}>{t(`verifications.${property.verificationAsked ? "verification_asked" : "ask_verification"}`)}</p>}
                                    </div>
                                </div>
                            </div>
                        )
                    }) : <Loading/>}
                </div>
            </div>
        </div>
        </div>
    );
};

export default Verifications;
