import React from "react";

const Donwload = (props) => (
    <svg
        width={14}
        height={17}
        viewBox="0 0 14 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M14 6h-4V0H4v6H0l7 7 7-7ZM6 8V2h2v6h1.17L7 10.17 4.83 8H6Zm-6 7h14v2H0v-2Z"
            fill="#fff"
        />
    </svg>
);

export default Donwload;