import CircularProgress from "@material-ui/core/CircularProgress";
import React, {useEffect, useMemo} from "react";
import "./style.less";

const Loading = ({timeout, cover = 0, clear, relative}) => {

    const isRelative = useMemo(() => {return relative}, [relative]);

    const clearCover = () => {
        let loading_el = document.getElementById("loading");
        if(loading_el) {
            loading_el.style.opacity = "0";
        }
    };

    if(timeout) {
        setTimeout(clearCover, timeout)
    }

    useEffect(()=>{
        if(clear) {
            clearCover();
        }
    }, [clear]);

    return (<div id="loading" style={{opacity: "1", backgroundColor: cover && `rgba(255, 255, 255, ${cover})`}} className={"Loading" + (isRelative ? " relative" : "")}>
        <CircularProgress />
    </div>)
};

export default Loading;
