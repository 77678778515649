import React, {useMemo} from 'react';
// Import the useDropzone hooks from react-dropzone
import {useDropzone} from 'react-dropzone';
import {Draggable} from 'react-beautiful-dnd';
import {useTranslation} from 'react-i18next';

import dragAndDropIcon from './images/drag_and_drop.svg';

const Dropzone = ({onDrop, accept, id, image, title, minPhotos = 1, upload = false, isRoomsSection = false}) => {
  // Initializing useDropzone hooks with options
  const {getRootProps, getInputProps} = useDropzone({
    multiple: false,
    onDrop,
    accept
  });

  /*
   useDropzone hooks exposes two functions called getRootProps and getInputProps
   and also exposes isDragActive boolean
   */

  const {t} = useTranslation();

  const getInputPropsConst = () => {
    if (upload) return {...getInputProps()}
    else return {}
  };

  const num = useMemo(() => {
    if (image)
    return Object.keys(image).length
  }, [image])

  return (
    <div {...getRootProps()} className="ChooseOnePhoto__dropzone">
      <input className="ChooseOnePhoto__dropzone-input" {...getInputPropsConst()}/>
      <button id="ChooseOnePhoto__change-image" type="button">
        <label className="ChooseOnePhoto__change-image__title">{title}</label>
        <div className="ChooseOnePhoto__change-image__placeholder">
          <div className="ChooseOnePhoto__change-image__placeholder--icon center">
            <Draggable key={id} draggableId={id} index={0} /*ref={ref}*/ /*style={{ opacity: isDragging ? 0 : 1 }}*/>
              {(provided, snapshot) => (
                <div ref={provided.innerRef}
                     {...provided.draggableProps}
                     {...provided.dragHandleProps}
                     style={{transitionDuration: `0.001s`}}>
                  {num === 0 &&
                   <div className={'icon'}><img className={'drag_and_drop'} src={dragAndDropIcon}/></div>}
                  {num > 0 &&
                   <span style={{backgroundImage: `url('${image.src}')`}} className="file-img"/>}
                </div>)}
            </Draggable>
          </div>
          <div className="ChooseOnePhoto__change-image__info">
            <label>{t('dropzone.' + (isRoomsSection ? 'drag_photo_rooms' : 'drag_photo'))}</label>
            <p>{`${num > 0 ? 1 : 0} ${t('photo', {count: num > 0 ? 1 : 0})} | ${t('minimum')} ${minPhotos}`}</p>
          </div>
        </div>
      </button>
    </div>
  );
};

export default Dropzone;
