import React, {useEffect, useMemo, useState} from 'react';

import "./style.less";
import {visitServices} from '../../../../services';
import VisitsList from './components/VisitsList';
import PagesSwitch from "../../../../components/PagesSwitch";
import moment from "moment";
import {connect} from "react-redux";
import {useAuth} from "../../../../services/authServices";
import Loading from "../../../../components/Loading";

const pages = [
    {
        label: 'main_page.my_visits.requests.label',
        header: 'main_page.my_visits.requests.page_header',
        path: 'requests',
        component: VisitsList,
        filter: 'requests'
    },
    {
        label: 'main_page.my_visits.pending.label',
        header: 'main_page.my_visits.pending.page_header',
        path: 'pending',
        component: VisitsList,
        filter: 'pending'
    },
    {
        label: 'main_page.my_visits.scheduled.label',
        header: 'main_page.my_visits.scheduled.page_header',
        path: 'scheduled',
        component: VisitsList,
        filter: 'scheduled'
    },
    {
        label: 'main_page.my_visits.rejected.label',
        header: 'main_page.my_visits.rejected.page_header',
        path: 'rejected',
        component: VisitsList,
        filter: 'rejected'
    },
    {
        label: 'main_page.my_visits.ended.label',
        header: 'main_page.my_visits.ended.page_header',
        path: 'ended',
        component: VisitsList,
        filter: 'ended'
    },
    {
        label: 'main_page.my_visits.cancelled.label',
        header: 'main_page.my_visits.cancelled.page_header',
        path: 'cancelled',
        component: VisitsList,
        filter: 'cancelled'
    }
];

const MyVisits = () => {

    const path = "/my_visits";

    const {user} = useAuth();
    const [visits, setVisits] = useState(null);
    const [totalResults, setTotalResults] = useState(0);
    const [loading, setLoading] = useState(true);
    //const [filteredVisits, setFilteredVisits] = useState();


    const updateVisits = async (page, filters) => {
        await visitServices.getVisits(page, filters).then((data) => {
            setVisits(data.visits);
            setTotalResults(data.total);
        });
    }

    useEffect(() => {
        pages[0].num = user?.visitRequests;
        pages[1].num = user?.pendingVisits;
        pages[2].num = user?.scheduledVisits;
        pages[3].num = user?.rejectedVisits;
        pages[4].num = user?.completedVisits;
        pages[5].num = user?.cancelledVisits;
        setLoading(false);
    }, [user, user?.visitRequest, user?.pendingVisits, user?.scheduledVisits, user?.rejectedVisits, user?.completedVisits, user?.cancelledVisits]);


    return (
        <div className='Visits'>
            {loading ? <Loading /> :
                <PagesSwitch path={path}
                             pages={pages}
                             containerClass={true}
                             boxShadow={true}
                             type={"secondary"} updateVisits={updateVisits}
                             visits={visits} setVisits={setVisits}
                             totalResults={totalResults}
                />}
        </div>

    );
};


export default MyVisits;
