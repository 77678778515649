import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

import en from '../locales/en.json'
import pt from '../locales/pt.json'
import es from '../locales/es.json'
import it from '../locales/it.json'

const resources = {
  en, pt, es, it
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(Backend)
  .init({
    order: ['querystring', 'localStorage', 'cookie', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    lookupQuerystring: 'lang',
    resources,
    fallbackLng: 'en',
    debug: true,
    crossDomain: true,
    interpolation: {
      escapeValue: false
    },
    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i','a'],
      useSuspense: false
    }
  });

window._i18nChangeLanguage = (lang) => {
  i18n.changeLanguage(lang);
}

export default i18n;
