import React from "react";

const PersonAdd = (props) => (
    <svg
        width={48}
        height={48}
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="person_add" clipPath="url(#clip0_2344_9674)">
            <path
                id="Vector"
                d="M30 24C34.42 24 38 20.42 38 16C38 11.58 34.42 8 30 8C25.58 8 22 11.58 22 16C22 20.42 25.58 24 30 24ZM30 12C32.2 12 34 13.8 34 16C34 18.2 32.2 20 30 20C27.8 20 26 18.2 26 16C26 13.8 27.8 12 30 12ZM30 28C24.66 28 14 30.68 14 36V40H46V36C46 30.68 35.34 28 30 28ZM18 36C18.44 34.56 24.62 32 30 32C35.4 32 41.6 34.58 42 36H18ZM12 30V24H18V20H12V14H8V20H2V24H8V30H12Z"
                fill="#434A5F"
            />
        </g>
        <defs>
            <clipPath id="clip0_2344_9674">
                <rect width={48} height={48} fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default PersonAdd;
