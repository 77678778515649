import React, {useCallback, useMemo, useState, useRef, useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {propertyAdHistoryActions} from '../../../../../../redux/actions';
import {connect} from 'react-redux';
import {usePopupDialog} from '../../../../../../components/PopupDialog';
import {propertyServices} from '../../../../../../services';
import PropertyResume from '../../../../../../components/ResumeItem/PropertyResume';
import {useTranslation} from 'react-i18next';

import './style.less'
import SearchFilter from '../../../../../../components/SearchFilter';
import EditAdDialog from '../../../../../../components/EditAdDialog';
import Loading from '../../../../../../components/Loading';
import {BsPlusCircle} from 'react-icons/bs';
import Dialog from "@material-ui/core/Dialog";
import ProgressBar from "@ramonak/react-progress-bar";
import {faCheckCircle} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FiltersContainer from "../../../../../../components/FiltersContainer";
import useQuery from "../../../../../../utils/query";
import PaginationLegacy from "../../../../../../components/PaginationLegacy";
import {useAuth} from "../../../../../../services/authServices";

const isPropertyDisabled = (property) => {
    if (property.accommodation === 'apartment' && property.disabled) return property.disabled
    if (property.accommodation === 'apartment') return property.rooms?.['room_1']?.disabled
    return Object.values(property.rooms || {}).some(room => room.disabled);
}

const isPropertyAvailable = (property) => {
    if (property.accommodation === 'apartment' && property.disabled) return !property.disabled
    if (property.accommodation === 'apartment') return !property.rooms?.['room_1']?.disabled
    return Object.values(property.rooms || {}).some(room => !room.disabled);
}

const ConfirmAvailabilitiesDialog = ({open, setOpen, needConfirm, updatePropertyList}) => {

    const {t} = useTranslation();

    const [confirming, setConfirming] = useState(false);
    const [confirmed, setConfirmed] = useState(0);
    const [finished, setFinished] = useState(false);

    const confirm = async () => {
        setConfirming(true);
        for (let i = 0; i < needConfirm.length; i++) {
            await propertyServices.confirmAvailability(needConfirm[i].id);
            setConfirmed(prevState => prevState + 1);
        }
        setConfirming(false);
        setFinished(true);
        setTimeout(handleClose, 4000);
    }

    const handleClose = () => {
        setFinished(false);
        setConfirmed(0);
        setOpen(false);
        updatePropertyList();
    }

    return (<Dialog open={open} onClose={handleClose} className={"ConfirmAvailabilitiesDialog"}>
        <h3>{t('main_page.my_ads.confirm_all_availabilities.title')}</h3>
        <p>{t('main_page.my_ads.confirm_all_availabilities.desc')}</p>
        {confirming && <Loading/>}
        <div className={`ConfirmAvailabilities__Success ${finished ? 'finished' : ''}`}>
            <FontAwesomeIcon icon={faCheckCircle}/>
        </div>
        <div className="ConfirmAvailabilities__ProgressContainer">
            <ProgressBar
                className={"ConfirmAvailabilities__Progress"}
                completed={(confirmed / needConfirm.length) * 100}
                bgColor={'#1D9AFA'}
                borderRadius={"2px"}
                height={"5px"}
                isLabelVisible={false}
                transitionDuration={"0.1s"}
            />
            <p>{confirmed} / {needConfirm.length}</p>
        </div>

        <div className="ConfirmAvailabilities__Actions">
            <button className="btn btn_grey_soft" disabled={confirming} onClick={handleClose}>
                {finished ? t('buttons.close') : t('buttons.cancel')}
            </button>
            <button className="btn btn_blue" disabled={confirming || finished} onClick={confirm}>
                {t('buttons.confirm')}
            </button>
        </div>
    </Dialog>)
};

let openInfo;

export function getOpenInformation() {
    return openInfo;
}

export function setOpenInformation(open) {
    openInfo = open;
}

const AdsList = ({userProperties, totalResults, filter, addHistoryReset, updateUserPropertiesList, totalListings, ...props}) => {

    const {user, setUser} = useAuth();

    const popupDialog = usePopupDialog();
    const history = useHistory();
    const location = useLocation();

    const {t} = useTranslation();
    const query = useQuery();
    const [pages, setPages] = useState();
    const [page, setPage] = useState(parseInt(query.prevPage) || 0);
    const [loading, setLoading] = useState(true);
    const [searchFilter, setSearchFilter] = useState('');

    const isIntegrationAccout = useMemo(() => {
       return userProperties?.find((property) => property.integration_platform);
    }, [userProperties]);

    useEffect(() => {
        if((query.propertyId || query.roomId ) && !loading) {
            setOpen({
                property_id: query.propertyId,
                room_id: query.roomId,
                isRoom: !!query.roomId,
            });

            const searchParams = new URLSearchParams(location.search);
            searchParams.delete('propertyId');
            searchParams.delete('roomId');
            history.push(location.pathname + '?' + searchParams.toString());
        }
    }, [loading]);

    useEffect(() => {
        (async () => {
            await updateUserPropertiesList(page, {"currentStatus": filter || "published", "searchKey": searchFilter});
            setLoading(false);
        })();
    }, [searchFilter])

    const userPropertiesFilter = useMemo(() => {
        if (!userProperties) return [];
        return userProperties;
    }, [userProperties]);


    window._listProperties = () => {
        let _list = ["type\taddress\tinternal name\trent (base)\tlink"];
        userPropertiesFilter.forEach(p => {
            if (p.accommodation === "apartment") {
                _list.push("apartment\t" + p.address + "\t" + p.internal_name + "\t" + p.rooms["room_1"].rent + "\t" + propertyServices.generateStudentUrl(p.id));
            } else {
                Object.values(p.rooms).forEach(r => {
                    _list.push(p.accommodation + "\t" + p.address + "\t" + p.internal_name + "\t" + r.rent + "\t" + propertyServices.generateStudentUrl(p.id, r.id));
                });
            }
        });
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(_list.join('\n')));
        element.setAttribute('download', 'lista_anuncios.txt');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    const changeLevelHandler = useCallback(async (history, property_id, level, room) => {
        addHistoryReset();
        if (level && room)
            history.push(`/my_ads/ad/edit/${property_id}/${room}/${level}/${filter}`);
        else
            history.push(`/my_ads/ad/edit/${property_id}/${filter}`)
    }, []);

    const updateUserListingNumbers = (roomNumber, property) => {
        try {
            let amount = property.accommodation === "apartment" ? 1 : (property.rooms && Object.keys(property.rooms).length > 0 ? Object.keys(property.rooms).length : 1);
            if (roomNumber) {
                setUser(prev => ({
                    ...prev,
                    inProgressListings: (prev.inProgressListings - 1 > 0 ? prev.inProgressListings - 1 : 0)
                }))
            } else {
                setUser(prev => ({
                    ...prev,
                    inProgressListings: (prev.inProgressListings - amount > 0 ? prev.inProgressListings - amount : 0)
                }));
            }
        } catch (e) {
            console.log(e);
        }
    }

    const onDeleteHandler = useCallback((property_id, roomNumber, property) => {
        popupDialog.openPopupDialog({
            title: t(`popup-dialog.delete_${roomNumber ? 'room' : 'property'}.title`),
            message: t(`popup-dialog.delete_${roomNumber ? 'room' : 'property'}.message`),
            request: async () => {
                if (roomNumber) await propertyServices.deleteRoom(property_id, roomNumber)
                else await propertyServices.deleteProperty(property_id)
                updateUserListingNumbers(roomNumber, property);
            },
            request_done: async () => {
                await updateUserPropertiesList(page, {
                    "currentStatus": filter || "published",
                    "searchKey": searchFilter
                });
            }
        });
    }, []);

    const onDisableRoomHandler = useCallback((property_id, roomNumber) => {
        popupDialog.openPopupDialog({
            title: t(`popup-dialog.disable_${roomNumber ? 'room' : 'property'}.title`),
            message: t(`popup-dialog.disable_${roomNumber ? 'room' : 'property'}.message`),
            request: async () => {
                await propertyServices.disableRoom(property_id, roomNumber)
            },
            request_done: async () => {
                await updateUserPropertiesList(page, {
                    "currentStatus": filter || "published",
                    "searchKey": searchFilter
                });
            }
        })
    }, [])

    const propertyRef = useRef({});
    const roomsRef = useRef({});

    const setRoomsRef = (room_id, ref) => {
        roomsRef.current = {...roomsRef.current, [room_id]: ref};
    }

    const setPropertyRef = (property_id, ref) => {
        if (ref) {
            let index = Array.prototype.slice.call(ref.parentElement.children).indexOf(ref);
            propertyRef.current = {...propertyRef.current, [property_id]: {current: ref, index}};
        }
    }

    const updateUserPropertiesListAndScroll = async (property_id) => {
        if (property_id) {
            //let ref = room_id ? roomsRef.current[property_id + "_" + room_id].current : propertyRef.current[property_id];
            //ref.scrollIntoView({behavior:"instant", block: 'center'});
            let top = (propertyRef.current[property_id].index * 110) - 160;
            if (document.documentElement.oldScrollTop) {
                top = document.documentElement.oldScrollTop;
                delete document.documentElement.oldScrollTop;
            }
            document.scrollingElement.scrollTop = top;
            window.scrollTo({top: top, behavior: "auto"});
        }
        await updateUserPropertiesList(page, {"currentStatus": filter || "published", "searchKey": searchFilter});
    };

    const onEnableHandler = useCallback(async (property_id, roomNumber, accommodation) => {
        try {
            await propertyServices.enableProperty(property_id);
        } catch (e) {
            console.log(e);
        }
        //TODO ver porque com apartamento não está a funcionar
        if (accommodation === "apartment") {
            await propertyServices.enableRoom(property_id, "room_1");
        } else {
            try {
                await propertyServices.enableRoom(property_id, roomNumber);
            } catch (e) {
                console.log(e);
            }
        }
        setUser(prev => ({...prev, activeListings: prev.activeListings + 1, inactiveListings: prev.inactiveListings - 1}))
        await updateUserPropertiesList(page, {"currentStatus": filter || "published", "searchKey": searchFilter});
    }, []);

    let requireConfirmAvail = useMemo(() => {
        if (filter) return [];
        return userPropertiesFilter.filter(property => {
            if (property.accommodation === "residence" && Object.values(property.rooms || {}).every(room => room.openResidenceCalendar)) return false;
            if (property.accommodation === "apartment") {
                return new Date(property.rooms?.["room_1"]?.confirmedAvailability).getTime() < (Date.now() - 86400000);
            } else {
                return Object.values(property.rooms || {}).filter(room => !room.disabled).some(room => {
                    return new Date(room.confirmedAvailability).getTime() < (Date.now() - 86400000)
                })
            }
        })
    }, [userPropertiesFilter]);

    const noAdsToShow = useMemo(() => {
        let label;
        switch (filter) {
            case 'disabled':
                label = 'disabled';
                break;
            case 'not_finished':
                label = 'in_progress'
                break;
            default:
                label = 'published';
        }
        return t(`main_page.my_ads.${label}.no_ads_to_show`)
    }, [userPropertiesFilter]);

    const [open, setOpen] = useState({});
    const [displayRooms, setDisplayRooms] = useState(null);
    const [openConfirmAvailabilitiesDialog, setOpenConfirmAvailabilitiesDialog] = useState(false);

    useEffect(() => {
        setOpenInformation(open);
    }, [open]);

    useEffect(() => {
        if (query.id) {
            setDisplayRooms(query.id);
        }
    }, [query.id])

    useEffect(() => {
        if (displayRooms) {
            let top = (propertyRef.current[displayRooms]?.index * 110) - 160;
            //document.getElementById(displayRooms)?.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'})
            window.scrollTo({top: top, behavior: "smooth"});
        }
    }, [displayRooms])


    useEffect(async () => {
        let pagesTemp = props.pages;
        if (userProperties && !pages) {
            setPages(pagesTemp);
        }
    }, [props.pages, userProperties]);

    const searchTimeout = useRef(null);

    const handleSearchKeyChange = (searchedKey) => {
        //use deboucer to set searchFilter
        window.clearTimeout(searchTimeout.current);
        if (searchedKey == null || (searchedKey.length > 0 && searchedKey.length < 2)) return;
        setLoading(true)
        searchTimeout.current = window.setTimeout(() => {
            setSearchFilter(searchedKey);
            setLoading(false)
        }, 250);
    }

    return (<div className={'AdsList'}>
        <ConfirmAvailabilitiesDialog
            open={openConfirmAvailabilitiesDialog}
            setOpen={setOpenConfirmAvailabilitiesDialog}
            needConfirm={requireConfirmAvail}
            updatePropertyList={updateUserPropertiesList}
        />
        <div className="page__header">
            <div className="page__header__title">
            <h3 className={"AccountDetails__title"}>{t(props.header)}</h3>
            {pages && <FiltersContainer pages={pages} type={"my_ads"}/>}
        </div>

        {pages && <PaginationLegacy totalListings={totalListings} setPage={setPage} page={page}
                              updateList={updateUserPropertiesList} filter={filter || "published"}
                              setLoading={setLoading} total={totalResults} searchKey={searchFilter} isListings={true}/>}

        <div className={'CreateAndSearchProperty'}>
            <button className={'btn btn_blue shadow'} disabled={!!isIntegrationAccout}
                    onClick={() => history.push('/my_ads/ad/create')}>
                <BsPlusCircle/>
                <p>{(t('buttons.create_new_ad'))}</p>
            </button>
            <div className={'ConfirmAllAvailabilitiesContainer'}>
                {!filter && userPropertiesFilter.length > 0 && (requireConfirmAvail.length > 0 ?
                    <p className={'link link_blue'} onClick={() => setOpenConfirmAvailabilitiesDialog(true)}>
                        {t('main_page.my_ads.confirm_all_availabilities.button')}
                    </p> : <p>{t('main_page.my_ads.confirm_all_availabilities.all_confirmed')}</p>)}
            </div>
                <SearchFilter placeholder={'property_placeholder'} onChange={handleSearchKeyChange} />
        </div>
        </div>


        <EditAdDialog open={open} setOpen={setOpen} updatePropertyList={updateUserPropertiesListAndScroll}/>

        {userProperties == null || loading ? <Loading/> : (userPropertiesFilter.length > 0
            ? userPropertiesFilter.map(property =>
                (<PropertyResume key={property.id} tabIndex={'-1'} componentRef={(r) => {
                    setPropertyRef(property.id, r)
                }}
                                 setRoomsRef={setRoomsRef} property={property} type={'My_Ads'} history={history}
                                 changePage={(level, room) => changeLevelHandler(history, property.id, level, room)}
                                 currentPage={page}
                                 onDelete={(roomNumber) => onDeleteHandler(property.id, roomNumber, property)}
                                 onEnable={(roomNumber) => onEnableHandler(property.id, roomNumber)}
                                 filter={filter} displayRooms={displayRooms} setDisplayRooms={setDisplayRooms}
                                 onDisable={(roomNumber) => onDisableRoomHandler(property.id, roomNumber)}
                                 setOpen={(room_id, editLevel) => setOpen({
                                     property_id: property.id,
                                     room_id,
                                     isRoom: !!room_id,
                                     isApartment: property.accommodation === "apartment",
                                     editLevel
                                 })}
                />))
            :
            <div className={'AdsList_noAds'}>
                <p>{noAdsToShow}</p>
            </div>)
        }
    </div>)

};

const actionCreator = {
    addHistoryReset: propertyAdHistoryActions.reset
};

export default connect(null, actionCreator)(AdsList);

