import React from 'react';
import DatePicker, {registerLocale} from 'react-datepicker';
import {Controller} from 'react-hook-form';

import pt from 'date-fns/locale/pt';

registerLocale('pt', pt);

import './style.less'
import calendarIcon from './images/calendarIcon.svg';

const CustomDatePicker = ({
  selected,
  showIcon,
  name,
  control,
  className,
  rules,
  placeholder = 'dd.mm.yyyy',
  disabled,
  minDate,
  maxDate,
  onChange,
  dateFormat
}) => {

  return (
    <div className={'CustomDatePicker'}>
      <Controller
        control={control}
        name={name}
        defaultValue={selected}
        render={(props) => (
          <DatePicker
            className={className}
            onChange={(e) => {
              props.onChange(e)
              /* if (onChange) {
                onChange(e)
              } */
            }}
            selected={props.value}
            showPreviousMonths={false}
            locale={localStorage.getItem('i18nextLng').split('-')[0]}
            dateFormat={dateFormat ? dateFormat : 'dd MMM yyyy'}
            placeholderText={placeholder}
            formatWeekDay={nameOfDay => nameOfDay.substr(0, 1).toUpperCase()}
            popperPlacement='bottom-start'
            disabled={disabled}
            minDate={minDate}
            maxDate={maxDate}
            disabledKeyboardNavigation
            popperModifiers={{
              flip: {
                behavior: ['bottom'] // don't allow it to flip to be above
              },
              preventOverflow: {
                enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
              },
              hide: {
                enabled: false // turn off since needs preventOverflow to be enabled
              },
              computeStyle: { gpuAcceleration: false }
            }}
          />
        )}
        rules={rules}
      />
      {showIcon && <img src={calendarIcon}/>}
    </div>
  )
};

export default CustomDatePicker;

