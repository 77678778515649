import {useTranslation} from 'react-i18next';
import calendarIconGray from '../../images/calendarIconGray.svg';
import calendarIconBlue from '../../images/calendarIconBlue.svg';
import React, {useMemo} from 'react';

import './style.less'
import moment from "moment";

const componentLabel = 'dashboard_list_item'

const MoveInInfo = ({move_in, move_out, stayLength}) => {

    const {t} = useTranslation();

    const totalStay = useMemo(() => {
        const moveIn = new Date(move_in);
        const moveOut = new Date(move_out);

        const yearDiff = moveOut.getUTCFullYear() - moveIn.getUTCFullYear();
        const monthDiff = moveOut.getUTCMonth() - moveIn.getUTCMonth();

        const totalMonths = yearDiff * 12 + monthDiff + 1;

        return totalMonths > 1 ? `${totalMonths} ${t('months')}` : `${totalMonths} ${t('month')}`;
    }, [move_in, move_out]);

    return (
        <div className={'MoveInInfo'}>
            <div>
                <p className={'small'}>
                    <span>{t(componentLabel + '.entry_date')}</span> {moment(move_in, "YYYY/MM/DD").format("DD/MM/YYYY")}
                </p>
            </div>
            <div>
                <p className={'small'}>
                    <span>{t(componentLabel + '.exit_date')}</span> {moment(move_out, "YYYY/MM/DD").format("DD/MM/YYYY")}
                </p>
            </div>
            <p className={'small'}>
                <span>{t(componentLabel + '.totalStay')} </span>
                {totalStay}
            </p>
        </div>
    )
}

export default MoveInInfo;