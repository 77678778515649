import React from "react";

const CityBuilding = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g fill="#1D9AFA">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 3v18H2V3h2Zm12 0v18h-2V3h2Z"
            />
            <path fillRule="evenodd" clipRule="evenodd" d="M16 5H2V3h14v2Z" />
            <path d="M10 7h2v2h-2V7ZM6 7h2v2H6V7Zm0 4h2v2H6v-2Zm4 0h2v2h-2v-2Zm-4 4h2v2H6v-2Zm11-2h2v2h-2v-2Zm-7 2h2v2h-2v-2Zm-2 4h2v2H8v-2Zm9-2h2v2h-2v-2Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M22 9v12h-2V9h2Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M22 11h-7V9h7v2Z" />
        </g>
    </svg>
);

export default CityBuilding;
