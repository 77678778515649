import React, {forwardRef, useCallback, useState} from 'react'
import {Editable, withReact, useSlate, Slate} from 'slate-react';

import {
    Editor,
    Transforms,
    createEditor
} from 'slate'

import {Button} from "@material-ui/core";
import "./style.less";

import bold from "./img/format_bold.svg";
import italic from "./img/format_italic.svg";
import underline from "./img/format_underlined.svg";
import bullet_list from "./img/format_list.svg";
import heading_one from "./img/format_heading-one.svg";
import heading_two from "./img/format_heading-two.svg";
import numbered from "./img/format_numbered-list.svg";
import isHotkey from "is-hotkey";

const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+`': 'code',
}
const LIST_TYPES = ['numbered-list', 'bulleted-list']


const RichTextEditor = forwardRef(({handleOnChange, control, defaultValue, editor}) => {

    const renderElement = useCallback(props => <Element {...props} />, [])

    const Element = ({attributes, children, element}) => {
        const style = {textAlign: element.align}
        switch (element.type) {
            case 'block-quote':
                return (
                    <blockquote style={style} {...attributes}>
                        {children}
                    </blockquote>
                )
            case 'bulleted-list':
                return (
                    <ul style={style} {...attributes}>
                        {children}
                    </ul>
                )
            case 'heading-one':
                return (
                    <h1 style={style} {...attributes}>
                        {children}
                    </h1>
                )
            case 'heading-two':
                return (
                    <h2 style={style} {...attributes}>
                        {children}
                    </h2>
                )
            case 'list-item':
                return (
                    <li style={style} {...attributes}>
                        {children}
                    </li>
                )
            case 'numbered-list':
                return (
                    <ol style={style} {...attributes}>
                        {children}
                    </ol>
                )
            default:
                return (
                    <p style={style} {...attributes}>
                        {children}
                    </p>
                )
        }
    }

    const renderLeaf = useCallback(props => {
        return <Leaf {...props} />
    }, []);


    const Leaf = ({attributes, children, leaf}) => {
        if (leaf.bold) {
            children = <strong>{children}</strong>
        }

        if (leaf.code) {
            children = <code>{children}</code>
        }

        if (leaf.italic) {
            children = <em>{children}</em>
        }

        if (leaf.underline) {
            children = <u>{children}</u>
        }
        return <span {...attributes}>{children}</span>
    }

    const isMarkActive = (editor, format) => {
        const marks = Editor.marks(editor)
        return marks ? marks[format] === true : false
    }


    const toggleMark = (editor, format) => {
        const isActive = isMarkActive(editor, format)

        if (isActive) {
            Editor.removeMark(editor, format)
        } else {
            Editor.addMark(editor, format, true)
        }
    }

    const MarkButton = ({format, icon, alt}) => {
        const editor = useSlate()
        return (
            <Button
                className={"btn small btn_blue" + (isMarkActive(editor, format) ? " active" : "")}
                onClick={event => {
                    event.preventDefault()
                    toggleMark(editor, format)
                }}>
                <img src={icon} alt={alt}/>
            </Button>
        )
    }

    const toggleBlock = (editor, format) => {
        const isActive = isBlockActive(editor, format)
        const isList = LIST_TYPES.includes(format)

        Transforms.unwrapNodes(editor, {
            match: n => LIST_TYPES.includes(n.type),
            split: true,
        })

        Transforms.setNodes(editor, {
            type: isActive ? 'paragraph' : isList ? 'list-item' : format,
        })

        if (!isActive && isList) {
            const block = {type: format, children: []}
            Transforms.wrapNodes(editor, block)
        }
    }


    const isBlockActive = (editor, format) => {
        const nodes = Editor.nodes(editor, {
            match: n => n.type === format,
        })
        return !!nodes.next().value
    }

    const BlockButton = ({format, icon, alt}) => {
        const editor = useSlate()
        return (
            <Button
                className={"btn btn_blue small" + (isBlockActive(editor, format) ? " active" : "")}
                onClick={event => {
                    event.preventDefault()
                    toggleBlock(editor, format)
                }}
            >
                <img src={icon} alt={alt}/>
            </Button>
        )
    }

    return (
        <div className={"RichTextEditor"} id={"RichTextEditor"}>
            <Slate editor={editor} value={defaultValue}
                   onChange={v => {
                       handleOnChange(v);
                   }}>
                <div className="btnContainer">
                    <MarkButton format={"bold"} icon={bold} alt={"bold"}/>
                    <MarkButton format={"italic"} icon={italic} alt={"italic"}/>
                    <MarkButton format={"underline"} icon={underline} alt={"underline"}/>
                    <BlockButton format={"bulleted-list"} icon={bullet_list} alt={"bulleted-list"}/>
                    <BlockButton format={"heading-one"} icon={heading_one} alt={"heading-one"}/>
                    <BlockButton format={"heading-two"} icon={heading_two} alt={"heading-two"}/>
                    <BlockButton format={"numbered-list"} icon={numbered} alt={"numbered-list"}/>
                </div>
                <div className="editorContainer">
                    <Editable
                        control={control}
                        renderElement={renderElement}
                        renderLeaf={renderLeaf}
                        spellCheck
                        onKeyDown={event => {
                            for (const hotkey in HOTKEYS) {
                                if (isHotkey(hotkey, event)) {
                                    event.preventDefault()
                                    const mark = HOTKEYS[hotkey]
                                    toggleMark(editor, mark)
                                }
                            }
                        }}
                    />
                </div>
            </Slate>
        </div>
    );
});

export default RichTextEditor;