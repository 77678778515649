import React from "react";

const Inlife = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22 7.937 12 2 6.444 5.298V4.222H4.222v2.395L2 7.937a2.26 2.26 0 0 0 3.045.75L12 4.66l6.955 4.027A2.26 2.26 0 0 0 22 7.937ZM6.444 13.11a2.222 2.222 0 0 0-2.222-2.222V22h2.222v-8.889Zm13.334-2.222a2.222 2.222 0 0 0-2.222 2.222V22h2.222V10.889Zm-7.736 5.103c-.81 0-1.365.58-1.37 1.57V22H8.667v-7.678h1.911v1.355h.085c.325-.895 1.102-1.455 2.175-1.455 1.507 0 2.495 1.1 2.495 2.89V22h-2.005v-4.509c.004-.94-.48-1.5-1.286-1.5Z"
        />
    </svg>
);

export default Inlife;
