import React from "react";
import PropTypes from "prop-types";

const PriceRangeChart = (props) => {
    let bars = [];
    for (let i = props.min + props.step; i <= props.max; i += props.step) {
        // Find last
        let dataX = Object.keys(props.data).filter(value => value <= i).reverse()[0];
        if (props.data[dataX])
            bars.push(props.data[dataX]);
        else {
            bars.push(0);
        }
    }
    let max = Math.max(...(bars.slice(0,-1))); //all but last
    return <div className={"PriceRangeChart"}>
        {bars.map((value, idx) => {
            let currentPos = ((idx+0.5) * props.step) + props.min;
            return <span key={"price_range_"+idx} className={(currentPos >= (props.value[0] - props.step) && currentPos < props.value[1])?'active':''} style={{maxHeight: (value * 100 / max) + "%"}}/>
        })}
    </div>;
};

PriceRangeChart.propTypes = {
    data: PropTypes.object.isRequired,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    step: PropTypes.number.isRequired,
};

export default PriceRangeChart;
