import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {useForm} from 'react-hook-form';
import {FieldSetCheckBoxes} from '../../../../../components/FieldSetCheckBoxes';
import Buttons from '../../../../../components/Buttons';

import './style.less'
import {useTranslation} from 'react-i18next';
import isAreaNotUnique from '../../../../../functions/isAreaNotUnique';
import {updatePropertyActions} from "../../../../../../../../../../../redux/actions/updatePropertyActions";

const pageLabel = 'main_page.my_ads.create_ad.house_details.common_areas_details';

const CommonAreaComponent = ({
                               areaName,
                               amenetiesAreaOptionsNames,
                               property,
                               goToNextArea,
                               finishedSectionDetails,
                               updateProperty,
                               goToPreviousArea,
                               defineUpdateFunc
                             }) => {
  const {t} = useTranslation();

  const areaIndex = areaName.split('_')[1] ? areaName.split('_')[1] : 0;
  const title = t(`${pageLabel}.${areaName.split('_')[0]}.label`) + (areaName.split('_').length === 2 && isAreaNotUnique(areaName, property) ? ' ' + (+areaIndex + 1) : '');

  // the name as it is stored in the db
  const areaFinalName = areaName.split('_').length === 2 ? areaName : `${areaName}_0`;

  let amenetiesSpecificOptions = amenetiesAreaOptionsNames.map(option => ({
    value: option,
    label: t(`${pageLabel}.${areaName.split('_')[0]}.${option}`)
  }));

  const [commonAreasEquipments, setCommonAreasEquipments] = useState(() => {
    let comoditiesFinal = [];

    if (property.sectionDetails?.[areaFinalName]) {
      amenetiesAreaOptionsNames.map(value => {
        if (property.sectionDetails[areaFinalName][value] === true) comoditiesFinal.push(value)
      })
    }
    return comoditiesFinal;
  });

  const [changedSelectFields, setChangedSelectFields] = useState(false);

  const onSubmit = async (values) => {
    amenetiesAreaOptionsNames.map(value => {
      values[value] = commonAreasEquipments.includes(value);
    });

    values.area = areaName.split('_')[0];
    values.index = areaIndex;
    if (finishedSectionDetails) values.finishedSectionDetails = true;

    let stayInArea = false;
    try {
      stayInArea = await updateProperty(values, '1.5');
      setChangedSelectFields(false);
    } catch (e) {
    }
    if(!stayInArea) goToNextArea();
  };

  const {handleSubmit, register, formState} = useForm({shouldFocusError: true});

  useEffect(() => defineUpdateFunc(async () => new Promise(async (resolve) => {
    if(!formState.isDirty && !changedSelectFields) return resolve();
    await handleSubmit(async (values) => {
      await onSubmit(values);
      resolve()
    }, resolve)()
  })), [areaName, commonAreasEquipments, formState.isDirty, changedSelectFields]);

  return (
    <div className="CommonAreaComponent">
      <div className="CommonAreaComponent__header">
        <div className="CommonAreaComponent__header-title">
          <h4 className="title">{title}</h4>
          <div className="header-image" style={{backgroundImage: `url(${property.photos?.[areaFinalName]?.[0]?.md})`}}/>
        </div>
        <p className="CommonAreaComponent__header-subtitle">{`${title} - ${t(pageLabel + '.insert_only')}`}</p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={'scrollable'}>
          <div className={'input_container'}>
            <label htmlFor={'size'}>{t(`${pageLabel}.${areaName.split('_')[0]}.size_label`)}</label>
            <input name="size" type="number" defaultValue={property.sectionDetails?.[areaFinalName]?.size}
                   placeholder={`Ex: 70 m2`} className={`input_field`} ref={register} onWheel={(e) => e.target.blur()}
            />
          </div>

          <div className={'input_container ameneties_checkboxes'}>
            <FieldSetCheckBoxes t={t} checkBoxesNamesMapped={amenetiesSpecificOptions} name={'commonArea'}
                                values={commonAreasEquipments} setValues={(values) => {
                                  setCommonAreasEquipments(values);
                                  setChangedSelectFields(true);
                                }} register={register}
                                type={'right label_bold transparent'}
                                amountInLine={'two-in-line'}/>
          </div>
        </div>
        <Buttons saveAndExit={handleSubmit(v=>onSubmit({...v, saveAndExit: true}))} clickBack={goToPreviousArea}/>
      </form>
    </div>
  )
};

const actionCreator = {
  defineUpdateFunc: updatePropertyActions.defineUpdateFunc
};

export default connect(null, actionCreator)(CommonAreaComponent);
