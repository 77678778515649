import React from "react";

const Gas = (props) => (
    <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g fill="#F0F3F6">
            <path d="M10.469 11.774c1.712-1.97-.037-4.623-1.244-6.033a.415.415 0 0 0-.415-.137.415.415 0 0 0-.315.282v.033a.415.415 0 0 0 0 .158c.108 1.264-.116 1.795-.319 2.02a.655.655 0 0 1-.514.202c-.543 0-.867-.667-.954-.87a.415.415 0 0 0-.742-.042c-1.273 2.227-.734 3.615-.062 4.387a3.018 3.018 0 0 0 4.56 0h.005ZM6.53 11.23c-.614-.701-.667-1.634-.17-2.782a1.687 1.687 0 0 0 1.302.68 1.472 1.472 0 0 0 1.124-.473c.332-.404.522-.907.539-1.43.829 1.243 1.53 2.84.514 4.005a2.19 2.19 0 0 1-3.317 0h.008Z" />
            <path d="M13.957 14.17h-2.989a5.282 5.282 0 0 0 1.14-.97C16.196 8.504 8.791 1.414 8.472 1.116a.415.415 0 0 0-.572 0C7.585 1.413.176 8.503 4.264 13.2c.332.375.716.702 1.14.97h-2.99a.414.414 0 1 0 0 .829h11.543a.415.415 0 0 0 0-.83ZM4.89 12.658C1.739 9.037 6.888 3.333 8.207 2.002c1.298 1.33 6.447 7.048 3.317 10.655a4.377 4.377 0 0 1-6.593 0H4.89Z" />
        </g>
    </svg>
);

export default Gas;
