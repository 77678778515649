import React, {useEffect, useMemo, useState} from 'react';

import './style.less'
import { Trans, useTranslation } from 'react-i18next';
import PropertyResumePopOver from "./PropertyResume";
import SearchFilter from "../../../../../../../../components/SearchFilter";
import RoomResumePopOver from "./RoomResume";
import moment from "moment";
import { grind } from "../../../../../../../../utils/stringFunctions";
import {dashboardServices, propertyServices} from "../../../../../../../../services";
import VerticalTimeline from '../../../../../../../../components/VerticalTimeline';
import CloseIcon from "../../../../../../../../components/svg/Close";
import SendArrowIcon from "../../../../../../../../components/svg/SendArrow";
import ArrowBackwardIcon from '../../../../../../../../components/svg/Navigation/ArrowBackward';
import Check from '../../../../../../../../components/svg/Check';
import {useAuth} from "../../../../../../../../services/authServices.js";

const isPropertyAvailable = (property) => {
    if (property.accommodation === 'apartment' && property.disabled) return !property.disabled
    if (property.accommodation === 'apartment') return !property.rooms?.['room_1']?.disabled
    return Object.values(property.rooms || {}).some(room => !room.disabled);
}

const OfferPopOver = (props) => {

    const { t, i18n } = useTranslation();

    const {user} = useAuth();

    const [propertySelected, setPropertySelected] = useState();
    const [accept, setAccept] = useState(true);
    const [canSendOffer, setCanSendOffer] = useState(false);
    const [currentRooms, setCurrentRooms] = useState();
    const [searchFilter, setSearchFilter] = useState('');
    const [currentProperties, setCurrentProperties] = useState(null);
    const [allProperties, setAllProperties] = useState(null); // [property, property, ...
    const [offers, setOffers] = useState([]);
    const [roomSelected, setRoomSelected] = useState();
    const [hideUnavailable, setHideUnavailable] = useState(false);
    const [loading, setLoading] = useState(true);
    const [tenant, setTenant] = useState({...props.tenant, availableProperties: []});

    const filtersLabel = "main_page.radar.offers_filters";
    const label = "main_page.radar.offers_details";
    const numBedrooms = props.tenant?.preferences?.numRooms;
    const offersSent = props.tenant?.offers?.length;
    const tenantWantsApartment = props.tenant?.preferences.accommodation === "apartment";

    useEffect(async () => {
        setLoading(true)
        let tenantAvailableProperties = (await dashboardServices.getPotentialTenantProperties(tenant.id)).map(p => p.id);
        setTenant({...tenant, availableProperties: tenantAvailableProperties})
        setHideUnavailable(tenantAvailableProperties.length > 0)
        if (!allProperties) {
            if (user.integration_landlord) {
                let _integrations = (await propertyServices.getLandlordIntegrations(0, {}, 2000));
                setAllProperties(_integrations.integrations);
            } else {
                let _properties = (await propertyServices.getCachedPropertiesList()).filter(property => property.finished && isPropertyAvailable(property))
                setAllProperties(_properties);
            }
        }
        setLoading(false)
    }, []);

    useEffect(() => {
        if (allProperties) {
            setSearchFilter('');
            let _currentProperties = allProperties.filter(property => {
                return tenant.availableProperties.includes(property.id) || !hideUnavailable;
            })
            setCurrentProperties(_currentProperties);
        }
    }, [hideUnavailable, allProperties]);

    useEffect(() => {
        let counter = 0;
        if (offers) {
            offers.forEach((offers) => {
                if (offers.rooms.length === numBedrooms) counter++;
            });
            setCanSendOffer(counter === offers.length && offers.length > 0);
        }
    }, [offers]);

    const handleDeselectProperty = (property) => {
        if (propertySelected === property) {
            setPropertySelected(null);
        }

        setOffers([...offers?.filter((o) => {
            return o.property !== property.id
        })]);
    };

    const handleSelectProperty = (property) => {
        setPropertySelected(property);

        if (tenantWantsApartment || property.integration_version) {
            setCanSendOffer(true, () => {
                setOffers([...offers, property.room]);
                const updatedOffers = offers?.filter((o) => {
                    return o.property !== property.id;
                });
                setOffers(updatedOffers);
            });
        }
    };

    const handleSelectRoom = (room) => {
        setRoomSelected(room);
    }

    const handleDeselectRoom = (room) => {
        if (roomSelected === room) {
            setRoomSelected(null);
        }

        setOffers([...offers?.filter((o) => {
            return o.room !== room.id
        })]);
    };

    const manageRooms = (room, isSelected) => {
        let tempArray = [...offers];
        if (propertySelected) {
            let offer = offers.find((o) => {
                return o.property === propertySelected.id;
            })
            if (!offer) {
                if (offers?.length < (3 - offersSent)) {
                    offer = { "property": propertySelected.id, "rooms": [] };
                }
            }
            if (offer) {
                if (isSelected) {
                    offer["rooms"] = offer.rooms.filter((r) => { return r !== room.id })
                    tempArray = offers.filter((o) => { return o.property !== propertySelected.id; })
                    tempArray.push(offer);
                    setCanSelectMoreRooms(false);
                } else if (offer["rooms"].length < numBedrooms) {
                    offer["rooms"].push(room.id);
                    tempArray = offers.filter((o) => { return o.property !== propertySelected.id; })
                    tempArray.push(offer);
                } else {
                    setCanSelectMoreRooms(false);
                }
                setOffers(tempArray);
            } else {
                setCanSelectMoreProperties(false);
            }
        }
    }

    const toCapitalize = (word) => {
        let temp = word.charAt(0).toUpperCase();
        let newWord = word.split(word.charAt(0));
        return temp + newWord[1];
    }

    const restStart = () => {
        setPropertySelected(false);
        setCurrentRooms(false);
        //This two state are used only to show the info to the client
        /* setCanSelectMoreProperties(true);
        setCanSelectMoreRooms(true); */
    }

    const sendOffer = async () => {
        if(!canSendOffer) return;
        if (propertySelected.integration_version) {
            let offers = [{
                user: tenant.id,
                property: propertySelected.id + "_" + propertySelected.room_id,
                integration: true
            }];
            props.sendOffer(offers, tenant);
        } else if (tenantWantsApartment) {
            let offers = [];
            offers.push({
                user: tenant.id,
                property: propertySelected.id,
                rooms: [propertySelected.rooms.room_1.id]
            })
            props.sendOffer(offers, tenant);
        } else {
            let tempOffers = [];
            offers?.forEach((o) => {
                tempOffers.push({
                    user: tenant.id,
                    property: o.property,
                    rooms: tenantWantsApartment ? [o.property.rooms[0]] : o.rooms
                });
            })
            props.sendOffer(tempOffers, tenant);
        }
    }

    const OfferPopOverFooter = ({num, offers, numBedrooms, accept, canSendOffer, selectedProperty, type}) => {

        const [prices, setPrices] = useState();

        let amount = useMemo(() => {
            if (num) return num;
            let offer = offers?.find((o) => {
                return o.property === selectedProperty.id
            })
            if (offer) return offer.rooms.length;
            return 0;
        }, [offers]);

        useEffect(() => {
            if (selectedProperty) {
                let rent = propertyServices.getRentsInterval(selectedProperty);
                let split = rent.split(" - ");
                setPrices({
                    min: split[0].split("€")[0],
                    max: split[1] ? split[1].split("€")[0] : split[0].split("€")[0]
                })
            }
        }, [selectedProperty]);

        return (
            <div className={"popOver--propertySelectedActions"}>
                {(propertySelected && !roomSelected && !propertySelected.integration_version) && <div className="current-property-selected">
                    <div className="check-icon">
                        <Check />
                    </div>

                    <div className="current-property-info">
                        <p>{selectedProperty?.internal_name}</p>
                        <div>
                            <span>{t(label + ".price")}: </span>
                            <p>€{prices?.min}</p>
                            <span>{t("to").toLowerCase()}</span>
                            <p>€{prices?.max}</p>
                        </div>
                    </div>
                </div>}

                {roomSelected && !roomSelected.integration_version &&  <div className="current-property-selected">
                    <div className="check-icon">
                        <Check />
                    </div>

                    <div className="current-property-info">
                        <p>{roomSelected?.internalName}</p>
                        <div>
                            <span>{t(label + ".price")}: </span>
                            <p>€{roomSelected?.rent}</p>
                        </div>
                    </div>
                </div>}

                {(propertySelected && propertySelected.integration_version) && <div className="current-property-selected">
                    <div className="check-icon">
                        <Check />
                    </div>

                    <div className="current-property-info">
                        <p>{selectedProperty.internalName}</p>
                        <div>
                            <span>{t(label + ".price")}: </span>
                            <p>€ {selectedProperty.minRent}</p>
                        </div>
                    </div>
                </div>}

                <div className={"actions"}>
                    <div className={"actions__action"}>
                        {tenantWantsApartment || (currentRooms && propertySelected) || user?.integration_landlord ? <button className="btn btn-primary"
                            disabled={!accept || !canSendOffer || (!tenantWantsApartment && !roomSelected && !user?.integration_landlord)}
                            onClick={sendOffer}
                        >
                            <SendArrowIcon />
                            {t("dashboard_list_item.make_offer")}
                        </button> : <button className="btn btn-primary"
                            disabled={!propertySelected}
                            onClick={() => setCurrentRooms(propertySelected.rooms)}
                        >
                            {t("dashboard_list_item.continue")}
                        </button>}
                    </div>
                </div>
            </div>
        )
    }

    const applyFilter = () => {
        if(!allProperties?.length) return null;
        return allProperties
            .filter(property => {
                if (searchFilter) {
                    let sanitizedSearch = grind(searchFilter).trim();
                    let propertyFieldsSearch = [grind(property.internal_name),
                        grind(property.address),
                        grind(property.floor),
                        grind(property.region),
                        ...Object.values(property?.rooms || {}).map(room => grind(room.internalName))
                    ].join(' ');
                    return sanitizedSearch.split(' ').every(searchWord => propertyFieldsSearch.includes(searchWord))
                } else return true;
            });
    }

    useEffect(() => {
        setCurrentProperties(applyFilter());
    }, [searchFilter]);

    const checkAvailability = (moveIn, moveOut, minStay, firstAvailability, unavailabilities) => {
        if (moment(firstAvailability).isAfter(moveIn)) return false;
        let flag = true;
        if (unavailabilities) {
            Object.values(unavailabilities).forEach((una) => {
                if (flag) {
                    if (moment(moveIn).isBetween((una.start), (una.end))) flag = false;
                    if (moment(moveOut).isBetween((una.start), (una.end))) flag = false;
                }
            });
        }
        return flag;
    }

    const roomOfferTimelineItems = [
        { content: <Trans i18nKey={label + ".choose_property"} components={{ b: <strong /> }} /> },
        { content: <Trans i18nKey={label + (numBedrooms > 1 ? ".choose_rooms" : ".choose_room")} values={{ num: numBedrooms }} components={{ strong: <strong /> }} /> },
        { content: <Trans i18nKey={label + ".edit_prices"} components={{ strong: <strong /> }} /> },
        { content: <Trans i18nKey={label + ".max_offers"} components={{ strong: <strong /> }} /> }
    ];

    const apartmentOfferTimelineItems = [
        { content: <Trans i18nKey={label + ".chooseApartment"} components={{ b: <strong /> }} /> },
        { content: <Trans i18nKey={label + ".edit_prices"} components={{ strong: <strong /> }} /> },
        { content: <Trans i18nKey={label + ".max_offers"} components={{ strong: <strong /> }} /> }
    ];

    return (props.start ?
            <div className={"PotentialTenantsList__formPopOver"}>
                <div className={"popOver--header"}>
                    <h3>{currentRooms && tenant.preferences.accommodation !== "apartment" ? <>
                        <ArrowBackwardIcon onClick={() => restStart()}/>
                        <Trans
                            i18nKey={label + (numBedrooms > 1 ? ".choose_rooms_name_plural" : ".choose_rooms_name")}
                            values={{num: numBedrooms}}
                        />
                    </> : t(label + ".choose_apartment")}
                    </h3>
                    <CloseIcon width={24}
                               height={24}
                               onClick={(e) => {
                                   props.handleClick(e, false);
                                   props.setStart(false)
                               }}
                    />
                </div>

                <div className="popover__tenant-offer">
                    {/* {tenant?.photo && <div className="tenant-offer__avatar">

                </div>} */}

                    <div className="tenant-offer__info">

                        <h4><Trans i18nKey={label + ".makingOffer"} values={{name: tenant.name}}/></h4>
                        <p>
                            {tenantWantsApartment ? <Trans
                                i18nKey={label + ".tenantBudgetInfoForApartment"}
                                values={{
                                    budget: `€${tenant.preferences.budgetMin} - €${tenant.preferences.budgetMax}`,
                                    numRoom: tenant.preferences.numRooms,
                                    type: tenant.preferences.numRooms > 1 ? t("room_plural") : t("room"),
                                    moveIn: new Date(tenant.preferences.moveIn).toLocaleDateString(i18n),
                                    moveOut: new Date(tenant.preferences.moveOut).toLocaleDateString(i18n)
                                }}
                            /> : <Trans
                                i18nKey={label + ".tenantBudgetInfo"}
                                values={{
                                    budget: `€${tenant.preferences.budgetMin} - €${tenant.preferences.budgetMax}`,
                                    numRoom: tenant.preferences.numRooms,
                                    type: tenant.preferences.accommodation === "rooms" ? t("room") : t("accommodation_type.apartment"),
                                    moveIn: new Date(tenant.preferences.moveIn).toLocaleDateString(i18n),
                                    moveOut: new Date(tenant.preferences.moveOut).toLocaleDateString(i18n)
                                }}
                            />}
                        </p>
                    </div>

                    <SearchFilter placeholder={"property_placeholder"}
                                  onChange={setSearchFilter}
                    />

                </div>
                {tenant?.availableProperties.length > 0 && !currentRooms && !user.integration_landlord && <div className="ToggleSwitch">
                    <p>Esconder Indisponíveis</p>
                    <input type="checkbox" id={"hide_unavailable"}
                           onChange={() => setHideUnavailable(!hideUnavailable)}
                           checked={hideUnavailable}
                    />
                    <label htmlFor={"hide_unavailable"}/>
                </div>}

                {(propertySelected && currentRooms && tenant.preferences.accommodation !== "apartment" && !user.integration_landlord) ? <div className={"popOver--propertyList"}>
                    {Object.values(currentRooms).sort((rA, rB) => {
                        return checkAvailability(tenant.preferences.moveIn, tenant.preferences.moveOut, null, rA.firstAvailability, rA.unavailability) - checkAvailability(tenant.preferences.moveIn, tenant.preferences.moveOut, null, rB.firstAvailability, rB.unavailability);
                    }).map((room) => {
                        if (room && ((room.roomLevel !== "3.1.1" && room.roomLevel !== "2.4") || room.disabled)) return null;
                        return (<RoomResumePopOver key={room.id} room={room} address={propertySelected.address}
                                                   property={propertySelected}
                                                   tenant={tenant}
                                                   handleClickRoom={(room, isSelected) => manageRooms(room, isSelected)}
                                                   offers={offers}
                                                   isSelected={roomSelected === room}
                                                   handleSelectRoom={() => handleSelectRoom(room)}
                                                   handleDeselectRoom={() => handleDeselectRoom(room)}
                        />)
                    })}
                </div> : <div className={"popOver--propertyList"}>
                    {(currentProperties && currentProperties.sort((pA, pB) => {
                        const indexOfA = tenant.availableProperties.indexOf(pA.id);
                        const indexOfB = tenant.availableProperties.indexOf(pB.id);

                        if (indexOfA === -1) {
                            return 1;
                        } else if (indexOfB === -1) {
                            return -1;
                        }

                        return indexOfA - indexOfB;
                    }).map((property) => {
                        return (<PropertyResumePopOver key={property.id}
                                                       property={property}
                                                       tenant={tenant}
                                                       offers={offers}
                                                       isSelected={propertySelected === property}
                                                       handleSelectProperty={() => handleSelectProperty(property)}
                                                       handleDeselectProperty={() => handleDeselectProperty(property)}
                        />)
                    }))}
                </div>}
                {(propertySelected && offers) &&
                    <OfferPopOverFooter offers={offers} numBedrooms={numBedrooms} accept={accept}
                                        canSendOffer={canSendOffer} selectedProperty={propertySelected} type={"rooms"}/>}
                {!propertySelected &&
                    <OfferPopOverFooter num={offers?.length} numBedrooms={(3 - offersSent)}
                                        accept={accept} canSendOffer={canSendOffer} type={"properties"}/>}
            </div>
            :
            <div className={"PotentialTenantsList__popOver"}>
                {tenant.photo && <div className={"popOver--header"}>
                    <div>
                        <div style={{"backgroundImage": `url(${tenant.photo}`}}/>
                    </div>
                </div>}


                <div className={"popOver--content"}>
                    {tenantWantsApartment ? <h4>{t(label + ".popover_title_apartment", {
                        tenantName: toCapitalize(tenant.name)
                    })}</h4> : <h4>{t(label + (numBedrooms > 1 ? ".popover_title_plural" : ".popover_title"), {
                        tenantName: toCapitalize(tenant.name),
                        num: numBedrooms
                    })}</h4>}

                <VerticalTimeline items={tenantWantsApartment ? apartmentOfferTimelineItems : roomOfferTimelineItems} />
            </div>
                <div className={"popOver--action"}>
                <button className={'btn btn-secondary'} onClick={(e) => {
                        props.handleClick(e, false)
                    }}>{t(label + ".close")}</button>
                <button className={'btn btn-primary'} style={{whiteSpace: "nowrap"}} disabled={loading} onClick={() =>
                        props.setStart(true)
                    }>{t(label + (loading? ".loading_houses" : ".move_forward"))}</button>
                </div>
            </div>
    )
}

export default OfferPopOver;
