import React, {useEffect, useState} from 'react';

import './style.less'

import {useTranslation} from "react-i18next";
import {useAuth} from "../../services/authServices";
import moment from "moment";

import arrowDownIcon from '../ResumeItem/images/arrowDownIcon.svg';
import {Dialog, DialogContent} from "@material-ui/core";

const ApiKeys = () => {

    const {t} = useTranslation();

    const auth = useAuth();

    const [apiKeys, setApiKeys] = useState([]);
    const [generateDialogOpen, setGenerateDialogOpen] = useState(false);
    const [newApiKeyName, setNewApiKeyName] = useState("");

    useEffect(() => {
        (async () => {
            const data = await auth.getApiKeys();
            setApiKeys(data.keys);
        })();
    }, []);

    const handleDeleteApiKey = async (id) => {
        await auth.deleteApiKey(id);
        setApiKeys(prev => prev.filter(apiKey => apiKey.id !== id));
    }

    const handleUpdateApiKey = async (id, readOnly) => {
        await auth.updateApiKey(id, readOnly);
        setApiKeys(prev => [...prev.filter(apiKey => apiKey.id !== id), {...prev.find(apiKey => apiKey.id === id), readOnly}])
    }

    const handleOpenKeyDetails = (id) => {
        let apiKey = apiKeys.find(apiKey => apiKey.id === id);
        apiKey.open = !apiKey.open;
        setApiKeys([...apiKeys]);
    }

    const handleCloseDialog = () => {
        setNewApiKeyName("");
        setGenerateDialogOpen(false)
    }

    const handleGenerateApiKey = async () => {
        if(!newApiKeyName) return;
        const data = await auth.generateApiKey(newApiKeyName, false);
        setApiKeys(prev => [...prev, {...data.key, open: true}]);
        handleCloseDialog();
    }

    return <div className={"AccountDetails__ApiKeys"}>
        <h4>{t('my_account.api_keys.title')}</h4>
        <div className={"ApiKeys_container"}>
            {apiKeys.map(apiKey => <div className={"ApiKey_block"} key={apiKey.id}>
                <div className={"ApiKey_header"} onClick={()=>handleOpenKeyDetails(apiKey.id)}>
                    <h5>{apiKey.name}</h5>
                    <p>{apiKey.readOnly ? t('my_account.api_keys.read_only') : t('my_account.api_keys.read_write')}</p>
                    <div>
                        <div className={"ApiKey_header_last_used"}>
                            <small>{t('my_account.api_keys.last_used')}</small>
                            <strong>{apiKey.lastUsed ? moment(apiKey.lastUsed).format("DD/MM/yyyy HH:mm") : t('my_account.api_keys.never_used')}</strong>
                        </div>
                        <img className={`${apiKey.open ? 'flip_img' : ''}`} src={arrowDownIcon}/>
                    </div>
                </div>
                {apiKey.open && <div className={"ApiKey_content"}>
                    <div className={"ApiKey_content_main"}>
                        <div>
                            <small>{t('my_account.api_keys.key')}</small>
                            <strong>{apiKey.id}</strong>
                        </div>
                        {apiKey.secret && <div>
                            <small>{t('my_account.api_keys.secret')}</small>
                            <input className={"ApiKey_secret"} value={apiKey.secret} readOnly={true} onClick={(e) => {
                                e.target.focus();
                                e.target.select();
                            }}/>
                            <span className={"ApiKey_secret_desc"}>{t('my_account.api_keys.secret_description')}</span>
                        </div>}
                        <div>
                            <small>{t('my_account.api_keys.created')}</small>
                            <strong>{moment(apiKey.creationDate).format("DD/MM/yyyy HH:mm")}</strong>
                        </div>
                    </div>
                    <div className={"ApiKey_content_side"}>
                        <button onClick={async ()=>{
                            await handleUpdateApiKey(apiKey.id, !apiKey.readOnly)
                        }} type={'button'} className={'btn btn_grey'}>{t('my_account.api_keys.toggle_read_only')}</button>
                        <button onClick={async () => {
                            await handleDeleteApiKey(apiKey.id);
                        }} type={'button'} className={'btn btn-delete'}>{t('my_account.api_keys.delete_key')}</button>
                    </div>
                </div>}
            </div>)}
        </div>
        <div className={"ApiKeys_actions"}>
            <button onClick={()=>setGenerateDialogOpen(true)} type={'button'} className={'btn btn_blue'}>{t('my_account.api_keys.generate_new')}</button>

            <Dialog className={"ApiKeys_generate_dialog"} open={generateDialogOpen} onClose={handleCloseDialog}>
                <DialogContent className={"ApiKeys_generate_dialog_content"}>
                    <h4>{t('my_account.api_keys.generate_new')}</h4>
                    <div className={"input_container"}>
                        <label for={"api_key_name"} className={"api_key_name_label"}>{t("my_account.api_keys.new_api_key_name")}</label>
                        <input className={"input_field"} id={"api_key_name"} type={"text"} onChange={(e) => setNewApiKeyName(e.target.value)}
                           value={newApiKeyName}/>

                    </div>
                    <div className={"buttons_container"}>
                        <button className={"btn btn_blue"} disabled={!newApiKeyName} onClick={handleGenerateApiKey}>{t("buttons.confirm")}</button>
                        <button className={"btn btn_grey"} onClick={handleCloseDialog}>{t("buttons.cancel")}</button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    </div>
}

export default ApiKeys;
