import {get, post} from "./index.js";
import moment from "moment";

async function getReservations(page, filters) {
    return await get(`/reservation/getLandlordReservations`, {page, filters})
}

async function getReservationById(reservation_id) {
    return await get(`/reservation/getLandlordReservation/${reservation_id}`)
}

async function acceptReservation(reservation_id) {
    return await post("/reservation/acceptReservation/", {reservation_id})
}

const rejectReservation = async (fields) => {
    return await post ("/reservation/rejectReservation", fields)
}

const suggestNewDates = async (fields) => {
    return await post ("/reservation/suggestNewDates", fields)
}

const suggestNewProperty = async (fields) => {
    return await post ("/reservation/suggestNewProperty", fields)
}

const cancelReservation = async (fields) => {
    return await post ("/reservation/cancellationRequestLandlord", fields)
}

const getMonthlyRent = (reservation) => {
    let {fixedRent, rentValue, rentMonths, discount, extraTenantsValue} = (reservation.payment || {});
    if(fixedRent && !rentValue || (!fixedRent && (!rentMonths || Object.keys(rentMonths).length !== 12))) return "N/A";
    if (fixedRent) {
        let rent = (discount ? (rentValue - (rentValue * discount)) : rentValue) + (extraTenantsValue || 0);
        if(rent % 1 !== 0) rent = rent.toFixed(2);
        return rent + "€";
    }
    const _toMonth = (m) => moment(m).locale('en').format("MMMM").toLowerCase();
    let startMoveIn = moment(reservation.moveIn);
    let moveOutMoment = moment(reservation.moveOut);
    let min, max;
    while (startMoveIn.isBefore(moveOutMoment)) {
        let curMonthValue = parseInt(rentMonths[_toMonth(startMoveIn)]);
        if (!min || curMonthValue < min) min = curMonthValue;
        if (!max || curMonthValue > max) max = curMonthValue;
        startMoveIn.add(1, "month");
    }
    min += extraTenantsValue;
    max += extraTenantsValue;
    if(discount) {
        min -= min * discount;
        max -= max * discount;
    }
    if (min === max) return "€" + min;
    return (min + " - " + max + "€");
}
const getCancellationFee = (reservation) => {
    let preWarningDays = moment(reservation.moveIn).startOf("day").diff(moment(reservation.cancellation.requestDate || new Date()).startOf("day"), "days");
    let fee = 0;
    if(preWarningDays <= 30) fee = 100 * 1.23;
    if(preWarningDays === 0) fee = 250 * 1.23; //no dia do check-in
    return fee.toFixed(2);
}

const generateFirstRentalPayment = (property, moveIn, moveOut, numTenants) => {
    if (!property) return {};
    let {incompleteRentType: rentType} = property;
    let extraTenantsValue = (property.room.extraTenantsPrice && numTenants > 1) ? (property.room.extraTenantsPrice * (numTenants - 1)) : 0;

    let moveInDate = new Date(moveIn);
    let moveOutDate = new Date(moveOut);
    let moveInMoment = moment(moveIn);
    let moveOutMoment = moment(moveOut);

    let mo = moveInMoment.clone().startOf('month');
    let currentMonthExt = mo.locale('en').format("MMMM").toLowerCase();
    let monthRent = parseInt(property.room.fixedRent ? property.room.rent : property.room.rentMonths[currentMonthExt]);

    let nextMo = mo.clone().add(1, 'M');

    let nextMonthRent = parseInt(property.room.fixedRent ? property.room.rent : property.room.rentMonths[nextMo.locale('en').format("MMMM").toLowerCase()]);

    let calculatedRent;
    let secondMonthPayment = 0;

    if (rentType === 'daily') {
        let diffDays = moveOutMoment.diff(moveInMoment, "days");
        if (moveOutMoment.isSame(moveInMoment, 'month')) {
            if(moveInMoment.date() === 1 && moveOutMoment.date() === moveOutMoment.daysInMonth()) {
                calculatedRent = monthRent;
            } else {
                calculatedRent = Math.ceil(monthRent / moveInMoment.daysInMonth() * diffDays)
            }
        } else {
            let diffEndOfMonth = moment(moveInMoment).endOf('month').diff(moveInMoment, 'days');
            let diffStartOfMonth = moveInMoment.diff(moment(moveInMoment).startOf('month'), 'days');
            if((diffDays+1) > moveInMoment.daysInMonth()) {
                calculatedRent = monthRent;
                let subNextMonth = monthRent / moveInMoment.daysInMonth() * diffStartOfMonth;
                let adjustedNextMonthRent = nextMonthRent;
                if(moveOutMoment.isSame(moveInMoment.clone().add(1, "month"), "month")) { //move out is next month
                    adjustedNextMonthRent = (nextMonthRent / moveOutMoment.daysInMonth()) * moveOutMoment.date();
                }
                secondMonthPayment = Math.max(Math.round((adjustedNextMonthRent - subNextMonth)*100)/100, 0);
            } else {
                let curMonthDailyRent = monthRent / moveInMoment.daysInMonth();
                let nextMonthDays = moveOutMoment.date();
                let nextMonthDailyRent = nextMonthRent / moveOutMoment.daysInMonth();
                calculatedRent = Math.ceil(((curMonthDailyRent * diffEndOfMonth) + (nextMonthDailyRent * nextMonthDays))*100)/100;
            }
        }
    } else if(rentType === 'biweekly') {
        let moveInHalf = moveInDate.getDate() >= 15;
        let moveOutHalf = moveOutDate.getDate() <= 15;
        if(moveInDate.getMonth() === moveOutDate.getMonth() && moveInDate.getFullYear() === moveOutDate.getFullYear()) {
            if (moveInHalf || moveOutHalf) {
                calculatedRent = Math.ceil(monthRent / 2);
            } else {
                calculatedRent = monthRent;
            }
        } else {
            if (moveInHalf) {
                calculatedRent = Math.ceil(monthRent / 2 + nextMonthRent / 2);
                if (!(nextMo.month() === moveOutDate.getMonth() && moveOutHalf)) {
                    secondMonthPayment = Math.ceil(nextMonthRent / 2);
                }
            } else {
                calculatedRent = monthRent;
                if (nextMo.month() === moveOutDate.getMonth() && moveOutHalf) {
                    secondMonthPayment = Math.ceil(nextMonthRent / 2);
                } else {
                    secondMonthPayment = nextMonthRent;
                }
            }
        }
    } else {
        calculatedRent = monthRent;
        secondMonthPayment = nextMonthRent;
    }

    if(calculatedRent > monthRent) {
        secondMonthPayment = Math.round(secondMonthPayment + (calculatedRent - monthRent));
        calculatedRent = monthRent;
    }
    let discount;
    if(property.room.discount > 0 && (moveOutMoment.diff(moveInMoment, "month") >= 5)){
        discount = (property.room.discount / 100);
        calculatedRent -= calculatedRent * discount;
        secondMonthPayment -= secondMonthPayment * discount;
    }
    return {
        firstRent: calculatedRent,
        secondMonthPayment,
        extraTenantsValue,
        discount
    };
}
const getPaidAtMoveIn = (reservation) => {
    let dateRents = reservation.dateRents;
    let hasLastRent = reservation.property.paidAtMovinDate?.includes("Last Rent") && dateRents.length > 1;
    let lastRent = reservation.lastRent || 0;
    let securityDepositAmount = parseInt(reservation.securityDeposit.slice(1)) || 0;
    let paidAtMoveIn = hasLastRent ? (parseInt(lastRent.slice(1)) + securityDepositAmount) : securityDepositAmount;
    if(reservation.payment.extraAdministrationFee > 0) paidAtMoveIn += reservation.payment.extraAdministrationFee;
    return "€" + paidAtMoveIn.toFixed(2);
}


export const reservationServices = {
    getReservations,
    getReservationById,
    acceptReservation,
    rejectReservation,
    cancelReservation,
    suggestNewDates,
    suggestNewProperty,
    generateFirstRentalPayment,
    getMonthlyRent,
    getPaidAtMoveIn,
    getCancellationFee
};
