import React from "react";

const Internet = (props) => (
    <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.979 3.509a7.891 7.891 0 0 0-6.084 2.884.504.504 0 1 1-.781-.639A8.9 8.9 0 0 1 7.979 2.5c2.766 0 5.258 1.293 6.903 3.249a.505.505 0 0 1-.773.65c-1.466-1.745-3.683-2.89-6.13-2.89Zm0 2.523c-1.766 0-3.303.842-4.308 2.156a.505.505 0 0 1-.801-.613C4.05 6.03 5.877 5.023 7.979 5.023s3.928 1.007 5.11 2.552a.504.504 0 1 1-.802.613c-1.005-1.314-2.542-2.156-4.308-2.156Zm.042 2.522a2.832 2.832 0 0 0-2.465 1.39.505.505 0 0 1-.872-.509 3.842 3.842 0 0 1 3.337-1.89c1.405 0 2.657.773 3.332 1.882a.505.505 0 0 1-.862.524 2.923 2.923 0 0 0-2.47-1.397Zm-.042 2.523a.336.336 0 1 0 0 .672.336.336 0 0 0 0-.672Zm-1.345.336a1.345 1.345 0 1 1 2.69 0 1.345 1.345 0 0 1-2.69 0Z"
            fill="#F0F3F6"
        />
    </svg>
);

export default Internet;
