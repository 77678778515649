import {Trans, useTranslation} from 'react-i18next';
import React, {useEffect, useState} from 'react';
import {BsPlusCircle} from 'react-icons/bs';
import {useHistory} from 'react-router-dom';

import './style.less'
import bedIcon from '../../images/apartmentIcon.svg';
import apartmentIcon from '../../images/apartmentIcon.svg';
import {get, propertyServices} from '../../../../../../../../services';
import {ReactSelect} from '../../../../../../../../components/ReactSelect';
import {useAuth} from "../../../../../../../../services/authServices";

const pageLabel = 'main_page.my_ads.create_ad.property_type';

const CreateNewAdButton = ({onClick, disabled,label}) => {
  const {t} = useTranslation()

  return (
    <button className="btn btn_blue" disabled={disabled} onClick={() => {onClick()}}>
      <BsPlusCircle/>
      <p>{label?label:t('buttons.create_new_ad')}</p>
    </button>)
}

const PropertyType = ({updateProperty, setCurrentPageLevel}) => {

  const {t, i18n} = useTranslation();
  let history = useHistory();
  const {user, setUser} = useAuth();

  const [houseToCloneOptions, setHouseToCloneOptions] = useState([]);
  const [choosedHouse, setChoosedHouse] = useState('')

  const choosePropertyType = async (type) => {
    if (type === 'residence') {
      await updateProperty({accommodation: 'residence'})
      setCurrentPageLevel('1.1.1');
      setUser(prev => ({...prev, inProgressListings: prev.inProgressListings + 1}));
    } else {
      await updateProperty()
      setCurrentPageLevel('1.1.0');
    }
  }

  const createRoomHandler = async (propertyId) => {
    const property = await propertyServices.addRoomToExistingProperty(propertyId);
    setUser(prev => ({...prev, inProgressListings: prev.inProgressListings + 1}));
    history.push(`/my_ads/ad/edit/${propertyId}/${property.renting}`)
  }


  useEffect(() => {
    (async () => {
      //get the user houses
      let user_houses = (await propertyServices.getPropertiesList(0, null, 10000)).properties;
      setHouseToCloneOptions(user_houses.filter(property => property.finished && !(property.accommodation==='apartment' && !property.has_rooms)).map(option => ({
        label: option.internal_name,
        value: option.id
      })));
      //set the options
    })();
  }, [])

  return (
    <div className={'PropertyType'}>
      <div className={'basic_container PropertyType__container'}>
        <div className={'propertyType__icon'}>
          <img src={apartmentIcon}/>
        </div>
        <div className={'PropertyType__content'}>
          <h3>{t(pageLabel + '.new_property.title')}</h3>
          <p>{t(pageLabel + '.new_property.sub_title')}</p>
          <p>{t(pageLabel + '.new_property.description')}</p>
        </div>
        <CreateNewAdButton onClick={() => choosePropertyType('apartment')}/>
      </div>


      <div className={'basic_container PropertyType__create_room PropertyType__container'}>
        <div className={'propertyType__icon'}>
          <img src={apartmentIcon}/>
        </div>
        <div className={'PropertyType__content'}>
          <h3>{t(pageLabel + '.add_room.title')}</h3>
          <div className={"Property__Select"}>
            <ReactSelect
              placeholder={<p>T2 Alfama</p>}
              value={choosedHouse}
              options={houseToCloneOptions}
              onChange={(value) => setChoosedHouse(value)}/>
          </div>
        </div>
        <CreateNewAdButton label={t('buttons.add')} disabled={!choosedHouse} onClick={() => createRoomHandler(choosedHouse.value)}/>
      </div>


      <div className={'basic_container PropertyType__container'}>
        <div className={'propertyType__icon'}>
          <img src={bedIcon}/>
        </div>
        <div className={'PropertyType__content'}>
          <h3>{t(pageLabel + '.new_residence.title')}</h3>
          <p>{t(pageLabel + '.new_residence.sub_title')}</p>
          <p><Trans
              i18nKey={pageLabel + '.new_residence.description'}
              components={{strong: <b/>}}
          /></p>
        </div>
        <CreateNewAdButton onClick={() => choosePropertyType('residence')}/>
      </div>
    </div>
  )
}

export default PropertyType;
