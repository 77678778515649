import React from 'react';
import {withStyles} from '@material-ui/core';
import {Menu, MenuItem, Fade, Popper, Grow, Paper, ClickAwayListener, MenuList} from '@material-ui/core';
import './style.less'

export const StyledMenuItem = withStyles((theme) => ({
  root: {
    'fontSize': '0.875rem',
    '&:hover': {
      backgroundColor: 'rgba(29, 154, 250, 0.1)',
      color: '#18A0FB'
    },
    padding: props => props.zeropadding ? '0' : undefined
  }
}))(MenuItem);

const MUIMenu = ({anchorEl,open, menuRef, handleClose, handleClick, children, isIconCircle, roomResume}) => {

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      handleClose();
    }
  }

  return (
    <div className={'MUIMenu' + (roomResume ? " relative" : "" )}>
      <button onClick={handleClick} ref={anchorEl} className={`menu-img btn btn_white ${isIconCircle ? 'icon_circle' : ''}`}/>
      {isIconCircle && <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        ref={menuRef}
        open={!!anchorEl}
        onClose={handleClose}
        TransitionComponent={Fade}
        getContentAnchorEl={null}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        transformOrigin={{vertical: 'top', horizontal: 'left'}}
        PaperProps={{
          style: {
            maxHeight: '200px'
          }
        }}
      >
        {children}
      </Menu>}
      {!isIconCircle && <Popper
        open={open} anchorEl={anchorEl.current} role={undefined} transition disablePortal placement={'right-start'}>
        {({TransitionProps, placement}) => (
          <Grow
            {...TransitionProps}
            style={{transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom'}}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {children}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>}
    </div>)
}

export default MUIMenu;
