import React, {useEffect, useState, useMemo} from 'react';
import moment from 'moment'
import {Trans, useTranslation} from "react-i18next";
import Dialog from "@material-ui/core/Dialog";

import "./style.less"



const ChooseDatesComponent = ({chosenVisitDates, handleChooseDate, timezone = "Europe/Lisbon", summary}) => {
    const {t} = useTranslation();

    const currentLabel = "main_page.my_visits.visit_card.chooseDates";

    const datesNames = ["preferredDate", "alternativeDate", "secondAlternativeDate"];

    const [dialogOpen, setDialogOpen] = useState(-1);

    const [dateSlots, setDateSlots] = useState([])

    const timeInStringToInt = (time) => {
        const timeSplited = time?.split(':');
        return parseFloat(timeSplited[0]) + (parseInt(timeSplited[1]) / 60);
    }

    const convertToTimeValue = (time) => {
        let hours = Math.floor(time);
        let minutes = parseFloat(((time % 1) * 60))?.toFixed(0) + '';
        if (minutes.length === 1) {
            minutes = '0' + minutes;
        }
        return `${hours}:${minutes}`
    }

    const chooseDateSlot = (weekDay, index , dayAsMoment) => {
        setDateSlots(prevState => {
            prevState = prevState.map(slot => {
                if (slot.chosen === dialogOpen) {
                    delete slot.chosen;
                    return slot;
                }
                return slot;
            })
            prevState[index] = {...prevState[index], chosen: dialogOpen, dayAsMoment};
            handleChooseDate(dialogOpen,prevState[index]);
            return prevState;
        });
    }


    useEffect(() => {
        const finalSlots = [];
        const visitWeekDays = {
            monday: {"start": ["9:00"], "end": ["20:00"]},
            tuesday: {"start": ["9:00"], "end": ["20:00"]},
            wednesday: {"start": ["9:00"], "end": ["20:00"]},
            thursday: {"start": ["9:00"], "end": ["20:00"]},
            friday: {"start": ["9:00"], "end": ["20:00"]},
            saturday: {"start": ["9:00"], "end": ["20:00"]},
            sunday: {"start": ["9:00"], "end": ["20:00"]}
        }
        for (let weekDayName in visitWeekDays) {
            for (let hoursSlot = 0; hoursSlot < visitWeekDays[weekDayName].start.length; hoursSlot++) {
                let initialTime = timeInStringToInt(visitWeekDays[weekDayName].start[hoursSlot]);
                let finalTime = timeInStringToInt(visitWeekDays[weekDayName].end[hoursSlot]);
                while (initialTime < finalTime) {
                    finalSlots.push({
                        weekDay: weekDayName,
                        start: convertToTimeValue(initialTime),
                        end: convertToTimeValue(initialTime + 2 < finalTime ? initialTime + 2 : finalTime)
                    })
                    initialTime += 2;
                }
            }
        }
        setDateSlots(finalSlots);
    }, []);

    const handleDialogOpen = (index) => {
        setDialogOpen(index);
    }

    const handleDialogClose = () => {
        setDialogOpen(-1);
    }

    return (
        <div className={"ChooseDatesComponent"+(summary?' type-summary':'')}>
            <div className={"ChooseDatesComponent__dates"}>
                {datesNames.map((date, index) => {
                    let disabled = index > 0 && !chosenVisitDates[index-1];
                    return (<div key={date} className={`ChooseDatesComponent__dates-date ${disabled ? 'disabled' : ''}`}
                                 onClick={() => !disabled && handleDialogOpen(index)}>
                        {!summary && <i className={"icon-calendar_today"}/>}
                        <div>
                            <p className={'small'}>{t(`${currentLabel}.${date}`)}</p>
                            {chosenVisitDates[index].date && <label>{moment(chosenVisitDates[index].date)?.locale(localStorage.getItem('i18nextLng')?.split('-')?.[0] || "en")?.format('DD MMM YYYY')?.toUpperCase()}</label>}
                            <p className={'small'}>{chosenVisitDates[index].date ? t(currentLabel + ".chosenTimeTo", {a: chosenVisitDates[index].start, b: chosenVisitDates[index].end}) : t(currentLabel + ".chooseDateAndHour")}</p>
                        </div>
                        {summary && <i className={"icon-edit"}/>}
                    </div>)
                })}
            </div>
            {!summary && <p><Trans
                i18nKey={`${currentLabel}.footerText`}
                values={{
                    timezone: timezone
                }}
                components={{
                    compareLink: <a className={"link link_blue bold"} target={"_blank"} href={"https://www.google.com/search?q=current+time+in+"+(timezone?.split("/")?.[1]?.toLowerCase() || "lisbon")}/>,
                    span: <span/>,
                }}/></p>}
            <ChooseDatesTimesDialog open={dialogOpen} handleClose={handleDialogClose}
                                    dateSlots={dateSlots} chooseDateSlot={chooseDateSlot}/>
        </div>
    )
}

const ChooseDatesTimesDialog = ({open, handleClose, dateSlots, chooseDateSlot}) => {
    const {t} = useTranslation();

    const currentLabel = "main_page.my_visits.visit_card.chooseDates.dialog";

    const [currentDay, setCurrentDay] = useState(null);
    const [currentTimeSlot, setCurrentTimeSlot] = useState(null);

    const currentDayName = currentDay?.clone().locale('en').format('dddd').toLowerCase();

    const weekDays = useMemo(() => {
        let weekdaysFinal = [];
        let nextCurrentDay = null;
        for (let i = 1; i <= 7; i++) {
            const day = moment().add(i, 'day');
            const disabled = !dateSlots.some(slot => (slot.weekDay === day.clone().locale('en').format('dddd').toLowerCase() && !slot.chosen));
            weekdaysFinal.push({moment: day, disabled});
            if (!disabled && !nextCurrentDay) {
                nextCurrentDay = day;
            }
        }
        setCurrentDay(nextCurrentDay);
        return weekdaysFinal;
    }, [dateSlots]);

    useEffect(() => {
        const activeIndex = dateSlots.findIndex(slot => slot.chosen === open);
        if(activeIndex !== -1) setCurrentTimeSlot(activeIndex);
    }, [dateSlots,open]);

    const changeActiveDay = (day) => {
        setCurrentDay(day);
        setCurrentTimeSlot(null);
    }

    const handleChooseTimeSlot = () => {
        chooseDateSlot(currentDayName, currentTimeSlot,currentDay);
        handleClose();
    }


    return (<Dialog
        id="choose-dates-times-dialog"
        open={open > -1}
        keepMounted
        className={'flexInlineCenter'}
        onClose={handleClose}>
        <div className={'ChooseDatesTimesDialog'}>
            <div className={'ChooseDatesTimesDialog__chooseDay'}>
                {weekDays.map((weekDay, index) => (
                    <div key={index}
                         className={`ChooseDatesTimesDialog__chooseDay-day ${weekDay.disabled ? 'disabled' : ''}`}
                         onClick={() => changeActiveDay(weekDay.moment)}>
                        <p>{weekDay.moment.clone().format('dddd').slice(0, 1)}</p>
                        <div className={`flexInlineCenter ${weekDay.moment.isSame(currentDay, 'day') ? 'active' : ''}`}>
                            <p>{weekDay.moment.clone().format('DD')}</p>
                        </div>
                    </div>
                ))}
            </div>
            <div className={'ChooseDatesTimesDialog__chooseTime'}>
                {currentDay && dateSlots.map((timeSlot, index) => {
                    if (timeSlot.weekDay === currentDayName) {
                        let disabled = timeSlot.chosen !== undefined && timeSlot.chosen !== open;
                        let active = index === currentTimeSlot;
                        return (<div key={timeSlot.start}
                                     className={`ChooseDatesTimesDialog__chooseTime-slot flexInlineCenter ${disabled ? 'disabled' : (active ? 'active' : '')}`}
                                     onClick={() => !disabled && setCurrentTimeSlot(index)}>
                            <p>{`${timeSlot.start} ${t('to')} ${timeSlot.end}`}</p>
                        </div>)
                    }
                })}
            </div>
            <p className={"caption"}>{t(currentLabel + '.scheduledVisit')}</p>
            <div className={"ChooseDatesTimesDialog__buttons"}>
                <button className="btn btn-secondary"
                        onClick={handleClose}>
                    {t(currentLabel + '.cancel')}
                </button>
                <button className="btn btn-special"
                        disabled={!currentTimeSlot?.toString()} onClick={handleChooseTimeSlot}>
                    {t(currentLabel + '.confirm')}
                </button>
            </div>
        </div>
    </Dialog>)
}

export default ChooseDatesComponent;
