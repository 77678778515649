import React from "react";

const Google = (props) => (
    <svg
        width={21}
        height={20}
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#a)">
            <path
                d="M20.287 10.225a11.6 11.6 0 0 0-.158-1.892h-9.417v3.759h5.392c-.242 1.233-.95 2.275-2 2.983v2.5h3.217c1.883-1.742 2.966-4.308 2.966-7.35Z"
                fill="#4285F4"
            />
            <path
                d="M10.712 20c2.7 0 4.959-.9 6.609-2.425l-3.217-2.5c-.9.6-2.042.967-3.392.967-2.608 0-4.816-1.759-5.608-4.134H1.787v2.575C3.43 17.75 6.804 20 10.712 20Z"
                fill="#34A853"
            />
            <path
                d="M5.104 11.908A5.803 5.803 0 0 1 4.787 10c0-.667.117-1.308.317-1.908V5.517H1.787a9.884 9.884 0 0 0 0 8.966l3.317-2.575Z"
                fill="#FBBC05"
            />
            <path
                d="M10.712 3.958c1.475 0 2.792.509 3.834 1.5l2.85-2.85C15.67.992 13.412 0 10.712 0 6.804 0 3.43 2.25 1.787 5.517l3.317 2.575c.792-2.375 3-4.134 5.608-4.134Z"
                fill="#EA4335"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M.5 0h20v20H.5z" />
            </clipPath>
        </defs>
    </svg>
);

export default Google;
