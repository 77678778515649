import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import React, {useMemo, useState} from "react";
import moment from "moment/moment";
import {visitServices} from "../../../services";
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "../../MUIDialogComponents";
import Loading from "../../Loading";
import {DialogContent} from "@material-ui/core";
import Select from "react-select";
import DatePicker from "react-datepicker";
import calendarIcon from "../../CustomDatePicker/images/calendarIcon.svg";
import {OutdatedAdInputs} from "../../../pages/MainPage/containers/Reservations/containers/ReservationDetails";
import ChooseDatesComponent from "../../../pages/MainPage/containers/MyVisits/components/ChooseDatesComponent";
import "./style.less";
import {useAuth} from "../../../services/authServices";

const months = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];

const RejectVisitModal = ({open, setOpen, visit, updateVisits, setActionDialog}) => {

    const {t} = useTranslation();
    const {user, setUser} = useAuth();
    const history = useHistory()
    const translationDirc = "main_page.my_visits.visit_card.cancellation.";

    let property = visit.property;
    let visitRoom = visit.property?.room;

    const [loading, setLoading] = useState(false);
    const [motive, setMotive] = useState(null);
    //ocuppied_dates && cant_visit
    const [occupiedDatesOption, setOccupiedDatesOption] = useState("")
    const [availableFrom, setAvailableFrom] = useState(null);
    //other_dates
    const [otherDatesOption, setOtherDatesOption] = useState("");
    //outdated_ad
    const [newRents, setNewRents] = useState(false);
    const [newPeriodMin, setNewPeriodMin] = useState(false);
    const _defaultMonths = months.reduce((acc, m) => ({...acc, [m]: 0}), {});
    const [rentMonths, setRentMonths] = useState(visitRoom.rentMonths || _defaultMonths);
    const [rent, setRent] = useState(null);
    const [periodMin, setPeriodMin] = useState(null);
    const [fixedRent, setFixedRent] = useState(visitRoom.fixedRent)
    //not_possible
    const [dates, setDates] = useState([{date: null, start: null, end: null}, {
        date: null,
        start: null,
        end: null
    }, {date: null, start: null, end: null}])
    //no_visits
    const [noVisitsOption, setNoVisitsOption] = useState("");


    const motives = ["occupied_dates", "cant_visit", "other_dates", "outdated_ad", "not_possible", "no_visits", "remove_ad"].map((motive) => ({
        value: motive,
        label: t(translationDirc + "motive." + motive)
    }))

    const handleChooseDate = (index, value) => {
        setDates(prevState => {
            let auxArray = [...prevState]
            auxArray[index] = {date: value.dayAsMoment.format("YYYY-MM-DD"), start: value.start, end: value.end}
            return auxArray
        })
    }

    const handleClose = () => {
        setOpen(false);
        resetFields();
    }

    const resetFields = () => {
        setMotive(null);
        setAvailableFrom(null);
        setOccupiedDatesOption("");
        setOtherDatesOption("")
        setNoVisitsOption("")
        setNewRents(false)
        setNewPeriodMin(false)
        setRentMonths(visitRoom.rentMonths || _defaultMonths);
        setFixedRent(visitRoom.fixedRent);
        setRent(visitRoom.rent)
        setPeriodMin(null)
        setDates([{date: null, start: null, end: null}, {date: null, start: null, end: null}, {
            date: null,
            start: null,
            end: null
        }])
    }

    const isSubmitDisabled = useMemo(() => {
        if (!motive) return true;
        switch (motive.value) {
            case "occupied_dates":
                return !availableFrom && occupiedDatesOption === "option1" || !["option1", "option2"]?.includes(occupiedDatesOption);
            case "cant_visit":
                return !availableFrom;
            case "other_dates":
                return !["short_stay", "long_stay", "late_entry"]?.includes(otherDatesOption);
            case "outdated_ad":
                return !newPeriodMin && fixedRent === visitRoom.fixedRent && !newRents || (newPeriodMin && !periodMin);
            case "not_possible":
                return !Object.values(dates).some(date => date.date && date.start && date.end);
            // return Object.values(dates).some(date => !date.date || !date.start || !date.end)
            case "no_visits":
                return !["this_property", "all_properties"]?.includes(noVisitsOption);
            case "remove_ad":
                return false;
            default:
                return true;
        }
    }, [motive, occupiedDatesOption, availableFrom, otherDatesOption, newPeriodMin, fixedRent, newRents, dates, noVisitsOption, periodMin])

    const handleSubmit = async () => {
        if (motive.value === "not_possible" && (dates?.filter(date => date?.date && date?.start && date?.end)?.length < 1)) {
            return;
        }
        setLoading(true);
        let fields = {
            visit_id: visit.id,
            motive: motive.value
        }
        switch (motive.value) {
            case "occupied_dates":
                availableFrom ? (fields.availableFrom = moment(availableFrom).toISOString()) :
                    (fields.unavailable = true)
                break;
            case "cant_visit":
                fields.availableFrom = moment(availableFrom).toISOString();
                break;
            case "other_dates":
                fields.reason = otherDatesOption;
                break;
            case "outdated_ad":
                if (newPeriodMin) fields.periodStayMin = periodMin.toString();
                if (newRents) fixedRent ? fields.rent = rent.toString() : fields.rentMonths = rentMonths;
                if (fixedRent !== visitRoom.fixedRent) fields.fixedRent = fixedRent;
                break;
            case "not_possible":
                fields.suggestedDates = dates.filter(d => d.start && d.end && d.date);
                break;
            case "no_visits":
                fields.refuseVisits = noVisitsOption;
                break;
            case "remove_ad":
                break;
            default:
                break;
        }
        await visitServices.rejectVisit(fields);
        updateVisits && await updateVisits();
        setLoading(false);
        handleClose();
        let listing_id = visit.property?.id;
        if(visit.property?.accommodation !== "apartment") {
            listing_id += "_" + (visit.property?.room?.id || visit.room_id);
        }
        if(motive.value !== "not_possible") {
            setActionDialog("visit_rejected");
            if (visit.acceptedDate) {
                setUser(prev => ({...prev, cancelledVisits: prev.cancelledVisits + 1}))
            } else {
                setUser(prev => ({...prev, visitRequests: prev.visitRequests - 1, rejectedVisits: prev.rejectedVisits + 1}))
            }

        } else {
            setActionDialog("visit_dates");
            setUser(prev => ({...prev, visitRequests: prev.visitRequests - 1, pendingVisits: prev.pendingVisits + 1}))
            history.push("/my_visits/pending")
        }
    }


    return (
        <Dialog open={open} onClose={handleClose} className={`RejectVisitModal`} maxWidth={"xs"}>
            <DialogTitle className="DialogHeader" onClose={handleClose}/>
            {loading ? <Loading/> :
                <DialogContent className={"RejectVisit__content"}>
                    <div className={"RejectVisit__content-label"}>{t(translationDirc + "title")}</div>
                    <div
                        className={"RejectVisitModal__content-info"}>{t(translationDirc + "info_text", {name: visit.user.name.split(" ")[0]})}</div>
                    <div className={"RejectVisit__content-select-motive"}>
                        <label>1. {t(translationDirc + "motive.select_label")}</label>
                        <Select className={"select-motive"}
                                maxMenuHeight={150}
                                placeholder={t(translationDirc + "motive.please_choose")}
                                options={motives}
                                value={motive}
                                onChange={(value => {
                                    if (motive && motive.value === value.value) return;
                                    resetFields();
                                    setMotive(value);
                                })}
                        />
                    </div>
                    <div className={"line"}/>
                    {motive?.value === "occupied_dates" &&
                        <div className={"RejectVisitModal__content-inputs"}>
                            <div className={"double-input"}>
                                <div>
                                    <input type={"radio"} id={"option1"} name={"option"}
                                           onChange={() => {
                                               setOccupiedDatesOption("option1")
                                           }}
                                    />
                                    <label htmlFor={"option1"}>{t(translationDirc + "availableFrom")}</label>
                                </div>
                                <div>
                                    <div className={"CustomDatePicker"} style={{width: "fit-content"}}>
                                        <DatePicker className={"input_field basic_container"}
                                                    id={"availableFrom"} name={"availableFrom"}
                                                    onChange={(value) => setAvailableFrom(value)}
                                                    disabled={occupiedDatesOption !== "option1"}
                                                    selected={availableFrom}
                                                    showPreviousMonths={false}
                                                    locale={localStorage.getItem('i18nextLng').split('-')[0]}
                                                    dateFormat={"dd/MM/yyyy"}
                                                    formatWeekDay={nameOfDay => nameOfDay.substr(0, 1).toUpperCase()}
                                                    popperPlacement='auto'
                                                    minDate={new Date(moment().add(1, "day"))}
                                                    popperModifiers={{
                                                        preventOverflow: {
                                                            enabled: true // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                                        },
                                                        hide: {
                                                            enabled: false // turn off since needs preventOverflow to be enabled
                                                        }
                                                    }}/>
                                        <img src={calendarIcon} alt={"calendar icon"}/>
                                    </div>
                                </div>
                            </div>
                            <div className={"double-input"}>
                                <div>
                                    <input type={"radio"} id={"option2"} name={"option"}
                                           onChange={() => {
                                               setOccupiedDatesOption("option2")
                                           }}
                                    />
                                    <label htmlFor={"option2"}>{t(translationDirc + "unavailable_period")}</label>
                                </div>
                                <div>
                                    <span>{t(translationDirc + "unavailable_info")}</span>
                                </div>
                            </div>
                        </div>}
                    {motive?.value === "cant_visit" &&
                        <div className={"RejectVisitModal__content-inputs"}>
                            <div className={"simple-input"}>
                                <label htmlFor={"availableFrom"}>{t(translationDirc + "availableFrom")}</label>
                                <div className={"CustomDatePicker"} style={{width: "fit-content"}}>
                                    <DatePicker className={"input_field basic_container"}
                                                id={"availableFrom"} name={"availableFrom"}
                                                onChange={(value) => setAvailableFrom(value)}
                                                selected={availableFrom}
                                                showPreviousMonths={false}
                                                locale={localStorage.getItem('i18nextLng').split('-')[0]}
                                                dateFormat={"dd/MM/yyyy"}
                                                formatWeekDay={nameOfDay => nameOfDay.substr(0, 1).toUpperCase()}
                                                popperPlacement='auto'
                                                minDate={new Date(moment().add(1, "day"))}
                                                popperModifiers={{
                                                    preventOverflow: {
                                                        enabled: true // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                                    },
                                                    hide: {
                                                        enabled: false // turn off since needs preventOverflow to be enabled
                                                    }
                                                }}/>
                                    <img src={calendarIcon} alt={"calendar-icon"}/>
                                </div>
                            </div>
                        </div>
                    }

                    {motive?.value === "other_dates" &&
                        <div className={"RejectVisitModal__content-inputs"}
                             onChange={(e) => setOtherDatesOption(e.target.id)}>
                            <div className={"radio-input"}>
                                <input type={"radio"} id={"short_stay"} name={"otherDates-option"}/>
                                <label htmlFor={"short_stay"}>{t(translationDirc + "short_stay")}</label>
                            </div>
                            <div className={"radio-input"}>
                                <input type={"radio"} id={"long_stay"} name={"otherDates-option"}/>
                                <label htmlFor={"long_stay"}>{t(translationDirc + "long_stay")}</label>
                            </div>
                            <div className={"radio-input"}>
                                <input type={"radio"} id={"late_entry"} name={"otherDates-option"}/>
                                <label htmlFor={"late_entry"}>{t(translationDirc + "late_entry")}</label>
                            </div>
                        </div>
                    }

                    {motive?.value === "outdated_ad" &&
                        <OutdatedAdInputs roomId={visitRoom.id} room={visitRoom} fixedRent={fixedRent}
                                          setFixedRent={setFixedRent} newPeriodMin={newPeriodMin} newRents={newRents}
                                          rentMonths={rentMonths} setNewPeriodMin={setNewPeriodMin}
                                          setNewRents={setNewRents} setPeriodMin={setPeriodMin} periodMin={periodMin} rent={rent} setRent={setRent}
                                          setRentMonths={setRentMonths}/>
                    }

                    {motive?.value === "not_possible" &&
                        <div className={"RejectVisitModal__content-inputs"}>
                            <span>{t(translationDirc + "new_dates", {name: visit.user.name.split(" ")[0]})}</span>
                            <ChooseDatesComponent chosenVisitDates={dates} handleChooseDate={handleChooseDate} timezone={visit.timezone} property={visit.property}/>
                        </div>
                    }

                    {motive?.value === "no_visits" &&
                        <div className={"RejectVisitModal__content-inputs"}
                             onChange={(e) => setNoVisitsOption(e.target.id)}>
                            <div className={"radio-input"}>
                                <input type={"radio"} id={"this_property"} name={"noVisits-option"}/>
                                <label htmlFor={"this_property"}>{t(translationDirc + "this_property")}</label>
                            </div>
                            <div className={"radio-input"}>
                                <input type={"radio"} id={"all_properties"} name={"noVisits-option"}/>
                                <label htmlFor={"all_properties"}>{t(translationDirc + "all_properties")}</label>
                            </div>
                            <span style={{marginTop: "20px"}}>{t(translationDirc + "noVisits_warning")}</span>
                        </div>
                    }

                    {motive?.value === "remove_ad" &&
                        <div className={"RejectVisitModal__content-inputs"}>
                            <span>{t(translationDirc + "remove_warning")}</span>
                        </div>
                    }

                    <div className={"RejectVisit__content-actions"}>
                        <button className={"btn btn_grey"}
                                onClick={handleClose}>{t(translationDirc + "actions.go_back")}</button>
                        <button className={"btn btn_blue"} disabled={isSubmitDisabled}
                                onClick={handleSubmit}>{t("buttons.confirm")}
                        </button>
                    </div>
                </DialogContent>}
        </Dialog>
    )
}

export default RejectVisitModal;
