import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";


import { useTranslation } from 'react-i18next';

import "./style.less";
import logo from "../../images/logo.svg";
import {useAuth} from "../../services/authServices";

const Footer = ({displayFooter}) => {
  const {t} = useTranslation();
  const {user, setUser} = useAuth();

  const cities = ["lisboa", "porto", "coimbra", "braga", "evora", "aveiro", "madrid", "barcelona", "milan", "rome", "sevilla", "valencia"]


  const [showFooter,setShowFooter] = useState(displayFooter?.display);

  useEffect(() => {
    setShowFooter(displayFooter?.display);
  },[displayFooter])

  return (
      <div className={`Footer ${(!showFooter ) ? 'hidden' : ''} ${user ? 'footer_mobile' : ''}`}>
      <div className="container">
        <div className="Footer__container">
          <div className="Footer__container-main">
            <div>
                <img alt={"Inlife Landlord"} className={"hide_mobile"} src={logo} />
                <p>© Inlife, Inc. {new Date().getFullYear()}. We love our users!</p>
            </div>
            <div className="Footer__container-main-cities">
              {cities.map((city,index) => {
                return <h5 key={index}>{t("cities." + city)}</h5>
              })}
            </div>
          </div>

          <div className="Footer__container-links">
            <a href="https://inlifehousing.com/privacy-policy/">{t("terms.privacy_policy")}</a>
            <a href="https://inlifehousing.com/cookies-policy/">{t("terms.cookie_policy")}</a>
            <a href="https://inlifehousing.com/terms-conditions/">{t("terms.terms_and_conditions")}</a>
            <a href="#" className="termly-display-preferences">{t("footer.consent_preferences")}</a>
          </div>
        </div>
      </div>
    </div>
  );
};


export default Footer;
