import React, {useEffect, useRef, useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {RadioGroup, Radio, FormControlLabel} from '@material-ui/core';

import './style.less'

import {StyledRadio} from '../RadioButtons/index'
import {useTranslation} from 'react-i18next';


const RadioButtonsWithIcons = ({
                                 radioButtons,
                                 value,
                                 name,
                                 setValue,
                                 watchValue,
                                 type = 'double',
                                 error,
                                 control,
                                 rules
                               }) => {

  const {t} = useTranslation();

  const formControlRef = useRef({});

  return (
    <div className={`RadioButtons ${type}`} id={name}>
      <Controller
        as={
          <RadioGroup aria-label={name+"rg"}>
            {radioButtons.map((button, index) => (
              <div key={button.id} className={`clickable basic_container ${!type.startsWith('fullImage')?"withIcons":''} ${type} ${watchValue === button.buttonValue.toString()? 'active' : null}`}
                   onClick={() => formControlRef.current[name+index].click()}>
                <FormControlLabel
                  key={`${name}[${index}]`}
                  ref={r => formControlRef.current[name+index] = r}
                  value={button.buttonValue.toString()}
                  control={<StyledRadio />}
                  label={t(button.label)}
                  className={`${type}`}
                  onChange={()=>{setValue(button.buttonValue.toString())}}
                />
                {button.icon &&
                 <div className={`icon center ${watchValue === button.buttonValue.toString()? 'active' : null}`} id={'icon'}>
                   <img src={button.icon}/>
                 </div>}
              </div>
            ))}
          </RadioGroup>}
        defaultValue={value?value.toString():''}
        name={name}
        control={control}
        rules={rules}
      />
      {error && <p className={'error_message'}>{error.message}</p>}
    </div>)
}

export default RadioButtonsWithIcons;
