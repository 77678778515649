import React, {useEffect, useMemo, useState} from 'react';
import ReactFlagsSelect from 'react-flags-select';
import {Controller} from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';
import {useTranslation} from 'react-i18next';
import './style.less';
import {dataServices} from "../../services/dataServices";

const CountryAndPhoneSelect = ({
                                   country,
                                   setCountry,
                                   setDialCode,
                                   control,
                                   phoneNumber,
                                   setPhoneNumber,
                                   errors,
                                   errorsReq,
                                   clearCountryError,
                                   shouldAutoFocus,
                                   defaultPhone,
                                   setValue
                               }) => {

    const {t} = useTranslation();

    const [countries, setCountries] = useState();
    const [dialCodes, setDialCodes] = useState({});

    useEffect(() => {
        (async () => {
            let _countries = await dataServices.getCountries();
            setCountries(_countries)
        })();
    }, [])


    const countryOptions = useMemo(() => {
        if(!countries) return;
        let countryOptions = {};
        countries.map(country => {
            countryOptions[country.iso2.toUpperCase()] = t('country.' + country.iso2);
        });
        let dialCodes = {};
        countries.map(country => {
            dialCodes[country.iso2.toUpperCase()] = "+" + country.dial_code;
        });
        setDialCodes(dialCodes);
        return countryOptions;
    }, [countries, localStorage.getItem("i18nextLng")])

    useEffect(() => {
        console.log("diff phone", phoneNumber)
    }, [phoneNumber])

    return (
        <div className={'CountryAndPhoneSelect'}>
            <div className="input-container">
                <label htmlFor={'country'}>{`${t('start.signup.country')}`}</label>
                <Controller
                    as={ReactFlagsSelect}
                    name="country"
                    defaultCountry="PT"
                    control={control}
                    searchable={true}
                    selected={country}
                    customLabels={countryOptions}
                    className={`${errors?.country ? 'input_error' : null} input_field`}
                    onSelect={code => {
                        setCountry(code);
                        setDialCode(dialCodes[code]);
                        clearCountryError();
                        setValue && setValue("phone", undefined);
                    }}
                    placeholder={t('start.signup.country_search')}
                    searchPlaceholder={t('start.signup.country_search')}
                    rules={{
                        validate: (value) => (value || !!country) || t('start.signup.required.country')
                    }}/>
                {errors?.country && <p className={'error_message'}>{errors?.country?.message}</p>}
                {errorsReq?.includes('err-invalid_country') &&
                <p className={'error_message'}>{t('error.invalid_country')}</p>
                }
            </div>
            <div className="input-container">
                <label htmlFor={'phone'}>{`${t('start.signup.phone')}`}</label>
                <Controller
                    as={PhoneInput}
                    defaultCountry={country}
                    countryCallingCodeEditable={false}
                    name="phone"
                    autoFocus={shouldAutoFocus}
                    placeholder="+351 912345678"
                    control={control}
                    international
                    defaultValue={defaultPhone}
                    value={phoneNumber}
                    className={`${errors?.phone ? 'input_error' : null} input_field`}
                    onChange={setPhoneNumber}
                    rules={{
                        required: {value: true, message: `${t('start.signup.required.phone')}`},
                        maxLength: {
                            value: 15,
                            message: `${t('start.signup.pattern.phone')}`
                        },
            minLength: {
              value: 8,
              message: `${t('start.signup.pattern.phone')}`
            }
          }}
          error={phoneNumber ? (phoneNumber.length >=8 && phoneNumber.length <= 15 ? undefined : t("error.err-phone")) : 'Phone number required'}/>
        {errors?.phone && <p className={'error_message'}>{errors?.phone?.message}</p>}
      </div>
    </div>
  )
}

export default CountryAndPhoneSelect;
