import {useLocation} from "react-router-dom";

const useQuery = () => {
    let searchParams = {};
    new URLSearchParams(useLocation().search).forEach((value, key) => {
        searchParams[key] = value;
    });
    return searchParams;
};

export default useQuery;
