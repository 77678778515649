import React from "react";

const Place = (props) => (
    <svg
        width={17}
        height={16}
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="place_24px">
            <path
                id="icon/maps/place_24px"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.19987 1.33301C5.61987 1.33301 3.5332 3.41967 3.5332 5.99967C3.5332 9.49967 8.19987 14.6663 8.19987 14.6663C8.19987 14.6663 12.8665 9.49967 12.8665 5.99967C12.8665 3.41967 10.7799 1.33301 8.19987 1.33301ZM4.86654 5.99967C4.86654 4.15967 6.35987 2.66634 8.19987 2.66634C10.0399 2.66634 11.5332 4.15967 11.5332 5.99967C11.5332 7.91967 9.6132 10.793 8.19987 12.5863C6.8132 10.8063 4.86654 7.89967 4.86654 5.99967ZM6.5332 5.99967C6.5332 5.0792 7.2794 4.33301 8.19987 4.33301C8.79531 4.33301 9.34552 4.65067 9.64325 5.16634C9.94097 5.68201 9.94097 6.31734 9.64325 6.83301C9.34552 7.34868 8.79531 7.66634 8.19987 7.66634C7.2794 7.66634 6.5332 6.92015 6.5332 5.99967Z"
                fill="#434A5F"
            />
        </g>
    </svg>
);

export default Place;
