import * as ReactFlags from "react-flags-select";
import {components} from "react-select";
import flagNone from "../../images/flagNone.svg"
import React from "react";

const FlagOption = (props) => {
    const tempVariable = props.data.value.split("")[0].toUpperCase() + props.data.value.split("")[1];
    let CurrentFlag = ReactFlags[tempVariable === "En" ? "Gb" : tempVariable];

    return (
        <components.Option {...props}>
            <div className={"flag-option-wrapper"}>
                {CurrentFlag ? <CurrentFlag/> : <img src={flagNone} alt={"flag-icon"}/>}
                {props.children}
            </div>
        </components.Option>
    )
}

const FlagControl = (props) => {
    let selectValue = props.getValue();
    const tempVariable = selectValue && selectValue[0];
    let splitIso = (tempVariable?.dial?.iso || tempVariable?.value || "").split("");
    let reactFlagKey = splitIso[0]?.toUpperCase() + splitIso?.[1];
    let CurrentFlag = tempVariable && splitIso.length > 0 && ReactFlags[reactFlagKey === "En" ? "Gb" : reactFlagKey];

    return (
        <components.Control {...props}>
            <div className={"flag-control-wrapper"}>
                {CurrentFlag ? <CurrentFlag/> : <img src={flagNone} alt={"no-flag-icon"}/>}
                {props.children}
            </div>
        </components.Control>
    )
}

export {FlagOption, FlagControl};
