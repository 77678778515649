import React from "react";

const ArrowUpward = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="arrow_upward" clipPath="url(#clip0_3314_4438)">
            <path
                id="Vector"
                d="M4 12L5.41 13.41L11 7.83V20H13V7.83L18.58 13.42L20 12L12 4L4 12Z"
            />
        </g>
        <defs>
            <clipPath id="clip0_3314_4438">
                <rect width={24} height={24} fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default ArrowUpward;
