import React, {useEffect, useState, useMemo} from 'react';
import moment from 'moment'
import momenttz from 'moment-timezone'
import {Trans, useTranslation} from "react-i18next";
import {clampText, redactPhoneAndEmail} from "../../utils/stringFunctions";
import {useHistory} from 'react-router-dom';
import "./style.less"
import videocam from "./images/videocam.svg";
import share from "./images/share.svg";
import copy from "./images/clipboard.svg";
import whatsapp from "./images/whatsapp.svg";
import messenger from "./images/messenger.svg";
import email from "./images/email.svg";
import info from "./images/info_blue.svg";
import close from "../../pages/MainPage/containers/MakeOffers/components/PotentialTenantsList/components/OfferPopOver/images/close.svg";
import arrowDown from "./images/arrowDown.svg";
import naturePeople from "./images/nature_people.svg";
import place from "./images/place.svg";
import money from "./images/money.svg";
import meetingRoom from "./images/meeting_room.svg";
import avatarMale from "./images/avatar_male.png";
import avatarFemale from "./images/avatar_female.png";
import expire from "./images/expire.svg";
import notify from "./images/notification_important.svg";
import Dialog from "@material-ui/core/Dialog";
import {getExpirationDate} from "../../utils/dateFunctions";
import SuggestPropertyModal
    from "../../pages/MainPage/containers/Reservations/containers/ReservationDetails/modals/SuggestNewPropertyModal";
import calendarIconGray from "../ListItem/images/calendarIconGray.svg";
import useQuery from "../../utils/query";
import ActionDialog from "../ActionDialog";
import {Tooltip} from "@material-ui/core";

import {isMobile} from "../../utils/responsive";
import RejectVisitModal from "./RejectVisitModal";
import ScheduleDialog from "./ScheduleDialog";
import {useAuth} from "../../services/authServices";


const VisitCard = ({visit, filter, updateVisits}) => {

    const {t, i18n} = useTranslation();
    const {user, setUser} = useAuth();
    const history = useHistory();
    const query = useQuery();
    const [openRejectModal, setOpenRejectModal] = useState(false);
    const [openSuggestProperty, setOpenSuggestProperty] = useState(false);
    const [showDates, setShowDates] = useState(false);
    const [showActionDialog, setShowActionDialog] = useState(null)
    const [selectedDate, setSelectedDate] = useState(null);

    let property_image = visit.room_id ? visit.property?.room?.photos["featured"]?.md : visit.property?.photos?.["featured_0"]?.[0]?.md;
    let roomNumber = visit.roomNumber;

    let roomName = visit.property.room?.internalName;

    let visit_timezone = visit.timezone || "Europe/Lisbon";
    let local_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    let monthlyRent = useMemo(() => {
        if (visit.property.room.fixedRent) return  visit.property.room.rent + "€";
        const _toMonth = (m) => moment(m).locale('en').format("MMMM").toLowerCase();
        let startMoveIn = moment(visit.moveIn);
        let moveOutMoment = moment(visit.moveOut);
        let min, max;
        while (startMoveIn.isBefore(moveOutMoment)) {
            let curMonthValue = parseInt(visit.property.room.rentMonths[_toMonth(startMoveIn)]);
            if (!min || curMonthValue < min) min = curMonthValue;
            if (!max || curMonthValue > max) max = curMonthValue;
            startMoveIn.add(1, "month");
        }
        if (min === max) return min + "€";
        return  min + " - " + max + "€";
    }, [visit]);


    let tenantImage = visit.user.photo || ( (visit.user?.personalDetails ? visit.user.personalDetails.gender === "female" : visit.user.gender === "female") ? avatarFemale : avatarMale);
    let tenantAge = (new Date().getFullYear() - ((visit.user?.personalDetails ? visit.user?.personalDetails.birthdate?.split("-")?.[1] : visit.user.birthdate?.split("-")[1]) || 2004));
    let expiration = getExpirationDate((visit.suggestedDates || visit.needsStudentAcceptance) && visit.acceptedDate ? visit.acceptedDate : visit.requestDate)


    const [visitExpiration, setVisitExpiration] = useState(expiration);

    useEffect(()=>{
        if(query.schedule === visit.id) {
            setOpenScheduleDialog(true);
            history.replace({search: ''});
        } else if (query.cancel === visit.id) {
            setOpenRejectModal(true);
            history.replace({search: ''});
        }
        let expInterval = setInterval(() => {
            setVisitExpiration(expiration);
        }, 1000);
        return ()=>clearInterval(expInterval);
    });

    const [openScheduleDialog, setOpenScheduleDialog] = useState(false);
    const [shareDialogOpen, setShareDialogOpen] = useState(false);
    const [copied, setCopied] = useState(null);

    const shareTrans = "main_page.my_visits.visit_card.share_dialog.";
    const closeScheduleDialog = () => {
        setOpenScheduleDialog(false);
    };

    const closeShareDialog = () => {
        setShareDialogOpen(false);
    }


    const canJoin = useMemo(() => {
        return (momenttz.tz(visit.tenantSelectedDate || visit.selectedDate, visit_timezone).subtract(15, "minutes")).isBefore(momenttz.tz(Date.now(), visit_timezone));
    }, [visit.tenantSelectedDate, visit.selectedDate]);

    const handleSelectDate = async () => {
        setLoading(true);
        try {
            await visitServices.acceptVisit(visit.id, moment(selectedDate.date + " " + selectedTime));
            await updateVisits();
            closeScheduleDialog();
            setShowActionDialog("visit_accepted");
            setUser(prev => ({...prev, visitRequests: prev.visitRequests - 1, scheduledVisits: prev.scheduledVisits + 1}));
            history.push("/my_visits/scheduled");
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };

    let getVisitCallUrl = () => {
        let baseUrl;
        if(window.location.host.includes("staging")) {
            baseUrl = "https://rent.inlife.staging.appx.pt";
        } else {
            baseUrl = process.env.STUDENT_BASE_URL;
        }
        return `${baseUrl}/video/${visit.id}`;
    }

    const handleJoinVisit = () => {
        if(!canJoin) return;
        let url = getVisitCallUrl();
        window.open(`${url}?ltoken=${visit.landlord_token}`, "_blank");
    }

    const handleCopyDescription = async () => {
        let description = "";
        description += t(shareTrans + "visit_title", {title: roomName}) + "\r\n\r\n";
        description += t(shareTrans + "visit_number") + " " + visit.id.split("-")?.[0] + "\n";
        description += t(shareTrans + "tenant") + " " + visit.user.name.split(" ")[0] + ", " + t('country.'+(visit.user?.personalDetails ? visit.user?.personalDetails.nationality : visit.user.nationality)) + ", " + t(visit.user?.personalDetails?.gender || visit.user?.gender) + ", " + tenantAge + " " + t("years") + "\n";
        description += t(shareTrans + ((visit.user.personalDetails?.occupation || visit.user.occupation) === "student" ? "student" : "occupation")) + " "  + ((visit.user.personalDetails?.occupation || visit.user.occupation) === "student" ? (visit.user.personalDetails?.university || visit.user.university) : (visit.user.personalDetails.occupation_detail || visit.user.occupation_detail)) + "\n";
        description += t(shareTrans + "address") + " " + visit.property.address + (visit.property.region ? ", " + visit.property.region : "") + "\n";
        description += t(shareTrans + "room") + roomNumber + "\n";
        description += t(shareTrans + "date_time") + " " + momenttz.tz(visit.tenantSelectedDate || visit.selectedDate, visit_timezone).format("DD MMM YYYY[,] HH:mm") + visit_timezone;
        await navigator.clipboard.writeText(description);
        setCopied("description");
        setTimeout(() => {
            setCopied(null);
        }, 2000);
    }
    const handleCopyUrl = (isLandlord) => async () => {
        await navigator.clipboard.writeText(getVisitCallUrl() + (isLandlord ? "?ltoken=" + visit.landlord_token : ""));
        setCopied(isLandlord ? "landlord" : "visitor");
        setTimeout(() => {
            setCopied(null);
        }, 2000);
    }

    const shareHandler = (shareType, landlordShare) => () => {
        let url = encodeURIComponent(getVisitCallUrl() + (landlordShare ? "?ltoken=" + visit.landlord_token : ""));
        let text = encodeURIComponent(t(shareTrans + "enter_visit") + url);
        let link;
        switch (shareType) {
            case 'messenger':
                if (isMobile) {
                    link="fb-messenger://share?app_id=230079097767039&link=" + url + "&redirect_uri=" + url;
                    window.open(link, "_blank");
                } else {
                    link="https://www.facebook.com/dialog/send?app_id=230079097767039&link=" + url + "&redirect_uri=" + url;
                    window.open(link, "myWindow", "status=1, height=510, width=558, resizable=0");
                }
                break;
            case 'whatsapp':
                link = "https://wa.me/?text=" + text + encodeURIComponent("\n\n") + url;
                window.open(link, "_blank");
                break;
            case 'email':
                link= "mailto:?body=" + text + "%0D%0A" + url
                window.open(link, "_blank", "status=1, height=610, width=658, resizable=0");
                break;
            default:
                break;
        }
    }



    return (<div className={"VisitCard"}>
        <div className={"VisitCard__image"} style={{backgroundImage: `url(${property_image})`}}>
            {visit.landlord.id !== user?.id && <div className={"VisitCard__image__overlay"} style={{backgroundImage: `url(${visit.landlord.photo || avatarMale})`}}/>}
            <div className={"VisitCard__image-type_tag " + visit.type}>
                <img src={visit.type === "onsite" ? naturePeople : videocam} alt={(visit.type === "onsite" ? "in Person" : "videocam")}/>
                <span>{t('main_page.my_visits.visit_card.type.' + visit.type)}</span>
            </div>
        </div>
        <div className={"VisitCard__body"}>
            <div className={"VisitCard__property-info"}>
                <p>{clampText((visit.property.internal_name ? visit.property.internal_name : visit.property.title[localStorage.getItem('i18nextLng').split('-')[0]]), 85)}</p>
                <div className={"property-details"}>
                    <span><img src={place} alt={"place"}/>{visit.property.address}</span>
                    <span><img src={meetingRoom} alt={"meeting room"}/>ID {visit.property.id.split("-")[0]} {visit.room_id && ('| ' + (roomName ? roomName : t('main_page.my_visits.visit_card.room_n') + roomNumber))}</span>
                    <span><img src={money} alt={"money"}/>{t('main_page.my_visits.visit_card.monthly_rent')} <strong>{monthlyRent}</strong></span>
                </div>
            </div>
            <div className={"VisitCard__tenant-info"}>
                <div className={"avatar-container"} style={{backgroundImage: `url(${tenantImage})`}}>
                    <div className={'RoundedCircleFlag'}>
                        <div className={'CountryFlagCircle'}
                             style={{backgroundImage: `url(https://github.com/madebybowtie/FlagKit/raw/master/Assets/PNG/${(visit.user?.personalDetails ? visit.user?.personalDetails?.nationality?.toUpperCase() : visit.user?.nationality?.toUpperCase())}@2x.png?raw=true)`}}/>
                    </div>
                </div>
                <div className={"tenant-details-visits"}>
                    <p>{visit.user.name.split(" ")[0]}</p>
                    <span>{t('country.'+(visit.user?.personalDetails ? visit.user?.personalDetails.nationality : visit.user.nationality))} - {t((visit.user?.personalDetails ? visit.user?.personalDetails.gender : visit.user.gender))}, {tenantAge} {t('years')}</span>
                    <span>{t('main_page.my_visits.visit_card.occupation.'+(visit.user?.personalDetails ? visit.user?.personalDetails.occupation : visit.user?.occupation))} {t('at')} {(visit.user.occupation === "student" || (visit.user.personalDetails?.occupation === "student"))  ? (visit.user.personalDetails ? visit.user.personalDetails.university?.split(" - ")[0] : visit.user.university?.split(" - ")[0]) : (visit.user.personalDetails ? visit.user.personalDetails.occupation_detail : visit.user.occupation_detail)}</span>
                    <span><strong>{t('main_page.my_visits.visit_card.move_in')}:</strong> {moment(visit.moveIn).format("DD/MM/YYYY")}</span>
                    <span><strong>{t('main_page.my_visits.visit_card.move_out')}:</strong> {moment(visit.moveOut).format("DD/MM/YYYY")}</span>
                    <span><strong>{t('main_page.my_visits.visit_card.totalStay')}:</strong> {t('main_page.my_visits.visit_card.month', { count: Math.round(moment(visit.moveOut).diff(moment(visit.moveIn), "month", true)) })}</span>
                </div>
            </div>
         {/*   {visit.user.personalDetails?.extraInfo && /*visit.tenantInfoVerified &&
                <div className={"tenant_more_info"}>
                    <span className={"title"}><strong>{t('main_page.reservations.reservation_details.about')}</strong></span>
                    <div>
                        <span className={"text"}>{removePhoneNumbersAndEmails(visit.user.personalDetails?.extraInfo)}</span>
                    </div>
                </div>}*/}
            <div className={"VisitCard__tenant-info VisitCard__tenant-info__mobile"}>
                <div className={"avatar-container"} style={{backgroundImage: `url(${tenantImage})`}}>
                    {(visit.user?.personalDetails?.nationality || visit.user.nationality) && <div className={'RoundedCircleFlag'}>
                        <div className={'CountryFlagCircle'}
                             style={{backgroundImage: `url(https://github.com/madebybowtie/FlagKit/raw/master/Assets/PNG/${(visit.user?.personalDetails ? visit.user?.personalDetails.nationality.toUpperCase() : visit.user.nationality.toUpperCase())}@2x.png?raw=true)`}}/>
                    </div>}
                </div>
                <div className={"tenant-details"}>
                    <p>{visit.user.name.split(" ")[0]}</p>
                    <span>{t('country.'+(visit.user?.personalDetails?.nationality || visit.user.nationality))} - {t(visit.user?.personalDetails?.gender || visit.user.gender)}, {tenantAge} {t('years')}</span>
                    <span>{t('main_page.my_visits.visit_card.occupation.'+(visit.user?.personalDetails?.occupation || visit.user.occupation))} {t('at')} {(visit.user.personalDetails?.occupation || visit.user.occupation) === "student" ? (visit.user.personalDetails?.university || visit.user.university)?.split(" - ")[0] : (visit.user?.personalDetails?.occupation_detail || visit?.user?.occupation_detail)}</span>
                </div>
               {/* {visit.user.personalDetails?.extraInfo && /*visit.tenantInfoVerified &&
                    <div className={"tenant_more_info tenant_more_info__mobile"}>
                        <span className={"title"}><strong>{t('main_page.reservations.reservation_details.about')}</strong></span>
                        <div>
                            <span className={"text"}>{removePhoneNumbersAndEmails(visit.user.personalDetails?.extraInfo)}</span>
                        </div>
                    </div>}*/}
            </div>
            <div className={"VisitCard__tenant-stay-info__mobile"}>
                <span><img alt={"icon"} src={calendarIconGray}/>{t('main_page.my_visits.visit_card.move_in')}: <strong>{moment(visit.moveIn).format("DD/MM/YYYY")}</strong></span>
                <span><img alt={"icon"} src={calendarIconGray}/>{t('main_page.my_visits.visit_card.move_out')}: <strong>{moment(visit.moveOut).format("DD/MM/YYYY")}</strong></span>
            </div>
            {(filter === "pending" || filter === "requests") && !visit.acceptedDate && !visit.selectedDate &&
            <div className={"VisitCard__dates"}>
                <p>{t('main_page.my_visits.visit_card.dates')}</p>
                <div className={"dates-container"}>
                    {visit.chosenDates?.map((chosenDate, cdIndex) => (
                        <Tooltip placement={"top"}
                                 arrow
                                 classes={{tooltip: "DateTooltip"}}
                                 title={t('main_page.my_visits.visit_card.schedule.timezone', {timezone: visit_timezone})} key={"cd" + cdIndex}>
                            <div className={"date-box"}>
                                <span>{moment(chosenDate.date).utcOffset("+10:00").locale(localStorage.getItem('i18nextLng').split('-')[0]).format('DD MMM YYYY')}</span>
                                <strong>{chosenDate.start}-{chosenDate.end}</strong>
                            </div>
                        </Tooltip>))}
                </div>

            </div> }
            {(filter === "pending" || filter === "requests") && !visit.acceptedDate && !visit.selectedDate &&
            <div className={"VisitCard__dates mobile"}>
                <p onClick={() => {setShowDates(!showDates)}}>{t('main_page.my_visits.visit_card.dates')} <img src={arrowDown} alt={"icon"}/></p>
                <div className={"dates-container "+(showDates ? " hidden" : "")}>
                    {visit.chosenDates?.map((chosenDate, cdIndex) => (<div key={"cd" + cdIndex} className={"date-box"}>
                        <span>{moment(chosenDate.date).utcOffset("+10:00").locale(localStorage.getItem('i18nextLng').split('-')[0]).format('DD MMM YYYY')}</span>
                        <strong>{chosenDate.start}-{chosenDate.end}</strong>
                    </div>))}
                </div>
            </div>}

            {(filter === "pending" || filter === "requests")  ? <div className={"VisitCard__actions"}>
                <span className={"expires-in"}><img alt={"expires"}
                    src={expire}/> {t( 'dashboard_list_item.expiration_date', {date: moment(visitExpiration).locale(i18n.language).format('DD MMM YYYY'), time: moment(visitExpiration).locale(i18n.language).format('HH:mm')})}</span>
                {(visit.suggestedDates || visit.needsStudentAcceptance) && visit.acceptedDate ? <div className={"VisitCard__summary"}><div>
                    <span>{t("main_page.my_visits.visit_card.suggested_on")} {momenttz.tz(visit.tenantSelectedDate || visit.selectedDate, visit_timezone).format("DD/MM/YYYY")}</span>
                    <span>{t("main_page.my_visits.visit_card.waiting_for_student_response")}</span>
                </div></div> : <>
                        <button className={'btn btn-primary'} onClick={() => setOpenScheduleDialog(true)}>
                        <img src={notify} alt={"notification icon"}/>
                        {t('main_page.my_visits.visit_card.schedule_visit')}
                    </button>
                        {!visit.orginalReservation_id && !visit.suggestionType && <button className={"btn btn-secondary"} onClick={() => setOpenSuggestProperty(true)}>{t("main_page.my_visits.visit_card.suggest_another_ad")}</button>}
                    <span className={"reject-visit"}
                          onClick={() => setOpenRejectModal(true)}>{t('main_page.my_visits.visit_card.reject_visit')}</span></>}
            </div> : <div className={"VisitCard__summary"}>
                {filter === "scheduled" ? <div>
                        {(visit.selectedDate || visit.tenantSelectedDate) &&
                        <>
                            <span>{t('main_page.my_visits.visit_card.visit_date')}: <strong>{momenttz.tz(visit.tenantSelectedDate || visit.selectedDate, visit_timezone).format("DD/MM/YYYY")}</strong></span>
                            <Tooltip placement={"top"}
                                     arrow
                                     classes={{tooltip: "DateTooltip"}}
                                     title={<span style={{textAlign:"center",display:"flex"}}>
                                         {t('main_page.my_visits.visit_card.schedule.timezone', {timezone: visit_timezone})}
                                         {local_timezone !== visit_timezone && <>
                                             <br/>
                                             {t('main_page.my_visits.visit_card.schedule.local_time', {
                                                 timezone: local_timezone,
                                                 time: momenttz.tz(visit.tenantSelectedDate || visit.selectedDate, visit_timezone).format("HH:mm")
                                             })}
                                         </>}
                                     </span>}>
                                <span>{t('main_page.my_visits.visit_card.visit_hour')}: <strong>{momenttz.tz(visit.tenantSelectedDate || visit.selectedDate, visit_timezone).format("HH:mm")}</strong> ({visit_timezone})</span>
                            </Tooltip>
                        </>}
                        {visit.type !== "onsite" && <>
                            <Tooltip placement={"top"}
                                     arrow
                                     classes={{tooltip: "DateTooltip"}}
                                     title={canJoin ? "" : t('main_page.my_visits.visit_card.cant_join')}>
                                <div className={"btn-videocall" + (canJoin ? "" : " disabled")}
                                     onClick={handleJoinVisit}><img src={videocam} alt={"videocam"}/> {t('main_page.my_visits.visit_card.join_call')}</div>
                            </Tooltip>
                            <button className={"btn btn-share"} onClick={() => setShareDialogOpen(true)}><img src={share} />{t('main_page.my_visits.visit_card.share_link')}</button>
                        </>

                        }
                    </div> :
                    (filter === "ended" ?
                        <div>
                            <span>{t('main_page.my_visits.visit_card.ended_on')}: {moment(visit.rejectedDate || moment(visit.suggestedDates && visit.acceptedDate ? visit.acceptedDate : visit.requestDate).add(1, 'day')).format("DD/MM/YYYY")}</span>

                        </div>
                        :
                        <div>
                            <span>{filter === "rejected" ? t('main_page.my_visits.visit_card.rejected_on') : t('main_page.my_visits.visit_card.cancelled_on')}: {moment(visit.rejectedDate || moment(visit.suggestedDates && visit.acceptedDate ? visit.acceptedDate : visit.requestDate).add(1, 'day')).format("DD/MM/YYYY")}</span>
                            <span><strong>{t('main_page.my_visits.visit_card.motive')}: </strong>{t('main_page.my_visits.visit_card.reject_motive.' + (visit.rejectedMotive === "suggested_property" ? "suggested_property" : (visit?.rejectedMotive?.includes("landlord") ? "landlord" : "tenant") || "expired"))}</span>
                        </div>)}
                {filter === "scheduled" && !visit.tenantSelectedDate &&
                <span className={"reject-visit"} onClick={() => setOpenRejectModal(true)}>{t('buttons.cancel')}</span>}
            </div>}
        </div>
        <ActionDialog type={showActionDialog}
                      open={showActionDialog} setOpen={setShowActionDialog}
                      title={showActionDialog ? t(`action_dialog.${showActionDialog}`, {
                          visitMoment: showActionDialog === "visit_accepted" ? moment(selectedDate).locale(localStorage.getItem('i18nextLng').split('-')[0]).format(`DD MMM [${t("at_time")}] HH:mm`) : null,
                          tenantName: visit.user.name.split(" ")[0]
                      }): ""}
                      message={showActionDialog === "visit_dates" ?
                          <div style={{display: "flex", flexDirection: "column"}}>
                              {visit.suggestedDates[0] && <span>{moment(visit.suggestedDates[0].date).locale(localStorage.getItem("i18nextLng")).format("dddd, DD MMM · ") + visit.suggestedDates[0].start + "-" + visit.suggestedDates[0].end}</span>}
                              {visit.suggestedDates[1] && <span>{moment(visit.suggestedDates[1].date).locale(localStorage.getItem("i18nextLng")).format("dddd, DD MMM · ") + visit.suggestedDates[1].start + "-" + visit.suggestedDates[1].end}</span>}
                              {visit.suggestedDates[2] && <span>{moment(visit.suggestedDates[2].date).locale(localStorage.getItem("i18nextLng")).format("dddd, DD MMM · ") + visit.suggestedDates[2].start + "-" + visit.suggestedDates[2].end}</span>}
                          </div>
                          : showActionDialog ? t("action_dialog." +  showActionDialog + "-message", {tenantName: visit.user.name.split(" ")[0]}) : ""}/>
        <RejectVisitModal open={openRejectModal} setOpen={setOpenRejectModal}
                          visit={visit} updateVisits={updateVisits} setActionDialog={setShowActionDialog}/>
        <SuggestPropertyModal request={visit} open={openSuggestProperty} setOpen={setOpenSuggestProperty} room={visit.property.room} room_id={visit.property.room.id} currentRent={parseFloat(monthlyRent?.includes("-") ? monthlyRent.split("-")[0] : monthlyRent.slice(0, -1))} type={"visit"} updateVisits={updateVisits}/>
        <Dialog
            onClose={closeShareDialog}
            className={"VisitCard__share-dialog"}
            open={!!shareDialogOpen}>
            <div className={"VisitCard__share-dialog-content"}>
                <div className={"title"}>{t(shareTrans + "title")}<img alt={"close"} src={close} onClick={closeShareDialog}/></div>
                <div className={"info-box"}>
                    <img align={"info"} src={info} alt={"info"}/>
                    <span>{t(shareTrans + "info_text")}</span>
                </div>
                <div className={"visit-section"}>
                    <div className={"property-photo"} style={{backgroundImage: `url(${property_image})`}}/>
                    <div className={"visit-info"}>
                        <div className={"property-name"}>{t(shareTrans + "visit_title", {title: roomName})}</div>
                        <span><strong>{t(shareTrans + "visit_number")}</strong>{visit.id.split("-")?.[0]}</span>
                        <span><strong>{t(shareTrans + "tenant")}</strong>{visit.user.name.split(" ")[0]}, {t('country.'+(visit.user?.personalDetails ? visit.user?.personalDetails.nationality : visit.user.nationality))}, {t(visit.user?.personalDetails?.gender || visit.user?.gender)}, {tenantAge + " " + t("years")}</span>
                        <span><strong>{t(shareTrans + ((visit.user.personalDetails?.occupation || visit.user?.occupation) === "student" ? "student" : "occupation"))}</strong>{(visit.user.personalDetails?.occupation || visit.user?.occupation) === "student" ? (visit.user.personalDetails?.university || visit.user?.university) : (visit.user?.personalDetails?.occupation_detail || visit.user?.occupation_detail)}</span>
                        <span><strong>{t(shareTrans + "address")}</strong>{visit.property.address}{visit.property.region ? ", " + visit.property.region : ""}</span>
                        <span><strong>{t(shareTrans + "room")}</strong>{roomNumber}</span>
                        <span><strong>{t(shareTrans + "date_time")}</strong>{momenttz.tz(visit.tenantSelectedDate || visit.selectedDate, visit_timezone).format("DD MMM YYYY[,] HH:mm")} {visit_timezone}</span>
                    </div>
                    <button className={"btn btn_white"} onClick={handleCopyDescription}><img src={copy}/>{t(shareTrans + (copied === "description" ? "copied" : "copy_description"))}</button>
                </div>
                <div className={"share-section"}>
                    {["landlord", "visitor"].map(type => {
                        return(
                            <div className={"share-buttons"} key={"share_buttons-" + type}>
                                <div>
                                    <strong>{t(shareTrans + "copy_" + type)}</strong>
                                    <div>
                                        <input value={getVisitCallUrl() + (type === "landlord" ? "?ltoken=" + visit.landlord_token : "")} className={`basic_container input_field`}
                                               readOnly={true} onClick={(e) => {
                                            e.target.focus();
                                            e.target.select();
                                        }}/>
                                        <Tooltip title={t(shareTrans + (copied === type ? "copied" : "copy"))} placement={"top"} arrow>
                                            <button type={'button'} className={'btn btn_grey_soft'}
                                                    onClick={handleCopyUrl(type === "landlord")}><img src={copy} style={{marginRight: "0px"}} alt={"clipboard"}/></button>
                                        </Tooltip>
                                    </div>
                                </div>
                                {type === "visitor" && <div>
                                    <button className={"btn btn_white"} onClick={shareHandler("email", type === "landlord")}><img src={email} alt={"email"}/>Email</button>
                                    <button className={"btn btn_white"} onClick={shareHandler("messenger", type === "landlord")}><img src={messenger} alt={"messenger"}/>Messenger</button>
                                    <button className={"btn btn_white"} onClick={shareHandler("whatsapp", type === "landlord")}><img src={whatsapp} alt={"whatsapp"}/>Whatsapp</button>
                                </div>}
                            </div>
                        )
                    })}
                </div>
            </div>
        </Dialog>
        <ScheduleDialog openDialog={!!openScheduleDialog} closeDialog={closeScheduleDialog} visit={visit} setVisitMoment={setSelectedDate} setActionDialog={setShowActionDialog}/>
    </div>)
};


export default VisitCard;
