import React, { useState } from 'react';
import './styles.less';

import DatePicker, { registerLocale } from 'react-datepicker';
import { Controller } from 'react-hook-form';
import pt from 'date-fns/locale/pt';
import DatepickerCalendarIcon from '../svg/DatepickerCalendar';
import moment from "moment"

registerLocale('pt', pt);

const DatePickerRange = ({
    selected,
    showIcon,
    name,
    control,
    className,
    rules,
    placeholder = 'dd/mm/yyyy',
    disabled,
    minDate,
    maxMonthLimit,
    dateFormat,
    isClearable,
    excludeDates,
    excludeDateIntervals,
    selectedStart,
    selectedEnd,
    showMonthDropdown
}) => {
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    return (
        <div className="date-picker-range">
            <Controller
                control={control}
                name={name}
                defaultValue={selected}
                render={(props) => (
                    <DatePicker
                        className={className}
                        selectsRange={true}
                        startDate={selectedStart ? selectedStart : startDate}
                        endDate={selectedEnd ? selectedEnd : endDate}
                        excludeDateIntervals={excludeDateIntervals}
                        excludeDates={excludeDates}
                        dateFormat={dateFormat ? dateFormat : 'dd MMM yyyy'}
                        onChange={(update) => {
                            setDateRange(update);
                            props.onChange(update)
                        }}
                        locale={localStorage.getItem('i18nextLng').split('-')[0]}
                        formatWeekDay={nameOfDay => nameOfDay.substring(0, 1).toUpperCase()}
                        placeholderText={placeholder}
                        disabled={disabled}
                        minDate={minDate}
                        maxDate={moment(startDate).add(maxMonthLimit, "month").toDate()}
                        isClearable={isClearable}
                        showMonthDropdown={showMonthDropdown}
                        popperPlacement="bottom"
                        popperModifiers={{
                            flip: {
                                behavior: ["bottom"] // don't allow it to flip to be above
                            },
                            preventOverflow: {
                                enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                            },
                            hide: {
                                enabled: false // turn off since needs preventOverflow to be enabled
                            }
                        }}
                    />
                )}
                rules={rules}
            />
            {showIcon && <DatepickerCalendarIcon className={isClearable ? 'spaced' : null} />}
        </div>
    )
};

export default DatePickerRange;
