import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import rentingTypeIcon from '../images/rentingTypeIcon.svg';
import arrowDownIcon from '../images/arrowDownIcon.svg';
import infoIcon from '../images/infoIcon.svg';
import {connect} from 'react-redux';

import './style.less'
import verifyAttributeExistenceToString from '../functions/verifyAttributeExistenceToString';
import ResumeButtons from '../components';
import RoomResume from '../RoomResume';
import pencilIconBlue from '../images/pencilIconBlue.svg';
import {propertyAdHistoryActions} from '../../../redux/actions';
import {propertyServices} from '../../../services'
import {clampText} from "../../../utils/stringFunctions";
import avatarMale from "../../VisitCard/images/avatar_male.png";
import {useAuth} from "../../../services/authServices";
import PencilIcon from "../../../components/svg/Pencil";
import ReactMoment from "react-moment";
import {calcFirstAvailability} from "../../../utils/firstAvailability.js";
import MUIMenu, {StyledMenuItem} from "../../MUIMenu/index.js";

const locale = localStorage.getItem('i18nextLng')?.split('-')?.[0];

//There are three types of PropertyResume
//"My_Ads" - that is for listing properties on My_Ads tab
//"Resume" - that is to show the property Resume on the level 3.1.1 of the form
//"Integrations" - for integrations
const PropertyResume = ({property, type, changePage, ButtonsComponent, currentPage, ...props}) => {
  const {t, i18n} = useTranslation();
  const {user} = useAuth();

  const displayRoomsToogleHandler = () => {
    props.setDisplayRooms(props.displayRooms === property.id ? null : property.id)
  }

  const handleVerification = async (e) => {
    e.stopPropagation();
    await propertyServices.askVerification(property.id);
    property.verificationAsked = true;
    displayRoomsToogleHandler();
  };

  const PropertyResumeButtons = () => {
    return (
      <div className={'PropertyResumeButtons'}>
        {(property.rooms && Object.values(property.rooms).length) ?
          property.finished ?
            <span className={'PropertyResumeButtons__editProperty'}>
              {property.accommodation !== 'apartment' &&
                <button className={'btn btn-tertiary'} onClick={(e) => {
                 e.stopPropagation();
                 props.setOpen();
               }}>
                  <PencilIcon />
                 <p>{t(`property_resume.edit_property`)}</p>
               </button>}
              <img className={`${props.displayRooms === property.id ? 'flip_img' : ''}`} src={arrowDownIcon}/>
          </span>
            : <span className={'PropertyResumeButtons__openRooms'}>
          <label>{t(`property_resume.${props.displayRooms === property.id ? 'close_rooms' : 'open_rooms'}`)}</label>
          <img className={`${props.displayRooms === property.id ? 'flip_img' : ''}`} src={arrowDownIcon}/>
          </span>
          : <ResumeButtons property={property} changePage={changePage} {...props} currentPage={currentPage}/>}
      </div>
    )
  };

  const PropertyResumeMyAds = () => {
    let clampedAddress = clampText(verifyAttributeExistenceToString(property, 'address'), 64);
    let numRooms = property.accommodation === 'residence' ? ((property.bedrooms||0) + (property.studio_rooms||0)) : (property.rooms ? Object.keys(property.rooms).length : 0);
    let numActive = property.rooms ? Object.values(property.rooms).filter(room => props.filter === "disabled" ?room.disabled:!room.disabled).length : 0;
    return (
      <div className={`PropertyResume My_Ads`} ref={props.componentRef}>
        <div className={`PropertyResume__header ${type === 'Resume' ? 'type_small' : ''}`}
             onClick={displayRoomsToogleHandler}>
          {/*property.landlord.id !== user?.id && <div className={"PropertyResume__avatar__overlay"} style={{backgroundImage: `url(${property.landlord.photo || avatarMale})`}}/>*/}
          <div className={'PropertyResume__header-main'}>
            <h5>
              {clampedAddress + ', ' + verifyAttributeExistenceToString(property, ['floor', 'region'])}
              <small>{(property.internal_name ? `(${property.internal_name})` : "" )} {(property.integration_id ? property.integration_id : "")}</small>
            </h5>
            <div className={'PropertyResume__header-complementary'}>
              <div
                className={'PropertyResume__header-icons'}>
                <div>
                  <div className={'icon'}>
                    <img src={rentingTypeIcon}/>
                  </div>
                  <p className={'small'}>{t(`property_resume.accomodation_type.${property.accommodation}`)}</p>
                </div>

                {!property.verified && (property.verificationAsked ?
                  <div>
                      <div className={'icon'}>
                        <img src={infoIcon}/></div>
                      <p className={'small'}>{t(`property_resume.verification_asked`)}</p>
                  </div> : <div className={'clickable verified-tag'} onClick={handleVerification}>
                      <div className={'icon'}>
                        <img src={infoIcon}/></div>
                      <p className={'small'}>{t(`property_resume.ask_verification`)}</p>
                    </div>)}
              </div>
            </div>
          </div>
          <div
            className={`PropertyResume__header-roomsInfo`}>
            <div>
              <label>{property.accommodation === 'apartment' ? (numRooms||1) : numRooms} {t(`property_resume.room_in_${property.accommodation === 'residence' ? 'residence' : 'property'}`, {count: numRooms, context: numRooms+""})}</label>
              {property.accommodation === 'apartment' ?
                <p>1 {t(`property_resume.${props.filter||"active"}_ad`)}</p> :
                <p>{numActive} {t(`property_resume.${props.filter||"active"}_ad`, {count: numActive, context: numActive+""})}</p>}
            </div>
            <PropertyResumeButtons />
          </div>


        </div>
        {property.rooms && props.displayRooms === property.id &&
         <div className={`PropertyResume__content ${props.displayRooms !== property.id ? 'hide_rooms' : ''}`}>
           {(property.accommodation !== 'apartment' ? Object.keys(property.rooms) : ['room_1']).map((roomName) => {
             if (property.accommodation !== 'apartment') {
               if (props.filter !== 'disabled' && property?.rooms?.[roomName]?.disabled) {
                 return null
               } else if (props.filter === 'disabled' && !property?.rooms?.[roomName]?.disabled)
                 return null
             }
             return <RoomResume
               key={roomName}
               roomName={roomName}
               room={property.rooms[roomName]}
               property={property}
               type={type}
               changePage={changePage}
               currentPage={currentPage}
               {...props}
             />
           })}
         </div>}
      </div>)
  }


  const PropertyResumeHeader = () => {
    return (
      <div className={`PropertyResume`}>
        <div className={`PropertyResume__header ${type === 'Resume' ? 'type_small' : ''}`}
             onClick={displayRoomsToogleHandler}>
          <div className={'PropertyResume__featured-photo'}>
            <div className={'PropertyResume__photo'}
                 style={{backgroundImage: 'url(' + property.photos?.featured_0?.[0]?.md + ')'}}/>
          </div>
          <div className={'PropertyResume__header-main'}>
            <h5>{verifyAttributeExistenceToString(property, 'internal_name')}</h5>
            <p className={'header-address'}>{clampText(verifyAttributeExistenceToString(property, 'address'), 60)}</p>
            <p className={'bold'}>{verifyAttributeExistenceToString(property, 'neighborhood')}</p>
          </div>
        </div>
        {property.rooms &&
         <div className={`PropertyResume__content`}>
           {Object.keys(property.rooms).map((roomName) =>
             <RoomResume
               key={roomName}
               roomName={roomName}
               room={property.rooms[roomName]}
               property={property}
               type={type}
               changePage={changePage}
               currentPage={currentPage}
               {...props}
             />)}
         </div>}
      </div>)
  }

  const getPricesInterval = (currentRoom) => {
    if (currentRoom?.rentMonths && Object.keys(currentRoom?.rentMonths).length > 0) {
      let minRent = currentRoom.rentMonths['january'];
      let maxRent = currentRoom.rentMonths['january'];
      Object.keys(currentRoom.rentMonths).map(month => {
        if (currentRoom.rentMonths[month] < minRent) minRent = currentRoom.rentMonths[month]
        if (currentRoom.rentMonths[month] > maxRent) maxRent = currentRoom.rentMonths[month]
      })
      if (minRent === maxRent) return minRent + '€';
      return minRent + '-' + maxRent + '€';
    } else if (currentRoom?.fixedRent || (property.migrated && currentRoom?.rent)) {
      return currentRoom.rent + '€';
    } else if (currentRoom?.minRent && currentRoom?.maxRent) {
      return (currentRoom.minRent === currentRoom.maxRent ? currentRoom.minRent : currentRoom.minRent + '-' + currentRoom.maxRent) + '€';
    } else {
      return t('tbd');
    }
  }

  const [menuOpen, setMenuOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const menuRef = useRef(null);

  const handleClick = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClose = () => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setMenuOpen(false);
  }

  const PropertyResumeIntegration = () => {
    let photos = [...(property.room_photos||[]), ...(property.photos||[])];
    let clampedAddress = clampText(verifyAttributeExistenceToString(property, 'address'), 64);
    let firstAvailability = property.firstAvailability;

    return (<div className={`PropertyResume Integrations RoomResume`}>
      <div className={'RoomResume__main'}>
        <div className={'RoomResume__photo'} style={{backgroundImage: `url('${photos[0]}')`}}/>
        <div className={'RoomResume__main-content'}>
          <div className={'RoomResume__info'}>
            <div className={'PropertyResume__header-main'}>
              <h5 style={{margin:0}}>
                {clampedAddress + ', ' + verifyAttributeExistenceToString(property, ['floor', 'region'])}
                <small>{(property.internal_name ? `(${property.internal_name})` : "" )}</small>
              </h5>
              <p>{(property.integration_id ? property.integration_id : "")}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={`RoomResume__complementary`}>
        <div className={'PriceAndAvailabilityComponent MyAds'}>
          <div style={{justifyContent: "space-between"}}>
            <div>
              <p className={'prices-interval'}>{getPricesInterval(property)}</p>
            </div>
            <div>
              <p className={"bills-included"}>{property.billsIncluded ? t('property_resume.bills_included') : t('property_resume.bills_not_included')}</p>
            </div>
            <div className={'AvailabilityComponent'}>
              <p>{`${t('property_resume.available_from')} `}</p>
              {firstAvailability ?
                  <ReactMoment locale={locale}
                               format={locale === 'en' ? 'MMM D yyyy' : 'D MMM yyyy'}>
                    {new Date(firstAvailability) < Date.now() ? Date.now() : firstAvailability}
                  </ReactMoment>
                  : <p>{t('tbd')}</p>}
            </div>
          </div>
        </div>
        <div>
          <a target="_blank" className={"full_link"}
             href={propertyServices.generateStudentUrl(property.id, property.room_id)}>
            {t('property_resume.preview')}
          </a>
        </div>
      </div>
    </div>)
  }

  switch(type) {
    case "Resume":
      return <PropertyResumeHeader/>;
    case "Integrations":
      return <PropertyResumeIntegration/>;
    default:
      return <PropertyResumeMyAds/>;
  }
};

const actionCreator = {
  addHistoryReset: propertyAdHistoryActions.reset
};


export default connect(null, actionCreator)(PropertyResume);
