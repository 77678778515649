import React, {useCallback, useEffect} from 'react';

import Dropzone from './Dropzone';

import './styles.less';
import {useTranslation} from 'react-i18next';
import cuid from 'cuid';
import {Droppable} from 'react-beautiful-dnd';

const ChooseOnePhoto = ({image, title, minPhotos, displayError, errorMessage, type, id,userActionsHandler,upload, isRoomsSection}) => {
  const {t, i18n} = useTranslation();

  // const onDrop = useCallback((acceptedFiles) => {
  //   acceptedFiles.map((file) => {
  //     const reader = new FileReader();
  //     reader.onload = function (e) {
  //       setImage({id: cuid(), src: e.target.result, file: file});
  //       const fieldId=id.split(' ').length>1?id.split(' ')[0]:id;
  //       userActionsHandler({
  //         type: 'new',
  //         field: `${fieldId}[featured]`
  //       })
  //     };
  //     reader.readAsDataURL(file);
  //     return file;
  //   });
  // }, []);


  return (
    <Droppable droppableId={id} id={id} direction={'horizontal'} type={'sub'}>
      {(provided, snapshot) => (
        <div id={id} ref={provided.innerRef} tabIndex={'-1'}
             {...provided.droppableProps}
             className={`ChooseOnePhoto ${displayError ? 'error_container' : ''} ${type ? 'ChooseOnePhoto__' + type : ''}`}
             style={{transitionDuration: `0.001s`}}>
          <Dropzone accept={'image/*'} id={id} image={image} upload={upload} title={title} minPhotos={minPhotos} isRoomsSection={isRoomsSection}/>
          {displayError && <p className={'error_message'}>{errorMessage}</p>}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default ChooseOnePhoto;
