import React, { useState } from "react";
import PropTypes from 'prop-types';
import "./style.less";

/* 
    @param {number} delay (optional): The number of milliseconds to wait before displaying the tooltip. Default is 400.
    @param {string} position (optional): The position in which to position the tooltip. Can be "top", "right", "bottom", or "left". Default is "top".
    @param {node} children (required): The child element that the tooltip will be displayed on when hovered over.
*/
const Tooltip = ({ delay = 400, position = "top", children }) => {
    let timeout;
    const [active, setActive] = useState(false);

    const showTip = () => {
        timeout = setTimeout(() => {
            setActive(true);
        }, delay);
    };

    const hideTip = () => {
        clearInterval(timeout);
        setActive(false);
    };

    const childArray = React.Children.toArray(children);
    const iconElement = childArray.find((child) => child.type === Tooltip.Icon);
    const contentElement = childArray.find((child) => child.type === Tooltip.Content);

    return (
        <div
            className="tooltip__wrapper"
            onMouseEnter={showTip}
            onMouseLeave={hideTip}
        >
            {iconElement}
            {active && contentElement && (
                <div className={`tooltip-tip ${position}`}>
                    {contentElement.props.children}
                </div>
            )}
        </div>
    );
};

Tooltip.Icon = ({ children }) => children;
Tooltip.Content = ({ children }) => children;

Tooltip.propTypes = {
    delay: PropTypes.number,
    position: PropTypes.string,
    children: PropTypes.node
};

Tooltip.Icon.propTypes = {
    children: PropTypes.node,
};

Tooltip.Content.propTypes = {
    children: PropTypes.node,
};

export default Tooltip;
