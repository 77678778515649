import React, { useMemo } from "react";
import './style.less';
import { useTranslation } from 'react-i18next';
import { useAuth } from "../../services/authServices";


const PaginationLegacy = ({ setPage, total, page, setLoading, updateList, filter, filters, searchKey = "", totalListings, docsPerPage = 20, isListings }) => {

    const { user, setUser } = useAuth();

    const { t } = useTranslation();
    const handleSetPage = async (next_page) => {
        if (next_page === page || next_page < 0 || next_page > Math.ceil(total / docsPerPage)) return;
        setLoading(true)
        setPage(next_page);
        if (filters) filters["searchKey"] = searchKey;
        updateList && await updateList(next_page, {"currentStatus": filter, "searchKey": searchKey, ...filters } );
        setLoading(false)
    };

    let pageButtons = useMemo(() => {
        let maxPage = Math.ceil(total / docsPerPage || 1);
        let ret = [];
        if (maxPage <= 7) {
            for (let np = 1; np <= maxPage; np++) {
                ret.push(np);
            }
        } else {
            let npage = page + 1;
            if (npage < 5)
                ret = [1, 2, 3, 4, 5, "..", maxPage];
            else if (npage > maxPage - 4)
                ret = [1, "..", maxPage - 4, maxPage - 3, maxPage - 2, maxPage - 1, maxPage];
            else
                ret = [1, "..", npage - 1, npage, npage + 1, "..", maxPage];
        }
        return ret.map((p, i) => <button
            className={`PageButton btn_white ${p === page + 1 ? "active" : ""}`}
            key={p + "_" + i} disabled={p === ".."}
            onClick={async () => {
                if (p !== "..") await handleSetPage(p - 1)
            }
            }>
            {p}
        </button>)
    }, [total, page]);

    let numListings;
    if (filter === "published") {
        numListings = user.activeListings;
    } else if (filter === "disabled") {
        numListings = user.inactiveListings;
    } else if (filter === "integrations") {
        numListings = total;
    } else {
        numListings = user.inProgressListings;
    }

    return (
        <div className={"PaginationLegacy"} style={{ display: total > docsPerPage ? "flex" : "none" }}>
            <div className={"total-listings"}>{isListings ? numListings : total}</div>
            <div className="total-pages">
            {pageButtons?.map((button, index) => {
                return button;
            })}
            </div>
        </div>
    )
}

export default PaginationLegacy;
