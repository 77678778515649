import React from "react";

const Notes = (props) => (
    <svg
        width={10}
        height={10}
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="notes">
            <path
                id="Vector"
                d="M8.75 4.5875L1.25 4.58333V5.41667H8.75V4.5875ZM1.25 6.66667H6.25V7.5H1.25V6.66667ZM8.75 2.5H1.25V3.3375L8.75 3.33333V2.5Z"
                fill="#284F83"
            />
        </g>
    </svg>
);

export default Notes;
