import React, {useMemo} from "react";

import './style.less';
import {useTranslation} from "react-i18next";
import {useHistory, useLocation} from "react-router-dom";
import ArrowLeftIcon from "../svg/Navigation/ArrowLeft";

const NavBarGoBack = () => {

    const {t} = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const types = ["requests", "request", "pending", "confirmed", "rejected", "cancelled", "not_finished", "create"]

    const type = useMemo(() => {
        let split = location.pathname.split("/");
        if (split) {
            if (split[1] === "my_ads" && split[split.length - 1] === "published") return false;
            return split[1];
        }
    }, [location.pathname]);

    let propertyId = useMemo(() => {
        let split = location.pathname.split("/");
        if (split?.[1] === "my_ads" && split[3] === "edit") {
            return split[4];
        } else {
            return false
        }
    }, [location.pathname]);

    let prevPage = useMemo(() => {
        if(propertyId) {
            let prevPage_key =  "prevPage_" + propertyId
            let page = localStorage.getItem(prevPage_key);
            if (page) localStorage.removeItem(prevPage_key);
        }
    }, [propertyId]);

    const filter = useMemo(() => {
        let split = location.pathname.split("/");
        if (split) {
            let filter = split[split.length - 1]
            return types.filter((type) =>
                {return type === filter}).length > 0 ? filter
                    : (type === "reservations" ? "requests" : null);
        }
    }, [location.pathname]);

    const sendBack = () => {
        if (type && filter) {
            if (filter === "not_finished") {
                history.push("/" + type + "/in_progress" + (prevPage ? "?prevPage=" + prevPage : ""));
            } else {
                history.push("/" + type + "/" + filter);
            }
        } else if (type) {
            history.push("/" + type);
        }
    }

    return (
        <div className={"NavBarGoBackContainer"} onClick={sendBack}>
            {type &&
            <>
                <div className={"NavBarGoBackContainer__button"}>
                    <ArrowLeftIcon />
                </div>
                <p>{t("GoBackNavBar.back") + " " + t("GoBackNavBar." + type + ".title") + (filter ? (" " + t("GoBackNavBar." + type + "." + filter)) : "")}</p>
            </>
            }
        </div>
    );
};

export default NavBarGoBack;
