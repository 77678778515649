import React, {useMemo, useRef, useState} from 'react';
import {Draggable} from 'react-beautiful-dnd';
import {useTranslation} from 'react-i18next';

import MUIMenu,{StyledMenuItem} from '../../../../../../../../components/MUIMenu';

// const type = 'Image'; // Need to pass which type element can be draggable

const Image = ({
                 image,
                 index,
                 removeImage,
                 setFeatured,
                 sendToOtherArea,
                 propertyAreasToSend,
                 roomsNumber,
                 currentRoomIdx,
                 setOpenLightbox
    }) => {
  const {t} = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const menuRef = useRef(null);

  const areaOptions = useMemo(() => {
    const areaOptionsFinal = propertyAreasToSend?[...propertyAreasToSend]:[];
    for (let i = 1; i <= roomsNumber; i++) {
      if(i !== currentRoomIdx+1)
      areaOptionsFinal.push({title: t('room') + ' ' + i, roomNumber: i});
    }
    return areaOptionsFinal;
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Draggable key={image.id} draggableId={image.id}
               index={index} /*ref={ref}*/ /*style={{ opacity: isDragging ? 0 : 1 }}*/>
      {(provided, snapshot) => (
        <div className="file-item" ref={provided.innerRef}
             {...provided.draggableProps}
             {...provided.dragHandleProps}>
          <div className={'file-item__buttons'}>
            {areaOptions.length>0 &&<MUIMenu anchorEl={anchorEl} menuRef={menuRef} isIconCircle={true} handleClick={handleClick} handleClose={handleClose}>
              <StyledMenuItem onClick={() => {
                handleClose();
                setFeatured({index})
              }}>{t('set_featured')}</StyledMenuItem>
              <StyledMenuItem disabled={true}>{t('move_to') + ':'}</StyledMenuItem>
              {areaOptions.map((option) => (
                <StyledMenuItem
                  key={option.title}
                  onClick={() => {
                    handleClose();
                    sendToOtherArea({ roomNumber: option.roomNumber,index,area:option.area})
                  }}>
                  {option.title}
                </StyledMenuItem>))}
            </MUIMenu>}
            <button onClick={removeImage(image)} className="remove-img btn btn_white"/>
          </div>
          <span
              style={{backgroundImage: `url('${image.src ? image.src : image.sm}')`}}
              className="file-img"
              onClick={()=>setOpenLightbox(image)}
          />
          {/*<img alt={`img - ${image.id}`} src={image.src} className="file-img" />*/}
        </div>
      )}
    </Draggable>
  );
};

const ImageList = ({images, areaProps, moveImage, fieldName, setOpenLightbox, deleteImage}) => {

  const removeImage = (image) => () => {
    deleteImage(image);
  };

  const renderImage = (image, index) => {
    return (
      <Image
        image={image}
        index={index}
        key={`${image.id}-image`}
        moveImage={moveImage}
        roomsNumber={areaProps.roomsNumber}
        currentRoomIdx={areaProps.currentRoomIdx}
        sendToOtherArea={(values) => areaProps.sendToOtherArea({source:fieldName,...values})}
        setFeatured={(values) => areaProps.sendFeatured({source:fieldName,...values})}
        propertyAreasToSend={areaProps.propertyAreasToSend}
        removeImage={removeImage} setOpenLightbox={setOpenLightbox}
      />
    );
  };

  return <section className="ChooseImages__image-list">{images.map(renderImage)}</section>;
};

export default ImageList;
