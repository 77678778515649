import React from "react";

const FilterDescending = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#clip0_1658_93)">
            <path d="M22 7H13V9H22V7Z" fill="#284F83" />
            <path d="M22 15H13V17H22V15Z" fill="#284F83" />
            <path d="M22 11H16V13H22V11Z" fill="#284F83" />
            <path
                d="M7.5 17.5L12.5 12.5H8.5V6.5H6.5V12.5H2.5L7.5 17.5Z"
                fill="#284F83"
            />
        </g>
        <defs>
            <clipPath id="clip0_1658_93">
                <rect width={24} height={24} fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default FilterDescending;
