import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import "./style.less";
import Buttons from "../../../../../components/Buttons";
import {updatePropertyActions} from '../../../../../../../../../../../redux/actions/updatePropertyActions';
import {useTranslation} from "react-i18next";

const RegionContainer = ({region,finalRegion,setFinalRegion}) => {

    const {t} = useTranslation();
    return (
        <button key={region.name}
                className={`PropertyDetails1__regions-region ${(region.value === finalRegion.region) ? 'active' : null}`}
                onClick={() => setFinalRegion({region: region.value})}>
            <div className="PropertyDetails1__regions-region__icon" style={{backgroundImage: `url(${process.env.API_BASE + '/public/static/neighborhood/' + region.value.normalize('NFKD').replace(/[\u0300-\u036f]/g, "").toLowerCase() + ".jpg"})`}} />
            <p>{t("cities." + region.name)}</p>
        </button>
    )
}


const PropertyDetails1 = ({
                              updateProperty,
                              currentLevel,
                              property,
                              changePage,
                              defineUpdateFunc,
                              updatePropertyFunction
                          }) => {

    const regions = [
        {value: "Lisboa", name: "lisboa"},
        {value: "Porto", name: "porto", image: "/api/public/static/neighborhood/porto.jpg"},
        {value: "Coimbra", name: "coimbra", image: "/api/public/static/neighborhood/coimbra.jpg"},
        {value: "Madrid", name: "madrid", image: "/api/public/static/neighborhood/madrid.jpg"},
        {value: "Barcelona", name: "barcelona", image: "/api/public/static/neighborhood/barcelona.jpg"},
        {value: "Milan", name: "milan", image: "/api/public/static/neighborhood/milan.jpg"},
        {value: "Roma", name: "rome", image: "/api/public/static/neighborhood/roma.jpg"},
        {value: "Sevilla", name: "sevilla", image: "/api/public/static/neighborhood/sevilla.jpg"},
        {value: "Valencia", name: "valencia", image: "/api/public/static/neighborhood/valencia.jpg"},
        {value: "Évora", name: "evora", image: "/api/public/static/neighborhood/evora.jpg"},
        {value: "Braga", name: "braga", image: "/api/public/static/neighborhood/braga.jpg"},
        {value: "Aveiro", name: "aveiro", image: "/api/public/static/neighborhood/aveiro.jpg"}
    ]

    const [finalRegion, setFinalRegion] = useState(property?.region ? {region: property.region} : {region: "Lisboa"});

    useEffect(() => defineUpdateFunc(async () => {
        await updateProperty(finalRegion, currentLevel);
    }), [finalRegion]);

    return (
        <div className={"PropertyDetails1"}>
            <div className={'scrollable'}>
                <div className={"PropertyDetails1__regions"}>
                    {regions.map((region, index) => (
                        <RegionContainer key={region.name} region={region} finalRegion={finalRegion}
                                         setFinalRegion={setFinalRegion}/>
                    ))}
                </div>
                <Buttons clickAdvance={finalRegion} updateProperty={(value) => updateProperty(value, currentLevel)}
                         changePage={changePage}/>
            </div>
        </div>
    );
};

const actionCreator = {
  defineUpdateFunc: updatePropertyActions.defineUpdateFunc,
};

export default connect(null, actionCreator)(PropertyDetails1);
