import "./style.less";
import Select from "react-select";
import {FlagControl, FlagOption} from "../FlagControl";
import React, {useEffect, useMemo, useState} from "react";
import {dataServices} from "../../services/dataServices";
import pencilIconGrey from "../../pages/MainPage/containers/MyAccount/containers/images/penciIconGrey.svg";


const PhoneInput = ({defaultDialCode, defaultPhone, onChange, register, getValues, phone_key, errors}) => {

    const [dialCodes, setDialCodes] = useState([]);
    const [dialCode, setDialCode] = useState(undefined);

    useEffect(() => {
        (async () => {
            let fetchedCountries = await dataServices.getCountries();
            setDialCodes(fetchedCountries.map(c => ({
                value: c.iso2,
                country_name: c.name,
                label: c.dial_code,
                dial: c.dial_code
            })))
        })();
    }, []);

    const dialCountrySearch = (option, searchText) => {
        return !searchText || option.country_name?.toLowerCase()?.includes(searchText.toLowerCase()) || option.dial?.toString()?.includes(searchText);
    }


    return (
        <div className={"PhoneInput"}>
            <div className={"input_field dial-code-select"}>
                {dialCodes.length > 0 && <Select options={dialCodes}
                        defaultValue={dialCodes?.find(c => c.dial === defaultDialCode)}
                        getOptionValue={option => option.dial}
                        getOptionLabel={option => "+" + option.dial}
                        selectedSize={14}
                        filterOption={(o, t) => dialCountrySearch(o.data, t)}
                        components={{
                            Option: FlagOption, Control: FlagControl
                        }}
                        onChange={o => onChange && onChange(o)}
                        name={"dialCode"}
                        required={true}
                        className={'input-select input-field'} classNamePrefix={'input-select'}
                        key={JSON.stringify(getValues("dialCode"))}
                />}
            </div>
            <div className={"input_field phone-input-wrapper"}>
                <input name={"phone"} required={true}
                       ref={register({required: true})}
                       className={`input_field ${errors?.phone && "input_error"}`}
                       style={{paddingLeft: "0px"}}
                       key={phone_key}
                       defaultValue={defaultPhone}
                       placeholder={"912345678"} type={"tel"}/>
            </div>
            <img src={pencilIconGrey} alt={"Pencil Icon"}/>
        </div>
    )
}

export default PhoneInput;