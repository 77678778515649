export const userConstants = {
  SIGNUP_REQUEST: "USER_SIGNUP_REQUEST",
  SIGNUP_SUCESS: "USER_SIGNUP_SUCESS",
  SIGNUP_FAIL: "USER_SIGNUP_FAIL",

  LOGIN_REQUEST: "USER_LOGIN_REQUEST",
  LOGIN_SUCESS: "USER_LOGIN_SUCESS",
  LOGIN_FAIL: "USER_LOGIN_FAIL",

  UPDATE_VALUES:"USER_UPDATE_VALUE",

  GET_USER: "USER_OBTER_PERFIL",

  LOGOUT: "USER_LOGOUT",
};
