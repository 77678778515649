import { DialogContent } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import React, { useEffect, useMemo, useState } from "react";
import ReactFlagsSelect from 'react-flags-select';
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import CheckBox from "../../../../../../components/CheckBox";
import Loading from "../../../../../../components/Loading";
import { propertyServices, userServices } from "../../../../../../services";
import { useAuth } from "../../../../../../services/authServices";
import { dataServices as dataService } from "../../../../../../services/dataServices";
import useQuery from "../../../../../../utils/query";
import MyAccountNav from "../MyAccountNav";
import "./style.less";

import Tooltip from "../../../../../../components/Tooltip";
import CheckmarkIcon from "../../../../../../components/svg/Checkmark";
import CircleAddIcon from "../../../../../../components/svg/CircleAdd";
import CloseIcon from "../../../../../../components/svg/Close";
import EmailSentIllustration from "../../../../../../components/svg/EmailSent";
import FloppyDiskIcon from "../../../../../../components/svg/FloppyDisk";
import InfoValidatedIllustration from "../../../../../../components/svg/InfoValidated";
import InputAlertIcon from "../../../../../../components/svg/InputAlert";
import InputCheckedIcon from "../../../../../../components/svg/InputChecked";
import PaperClipIcon from "../../../../../../components/svg/PaperClip";
import InputPencilIcon from "../../../../../../components/svg/Pencil";
import TrashIcon from "../../../../../../components/svg/Trash";
import UploadIcon from "../../../../../../components/svg/Upload";
import Drawer from "../../../../../../components/Drawer";
import { isMobile } from "../../../../../../utils/responsive";
import { shortenFileName } from "../../../../../../utils/stringFunctions";

const FlagAndInput = ({countries, field, info, setInfo, register, errors}) => {
    const {t} = useTranslation();

    const [validating, setValidating] = useState(false);
    const [error, setError] = useState(false);
    const [vatValidatedModal, setVatValidatedModal] = useState(false);

    const validateVAT = async () => {
        if (!info?.country || !info?.vat || error || info?.vatValidated) return;
        setValidating(true);
        let validationInfo = {};
        try {
            validationInfo = await userServices.validateVATNumber(info.id, info.vat, info.country);
        } catch (e) {
            console.log("Error validating vat number", e)
        }
        setValidating(false);
        if (validationInfo?.valid) {
            if (validationInfo.address === "---") delete validationInfo.address;
            if (validationInfo.name === "---") delete validationInfo.name;
            setInfo({...info, vatValidated: true, address: validationInfo.address, fiscalName: validationInfo.name});
            setVatValidatedModal(true);
        } else {
            setError(true);
        }
    }

    const countryIdentifiers = useMemo(() => {
        return Object.keys(countries).reduce((acc, key) => {
            acc[key] = key.toUpperCase();
            return acc;
        }, {});
    }, [countries]);

    return (
        <>
        <div className={"FlagAndInput"}>
                <div className="form-field-container">
                    <label className="form-field-container__label">{field === "iban" ? "IBAN" : t("billing_details.vat")}</label>
                    <div className="form-field-container__field">
                        <ReactFlagsSelect searchable={true}
                            name="country"
                            selected={info?.["country"]?.toUpperCase()}
                            onSelect={(iso2) => {
                                setInfo({ ...info, "country": iso2 })
                                if (error) setError(false);
                            }}
                            showSelectedLabel
                            customLabels={countryIdentifiers}
                            placeholder={t("billing_details.country")}
                            searchPlaceholder={t("billing_details.country")}
                            className="form-field-container__field__country-select"
                            classNamePrefix={'input-select'}
                            selectedSize={14}
                        />
                        <div className={`form-field-container__input 
                            ${!(info?.iban || info?.valid || info?.vatvalidated || info?.vat) && "error"}
                        `}>
                            <input name={field} type="text"
                                className={`${!(info?.iban || !info?.valid || info?.vatvalidated || info?.vat) && "error"}`}
                                defaultValue={info?.[field]}
                                readOnly={field === "iban" && info?.iban && info?.valid && info?.default}
                                onChange={(e) => setInfo({ ...info, [field]: e.target.value })}
                                placeholder={field.toUpperCase()}
                                onFocus={() => {
                                    if (error) setError(false)
                                }}
                                ref={register({ required: true })}
                            />
                            <div className="form-field-container__input__icon-wrapper">
                                <InputPencilIcon />

                                {field === "iban" && <Tooltip position="left">
                                    <Tooltip.Icon>
                                        {((!info.iban || !info?.hasOwnProperty("valid") ||
                                            !info?.valid) && <InputAlertIcon /> ||
                                            (info?.iban && info?.valid) && <InputCheckedIcon />)}
                                    </Tooltip.Icon>

                                    {!info.iban || !info?.hasOwnProperty("valid") ||
                                        !info?.valid && <Tooltip.Content>
                                            {(info?.iban && info?.hasOwnProperty("valid") &&
                                                !info?.valid) && t("billing_details.iban_not_validated")}
                                        </Tooltip.Content>}
                                </Tooltip>}

                                {field === "vat" && !validating && <Tooltip position="left">
                                    <Tooltip.Icon>
                                        {(info?.vatValidated && !error ? <InputCheckedIcon /> : <InputAlertIcon />)}
                                    </Tooltip.Icon>
                                    {!info?.vatValidated && <Tooltip.Content>
                                        {t("billing_details.vat_not_validated")}
                                    </Tooltip.Content>}
                                </Tooltip>}
                            </div>
                        </div>
                    </div>
                    {(field === "vat" && !info?.vatValidated) && <div className="form-field-container__validate-vat">
                        <button className="btn btn-secondary" onClick={validateVAT}>
                            {validating ? <Loading /> : t("billing_details.vat_validate")}
                        </button>
                    </div>}

                    <Dialog onClose={() => setVatValidatedModal(false)}
                        className="modal"
                        maxWidth="xs"
                        open={vatValidatedModal}>
                        <DialogContent>
                            <div className="modal__container">
                                <div className="modal__container__content">
                                    <div className="info-validated">
                                        <InfoValidatedIllustration />
                                        <div className="info-validated__content">
                                            <h3>{t("billing_details.vat_modal.title")}</h3>
                                            <p><Trans i18nKey="billing_details.vat_modal.content" values={{ vat: info?.vat }} /></p>
                                        </div>
                                        <button className="btn btn-primary"
                                            onClick={() => { setVatValidatedModal(false) }}
                                        >
                                            {t("billing_details.vat_modal.button")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                </div>

            </div>

        </>
    )
}


const BillingDetails = (props) => {

    const {t} = useTranslation();
    const query = useQuery();
    const {user} = useAuth();

    const parseInfo = (type) => {
        if(user && user[type]) {
            if(user[type].length > 0 && !user[type].find(el => el.default)) {
                user[type][0].default = true;
            }
            return user[type];
        } else {
            return [];
        }
    }

    const [countries, setCountries] = useState({});

    const [multipleBankInfo, setMultipleBankInfo] = useState(parseInfo("multipleBankInfo"));
    const [multipleFiscalInfo, setMultipleFiscalInfo] = useState(parseInfo("multipleFiscalInfo"));
    const [changedInfo, setChangedInfo] = useState(0);
    const [landlordProperties, setLandlordProperties] = useState([]);
    const [newIbanModal, setNewIbanModal] = useState(false);
    const [loadingProperties, setLoadingProperties] = useState(true);

    useEffect(() => {
        if (query?.newIban === "true") {
            setNewIbanModal(true);
        }
    }, [])

    useEffect(() => {
        (async () => {
            let _countries = {};
            (await dataService.getCountries()).map(c => _countries[c.iso2.toUpperCase()] = t('country.' + c.iso2));
            setCountries(_countries);
        })();

        (async () => {
            if(user?.integration_landlord) {
                let _integrations = (await propertyServices.getLandlordIntegrations(0, {}, 2000));
                setLandlordProperties(_integrations.integrations);
            } else {
                setLandlordProperties((await propertyServices.getCachedPropertiesList()).filter(property => property.finished));
            }
            setLoadingProperties(false);
        })();
    }, [])

    const BillingDetailsForms = ({ data, setMultipleInfo, multipleInfo, number, isBankInfo, landlordProperties, countries, loadingProperties }) => {

        const [isLoading, setIsLoading] = useState(false);
        const [isChecked, setIsChecked] = useState(true);
        const [info, setInfo] = useState(data);
        const [showSuccessMessage, setShowSuccessMessage] = useState(false);
        const [showAssociatedPropertiesModal, setShowAssociatedPropertiesModal] = useState(false);
        const [drawerStates, setDrawerStates] = useState({});

        const { handleSubmit, register, errors, formState, reset, setError, control } = useForm({ shouldFocusError: true });

        const submit = async (values) => {
            setIsLoading(true);
            let type = isBankInfo ? "multipleBankInfo" : "multipleFiscalInfo";
            let newInfo = user[type] || [];
            try {
                let index = number - 1 > newInfo.length + 1 ? newInfo.length : number - 1;
                let formData;
                newInfo[index] = info;
                if(!user[type]) user[type] = [];
                user[type][index] = info;
                if (isBankInfo) {
                    formData = new FormData();
                    Object.keys(newInfo[index]).filter(k => k !== "proofDocument").map(key => {
                        let value = newInfo[index][key];
                        if (Array.isArray(value)) {
                            value.forEach(val => {
                                formData.append(`${key}[]`, val);
                            });
                        } else {
                            formData.append(key, value);
                        }
                    });
                    formData.append("bank_info_id", newInfo[index].id);
                }
                reset(isBankInfo ? await userServices.saveBankInfo(formData) : await userServices.saveFiscalInfo(newInfo[index]));
                setMultipleInfo(newInfo);
                setShowSuccessMessage(true);
            } catch (e) {
                console.log(e)
                if (e.validationErrors) {
                    e.validationErrors.forEach(err => {
                        setError(err.param, {type: "string", message: t("error." + err.msg)});
                    })
                }
            }
            setIsLoading(false);
        };

        const changeInfo = (newData) => {
            let currentData = user[isBankInfo ? "multipleBankInfo" : "multipleFiscalInfo"]?.[number - 1] || {};
            if ((currentData?.vat !== newData.vat || currentData?.country !== newData.country) && currentData?.vatValidated) newData.vatValidated = false;
            if ((currentData?.iban !== newData.iban) && currentData?.valid) newData.valid = false;
            setInfo(newData);
        }

        const handleRemove = (id) => async () => {
            setIsLoading(true);
            try {
                reset(isBankInfo ? await userServices.deleteBankInfo(info.id) : await userServices.deleteFiscalInfo(info.id));
                setMultipleInfo(prev => (prev.filter(info => info.id !== id)))
            } catch (e) {
                if (e.validationErrors) {
                    e.validationErrors.forEach(err => {
                        setError(err.param, {type: "string", message: t("error." + err.msg)});
                    })
                }
            }
            setIsLoading(false);
        }

        const handleMakeDefault = (id) => async () => {
            setIsLoading(true);
            try {
                reset(isBankInfo ? await userServices.makeDefaultBankInfo(id) : await userServices.makeDefaultFiscalInfo(id));
                setMultipleInfo(prev => {
                    prev.forEach(info => {
                        info.default = info.id === id;
                    })
                    return prev;
                })
            } catch (e) {
                console.log("erro", e);
                if (e.validationErrors) {
                    e.validationErrors.forEach(err => {
                        setError(err.param, {type: "string", message: t("error." + err.msg)});
                    })
                }
            }
            setIsLoading(false);
        }

        const associatedProperties = useMemo(() => {
            if (!info.default) {
                return landlordProperties.filter(property => info?.propertyList?.includes(property.id));
            } else {
                const excludedProperties = user[isBankInfo ? "multipleBankInfo" : "multipleFiscalInfo"]?.filter(info => !info.default)?.map(info => info?.propertyList).flat();
                return landlordProperties.filter(property => !excludedProperties?.includes(property.id));
            }
        }, [info?.propertyList, landlordProperties, info?.default])

        const uploadProofDocument = async (e) => {
            if (e.target.files.length < 0) return;
            let file = e.target.files[0];
            setInfo(prev => ({...prev, proofDocumentUrl: file}))
        }

        const handleFileUploadClick = (number) => {
            document.getElementById("proofDocumentUrl" + number).click();
        }

        const removeProofDocument = async () => {
            setIsLoading(true);
            reset(await userServices.removeBankInfoDocument(info));
            setIsLoading(false);
        }

        const toggleAssociatedProperty = (propertyId) => {
            if (!info.hasOwnProperty("propertyList") || !Array.isArray(info.propertyList)) {
                info.propertyList = [];
            }

            // Check if propertyId is in the propertyList
            const isInPropertyList = info.propertyList.includes(propertyId);

            if (isInPropertyList) {
                // Remove the property ID if it's in the list
                const updatedPropertyList = info.propertyList.filter(id => id !== propertyId);
                changeInfo({ ...info, propertyList: updatedPropertyList });
            } else {
                // Add the property ID if it's not in the list
                const updatedPropertyList = [...info.propertyList, propertyId];
                changeInfo({ ...info, propertyList: updatedPropertyList });
            }
        }

        const toggleDrawer = (drawerId) => {
            setDrawerStates((prevState) => ({
                ...prevState,
                [drawerId]: !prevState[drawerId],
            }));
        };

        return (
            <div className={`billing-details-form ${isBankInfo && "margin-bottom"}`}>
                {isLoading && <Loading/>}
                <div className="billing-details-form__header">
                    <h5 className="billing-details-form__header__title">
                        {isBankInfo ? t("billing_details.bank_details") : t("billing_details.bill_details")} {!info?.default && number}
                    </h5>
                    {info?.default ?
                        <h4 className={"billing-details-form__header__default"}>{t("billing_details.default")}</h4> :
                        <div className="billing-details-form__header__not-default">
                            <button type={'button'}
                                disabled={!info.id || !info.fiscalName || !info.country || (isBankInfo ? !info.iban : !info.vat) || (!data.default && !info?.propertyList?.length) || (!isChecked && isBankInfo)}
                                onClick={handleMakeDefault(info.id)}
                                className="btn btn-secondary">
                                <p>{t('buttons.make_default')}</p>
                            </button>
                            <button className="btn btn-primary--danger"
                                onClick={handleRemove(info.id)}
                            >
                                <TrashIcon />
                                <p>{t('billing_details.remove')}</p>
                            </button>
                        </div>
                    }
                </div>

                <form className="billing-details-form__container" onSubmit={handleSubmit(submit)}>
                    <div className="form-field-container">
                        <label htmlFor="name">{t("billing_details.name")}</label>
                        <div className="form-field-container__input">
                            <input name="fiscalName" type="text"
                                value={info?.fiscalName}
                                onChange={(e) => changeInfo({ ...info, fiscalName: e.target.value })}
                                placeholder={t("billing_details.name")}
                                ref={register({ required: true })}
                            />
                            {errors.fiscalName &&
                                <p className={"error_message"}>{errors.fiscalName.message}</p>
                            }
                            <div className="form-field-container__input__icon-wrapper">
                                <InputPencilIcon />
                            </div>
                        </div>
                    </div>

                    {!isBankInfo &&
                        <FlagAndInput countries={countries} field={"vat"} info={info} register={register}
                            errors={errors} setInfo={changeInfo} />
                    }

                    {!isBankInfo && <div className="form-field-container">
                        <label htmlFor="name">{t("billing_details.address")}</label>
                        <div className="form-field-container__input">
                            <input name="address" type="text"
                                value={info?.address}
                                onChange={(e) => changeInfo({ ...info, address: e.target.value })}
                                className={`input_field ${errors.address ? 'input_error' : ''}`}
                                placeholder={t("billing_details.address")}
                            />
                            {errors.address && <p className={"error_message"}>{errors.address.message}</p>}
                            <div className="form-field-container__input__icon-wrapper">
                                <InputPencilIcon />
                            </div>
                        </div>
                    </div>}

                    {isBankInfo && <>
                        <FlagAndInput countries={countries} field={"iban"} info={info} register={register}
                            errors={errors} setInfo={changeInfo} />
                        <div className="form-field-container">
                            <label htmlFor="proofDocumentUrl">
                                {t("billing_details.choose_proof")}
                                <p>{t("billing_details.proof_info")}</p>
                            </label>
                            <div className={`file-drop-container ${info.proofDocumentUrl && "file-submitted"}`}>
                                <div className="file-drop-container__upload-box">
                                    <div className="file-drop-container__details" onClick={() => handleFileUploadClick(number)}>
                                        {!info.proofDocumentUrl ? <UploadIcon /> : <PaperClipIcon />}
                                        <input type="file" className="file-drop-container__input" id={"proofDocumentUrl" + number} accept="image/*,.pdf, application/pdf" onChange={uploadProofDocument} />
                                        <span>
                                            {info.proofDocumentUrl ? (info.proofDocumentName ? shortenFileName(info.proofDocumentName) : t("billing_details.file_submitted")) : t("billing_details.no_file_chosen")}
                                        </span>
                                    </div>
                                    <div className="file-drop-container__action">
                                        {info.proofDocumentUrl && <button
                                            className="btn btn-primary--danger"
                                            onClick={() => removeProofDocument()}
                                        >
                                            <TrashIcon />
                                            <p>{t('billing_details.remove')}</p>
                                        </button>}
                                    </div>
                                </div>
                            </div>
                            {isBankInfo && <div className={'checkBox_container'}>
                                <CheckBox value={isChecked}
                                    generalClassName={'transparent'}
                                    onChange={() => setIsChecked(!isChecked)}
                                    label={t("billing_details.correct_iban")}
                                />
                            </div>}
                        </div>
                    </>
                    }

                    {associatedProperties &&
                        <div className="form-field-container">
                            <div className="form-field-container__properties">
                                <p>
                                    {loadingProperties ? "Loading..." :
                                        (associatedProperties.length > 0 ? `${associatedProperties.length} ${t("billing_details.properties_associated")}` : t("billing_details.none_associated"))
                                    }
                                </p>
                            </div>
                        </div>
                    }

                    {isMobile ? <Drawer isOpen={drawerStates["associatedProperties"]} onClose={() => toggleDrawer("associatedProperties")}>
                        <div className="modal">
                            <div className="modal__container">
                                <div className="modal__container__header">
                                    <div className="modal__container__header__title">
                                        {associatedProperties.length > 0
                                            ? `${associatedProperties.length} ${t("billing_details.properties_associated")}`
                                            : t("billing_details.none_associated")}

                                    </div>
                                </div>
                                {/* <div className="select-all-associated">
                                    <CheckBox value={selectAllPropertiesToggle}
                                        generalClassName={'transparent'}
                                        onChange={toggleSelectAll}
                                        label={t("billing_details.associate_all")}
                                    />
                                </div> */}

                                <div className="associated-properties">
                                    {landlordProperties.map((property) => {
                                        const isAssociated = associatedProperties.some((associated) => associated.id === property.id);

                                        if (info?.default && !isAssociated) return;

                                        return (
                                            <div key={property.id} className="associated-properties__card">
                                                <div className="associated-properties__card__photo">
                                                    <img src={property.integration_version ? [...(property.room_photos||[]), ...(property.photos||[])][0] : property.photos?.featured_0?.[0]?.md} />
                                                </div>

                                                <div className={`associated-properties__card__content ${isAssociated && "associated"}`}>
                                                    <div className="associated-properties__card__content__info">
                                                        <h5>{property.internal_name||property.internalName}<br />{property.address}</h5>
                                                        <p>
                                                            {`${t(`accommodation_type.${property.accommodation}`)} in ${property.region}`}
                                                            <br />
                                                            T{property.bedrooms || (property.rooms && Object.values(property.rooms).length)} · {`${property.bedrooms} ${t("accommodation_type.rooms")}`}
                                                        </p>
                                                    </div>
                                                    <button className={`btn ${isAssociated ? "btn-ghost" : "btn-primary"}`}
                                                        onClick={() => !info.default && toggleAssociatedProperty(property.id)}
                                                    >
                                                        {(isAssociated || info?.default) ? <>
                                                            <CheckmarkIcon />{t("billing_details.associated")}
                                                        </> : t("billing_details.associate")}
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </Drawer> : <Dialog
                        onClose={() => setShowAssociatedPropertiesModal(false)}
                        className="modal"
                        maxWidth={"xs"}
                        open={!!showAssociatedPropertiesModal}>
                        <div className="modal">
                            <div className="modal__container">
                                <div className="modal__container__header">
                                    <div className="modal__container__header__title">
                                        {associatedProperties.length > 0
                                            ? `${associatedProperties.length} ${t("billing_details.properties_associated")}`
                                            : t("billing_details.none_associated")}

                                    </div>
                                        <CloseIcon onClick={() => setShowAssociatedPropertiesModal(false)} />
                                    </div>

                                    <div className="associated-properties">
                                        {landlordProperties.map((property) => {
                                            const isAssociated = associatedProperties.some((associated) => associated.id === property.id);

                                            if (info?.default && !isAssociated) return;

                                            return (
                                                <div key={property.id} className="associated-properties__card">
                                                    <div className="associated-properties__card__photo">
                                                        <img src={property.integration_version ? [...(property.room_photos||[]), ...(property.photos||[])][0] : property.photos?.featured_0?.[0]?.md} />
                                                    </div>

                                                    <div className={`associated-properties__card__content ${isAssociated && "associated"}`}>
                                                        <div className="associated-properties__card__content__info">
                                                            <h5>{property.internal_name||property.internalName}<br />{property.address}</h5>
                                                            <p>
                                                                {`${t(`accommodation_type.${property.accommodation}`)} in ${property.region}`}
                                                                <br />
                                                                T{property.bedrooms || (property.rooms && Object.values(property.rooms).length)} · {`${property.bedrooms} ${t("accommodation_type.rooms")}`}
                                                            </p>
                                                        </div>
                                                        <button className={`btn ${isAssociated ? "btn-ghost" : "btn-primary"}`}
                                                            onClick={() => !info.default && toggleAssociatedProperty(property.id)}
                                                        >
                                                            {(isAssociated || info?.default) ? <>
                                                                <CheckmarkIcon />{t("billing_details.associated")}
                                                            </> : t("billing_details.associate")}
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                    </Dialog>}

                    <div className={`save-info-container ${isBankInfo && "double"}`}>
                        <button type="button"
                            className="btn btn-secondary"
                            onClick={() => !loadingProperties && isMobile ? toggleDrawer("associatedProperties") : setShowAssociatedPropertiesModal(true)}
                        >
                            {!info.default ? t("billing_details.associate_properties") : t("billing_details.see_properties")}
                        </button>
                        <button type={'submit'}
                            disabled={!info.fiscalName || !info.country || (isBankInfo ? !info.iban : !info.vat) || (!data.default && !info?.propertyList?.length) || (!isChecked && isBankInfo)}
                            className={'btn btn-primary'}
                        >
                            <FloppyDiskIcon />
                            {t('buttons.save')}
                        </button>

                        {/* <Dialog onClose={() => setEmailSentModal(false)}
                            className="modal"
                            maxWidth="xs"
                            open={newIbanModal}>
                            <DialogContent>
                                <div className="modal__container">
                                    <div className="modal__container__header--no-title">
                                        <CloseIcon onClick={() => setEmailSentModal(false)} />
                                    </div>
                                    <div className="modal__container__content">
                                        <div className="info-validated">
                                            <EmailSentIllustration />
                                            <div className="info-validated__content">
                                                <h3>{t("billing_details.iban_modal.title")}</h3>
                                                <p><Trans t={t} i18nKey="billing_details.iban_modal.content" /></p>
                                            </div>
                                            <button className="btn btn-primary" onClick={() => setEmailSentModal(false)}>
                                                {t("billing_details.iban_modal.button")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </DialogContent>
                        </Dialog> */}
                    </div>
                </form>
            </div>
        )
    }

    const AddMore = ({ isBankInfo }) => {

        const [loading, setLoading] = useState(false);

        const handleAdd = async () => {
            setLoading(true);
            try {
                if (isBankInfo) {
                    let response = await userServices.addBankInfo();
                    let newMultipleBankInfo = JSON.parse(response?.Attributes?.multipleBankInfo?.S);
                    let newBankInfo = newMultipleBankInfo?.[newMultipleBankInfo.length - 1];
                    setMultipleBankInfo(prev => ([...prev, {"id": newBankInfo?.id, "default": newBankInfo?.default}]))
                } else {
                    let response = await userServices.addFiscalInfo();
                    let newMultipleFiscalInfo = JSON.parse(response?.Attributes?.multipleFiscalInfo?.S);
                    let newFiscalInfo = newMultipleFiscalInfo?.[newMultipleFiscalInfo.length - 1];
                    setMultipleFiscalInfo(prev => ([...prev, {
                        "id": newFiscalInfo?.id,
                        "default": newFiscalInfo?.default
                    }]))
                }
            } catch (e) {
                console.log(e)
            }
            setLoading(false);
        }

        return (
            <>
                {loading ?
                    <Loading relative /> :
                    <div className="btn-container">
                        <div className="btn btn-tertiary" onClick={handleAdd}>
                            <CircleAddIcon />
                            {t("billing_details.add_more_details")}
                        </div>
                    </div>
                }
            </>
        )
    }

    let lastBankInfo = multipleBankInfo?.[multipleBankInfo?.length - 1];
    let lastFiscalInfo = multipleFiscalInfo?.[multipleFiscalInfo?.length - 1];

    return (
        <>
            <div className="billing-details" data-changed={changedInfo}>
                <MyAccountNav pages={props.pages}/>
                <div className="billing-details__container">
                    <div className="billing-details__container__card">
                    {multipleBankInfo?.map((bankInfo, idx) => {
                        if (bankInfo.id) return <BillingDetailsForms data={bankInfo}
                                                                     setMultipleInfo={(newInfo) => {
                                                                         setMultipleBankInfo(newInfo);
                                                                         setChangedInfo(i => i+1);
                                                                     }}
                                                                     multipleInfo={multipleBankInfo}
                                                                     landlordProperties={landlordProperties}
                                                                     loadingProperties={loadingProperties}
                                                                     number={idx + 1}
                            isBankInfo key={"bankingInfo-" + idx} countries={countries} />
                    })}
                    {(multipleBankInfo.length === 0 || (lastBankInfo?.iban && lastBankInfo?.fiscalName && lastBankInfo?.country)) &&
                            <AddMore isBankInfo />}
                    </div>
                    <div className="billing-details__container__card">
                    {multipleFiscalInfo?.map((fiscalInfo, idx) => {
                        if (fiscalInfo.id) return <BillingDetailsForms data={fiscalInfo}
                                                                       setMultipleInfo={(newInfo) => {
                                                                           setMultipleFiscalInfo(newInfo);
                                                                           setChangedInfo(i => i+1);
                                                                       }}
                                                                       multipleInfo={multipleFiscalInfo}
                                                                       landlordProperties={landlordProperties}
                                                                       loadingProperties={loadingProperties}
                                                                       number={idx + 1} key={"fiscalInfo-" + idx} countries={countries}/>
                    })}
                    {(multipleFiscalInfo.length === 0 || (lastFiscalInfo?.vat && lastFiscalInfo?.fiscalName && lastFiscalInfo?.address && lastFiscalInfo?.country)) &&
                            <AddMore />}
                    </div>
                </div>
            </div>
            <Dialog onClose={() => setNewIbanModal(false)}
                className="modal"
                maxWidth="xs"
                open={newIbanModal}>
                <DialogContent>
                    <div className="modal__container">
                        <div className="modal__container__content">
                            <div className="info-validated">
                                <EmailSentIllustration />
                                <div className="info-validated__content">
                                    <h3>{t("billing_details.iban_modal.title")}</h3>
                                    <p><Trans t={t} i18nKey="billing_details.iban_modal.content" /></p>
                                </div>
                                <button className="btn btn-primary" onClick={() => setNewIbanModal(false)}>
                                    {t("billing_details.iban_modal.button")}
                                </button>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default BillingDetails;
