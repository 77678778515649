import React from "react";

import { userConstants } from "../constants";
import { userServices } from "../../services";

export const userActions = {
    getUser,
  userUpdateValues,
  recoverPassword
};

function sucess(user) {
    return {
        type: userConstants.LOGIN_SUCESS,
        payload: {user},
    };
}

// function login(email, password, redirect, errorr) {
//     return async (dispatch) => {
//         dispatch(request(email));
//
//         try {
//             const {data: user, redirect: red} = await login(email, password);
//             dispatch(sucess(user));
//             if (errorr === "signup") {
//                 if ((!user.email_verified || !user.phone_verified || (!user.has_password && !user.googleId) || !user.phone) && !user.impersonateOriginal) {
//                     redirect("/start/verification")
//                 } else {
//                     redirect("/my_ads/published?account_created")
//                 }
//             } else {
//                 redirect(red)
//             }
//         } catch (e) {
//             if (errorr && errorr !== "signup") {
//                 errorr(e.message || [e]);
//             }
//         }
//     };
//
//     function request(email) {
//         return {type: userConstants.LOGIN_REQUEST, payload: {email}};
//     }
//
//     function fail(error) {
//         return {type: userConstants.LOGIN_FAIL, payload: {error}};
//     }
// }
//
//
// function logout() {
//     return async (dispatch) => {
//         await userServices.logout();
//
//         dispatch({type: userConstants.LOGOUT});
//     };
// }
//
function getUser() {
    return async (dispatch) => {
        try {
            const user = await userServices.getUser();
            dispatch(userProfile(user));
        } catch (e) {
            dispatch(fail(e));
        }
    };

    function userProfile(user) {
        return {type: userConstants.GET_USER, payload: {user}};
    }

    function fail(error) {
        return {type: userConstants.LOGIN_FAIL, payload: {error}};
    }
}

function signup(user, success, error) {
    return async (dispatch) => {
        dispatch(request(user.email));

        try {
            const signedUpUser = await userServices.signup(user);
            dispatch(sucess(signedUpUser));
            success && success();
        } catch (e) {
            if (error) {
                error(e.response?.data || [e]);
            }
        }
    };

    function request(email) {
        return {type: userConstants.SIGNUP_REQUEST, payload: {email}};
    }

    function sucess(user) {
        return {
            type: userConstants.SIGNUP_SUCESS,
            payload: {user},
        };
    }

}

function userUpdateValues(values) {
    return (dispatch) => {
        dispatch({type: userConstants.UPDATE_VALUES, payload: values})
    };
}

async function recoverPassword(email) {
    await userServices.recoverPassword(email);
}
