import React, {useEffect, useState} from "react";
import {usePopper} from 'react-popper';
import arrowDownIcon from "../../components/ResumeItem/images/arrowDownIcon.svg"
import arrowDownBlueIcon from "./images/arrowDownBlueIcon.svg"

import './style.less';
import ReactDOM from "react-dom";

const DropdownInput = (props) => {
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);

    const [value, setValue] = useState(props.value || props.defaultValue);
    const [open, setOpen] = useState(!!props.openDropdown);

    const {styles, attributes} = usePopper(referenceElement, popperElement, {
        modifiers: [{
            name: 'offset',
            options: {
                offset: props.offset,
            },
        },],
        placement: props.placement,
        strategy: 'fixed'
    });

    const onChange = (value) => {
        if (typeof props.value === "undefined" || props.changeOnClose) {
            setValue(value);
        } else {
            props.onChange && props.onChange(value);
        }
        if (props.closeOnChange) {
            close();
        }
    };

    const onChangeObject = (field, val) => {
        if (typeof props.value === "undefined" || props.changeOnClose) {
            setValue(_value => ({..._value, [field]: val}));
        } else {
            props.onChange && props.onChange({...value, [field]: val});
        }
        if (props.closeOnChange) {
            close();
        }
    };

    const openDropdown = () => {
        if (!open) {
            setOpen(true);
            props.onOpen && props.onOpen();
        }

        // Trigger click on overlays
        let overlays = document.getElementsByClassName("outside-click-listener");
        for (let overlay of overlays)
            overlay.click();
    };

    const close = () => {
        if(props.changeOnClose && props.onChange && value !== props.defaultValue) {
             props.onChange(value);
        }
        props.onClose && props.onClose();
        setOpen(false);
    }

    useEffect(() => {
        if (typeof props.value !== "undefined")
            setValue(props.value);
    }, [props.value]);

    useEffect(() => {
        setOpen(!!props.openDropdown);
    }, [props.openDropdown]);

    return <div className={"DropdownInput "+(props.wrapperClassName)}>
        {open && <div className={"outside-click-listener"} onClick={close}/>}
        <div ref={setReferenceElement} className={"trigger " + props.triggerStyle + (props.triggerStyle === "button" ? " btn btn-alternative btn-shadow btn-dropdown" : "")}
             onClick={openDropdown}
        >
            {props.valueRenderer(value)}
            {!props.hideChevron && <img src={open ? arrowDownBlueIcon : arrowDownIcon} style={{transform: open ? "scale(-1)" : ""}}/>}
        </div>
        {(props.usePortal ? ReactDOM.createPortal : ((r)=>r))(<div ref={setPopperElement} style={styles.popper}
                                                                   className={"dropdown " + props.dropdownClassName + (props.strippedDropdown ? " stripped": "") + (props.mobileFix ? ' dates-mobile-fix' : '') + (open ? " open" : "")}>
            {props.renderDropdown(value, (props.objectValue ? onChangeObject : onChange), close)}
        </div>, document.getElementById('root'))}
    </div>
};


DropdownInput.defaultProps = {
    triggerStyle: 'button',
    closeOnChange: false,
    changeOnClose: false,
    objectValue: false,
    mobileFix: false,
    usePortal: false,
    wrapperClassName: '',
    strippedDropdown: false,
    placement: "bottom-start",
    offset: [0, 15]
};

export default DropdownInput;
