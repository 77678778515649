import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import SignUpContainer from "./containers/SignUpContainer";

import './style.less'
import HorizontalNavBar from "../../components/HorizontalNavBar";
import LoginContainer from "./containers/LoginContainer";
import RecoverContainer from "./containers/RecoverContainer";
import IntroductionContainer from "./containers/IntroductionContainer";
import {userActions} from "../../redux/actions";
import VerificationContainer from "./containers/VerificationContainer";
import bgImage1 from "./images/bg_image_main.jpg";
import bgImage2 from "./images/bg_image_verification.jpg";
import {useAuth} from "../../services/authServices";

const pages = [
    {
        label: "start.login.nav",
        path: "login",
        component: LoginContainer,
    },
    {
        label: "start.signup.nav",
        path: "signup",
        component: SignUpContainer,
    },
    {
        path: "recover",
        component: RecoverContainer,
    },
    {
        path: "verification",
        component: VerificationContainer,
    }
];

const Start = () => {

    const {user, onLoaded} = useAuth();
    const history = useHistory();
    const [image, setImage] = useState(bgImage1)

    useEffect(() => {
        (async () => {
            let user = await onLoaded();
            if (user) {
                if ((!user.email_verified || !user.phone_verified || (!user.has_password && !user.googleId && !user.facebookId) || !user.phone) && !user.impersonateOriginal) {
                    history.push('/start/verification');
                } else {
                    history.push('/dashboard');
                }
            }
        })();
    }, [user])

    let pathSplit = location.pathname?.split("/");
    let currentPath = pathSplit[pathSplit?.length - 1];

    return (
        <>
            <div className="Start">
                <div className="background-introduction-container">
                    <div className="background" style={{ backgroundImage: `url(${image})`, filter: image === bgImage2 ? "unset" : "" }} />
                    <div className="introduction">
                        {image === bgImage1 && <IntroductionContainer />}
                    </div>
                </div>

                <div className="auth-container">
                    <HorizontalNavBar path={"/start"} pages={pages} basicContainer={true} type={'start_page'} />
                </div>
            </div>
        </>
    );
};


export default Start;
