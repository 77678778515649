import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from 'react-i18next';
import "./style.less";
import MyAccountNav from "../MyAccountNav";
import rightArrow from "../../../../../../components/PageHeader/images/rightArrowIcon.svg"
import {dashboardServices, userServices} from "../../../../../../services";
import {useAuth} from "../../../../../../services/authServices";
import momenttz from "moment-timezone";
import ChevronRightIcon from "../../../../../../components/svg/Navigation/ChevronRight";

const Notifications = (props) => {

    const {t} = useTranslation();
    const {user, setUser} = useAuth();

    const [activeOption, setActiveOption] = useState("ads")

    const notificationSections = {
        ads: ["validatedAds", "updateAds"],
        reservations: ["reservationRequests", "reservationCancellations", "reservationReminders", "checkInReminders", "reservationConfirmations"],
        visits: ["visitRequests", "visitConfirmations", "visitCancellations", "visitReminders"],
        offers: ["potentialTenants"],
        marketing: ["newsletter"] //subscribe mailchimp
    }

    let defaultNotificationBlocks = {};
    Object.values(notificationSections).forEach(array => {
        array.forEach(section => {
            defaultNotificationBlocks[section] = section === "newsletter" ? {"email": false} : {
                "push": false,
                "email": false,
                "sms": true
            }
            if(["visitRequests"].includes(section)) {
                defaultNotificationBlocks[section]["sms"] = false;
                defaultNotificationBlocks[section]["phone"] = false;
            }
            if(["reservationRequests", "reservationReminders"].includes(section)) {
                defaultNotificationBlocks[section]["sms"] = false;
                defaultNotificationBlocks[section]["phone"] = false;
                defaultNotificationBlocks[section]["whatsapp"] = false;
            }
        })
    })

    const [notificationBlocks, setNotificationBlocks] = useState(user?.notificationBlocks || defaultNotificationBlocks)
    const [disableButton, setDisableButton] = useState(false);


    const handleNotificationChange = async (section, type) => {
        if (Object.values(notificationBlocks[section]).filter(v => v).length === 2 && !notificationBlocks[section][type]) return;
        let nextNotificationBlocks = {
            ...notificationBlocks,
            [section]: {
                ...notificationBlocks[section],
                [type]: !notificationBlocks[section][type]
            }
        };
        setNotificationBlocks(nextNotificationBlocks);
        setUser({...user, notificationBlocks: nextNotificationBlocks})
        await userServices.updateNotificationBlocks(nextNotificationBlocks);
        if (section === "newsletter") {
            setDisableButton(true)
            let subscribe = notificationBlocks[section][type];
            if (subscribe) {
                await userServices.subscribeMailchimp();
            } else {
                await userServices.unsubscribeMailchimp();
            }
            setDisableButton(false)
        }
    }
    //retirar assim que o problema do calendario for resolvido
    useEffect(() => {
        (async () => {
            console.log("daily calendar", await dashboardServices.getDailyCalendar(new Date()));
            await dashboardServices.getMontlyCalendar(new Date()).then((data) => console.log(data.filter(d => momenttz(d.sortDate).isSame(momenttz(new Date(), "Europe/Lisbon"), 'day', []))));
        })();
    }, [user]);

    return (
        <div className={"MyAccount__Notifications"}>
            <MyAccountNav pages={props.pages}/>
            <div className="Notifications__container">
                <div className={"MyAccount__Notifications__options-nav"}>
                    <h5>{t("notification_settings.title")}</h5>
                    <div className="options__nav">
                        {Object.keys(notificationSections).map(option => {
                            return <div key={option}
                                onClick={() => setActiveOption(option)}
                                className={`notification__nav ${activeOption === option && "active"}`}
                            >
                                <h5>{t("notification_settings." + option + ".title")}</h5>
                                {activeOption !== option && <ChevronRightIcon />}
                            </div>
                        })}
                    </div>
            </div>
            <div className={"MyAccount__Notifications_sections"}>
                {notificationSections[activeOption]?.map(section => {
                    return <div className={"section"} key={activeOption + "-" + section}>
                        <h5>{t("notification_settings." + activeOption + "." + section)}</h5>
                        <div className="section__options">
                            {["push", "email", "sms", "phone", "whatsapp"].map(type => {
                                if ((type === "phone" || type === "whatsapp") && !["reservationRequests", "reservationReminders"].includes(section)) return;
                            if(section === "newsletter" && type !== "email" || type === "phone") return;
                                return <div className="option" key={activeOption + "-" + section + "-" + type}>
                                {t("notification_settings." + type)}
                                <div className="switch">
                                    <input type="checkbox" id={"toggle-" + section + "-" + type}
                                           onChange={() => handleNotificationChange(section, type)}
                                           checked={!notificationBlocks[section][type]}
                                           disabled={(!["updateAds", "reservationReminders", "checkInReminders", "visitReminders", "potentialTenants", "newsletter"].includes(section) && type === "email") || section === "newsletter" && disableButton}
                                    />
                                    <label htmlFor={"toggle-" + section + "-" + type}/>
                                </div>
                            </div>
                            })}
                        </div>
                    </div>
                })
                }
            </div>
            </div>
        </div>
    );
};


export default Notifications;
