import React from 'react';
import {useLocation, NavLink, Redirect, Route, Switch} from 'react-router-dom';

import {useTranslation} from 'react-i18next';

import './style.less';

import Static from "../../pages/Static";
import MetaTags from "react-meta-tags";

const HorizontalNavBar = ({
                              path,
                              pages,
                              renderLinks,
                              containerClass = false,
                              pageHeader,
                              boxShadow,
                              navBarButton,
                              type = 'main',
                              basicContainer,
                              extraRoutes,
                              ...props
                          }) => {

    const {t, i18n} = useTranslation();

    path = path !== '/' ? path + '/' : path;

    const location = useLocation();

    const isActive = (path, match, location, link) => !!(match || path === location.pathname);

    if ((location.pathname).includes('/my_ads/add')) containerClass = true;

    return (
        <div className={`HorizontalNavBar ${type}`}>
            {!(location.pathname === '/' || location.pathname === '/how-to' || location.pathname === '/start/recover' || location.pathname === '/start/verification' || location.pathname === "/dashboard") &&
                (type !== "secondary" || pages.find(page => (path + page.path) === location.pathname)) && 
                <div className={`container ${type === "start_page" && "auth-container"}`}>
                    <div className={'horizontalbar-auth'}>
                        <div className={'links-auth'}>
                            {pages.filter(p => type !== 'start_page' || p.label).map((p) => (
                                <NavLink key={p.path} to={path + p.path} isActive={isActive.bind(this, path + p.path)}>
                                    <label>{t(p.label)}</label>
                                    {(type === 'secondary' || type === 'start_page') &&
                                    <span className={`underline ${isActive.bind(this, path + p.path) ? 'active' : ''}`} />}
                                </NavLink>))}
                            {renderLinks}
                         </div>
                        {/*{navBarButton && <button className={'btn btn_blue shadow'}*/}
                        {/*                         onClick={() => history.push('/my_ads/ad/create')}>{(t('buttons.create_new_ad')).toUpperCase()}*/}
                        {/*</button>}*/}
                    </div>
                </div>
            }

            {!(location.pathname === '/' ||
                location.pathname === '/how-to' ||
                location.pathname === '/start/login' ||
                location.pathname === '/start/signup' ||
                location.pathname === "/start/verification" ||
                location.pathname === "/start/recover") &&
                <div className={`white_bg ${type} ${boxShadow ? 'box_shadow' : null}`}>
                    <div className={`container`}>
                        <div className={'horizontalbar-dashboard'}>
                            <div className={'links-dashboard'}>
                                {pages.filter(p => type !== 'start_page' || p.label).map((p) => (
                                    <NavLink key={p.path} to={path + p.path} isActive={isActive.bind(this, path + p.path)}>
                                        <label>{t(p.label)}</label>
                                        {(type === 'secondary' || type === 'start_page') &&
                                            <span className={`underline ${isActive.bind(this, path + p.path) ? 'active' : ''}`} />}
                                    </NavLink>))}
                                {renderLinks}
                            </div>
                        </div>
                    </div>
            </div>}
            <div id="horizontalbar_content" className={`${containerClass ? 'container' : null} ${basicContainer?'basic_container':''}`}>
                <Switch>
                    {pages.map((p) => {
                        return <Route key={p.path} path={path + p.path} render={() => <p.component {...p} {...props} path={path + p.path}/>}/>;
                    })}
                    {extraRoutes}
                    {type === "main" ? <>
                        <Route exact path="/" render={()=><><MetaTags><meta id="metaviewport" name="viewport" content="width=device-width, initial-scale=1"/></MetaTags><Static/></>}/>
                        <Route exact path="/how-to" render={()=><><MetaTags><meta id="metaviewport" name="viewport" content="width=device-width, initial-scale=1"/></MetaTags><Static page={"/como-funciona-landlords"}/></>}/>
                    </> : <Redirect to={path + pages[0].path}/>}
                    <Route path="/" render={() => <>
                        <MetaTags>
                            {/*<meta id="metaviewport" name="viewport" content="width=1280, initial-scale=0.1"/>*/}
                            <meta name="title" content={t('inlife_title_default')}/>
                            <title>{t('inlife_title_default')}</title>
                        </MetaTags>
                    </>}/>
                </Switch>
            </div>
        </div>
    );
};

export default HorizontalNavBar;
