import "./style.less";
import React, { useEffect, useState } from "react";
import bannerLeftImage from "./images/banner-left-image.png";
import downloadPlayStore from "./images/google-play.png";
import downloadAppleStore from "./images/app-store.png";
import Cookie from "cookie";
import { isMobile } from "../../utils/responsive";
import { useTranslation } from "react-i18next";

const isIOS = () => /(iPhone|iPad|iPod|Macintosh)/.test(navigator.userAgent);

const playStoreUrl =
  "https://play.google.com/store/apps/details?id=com.inlifehousing.landlord";

const appStoreUrl = "https://apps.apple.com/en/app/inlife-for-landlords/id1604123237";

const AppBanner = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModalHandler = () => setModalIsOpen(true);
  const closeModalHandler = () => setModalIsOpen(false);

  const setCookie = () => {
    document.cookie = Cookie.serialize("dont_show_banner", true);
    closeModalHandler();
  };

  const download = () => {
    if (isIOS()) {
      window.open(appStoreUrl, "_blank");
    } else {
      window.open(playStoreUrl, "_blank");
    }
  
    document.cookie = Cookie.serialize("dont_show_banner", true);

    closeModalHandler();
  };

  useEffect(() => {
    const cookies = Cookie.parse(document.cookie);
    if (!cookies["dont_show_banner"]) {
      openModalHandler();
    }
  }, []);

  return isMobile ? (
    modalIsOpen && (
      <MobileBannerContent
        onClose={closeModalHandler}
        onSetCookie={setCookie}
        download={download}
      />
    )
  ) : (
    <div>
      {modalIsOpen && (
        <BannerContent onClose={closeModalHandler} onSetCookie={setCookie} download={download} />
      )}
    </div>
  );
};

const MobileBannerContent = ({ onClose, download }) => {
  const { t } = useTranslation();

  return (
    <div className="app-banner">
      <div onClick={onClose} className="close-btn">
        &times;
      </div>
      <div style={{ height: "85%" }}>
        <img
          src={bannerLeftImage}
          alt="Screenshots of App"
          className="phones-img"
        />
        <div className="banner-text">
          <h5>{t("app_banner.new_app_for_landlords")}</h5>
          <p className="app-description">{t("app_banner.app_description")}</p>
        </div>
      </div>
      <a className="download-btn" onClick={download}>
        <img
          width="150px"
          src={isIOS() ? downloadAppleStore : downloadPlayStore}
          alt="Apple Logo"
        />
      </a>
    </div>
  );
};

const BannerContent = ({ onClose, onSetCookie, download }) => {
  const { t } = useTranslation();

  return (
    <div className="modal-backdrop">
      <div className="modal-container">
        <div className="modal-image-section">
          <img
            className="left-image"
            src={bannerLeftImage}
            alt="Phone screens"
          />
        </div>
        <div className="modal-content-section">
          <button className="modal-close-button" onClick={onClose}>
            ×
          </button>
          <h2 className="modal-title">
            {t("app_banner.new_app_for_landlords")}
          </h2>
          <h4 className="modal-description">
            {t("app_banner.app_description")}
          </h4>
          <div className="modal-feature-list">
            <ul>
              <li>
                <span className="checkmark checked"></span>{t("app_banner.app_bullet1")}
              </li>
              <li>
                <span className="checkmark checked"></span>{t("app_banner.app_bullet2")}
              </li>
              <li>
                <span className="checkmark checked"></span>{t("app_banner.app_bullet3")}
              </li>
            </ul>
          </div>
          <div className="download-section">
            <div className="download-buttons">
              <a href={appStoreUrl} target="_blank">
                <img src={downloadAppleStore} alt="Download on the App Store" />
              </a>
              <a href={playStoreUrl} target="_blank">
                <img src={downloadPlayStore} alt="Get it on Google Play" />
              </a>
            </div>
            <button className="download-button" onClick={download}>
              {t("app_banner.download_now")}
            </button>
            <a onClick={onSetCookie} className="dismiss-link">
              {t("app_banner.i_dont_want_to_see")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppBanner;
