import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';

import './style.less';
import Buttons from '../../../../../components/Buttons';
import {useForm} from 'react-hook-form';
import RadioButtons, {StyledRadio} from '../../../../../components/RadioButtons';
import PriceComponent from '../../../../../components/PriceComponent';
import {FieldSetCheckBoxes} from '../../../../../components/FieldSetCheckBoxes';
import {updatePropertyActions} from "../../../../../../../../../../../redux/actions/updatePropertyActions";
import AmountButtonsInput from "../../../../../components/AmountButtonsInput/index.js";
import Dialog from "@material-ui/core/Dialog";
import {DialogContent, FormControlLabel, RadioGroup} from "@material-ui/core";
import {Trans} from "react-i18next";

import updateBookingWindowIcon from '../../../../../../../../../../../components/ActionDialog/images/booking_window.svg';
import finishedBookingWindowIcon from '../../../../../../../../../../../components/ActionDialog/images/booking_window_done.svg';
import {useAuth} from "../../../../../../../../../../../services/authServices.js";
import Loading from "../../../../../../../../../../../components/Loading/index.js";
import {propertyServices} from "../../../../../../../../../../../services/index.js";

const pageLabel = 'main_page.my_ads.create_ad.house_details.landlord_rules.level5';

const getInitialRequiredDocumentsSwitch = (property) => {
    if (property.requiredDocuments?.length > 0) return 'true';
    else if (property.level === '1.2.5' || property.level === '1.2.4') return 'true';
    else return 'false';
};

const LandlordRules5 = ({updateProperty, t, currentLevel, property, changePage, defineUpdateFunc}) => {

    //Initial values
    const [currentProperty, setCurrentProperty] = useState(() => {
        return {
            ...property,
            contractType: property.hasOwnProperty('contractType') ? ""+property.contractType : 'traditional',
            hasBookingWindow: property.hasOwnProperty('hasBookingWindow') ? property.hasBookingWindow : false,
            bookingWindow: property.hasOwnProperty('bookingWindow') ? property.bookingWindow : null,
            extraAdministrativeFeeSwitch: property.hasOwnProperty("extraAdministrativeFeeType") ? (property.extraAdministrativeFeeType !== "no").toString() : "false",
            extraAdministrativeFeeType: property.hasOwnProperty("extraAdministrativeFeeType") ? property.extraAdministrativeFeeType : (property.extraAdministrativeFee > 0 ? "specific_value" : "no"),
            extraAdministrativeFee: property.hasOwnProperty('extraAdministrativeFee') ? property.extraAdministrativeFee : 0,
            requiredDocumentsSwitch: getInitialRequiredDocumentsSwitch(property),
            requiredDocuments: property.requiredDocuments ? property.requiredDocuments : ['cc', 'passport']
        }
    });

    const [extraFee, setExtraFee] = useState(property.extraAdministrativeFee);
    const [extraAdministrativeFeeSwitch, setExtraAdministrativeFeeSwitch] = useState(currentProperty.extraAdministrativeFeeSwitch);

    const handlePropertyFieldChange = (field, value) => {
        setCurrentProperty(prevState => ({
            ...prevState,
            "extraAdministrativeFee": value === "no" && field === "extraAdministrativeFeeType" ? 0 : prevState.extraAdministrativeFee,
            [field]: value
        }));
        if ((value === "no" || value === "specific_value") && field === "extraAdministrativeFeeType") {
            setExtraFee(null);
            setValue("extraAdministrativeFee", null);
        }
    };

    const handleAdminFeeSwitch = (field, value) => {
        setExtraAdministrativeFeeSwitch(value)
        if (!value) handlePropertyFieldChange("extraAdministrativeFeeType", "no");
    };

    const {handleSubmit, register, errors, watch, control, formState, setValue} = useForm({shouldFocusError: true});

    const watchFields = watch(['extraAdministrativeFeeType', 'extraAdministrativeSwitch', 'requiredDocumentsSwitch', 'extraAdministrativeFee', 'hasBookingWindow', 'bookingWindow']);

    const [updateBookingModalOpen, setUpdateBookingModalOpen] = useState(false);
    const bookingWindowUpdatePromiseRef = useRef(null);

    const openUpdateBookingWindowAllPropertiesModal = async () => {
        return new Promise((resolve) => {
            setUpdateBookingModalOpen(true);
            bookingWindowUpdatePromiseRef.current = resolve;
        });
    }

    const UpdateBookingWindowModal = () => {
        const [updateAllBookingWindowProperties, setUpdateAllBookingWindowProperties] = useState(null);
        const [updatingProperties, setUpdatingProperties] = useState(false);
        const [currentUpdatingProperty, setCurrentUpdatingProperty] = useState(0);
        const [finishedUpdatingProperties, setFinishedUpdatingProperties] = useState(false);

        const {user} = useAuth();

        const handleUpdateBookingWindow = async () => {
            if (updateAllBookingWindowProperties === "yes") {
                setUpdatingProperties(user?.houses?.length);
                for (let i = 0; i < user?.houses?.length; i++) {
                    setCurrentUpdatingProperty(i);
                    if(user?.houses[i] !== property.id) {
                        await propertyServices.enableBookingWindow(user?.houses[i], watchFields.bookingWindow);
                    }
                }
                setFinishedUpdatingProperties(true);
            } else {
                setUpdateBookingModalOpen(false);
                bookingWindowUpdatePromiseRef.current?.(false);
            }
        }

        return <Dialog open={updateBookingModalOpen} onClose={() => setUpdateBookingModalOpen(false)} className={`UpdateBookingWindowModal ActionDialog`} maxWidth={"xs"}>
            {finishedUpdatingProperties ? <DialogContent className={"ActionDialog__content"}>
                <img src={finishedBookingWindowIcon}/>
                <div className={"title"}>{t(pageLabel + ".bookingWindow.finished.title")}</div>
                <small className={"small-desc"}><Trans i18nKey={pageLabel + ".bookingWindow.finished.desc"}
                                                       values={{days: watchFields.bookingWindow}}
                                                       components={{blue: <span style={{color: "#57B4F2"}}/>}}
                /></small>
                <div className={"buttons_container"}>
                    <button className={"btn btn-primary"} onClick={()=>{
                        setUpdateBookingModalOpen(false);
                        bookingWindowUpdatePromiseRef.current?.(false);
                    }}>{t('buttons.continue')}</button>
                </div>
            </DialogContent> : (updatingProperties ? <DialogContent className={"ActionDialog__content"}>
                <div className={"title"}>{t(pageLabel + ".bookingWindow.updating")}</div>
                <h1>{(currentUpdatingProperty+1)} / {updatingProperties}</h1>
                <Loading relative={true}/>
            </DialogContent> : <DialogContent className={"ActionDialog__content"}>
                <img src={updateBookingWindowIcon}/>
                <div className={"title"}>{t(pageLabel + ".bookingWindow.modal.title")}</div>
                <small className={"small-desc"}><Trans i18nKey={pageLabel + ".bookingWindow.modal.desc"}
                              values={{days: watchFields.bookingWindow}}
                              components={{blue: <span style={{color: "#57B4F2"}}/>}}
                /></small>
                <div className={"apply-input-radio"}>
                    <strong className={"apply-input-radio_label"}>{t(pageLabel + ".bookingWindow.modal.label")}</strong>
                    <div className={"RadioButtons"}>
                        <RadioGroup onChange={(v) => {
                            setUpdateAllBookingWindowProperties(v.target.value);
                        }}>
                            <FormControlLabel value="yes" control={<StyledRadio />} label={t(pageLabel + ".bookingWindow.modal.option_yes")} />
                            <FormControlLabel value="no" control={<StyledRadio />} label={t(pageLabel + ".bookingWindow.modal.option_no")} />
                        </RadioGroup>
                    </div>
                </div>

                <div className={"buttons_container"}>
                    <button className={"btn btn-secondary"} onClick={() => {
                        setUpdateBookingModalOpen(false);
                        bookingWindowUpdatePromiseRef.current?.(true);
                    }}>{t('buttons.cancel')}</button>
                    <button className={"btn btn-primary"} disabled={updateAllBookingWindowProperties == null}
                            onClick={handleUpdateBookingWindow}>{t('buttons.confirm')}</button>
                </div>
            </DialogContent>)}
        </Dialog>
    }

    useEffect(() => defineUpdateFunc(async () => new Promise(async (resolve) => {
        if (!formState.isDirty) return resolve();
        if(!currentProperty.hasBookingWindow && watchFields.hasBookingWindow === "true" || (currentProperty.bookingWindow !== watchFields.bookingWindow && typeof watchFields.bookingWindow !== "undefined")) {
            if(await openUpdateBookingWindowAllPropertiesModal()) return resolve(true);
        }
        await handleSubmit(async (values) => {
            await onSubmit(values);
            resolve()
        }, resolve)()
    })), [currentProperty, watchFields.hasBookingWindow, formState.isDirty]);

    const radioButtonsHasBookingWindow = [
        {id: 'has_booking_window_yes', buttonValue: 'true', label: 'yes'},
        {id: 'has_booking_window_no', buttonValue: 'false', label: 'no'}
    ];

    const radioButtonsContractType = [{
        id: 'traditional',
        buttonValue: 'traditional',
        label: pageLabel + '.contractType.traditional.label',
        info: pageLabel + '.contractType.traditional.info',
        styleLabel: {flex: 0.5}
    },
    {
        id: 'renting',
        buttonValue: 'renting',
        label: pageLabel + '.contractType.renting.label',
        info: pageLabel + '.contractType.renting.info',
        styleLabel: {flex: 0.5}
    },
    {
        id: 'undefined',
        buttonValue: 'undefined',
        label: pageLabel + '.contractType.undefined.label',
        info: pageLabel + '.contractType.undefined.info',
        styleLabel: {flex: 0.5}
    }];


    const radioButtonsExtraAdministrativeSwitch = [{id: 'extra_administrative_yes', buttonValue: 'true', label: 'yes'},
        {id: 'extra_administrative_no', buttonValue: 'false', label: 'no'}];

    const radioButtonsExtraAdministrativeFee = ["1/2month", "1month", "3/2months", "2months", "5/2months", "3months", "specific_value"].map(type => {
        let label = 'main_page.my_ads.create_ad.house_details.landlord_rules.level4';
        return {
            id: type,
            buttonValue: type,
            label: type === "no" ? type : label + ".securityDeposit." + type
        };
    });

    const radioButtonsRequiredDocumentsSwitch = [{id: 'required_documents_yes', buttonValue: 'true', label: 'yes'},
        {id: 'required_documents_no', buttonValue: 'false', label: 'no'}];

    let requiredDocumentsOptions = [{value: 'cc', label: t(pageLabel + '.requiredDocuments.cc')},
        {value: 'passport', label: t(pageLabel + '.requiredDocuments.passport')},
        {value: 'visa', label: t(pageLabel + '.requiredDocuments.visa')},
        {value: 'contract', label: t(pageLabel + '.requiredDocuments.contract')},
        {value: 'university', label: t(pageLabel + '.requiredDocuments.university'), styleLabel: {width: '66%'}}];

    const onSubmit = async (values) => {
        values.requiredDocuments = values.requiredDocumentsSwitch === 'true' ? currentProperty.requiredDocuments : [];
        values.extraAdministrativeFee = values.extraAdministrativeFeeType === "no" ? 0 : values.extraAdministrativeFee || 0;
        values.extraAdministrativeFeeType = values.extraAdministrativeSwitch === 'true' ? (values.extraAdministrativeFeeType || "specific_value") : "no";
        values.hasBookingWindow = values.hasBookingWindow === 'true';
        await updateProperty(values, currentLevel);
    };

    useEffect(() => {
        if (errors) {
            const anchor = document.querySelector(`#${Object.keys(errors)[0]}`);
            if (anchor) anchor.scrollIntoView({behavior: 'smooth', block: 'center'})
        }
    }, [errors]);

    return (
        <div className={'LandlordRules5'}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={'scrollable'}>

                    <div className="LandlordRules_container">
                        <div className={'input_container'}>
                            <label htmlFor={'contractType'}>{`${t(pageLabel + '.contractType.label')}`}</label>
                            <RadioButtons radioButtons={radioButtonsContractType} name={'contractType'}
                                          value={currentProperty.contractType}
                                          t={t} className={'margin_bottom'}
                                          control={control} rules={{required: true}} type={'info longLabel'}/>
                        </div>
                    </div>

                    <div className="LandlordRules_container">
                        <div className={'input_container has-booking-window-input'}>
                            <label htmlFor={'hasBookingWindow'}>{`${t(pageLabel + '.bookingWindow.hasBookingWindowLabel')}`}</label>
                            <p className={"booking-window-info"}>{t(pageLabel + '.bookingWindow.hasBookingWindowInfo')}</p>
                            <RadioButtons radioButtons={radioButtonsHasBookingWindow} name={'hasBookingWindow'}
                                          watchValue={watch('hasBookingWindow')}
                                          value={currentProperty.hasBookingWindow}
                                          t={t} className={'margin_bottom'}
                                          control={control}/>
                        </div>
                        {(((typeof watchFields.hasBookingWindow) === "undefined") ? currentProperty.hasBookingWindow : (watchFields.hasBookingWindow === "true")) && <div className={'input_container'}>
                            <label htmlFor={'bookingWindow'}>{`${t(pageLabel + '.bookingWindow.label')}`}</label>
                            <AmountButtonsInput
                                name={'bookingWindow'} value={currentProperty.bookingWindow} placeholder={t(pageLabel + '.bookingWindow.placeholder')}
                                min={1}
                                amount={1}
                                units={t(pageLabel + '.bookingWindow.days')}
                                id={'bookingWindow'}
                                className={`input_field`} watchValue={watch('bookingWindow')}
                                setValue={setValue}
                                register={register({
                                    valueAsNumber: true
                                })}/>
                        </div>}
                    </div>

                    <div className="LandlordRules_container">
                        <div className={'input_container'}>
                            <label
                                htmlFor={'extraAdministrativeFeeSwitch'}>{`${t(pageLabel + '.extraAdministrativeFee.property_label')}`}</label>
                            <RadioButtons id={"extraAdministrativeSwitch"}
                                          radioButtons={radioButtonsExtraAdministrativeSwitch}
                                          name={'extraAdministrativeSwitch'}
                                          value={currentProperty.extraAdministrativeFeeSwitch}
                                          setValue={handleAdminFeeSwitch} t={t} className={'triple'}
                                          control={control} rules={{required: true}}/>
                            {extraAdministrativeFeeSwitch === "true" &&
                                <RadioButtons radioButtons={radioButtonsExtraAdministrativeFee}
                                              name={'extraAdministrativeFeeType'}
                                              value={currentProperty.extraAdministrativeFeeType}
                                              setValue={handlePropertyFieldChange} t={t} className={'triple'}
                                              control={control} rules={{required: true}}/>}
                            {extraAdministrativeFeeSwitch === "true" &&
                                <>
                                    <label
                                        style={{marginTop: "5px"}}>{t('main_page.my_ads.create_ad.house_details.landlord_rules.' + (watchFields.extraAdministrativeFeeType === "specific_value" ? 'level4.securityDeposit.specific_value' : 'additional_value')) + (!["no", "specific_value"].includes(watchFields.extraAdministrativeFeeType || "no") ? " (+ " + t('main_page.my_ads.create_ad.house_details.landlord_rules.level4.securityDeposit.' + watchFields.extraAdministrativeFeeType).toLowerCase() + ")" : "") + ":"}</label>

                                    <PriceComponent
                                        name="extraAdministrativeFee"
                                        value={currentProperty.extraAdministrativeFee}
                                        disabled={watchFields.extraAdministrativeFeeType === "no"}
                                        error={errors.extraAdministrativeFee}
                                        placeholder={watchFields.extraAdministrativeFeeType === "specific_value" ?
                                            t('main_page.my_ads.create_ad.house_details.landlord_rules.level4.securityDeposit.specific_value') :
                                            "0"
                                        }
                                        forceShowTag={watchFields.extraAdministrativeFeeType !== "specific_value"}
                                        watchValue={extraFee}
                                        register={register}
                                        onChange={value => setExtraFee(value)}
                                        rules={watchFields.extraAdministrativeFeeType === 'specific_value' ? {
                                            required: watchFields.extraAdministrativeFeeType === "specific_value" ? {
                                                value: true,
                                                message: t(pageLabel + '.extraAdministrativeFee.error')
                                            } : false,
                                            validate: value => value > 0 || t(pageLabel + '.extraAdministrativeFee.error')
                                        } : undefined}/>
                                </>}
                        </div>
                    </div>

                    <div className="LandlordRules_container LandlordRules_container_last">
                        <div className={'input_container'}>
                            <label
                                htmlFor={'requiredDocumentsSwitch'}>{`${t(pageLabel + '.requiredDocuments.label')}`}</label>
                            <RadioButtons radioButtons={radioButtonsRequiredDocumentsSwitch}
                                          name={'requiredDocumentsSwitch'}
                                          value={currentProperty.requiredDocumentsSwitch}
                                          setValue={handlePropertyFieldChange} t={t} className={'triple'}
                                          control={control} rules={{required: true}}/>
                            {currentProperty.requiredDocumentsSwitch === "true" &&
                                <div className={'input_checkboxes input_container'}>
                                    <FieldSetCheckBoxes checkBoxesNamesMapped={requiredDocumentsOptions}
                                                        name={'requiredDocuments'}
                                                        register={register({validate: value => currentProperty.requiredDocuments.length > 0 || t(pageLabel + '.requiredDocuments.error')})}
                                                        values={currentProperty.requiredDocuments}
                                                        setValues={(value) => handlePropertyFieldChange('requiredDocuments', value)}/>
                                    {errors.requiredDocuments && currentProperty.requiredDocuments < 1 &&
                                        <p className={'error_message'}>{errors.requiredDocuments.message}</p>}
                                </div>}
                        </div>

                    </div>
                </div>

                <Buttons saveAndExit={handleSubmit(v => onSubmit({...v, saveAndExit: true}))} changePage={changePage}/>
            </form>
            <UpdateBookingWindowModal/>
        </div>
    );
};

const actionCreator = {
    defineUpdateFunc: updatePropertyActions.defineUpdateFunc
};

export default connect(null, actionCreator)(LandlordRules5);
