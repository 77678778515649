import React from 'react';
import {useDropzone} from 'react-dropzone';
import uploadIconGrey from '../../../../../../../../images/upload_icon_grey.svg';
import {useTranslation} from 'react-i18next';

const getClassName = (className, isActive) => {
  if (!isActive) return className;
  return `${className} ${className}-active`;
};

const Dropzone = ({onDrop, accept, images, areaProps, hasPlaceholder, id}) => {
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    accept
  });

  const {t} = useTranslation();

  return (
    <div className={getClassName('ChooseImages__dropzone', isDragActive)} {...getRootProps()}>
      <input className="ChooseImages__dropzone-input" {...getInputProps()} />
      {Object.keys(images).length === 0 && hasPlaceholder && (
        <button className="ChooseImages__change-image" type="button">
          <div className="ChooseImages__change-image__title-container">
            <label
              className="ChooseImages__change-image__title">{`${areaProps.title.toUpperCase()}${areaProps.isAreaNotUnique ? ' ' + (+areaProps.divNum + 1) : ''}`}</label>
            {areaProps.info && <p className="ChooseImages__change-image__info">{areaProps.info}</p>}
          </div>
          <div className="ChooseImages__change-image__placeholder">
            <div className="ChooseImages__change-image__placeholder--icon center">
              <img src={uploadIconGrey}/>
            </div>
            <div className="ChooseImages__change-image__info">
              <label>{t('dropzone.add_photos')}</label>
              <p>{`0 ${t('photo_plural')} ${areaProps.minPhotos ? `| ${t('minimum')} ${areaProps.minPhotos}` : ''}`}</p>
            </div>
          </div>
        </button>)}
      {(Object.keys(images).length !== 0 || !hasPlaceholder) && <img src={uploadIconGrey}/>}
    </div>
  );
};

export default Dropzone;
