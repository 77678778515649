import React from "react";

const BlueEye = (props) => (
    <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="Icon">
            <path
                id="icon/image/remove_red_eye_24px"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.666992 8C1.82033 5.07333 4.66699 3 8.00033 3C11.3337 3 14.1803 5.07333 15.3337 8C14.1803 10.9267 11.3337 13 8.00033 13C4.66699 13 1.82033 10.9267 0.666992 8ZM13.8803 8C12.7803 5.75333 10.527 4.33333 8.00033 4.33333C5.47366 4.33333 3.22033 5.75333 2.12033 8C3.22033 10.2467 5.46699 11.6667 8.00033 11.6667C10.5337 11.6667 12.7803 10.2467 13.8803 8ZM8.00033 6.33333C8.92033 6.33333 9.66699 7.08 9.66699 8C9.66699 8.92 8.92033 9.66667 8.00033 9.66667C7.08033 9.66667 6.33366 8.92 6.33366 8C6.33366 7.08 7.08033 6.33333 8.00033 6.33333ZM5.00033 8C5.00033 6.34667 6.34699 5 8.00033 5C9.65366 5 11.0003 6.34667 11.0003 8C11.0003 9.65333 9.65366 11 8.00033 11C6.34699 11 5.00033 9.65333 5.00033 8Z"
            />
        </g>
    </svg>
);

export default BlueEye;
