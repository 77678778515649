export const transformAvailabilityPeriods = (availabilities, locale) => {

    return availabilities?.map((availability) => {
        const { move_in, move_out } = availability;

        return {
            added: availability.added,
            move_in: {
                start: {
                    day: new Date(move_in[0]).getDate(),
                    month: new Date(move_in[0]).getMonth() + 1
                },
                end: {
                    day: new Date(move_in[1]).getDate(),
                    month: new Date(move_in[1]).getMonth() + 1
                }
            },
            move_out: {
                start: {
                    day: new Date(move_out[0]).getDate(),
                    month: new Date(move_out[0]).getMonth() + 1
                },
                end: {
                    day: new Date(move_out[1]).getDate(),
                    month: new Date(move_out[1]).getMonth() + 1
                }
            },
            temporary_year: availability.temporary_year
        };
    });
}
