import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {NavLink, useHistory} from "react-router-dom";

import "./style.less";
import SocialAccountButtons from "../../components/SocialAccountButtons";

import {useAuth} from "../../../../services/authServices";
import useQuery from "../../../../utils/query";
import ActionDialog from "../../../../components/ActionDialog";

const LoginContainer = () => {
    const {t, i18n} = useTranslation();

    const auth = useAuth();
    const history = useHistory();
    const query = useQuery();
    const [newEmail, setNewEmail] = useState(false);


    let [errosReq, setErrosReq] = useState([]);

    const onSubmit = async (values) => {
        setErrosReq([]);
        try {
            await auth.login(
                values.email.trim(),
                values.password,
                setErrosReq
            );
            history.push("/dashboard");
        } catch (e) {
            //history.push("/start/login");
            setErrosReq(["err-invalid_user"]);
        }

    }

    useEffect(() => {
        if (query?.newEmail === "true") {
            setNewEmail(true)
        }
    }, []);

    const {handleSubmit, register, errors} = useForm({shouldFocusError: true});


    return (
        <div className="LoginContainer">
            {/* <h3>{t("start.login.label")}</h3> */}

            <SocialAccountButtons/>
            <div className="with-email-separator-container">
                <hr className="separator-line" />
                <p>{t("start.signup.orEmail")}</p>
                <hr className="separator-line" />
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={"base-info"}>
                    <div className="input-container">
                        <label htmlFor={"email"}>{`${t("start.signup.email")}`}</label>
                        <input
                            name="email"
                            placeholder={`${t("start.signup.email_placeholder")}`}
                            className={`${(errosReq.includes("err-invalid_user") || errors.email) ? "input_error" : null}`}
                            ref={register({
                                required: `${t("start.signup.required.email")}`,
                                pattern: {
                                    value: /^[\s]*[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}[\s]*$/i,
                                    message: `${t("start.signup.pattern.email")}`,
                                },
                            })}
                        />
                        {errors.email && <p className={"error_message"}>{errors.email.message}</p>}
                    </div>
                    <div className="input-container">
                        <div className={"login__password-recover"}>
                            <label htmlFor={"password"}>{`${t("start.signup.password")}`}</label>
                        </div>
                        <input
                            name="password"
                            type="password"
                            placeholder={`${t("start.signup.password_placeholder")}`}
                            className={`${(errosReq.includes("err-invalid_user") || errors.password) ? "input_error" : null}`}
                            ref={register({
                                required: `${t("start.signup.required.password")}`,
                                minLength: {
                                    value: 6,
                                    message: `${t("start.signup.min_length.password")}`,
                                },
                            })}
                        />
                        {errors.password && <p className={"error_message"}>{errors.password.message}</p>}
                    </div>
                    {errosReq.includes("err-invalid_user") &&
                        <p className={"error_message"}>{t('error.invalid_login')}</p>}

                    <button type="submit" className={"btn btn-primary"}>{t("start.login.button")}</button>
                </div>
            </form>
            <NavLink to={"/start/recover"} className="blue-link-label">
                {t("start.login.recover_password")}
            </NavLink>
            <div id="actions">
                <p>{t("start.login.have_account")}</p>
                <NavLink to="signup" className="blue-link-label">{t("start.login.signup")}</NavLink>
            </div>
            <ActionDialog type={"success"} open={newEmail} setOpen={setNewEmail}
                          title={t("action_dialog.new_email_login")}
                          message={t("action_dialog.new_email_login_message")}/>
        </div>
    )
}


export default LoginContainer;
