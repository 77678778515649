import React from "react";

const Separator = (props) => (
    <svg
        width={112}
        height={4}
        viewBox="0 0 112 4"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect width={112} height={4} rx={2} fill="#EEF7FE" />
    </svg>
);

export default Separator;
