const grind = (raw) => {
    if(raw === null || raw === undefined) return "";
    return ("" + raw)
        .normalize('NFKD')
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[^\w\s_]/g, '')
        .toLowerCase()
};

const clampText = (text, length) => {
    if (!text || text.length < length) return text;
    return text.substring(0, length).trim() + '...';
};

const redactPhoneAndEmail = (text) => {
    return text.replace(
        /(\+\d{1,3}[\s-]?)?(\d{3}[\s.-]?)?(\d{3}[\s.-]?)?(\d{4}|\d{3}\s\d{3}\s\d{3})(\s*x\d+)?|\S+@\S+\.\S+/g,
        (match) => {
            if (match.match(/\S+@\S+\.\S+/)) {
                return '*****@****.**';
            } else {
                return '**********';
            }
        }
    );
};

const shortenFileName = (fileName, maxLength = 10) => {
    if (fileName.length <= maxLength) {
        return fileName;
    } else {
        return fileName.substring(0, maxLength) + '...';
    }
}

export { grind, clampText, redactPhoneAndEmail, shortenFileName };
