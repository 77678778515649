import React from "react";

import {propertyConstants} from '../constants';

export const updatePropertyActions = {
  defineUpdateFunc,
}

function defineUpdateFunc(updateProperty) {
  return (dispatch) => {
    dispatch({ type: propertyConstants.UPDATE_FUNCTION,payload:{updateProperty}});
  };
}


