import React from "react";

const ArrowLeft = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="chevron_left" clipPath="url(#clip0_2344_12239)">
            <path
                id="Vector"
                d="M15.41 7.41L14 6L8 12L14 18L15.41 16.59L10.83 12L15.41 7.41Z"
                fill="#1D9AFA"
            />
        </g>
        <defs>
            <clipPath id="clip0_2344_12239">
                <rect width={24} height={24} fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default ArrowLeft;
