import React, {useMemo} from 'react';
import {connect} from 'react-redux';

import bannerIcon from './images/bannerIcon.svg'
import locationIcon from './images/locationIcon.svg'
import ascArrowIcon from './images/ascArrowsIcon.svg'
import descArrowsIcon from './images/descArrowsIcon.svg'
import indicatorStrengthIcon from './images/indicatorStrengthIcon.svg'
import percentageGoalIcon from './images/percentageGoalIcon.svg'
import timeGoalIcon from './images/timeGoalIcon.svg'
import risingStarIcon from './images/risingStarIcon.svg'
import topLandlordIcon from './images/topLandlordIcon.svg'
import newLandlordIcon from './images/newLandlordIcon.svg'
import './style.less'
import {useTranslation} from 'react-i18next';
import {useAuth} from "../../../../../../services/authServices";

const componentLabel = 'main_page.dashboard.top_landlord'

const TopLandlordHeader = () => {
  const {t} = useTranslation();

  return (
    <div className={'TopLandlordHeader'}>
      <img src={topLandlordIcon}/>
      <h4>{t(componentLabel + '.title')}</h4>
      <p>{t(componentLabel + '.description')}</p>
    </div>
  )
}

const ProgressBarWithIcons = ({indicator, value = '3h44'}) => {

  const {t, i18n} = useTranslation();

  const objective = indicator.objective === 80 ? 80 : 50;

  const completedPercentage = indicator.current ?  (indicator.current < indicator.max ? (indicator.current / indicator.max * 100 ) : 102.6) :0;

  const completedPercentageLabel= useMemo(()=> {
      if(indicator.type.includes('time')) {
        const timeArrayAux = indicator?.current?.toString()?.split('.');
        if (timeArrayAux?.length > 1) {
          timeArrayAux[1]=(timeArrayAux[1]*0.6).toString().substring(0, 2);
        }
        return timeArrayAux.join('h');
      }
      else return indicator.current + '%'
    }
    ,[indicator])

  const styles = {
    completed: {
      width: `${completedPercentage ? completedPercentage : 0}%`
    },
    currentPositionIcon: {
      left: `calc(${completedPercentage ? completedPercentage : 0}% - 33px)`
    },
    goalPositionIcon: {
      left: `calc(${objective}% - 13px)`
    }
  }

  return (
    <div className={'ProgressBarWithIcons center'}>
      <div className={`ProgressBarWithIcons__bar ${!completedPercentage ? 'no_data' : ''}`} style={styles.bar}>
        <span className={'ProgressBarWithIcons__completed'} style={styles.completed}/>
      </div>
      <div className={'goalAchieved center'} style={styles.goalPositionIcon}>
        <div className={'ProgressBarIcon center'}>
          <img src={bannerIcon}/>
        </div>
      </div>

      <div className={'currentPosition'} style={styles.currentPositionIcon}>
        <h6>{t('you')}</h6>

        <div className={'ProgressBarIcon center'}>
          <img src={locationIcon}/>
        </div>

        {completedPercentage ?
          <h5>{completedPercentageLabel}</h5> : <p className={'small'}>{t('no_data')}</p>}

      </div>

      <img className={'arrowIcons'} src={indicator.direction ? descArrowsIcon : ascArrowIcon}/>
    </div>
  )
}

//type has 2 possible values "percentage" or "time"
//indicatorStrength has 3 possible values "rising_star" or "top_landlord" or "new_lanlord"
const TopLandlordIndicatorContainer = (
  {
    indicator,
    goalLabel = '80%'
  }) => {

  const {t} = useTranslation();

  return (
    <div className={'TopLandlordIndicatorContainer basic_container'}>
      <div>
        <div className={'TopLandlordIndicatorBox'}>
          <h6>{t(componentLabel + '.indicator_label')}</h6>
          <p className={'small'}>{t(componentLabel + '.indicators.' + indicator.type)}</p>
        </div>
          {indicator.strength &&
            <div className={'TopLandlordIndicatorBox indicatorStrength'}>
              <h6>{t(componentLabel + '.indicator_strength_label')}</h6>
              <div>
                  <p className={'small'}>{t(componentLabel + '.rank.' + indicator.strength + '.designation')}</p>
                      <img src={indicatorStrengthIcon}/>
              </div>
            </div>}
        <div className={'TopLandlordIndicatorBox'}>
          <h6>{t(componentLabel + '.goal_label')}</h6>
          <div>
            <p className={'small'}>{`${indicator.direction?'<':''}${indicator.objective}${indicator.type.includes('time')?'h':'%'}`}</p>
            <img src={indicator.type.includes('time') ? timeGoalIcon : percentageGoalIcon}/>
          </div>
        </div>
      </div>
      <ProgressBarWithIcons indicator={indicator}/>
    </div>
  )
}

const TopLandlordRank = ({rank, user}) => {
  const {t} = useTranslation();

  const icon = rank === 'top_landlord' ? topLandlordIcon : rank === 'rising_star' ? risingStarIcon : newLandlordIcon;

  return (
    <div className={'TopLandlordRank basic_container'}>
      <div className={`DashboardIcon ${rank}`}>
        <img src={icon}/>
      </div>
      <h5>{`${user} ${t(componentLabel + '.rank.' + rank + '.title')}`}</h5>
      <p>{t(componentLabel + '.rank.' + rank + '.description')}</p>
    </div>
  )
}

const TopLandlordSection = ({user, indicators, status}) => {


  return (
    <div className={'TopLandLordSection container'}>
      <TopLandlordHeader/>

      <div className={'TopLandlordIndicators'}>
        {indicators?.map(indicator => (
          <TopLandlordIndicatorContainer key={indicator.type} indicator={indicator} rank={indicators?.indicator_global}/>
        ))}
      </div>

        {status && <TopLandlordRank rank={status} user={user?.name?.split(' ')?.[0]}/>}
    </div>
  )
}



export default TopLandlordSection;
