import React, { useState, useRef } from "react";
import "./style.less";

import useOutsideClick from "../../../hooks/useOutsideClick";
import ExpandMoreIcon from "../../svg/ExpandMore";
import ExpandLessIcon from "../../svg/ExpandLess";

const DropdownWindow = ({ title, placeholder, children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => setIsOpen(!isOpen);
    const dropdownRef = useRef(null);

    useOutsideClick(dropdownRef, () => {
        setIsOpen(false);
    });

    const childArray = React.Children.toArray(children);
    const contentElement = childArray.find((child) => child.type === DropdownWindow.Content);

    return (
        <div className="dropdown-window" ref={dropdownRef}>
            {title && <label>{title}</label>}

            <div className="dropdown__container">
                <div className={`dropdown__header ${isOpen && 'active'}`} onClick={toggleDropdown}>
                    {placeholder}
                    {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </div>

                {isOpen && (
                    <div className="window__container">
                        {contentElement.props.children}
                    </div>
                )}
            </div>
        </div>
    );
};

DropdownWindow.Content = ({ children }) => children;

export default DropdownWindow;
