import React from "react";

import {propertyConstants} from '../constants';

export const propertyAdHistoryActions = {
  push,
  pop,
  reset
}

function push(action,state) {
  if(JSON.stringify(action) !== JSON.stringify((state.history[state.history.length-1]))) {
    return (dispatch) => {
      dispatch({type: propertyConstants.HISTORY_PUSH, payload: {action}});
    };
  }
  return (dispatch) => {
    dispatch({type: propertyConstants.HISTORY_KEEP});
  };
}

function pop(number) {
  return (dispatch) => {
    dispatch({ type: propertyConstants.HISTORY_POP,payload:{number}});
  };
}



function reset() {
  return (dispatch) => {
    dispatch({ type: propertyConstants.HISTORY_RESET});
  };
}