import React from 'react';
import AmountButtonsInput from "../AmountButtonsInput";

import "./style.less";

const AmountButtonsInputContainer = ({info,errors,title,name,value,labelPreFix,t,register,type,watch,setValue, minAreasError}) => {

  return (
    <div className={`AmountButtonsInputContainer ${type}`}>
      <div>
        <label>{title?title:t(`${labelPreFix}.${name}.label`)}</label>
        <div>
            <AmountButtonsInput name={name} value={value} placeholder={"Ex 1"}
                                min={0} amount={1} watchValue={watch(name)}
                                className={`${(errors[name]||(minAreasError&&['numKitchen','numLivingRoom','numBathroom'].includes(name))) ? "input_error" : null} input_field`}
                                register={register} id={name} setValue={setValue}
            />
            {errors[name] && <p className={"error_message"}>{errors[name].message}</p>}
        </div>
      </div>
      <p id="info">{info}</p>
    </div>
  )
}

export default AmountButtonsInputContainer;
