import React from "react";

const Check = (props) => (
    <svg
        width={30}
        height={30}
        viewBox="0 0 30 30"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="check" clipPath="url(#clip0_606_257)">
            <path
                id="Vector"
                d="M11.25 20.2126L6.03745 15L4.26245 16.7625L11.25 23.75L26.25 8.75005L24.4875 6.98755L11.25 20.2126Z"
            />
        </g>
        <defs>
            <clipPath id="clip0_606_257">
                <rect width={30} height={30} fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default Check;
